<template>
    <div class="mb-3 bg-light theme-dark__bg-gray-800 border rounded">
        <div class="d-flex flex-wrap h-100">
            <div class="w-100">
                <!-- IMAGE -->
                <div class="skeleton w-100">
                    <div class="skeleton-item py-5"></div>
                </div>
                <!-- BADGES -->
                <div v-if="enableBadges" class="skeleton mt-1 px-3 pt-3 w-100">
                    <div class="skeleton-item w-25 rounded">&nbsp;</div>
                </div>
                <!-- TITLE & INFO -->
                <div class="mb-3 p-3 w-100">
                    <content-skeleton :use-title="true" />
                </div>
            </div>
            <!-- BUTTON -->
            <div class="skeleton align-self-end w-100" :class="buttonClasses">
                <div class="skeleton-item py-3"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import ContentSkeleton from '../utility/ContentSkeleton';

    export default {
        name: 'CardStandardSkeleton',
        components: {
            ContentSkeleton
        },
        props: {
            buttonClasses: {
                type: String,
                default: ''
            },
            enableBadges: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
