<template>
    <div class="container pt-3 pt-lg-5 mb-5 pb-5">
        <!-- Filters top-->
        <div class="row mb-3">
            <div class="col-12 col-lg-3 mb-2 skeleton">
                <div class="skeleton-item py-3"></div>
            </div>
            <div class="col-6 col-lg-3 mb-2 skeleton">
                <div class="skeleton-item py-3"></div>
            </div>
            <div class="col-6 col-lg-3 mb-2 skeleton">
                <div class="skeleton-item py-3"></div>
            </div>
            <div class="col-6 col-lg-3 mb-2 skeleton mx-auto">
                <div class="skeleton-item py-3"></div>
            </div>
        </div>
        <div class="row">
            <!-- Filters left -->
            <div class="col-4 d-none d-lg-block skeleton">
                <div v-for="index in 5" :key="index" class="mb-4">
                    <div class="skeleton-item mb-2 w-25"></div>
                    <div class="skeleton-item py-3"></div>
                </div>
            </div>
            <div class="col-12 col-lg-8">
                <!-- Cards -->
                <card-horizontal-skeleton v-for="index in 4" :key="index"></card-horizontal-skeleton>
            </div>
        </div>
    </div>
</template>

<script>
    import CardHorizontalSkeleton from '../cards/CardHorizontalSkeleton';

    export default {
        name: 'UsedEquipmentSkeleton',
        components: {
            CardHorizontalSkeleton
        }
    };
</script>
