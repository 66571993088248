<template>
    <div class="splide__arrows splide__arrows__rounded d-none d-md-block">
        <button type="button" class="splide__arrow splide__arrow--prev d-flex align-items-center rounded">
            <i class="fas fa-caret-left"></i>
        </button>
        <button
            type="button"
            class="splide__arrow splide__arrow--next d-flex align-items-center justify-content-end rounded"
        >
            <i class="fas fa-caret-right"></i>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'SplideRoundedArrows'
    };
</script>
