<script>
    import { optimizeImage } from '../../lib/utilities';
    import { toSlug } from '../../lib/utilities';

    export default {
        name: 'UtilitiesMixin',
        methods: {
            slugify(value) {
                return toSlug(value);
            },
            setBackground(container, backgroundImage) {
                if (!!container && !!backgroundImage) {
                    container.style.cssText = `background: url(${optimizeImage(
                        backgroundImage
                    )}); background-size: cover`;
                }
            },
            setCanonicalUrl() {
                var link = document.querySelector("link[rel='canonical']")
                    ? document.querySelector("link[rel='canonical']")
                    : document.createElement('link');

                link.setAttribute('rel', 'canonical');
                link.setAttribute('href', location.protocol + '//' + location.host + location.pathname);

                document.head.appendChild(link);
            }
        }
    };
</script>
