import axios from 'axios';

const eventAPI = '/api/default/events?$expand=EventImage,Parent&$orderby=EventStart';

export function getAllEvents() {
    return new Promise(function(resolve) {
        axios.get(eventAPI).then(function(response) {
            var events = [];
            events = response.data.value;
            resolve(events);
        });
    });
}

export function getEvent(event) {
    let apiUrl = eventAPI + `&$filter=UrlName eq '${escape(event)}'`;
    return new Promise(function(resolve) {
        axios.get(apiUrl).then(function(response) {
            var eventInfo = [];
            eventInfo = response.data.value.shift();
            resolve(eventInfo);
        });
    });
}

export function getRelatedEvents(event) {
    let relatedEvents = [];
    let apiUrl;

    if (event && event.Id) {
        var filterVal = apiUrl + `&$filter=Id ne ${event.Id}`; //TODO: Add end date validation for current and upcoming events -> EventEnd ge ${dayjs.utc().format('')} and
        let filters = [];
        if (event.City) {
            filters.push({
                name: 'City',
                value: event.City
            });
        }
        if (event.Location) {
            filters.push({
                name: 'Location',
                value: event.Location
            });
        }
        filterVal += ' and (' + filters.map(item => `${item.name} eq '${item.value}'`).join(' or ') + ')';
        apiUrl = eventAPI + `&${filterVal}`;
    }

    return new Promise(function(resolve) {
        axios.get(apiUrl).then(function(response) {
            relatedEvents = response.data.value;
            resolve(relatedEvents);
        });
    });
}

export default {
    getAllEvents,
    getEvent,
    getRelatedEvents
};
