﻿// @ts-check
import axios from 'axios';
import { getDataPage } from './lib/ODataService';

function getFromAPI(endpoint) {
    return new Promise(resolve => {
        axios.get(endpoint)
            .then(response => {
                resolve(response.data);
            });
    });
}

function getFromOData(endpoint) {
    return new Promise(resolve => {
        getDataPage(endpoint, 0, false).then(response => {
            let results = response.data.value.map(result => {
                return result;
            });
            resolve(results);
        });
    });
}

export default {
    getIndustries: () => getFromAPI('/api/BuildYourOwn/GetIndustries'),
    getCategories: industry =>
        getFromAPI(
            `/api/BuildYourOwn/GetCategories?industry=${industry}`
        ),
    getSubcategories: (industry, category) =>
        getFromAPI(
            `/api/BuildYourOwn/GetSubcategories?industry=${industry}&category=${category}`
        ),
    getSeries: (industry, category, subcategory) =>
        getFromAPI(
            `/api/BuildYourOwn/GetSeries?industry=${industry}&category=${category}&subcategory=${subcategory}`
        ),
    getSeriesCategories: (industry, category, subcategory, series) =>
        getFromAPI(
            `/api/BuildYourOwn/GetSeriesCategories?industry=${industry}&category=${category}&subcategory=${subcategory}&series=${series}`
        ),
    getMachineInfo: machine =>
        getFromOData(
            `/api/default/deeremachines?$filter=UrlName eq '${machine}'&$expand=Customization($select=Id)&$select= Id, Title, Images, ListPrice, StartingPrice, Brochure1Url, UrlName`
        ),
    getCustomizationsByIds: ids =>
        getFromOData(
            `/api/default/deerecustomizations?$expand=Image($select=Url),Icon($select=Url),Type($select=Id,UrlName,Category,SelectionType,Name)&$filter=${ids}&$select=Id,UrlName,Name,Type,Price,StartingPrice,IsDefault,LayerLevel,Description`
        ),
    getDiscountsByMachine: machine => {
        let discounts = getFromOData(
            '/api/default/dealerdiscounts?$select=Id,UrlName,DiscountType,DiscountValue,Title&$expand=Machines($select=Id,UrlName),Customizations($select=Id,Name,UrlName)'
        )

        return new Promise(resolve => {
            discounts.then(response => {
                let results = response.filter(dis => dis.Machines.some(mac => mac.UrlName == machine));

                resolve(results);
            });
        });
    }
};
