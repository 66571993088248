<template>
    <div class="d-lg-none">
        <!-- modal trigger -->
        <btn-icon
            data-toggle="modal"
            data-target="#mobile-builder-modal"
            icon-left
            icon-right="fa-chevron-right"
            icon-color="#fff"
            btn-style="btn-primary btn-block p-2"
        >
            <i class="fas fa-palette mr-2"></i>
            Customize
        </btn-icon>

        <div
            id="mobile-builder-modal"
            ref="builderMobileSidebar"
            class="modal fade builder-sidebar modal-right-pane"
            tabindex="-1"
            role="dialog"
            aria-labelledby="mobile-builder-label"
            aria-hidden="true"
        >
            <div class="modal-dialog h-100 m-0 modal-dialog-scrollable">
                <div class="modal-content rounded-0">
                    <div class="modal-header d-flex align-items-center">
                        <h4 id="mobile-builder-label" class="modal-title">Customize</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="fas fa-times-circle"></i>
                        </button>
                    </div>
                    <div class="modal-body bg-gray-100 p-0">
                        <transition name="fade" mode="out-in">
                            <div v-if="!selectedSetting">
                                <div
                                    v-for="(mainSetting, mainSettingIndex) in sidebarSettings"
                                    :key="mainSetting.Id"
                                >
                                    <div class="bg-primary p-3 d-flex align-items-center text-white">
                                        <span
                                            class="order-box bg-1 bg-white text-dark d-flex justify-content-center align-items-center rounded-circle"
                                        >
                                            {{ mainSettingIndex + 1 }}
                                        </span>
                                        <span class="ml-2 text-uppercase">{{ mainSetting.Name }}</span>
                                    </div>
                                    <div v-for="subsetting in mainSetting.options" :key="subsetting.Name">
                                        <div class="card rounded-0">
                                            <div
                                                class="card-header border-0 d-flex justify-content-between align-items-center cursor-pointer w-100"
                                                @click="setSelectedSetting(subsetting)"
                                            >
                                                <div
                                                    class="pr-2 text-truncate text-uppercase"
                                                    :title="subsetting.Name"
                                                >
                                                    {{ subsetting.Name }}
                                                </div>
                                                <i class="fas fa-chevron-right card-header-caret"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-else>
                                <div class="card rounded-0">
                                    <div
                                        class="card-header rounded-0 text-white bg-primary"
                                        @click="clearSelectedSetting"
                                    >
                                        <span class="ml-2 text-uppercase cursor-pointer">
                                            <i class="fas fa-chevron-left card-header-caret mr-2"></i>
                                            {{ selectedSetting.Name }}
                                        </span>
                                    </div>
                                    <div class="card-body p-0">
                                        <div
                                            v-for="option in selectedSetting.options"
                                            :key="option.Id"
                                            :class="[
                                                'card-body-item py-2 d-flex justify-content-between align-items-center pl-4 pr-3 cursor-pointer',
                                                { 'selected-option': option.IsSelected }
                                            ]"
                                            @click="setOption(option)"
                                        >
                                            <div class="d-flex">
                                                <div
                                                    v-if="!!option.Icon"
                                                    class="card-body-item-icon rounded-circle mr-2 flex-shrink-0"
                                                    :style="{
                                                        backgroundImage: `url(${option.Icon.Url})`
                                                    }"
                                                ></div>
                                                <div
                                                    class="card-body-item-content d-flex justify-content-between text-wrap w-100"
                                                >
                                                    {{ option.Name }}
                                                </div>
                                            </div>
                                            <i v-if="option.IsSelected" class="fas fa-check text-primary"></i>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import jq from 'jquery';
    import btnIcon from '../utility/BtnIcon.vue';

    export default {
        components: {
            'btn-icon': btnIcon
        },
        props: {
            sidebarSettings: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                selectedSetting: undefined
            };
        },
        mounted() {
            this.$refs.builderMobileSidebar.parentElement.removeChild(this.$refs.builderMobileSidebar);
            document.querySelector('body').appendChild(this.$refs.builderMobileSidebar);

            ($ => {
                $(this.$refs.builderMobileSidebar).on('hidden.bs.modal', e => {
                    this.clearSelectedSetting();
                });
            })(jq);
        },
        methods: {
            setOption(option) {
                this.$parent.setOption(option);
            },
            isFirstElement(index_main_setting, index_setting) {
                return this.$parent.isFirstElement(index_main_setting, index_setting);
            },
            setSelectedSetting(selectedSetting) {
                this.selectedSetting = selectedSetting;
            },
            clearSelectedSetting() {
                this.selectedSetting = null;
            }
        }
    };
</script>
