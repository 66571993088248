﻿<template>
    <div id="header-classic" class="header-wrapper">
        <div :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-6">
                        <store-finder :use-legacy-location-dropdown="useLegacyLocationDropdown" />
                    </div>
                    <div class="col-lg-6">
                        <brow-links class="menu-bricks float-right" />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-3 col-md-6 order-xl-1 order-md-1">
                        <span class="logo">
                            <a href="/">
                                <img :src="$_logoUrl" :alt="$_logoAlt" />
                            </a>
                        </span>
                    </div>
                    <div class="col-xl-7 col-lg-12 order-xl-2 order-md-3">
                        <mega-menu class="menu-text" />
                    </div>
                    <div class="col-xl-2 col-md-6 order-xl-3 order-md-2">
                        <span class="float-right icon-list">
                            <search-bar :icon-only="true" />
                            <saved-equipment :icon-only="true" icon-class="text-primary" />
                            <compare-equipment :icon-only="true" icon-class="text-primary" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BrowLinks from './BrowLinks';
    import CompareEquipment from '../compare-equipment/CompareEquipment';
    import MegaMenu from '../menu/MegaMenu';
    import SavedEquipment from '../saved-equipment/SavedEquipment';
    import SearchBar from '../search/SearchBar';
    import StoreFinder from './StoreFinder';

    import HeaderMixin from '../mixins/HeaderMixin';
    import ThemeMixin from '../mixins/ThemeMixin';

    export default {
        name: 'HeaderClassic',
        components: {
            BrowLinks,
            CompareEquipment,
            MegaMenu,
            SavedEquipment,
            SearchBar,
            StoreFinder
        },
        mixins: [HeaderMixin, ThemeMixin],
        props: {
            useLegacyLocationDropdown: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
