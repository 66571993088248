<!--
Styleguide page (access by running `npm run styleguide`)
Place UI / Vue components on this page to develop in isolation (without running Sitefinity)
-->
<template>
    <div class="site-wrapper">
        <div
            class="container-fluid bg-secondary py-2 text-right fixed-top border-bottom border-primary d-flex"
        >
            <nav class="nav">
                <router-link class="nav-link" to="/">Elements</router-link>
                <router-link class="nav-link" to="/cards">Cards</router-link>
                <router-link class="nav-link" to="/homepage-widgets">Homepage Widgets</router-link>
            </nav>

            <dropdown-buttons
                class="ml-auto"
                :buttons="ThemeCustomizer.primaryColors"
                :rounded="false"
                @change="ThemeCustomizer.changePrimaryColor($event, $store)"
                >Primary Color</dropdown-buttons
            >
            <dropdown-buttons
                class="ml-2"
                :buttons="ThemeCustomizer.secondaryColors"
                :rounded="false"
                @change="ThemeCustomizer.changeSecondaryColor($event, $store)"
                >Accent Color</dropdown-buttons
            >

            <div class="ml-2 btn-group-toggle" data-toggle="buttons">
                <label
                    class="btn btn-primary rounded-0"
                    @click="ThemeCustomizer.toggleDarkTheme($store, $_theme)"
                >
                    {{ $_isLightTheme ? 'Enable' : 'Disable'}} Dark Theme
                </label>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    import DropdownButtons from './components/DropdownButtons';
    import ThemeCustomizer from './styleguide/lib/theme-customizer.js';
    import ThemeMixin from './components/mixins/ThemeMixin';

    export default {
        name: 'App',
        components: {
            DropdownButtons
        },
        mixins: [ThemeMixin],
        data() {
            return {
                ThemeCustomizer
            };
        }
    };
</script>

<style scoped>
    .site-wrapper {
        padding-top: 52px;
    }
</style>
