<script>
    import NewEquipmentService from '../../../services/NewEquipmentService';
    import SavedMachineService from '../../../services/SavedMachineService';

    export default {
        methods: {
            // Only used in the New Equipment Showroom
            getNewEquipmentBySelection(selectionMode, manufacturer, level, selectedIds, options) {
                return new Promise(function(resolve, reject) {
                    if (selectionMode === 'selected' && selectedIds && selectedIds.length > 0) {
                        NewEquipmentService.getNewEquipmentByIds(manufacturer, level, selectedIds).then(
                            newEquipment => {
                                resolve(newEquipment);
                            }
                        );
                    } else {
                        // Disable recursion if listing machines to limit the amount of data that would get downloaded into the homepage.
                        NewEquipmentService.getNewEquipment(manufacturer, level, options, {
                            recursive: level != 2
                        }).then(newEquipment => {
                            resolve(newEquipment);
                        });
                    }
                });
            },
            saveMachine(machine, dataSource) {
                let savedMachine = SavedMachineService.getMachine(machine.OriginalItemId || machine.Id);
                if (savedMachine) {
                    if (!savedMachine.Compare) {
                        SavedMachineService.toggleCompare(machine);
                    } else {
                        this.$toasted.show(`${machine.Title} has already been saved.`).goAway(1500);
                    }
                } else {
                    let machineToSave = {
                        Id: machine.Id,
                        BaseUrl: machine.BaseUrl || NewEquipmentService.getShowroomUrl(dataSource),
                        Brochure1Title: machine.Brochure1Title,
                        Brochure1Url: machine.Brochure1Url,
                        Compare: true,
                        DataSource: dataSource, // Compare
                        FullUrl: NewEquipmentService.getNewEquipmentUrl(machine),
                        Images: machine.ResultImages || machine.ImageUrl || machine.Images,
                        OriginalItemId: machine.OriginalItemId || machine.Id, // Compare
                        Title: machine.Title
                    };

                    SavedMachineService.saveMachine(machineToSave);
                    this.$toasted.show(`Saved ${machineToSave.Title} to your saved equipment!`).goAway(1500);
                }
            }
        }
    };
</script>
