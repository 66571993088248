<script>
    import constants from '../../config/constants';

    export default {
        name: 'HeaderMixin',
        computed: {
            $_logoAlt() {
                return 'Header Logo';
            },
            $_logoUrl() {
                return constants.elementConfig.LOGO_URL;
            },
            $_mobileSearchPlaceholder() {
                return 'TAP HERE AND SEARCH';
            }
        },
        methods: {
            $_mobileSearch(searchTerm) {
                document.location = `/search?q=${searchTerm}`;
            }
        }
    };
</script>
