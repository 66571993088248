<template>
    <div class="container mb-5">
        <h2 class="mt-5">Cards with grid layout example</h2>
        <p>One column per row initially, 3 on medium+ devices, and 4 per row on lg+ viewports.</p>
        <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4">
            <div class="col mb-4" v-for="i in 4" :key="i">
                <card-standard
                    class="h-md-100"
                    :enable-image="true"
                    :enable-button-link="true"
                    :image="{ src: '/equipment-placeholder.jpg', alt: '...' }"
                    title="Card Title"
                    :title-size="5"
                    action-name="Learn More"
                    action-url="#"
                >
                    <p>
                        <span v-for="j in i" :key="j">
                            This is a longer card with supporting text below.
                        </span>
                    </p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </card-standard>
            </div>
        </div>

        <h2 class="mt-5">Horizontal card example</h2>
        <card-horizontal :badges="['Test']" title="Test" image="/equipment-placeholder.jpg" url="#">
            <p>
                But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain
                was born and I will give you a complete account of the system, and expound the actual
                teachings of the great explorer of the truth, the master-builder of human happiness. No one
                rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do
                not know how to pursue pleasure rationally encounter consequences that are extremely painful.
            </p>
        </card-horizontal>

        <h2 class="mt-5">Wide card example</h2>
        <p class="text-muted">This card is used to build slides for the Hero widget.</p>

        <card-wide
            title="Card Title"
            description="Card Description"
            image="/equipment-placeholder.jpg"
            mobile-image="/equipment-placeholder.jpg"
            action-name="Click Here"
            action-url="#"
        ></card-wide>
    </div>
</template>

<script>
    import cardWide from '../components/cards/CardWide';
    import cardStandard from '../components/cards/CardStandard.vue';
    export default {
        components: {
            'card-standard': cardStandard,
            'card-wide': cardWide
        }
    };
</script>