<!--
Implementation of Blaze Search for Element's showroom widget.
-->
<template>
    <eb-index
        class="px-lg-5"
        :api-key="elementConfig.BLAZE_API_KEY"
        :service-name="elementConfig.BLAZE_SERVICE_NAME"
        :index-name="elementConfig.BLAZE_INDEX_NAME"
        :default-filters="showroomFilters"
        :form-persistence-provider="formPersistenceProvider"
        :scroll-top-pagination="false"
    >
        <div ref="showroomContainer" class="element-showroom bg-white" :style="cssVars">
            <!-- items that are rendered in the mobile header -->
            <div ref="mobileHeaderItems" class="sticky-top">
                <showroom-mobile-nav :selected-item="selectionTitle"></showroom-mobile-nav>
                <!-- get a quote button is only active when a detail route is loaded -->
                <btn-icon
                    v-if="showroomItem"
                    class="d-md-none rounded-0"
                    icon-left="fa-dollar-sign"
                    data-toggle="modal"
                    data-target="#showroom-get-a-quote"
                >
                    Get a Quote
                </btn-icon>
            </div>
            <div class="d-md-flex">
                <!-- left column -->
                <div class="showroom-navigation bg-gray-100">
                    <!-- vendor logo -->
                    <div
                        :class="[
                            'showroom-vendor-logo border-bottom',
                            { 'd-none d-md-block': !showVendorLogoOnMobile }
                        ]"
                    >
                        <router-link :to="{ name: 'showroom', path: '/' }">
                            <img class="img-fluid" :src="logo" :alt="manufacturer" />
                        </router-link>
                    </div>

                    <!-- showroom search -->
                    <div class="form-group p-2 mb-0">
                        <eb-suggestions
                            id="keywords-search"
                            :form-fields-to-clear="['ResultHierarchyPath', 'p']"
                        ></eb-suggestions>
                    </div>

                    <!-- showroom desktop navigation -->
                    <showroom-nav
                        :data-source="dataSource"
                        :manufacturer="manufacturer"
                        :hierarchy-root-nodes="hierarchyRootNodes"
                        :use-dealer-hierarchy="useDealerHierarchy"
                    ></showroom-nav>
                </div>

                <!-- right column -->
                <div ref="showroomResults" class="showroom-results flex-fill pt-md-1">
                    <!-- loading overlay/icon -->
                    <div v-if="isLoading" class="showroom-loading-overlay">
                        <span class="fas fa-spinner fa-spin"></span>
                    </div>

                    <!-- breadcrumbs -->
                    <showroom-breadcrumbs :item="showroomItem"></showroom-breadcrumbs>

                    <!-- list mode / grid items -->
                    <template v-if="!$_isItemPage">
                        <div
                            class="row no-gutters align-items-center bg-gray-100 border-bottom mb-3 mb-md-0 px-2 px-md-3 pt-md-2"
                        >
                            <!-- sort options -->
                            <div class="col-sm-auto mr-xl-3">
                                <div class="form-group d-flex align-items-center mb-2">
                                    <label for="results-sort" class="text-nowrap mr-2 mb-0">Sort By</label>
                                    <eb-sort-by-dropdown
                                        id="results-sort"
                                        class="form-control form-control-md-sm"
                                        :options="sortByOptions"
                                    ></eb-sort-by-dropdown>
                                </div>
                            </div>
                            <!-- models range / count -->
                            <div
                                class="col-sm-auto ml-sm-auto ml-xl-0 mr-xl-auto mb-2 font-weight-bold text-center text-md-left text-gray-800"
                            >
                                <span v-if="!isSearching">
                                    Showing {{ resultsRange }} of {{ resultsCount }} models
                                </span>
                            </div>

                            <!-- prevent column wrapping on smaller devices -->
                            <div class="w-100 d-xl-none"></div>

                            <!-- pagination -->
                            <div class="col-lg-auto ml-lg-auto mr-lg-3">
                                <div class="form-group mb-2">
                                    <eb-pagination
                                        pagination-class="pagination pagination-md-sm justify-content-center justify-content-lg-end mb-0"
                                        item-class="page-item"
                                        :pagination-size="3"
                                    ></eb-pagination>
                                </div>
                            </div>
                            <!-- grid size selector -->
                            <div class="d-none d-lg-flex col-lg-auto align-items-center form-group mb-2">
                                <eb-results-per-page-selector />
                            </div>
                        </div>

                        <!-- items grid -->
                        <eb-results
                            :results-wrapper-class="`showroom-results-cards card-group card-grid mb-md-3 px-3 px-md-0 row-cols-sm-2 row-cols-lg-${gridSize}`"
                        >
                            <template slot-scope="{ item }">
                                <showroom-result
                                    :key="item.Id"
                                    :base-url="baseUrl"
                                    :data-source="dataSource"
                                    :item="item"
                                ></showroom-result>
                            </template>
                        </eb-results>

                        <!-- footer pagination -->
                        <eb-pagination
                            pagination-class="pagination pagination-md-sm align-self-end justify-content-center"
                            :pagination-size="3"
                        ></eb-pagination>
                    </template>

                    <!-- detail page view -->
                    <showroom-item
                        v-if="showroomItem"
                        :base-url="baseUrl"
                        :brand-color="brandColors.BrandColor"
                        :build-your-own-page="buildYourOwnPage"
                        :data-source="dataSource"
                        :manufacturer="manufacturer"
                        :item="showroomItem"
                        :show-pricing="showPricing"
                        :use-embedded-quote-form="useEmbeddedQuoteForm"
                    ></showroom-item>
                </div>
            </div>
        </div>
    </eb-index>
</template>

<script>
    import btnIcon from '../utility/BtnIcon';
    import NewEquipmentService from '../../../services/NewEquipmentService';
    import showroomBreadcrumbs from './ShowroomBreadcrumbs';
    import showroomItem from './ShowroomItem';
    import showroomMixin from './ShowroomMixin.vue';
    import showroomMobileNav from './ShowroomMobileNav';
    import showroomNav from './ShowroomNav';
    import showroomPathProvider from '../../lib/blaze-showroom-path-provider';
    import showroomResult from './ShowroomResult';
    import { toSlug } from '../../lib/utilities';
    import utilities from '../mixins/UtilitiesMixin';

    export default {
        name: 'Showroom',
        components: {
            btnIcon,
            showroomResult,
            showroomItem,
            showroomBreadcrumbs,
            showroomMobileNav,
            showroomNav
        },
        mixins: [utilities, showroomMixin],
        beforeRouteUpdate(to, from, next) {
            if (from && to && from.name != to.name) {
                setTimeout(() => {
                    this.scrollIntoView();
                }, 500);
            }
            next();
        },
        props: {
            baseUrl: {
                type: String,
                required: true
            },
            brandColors: {
                type: Object,
                required: true
            },
            brandLogo: {
                type: String,
                default: ''
            },
            buildYourOwnPage: {
                type: String,
                default: null
            },
            dataSource: {
                type: String,
                required: true
            },
            /**
             * Use to limit results to items that exist within a selection of industries, for example.
             */
            hierarchyRootNodes: {
                type: Array,
                default: () => []
            },
            manufacturer: {
                type: String,
                required: true
            },
            showPricing: {
                type: Boolean,
                default: false,
            },
            showVendorLogoOnMobile: {
                type: Boolean,
                default: false
            },
            showroomName: {
                type: String,
                default: 'Showroom'
            },
            /**
             * When true, will pull the showroom nav tree data from the /api/showroom/nav endpoint
             * instead of using the ResultHierarchy field's facet values.
             */
            useDealerHierarchy: {
                type: Boolean,
                default: false
            },
            useEmbeddedQuoteForm: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formPersistenceProvider: null,
                logo:
                    this.brandLogo ||
                    NewEquipmentService.getLogo(`${this.dataSource}${this.dataSource == 'deere' ? '_alt' : ''}`),
                showroomItem: null, // set when detail page route is loaded
                showroomFilters: [],
                sortByOptions: [
                    {
                        field: 'Title',
                        fieldDirection: 'asc',
                        name: 'Model A-Z'
                    },
                    {
                        field: 'Title',
                        fieldDirection: 'desc',
                        name: 'Model Z-A'
                    },
                    {
                        field: 'PublicationDate',
                        fieldDirection: 'asc',
                        name: 'Recent'
                    }
                ],
                pageChangeMutations: ['search/changePage', 'search/decrementPage', 'search/incrementPage']
            };
        },
        computed: {
            cssVars() {
                return {
                    '--primary': this.brandColors.BrandColor,
                    '--primary-darken-7': this.brandColors.BrandHoverColor,
                    '--primary-darken-10': this.brandColors.BrandHoverColor,
                    '--text-primary-yiq': this.brandColors.BrandTextColor,
                    '--link-color': this.brandColors.LinkColor,
                    '--link-hover-color': this.brandColors.LinkHoverColor,
                    '--component-active-bg': this.brandColors.BrandColor,
                    '--pagination-link-color': this.brandColors.BrandColor
                };
            },
            gridSize() {
                return this.$store.state.search.resultsPerPage > 9 ? 4 : 3;
            },
            isLoading() {
                return this.isSearching || (this.isItemPage && !this.showroomItem);
            },
            selectionTitle() {
                return this.$_isItemPage
                    ? this.showroomItem && this.showroomItem.Title
                    : this.$_currentHierarchy && this.$_currentHierarchy[0].split('|').pop();
            },
            isSearching() {
                return this.$store.state.search.isSearching;
            },
            resultsCount() {
                return this.$store.state.search.searchResponse['@odata.count'] || 0;
            },
            /**
             * Returns the range portion of the "Showing 19-27 of 34 models", etc message.
             */
            resultsRange() {
                let searchResults = this.$store.state.search.searchResponse;
                let resultsPage = this.$store.state.search.resultsPage;
                let resultsPerPage = this.$store.state.search.resultsPerPage;
                let actualResultsOnPageCount =
                    (searchResults && searchResults.value && searchResults.value.length) || 0;

                let rangeStart = 1 + resultsPerPage * (resultsPage - 1);

                return actualResultsOnPageCount > 0
                    ? rangeStart + '-' + (rangeStart + actualResultsOnPageCount - 1)
                    : 0;
            }
        },
        watch: {
            '$route.params': function() {
                this.showroomItem = null; // clears item out in-case current route is the list view
                this.loadShowroomItem(); // loads item if user clicks on one from the list view
            },
            selectionTitle: function() {
                this.setMeta();
            }
        },
        created() {
            this.formPersistenceProvider = new showroomPathProvider({
                listRouteName: this.listRouteName,
                itemRouteName: this.itemRouteName,
                listRouteParams: this.listRouteParams,
                pathField: this.filterField,
                itemField: 'UrlName'
            });

            // add hierarchy filters after parent properties are available
            if (this.hierarchyRootNodes.length) {
                this.showroomFilters.push({
                    conditions: [
                        {
                            expression: 'eq',
                            value:
                                this.dataSource == 'shortline'
                                    ? this.hierarchyRootNodes.map(n => `${n.title}::${toSlug(n.title)}`)
                                    : this.hierarchyRootNodes.map(n => `${n.title}::${n.slug}`)
                        }
                    ],
                    fieldType: 'collection',
                    field: this.hierarchyField
                });
            }

            this.showroomFilters.push(
                {
                    conditions: [
                        {
                            expression: 'eq',
                            value: NewEquipmentService.getNewEquipmentCTPName(this.dataSource)
                        }
                    ],
                    field: 'ContentType'
                },
                {
                    conditions: [
                        {
                            expression: 'eq',
                            value: this.manufacturer
                        }
                    ],
                    fieldType: 'string',
                    field: this.manufacturerField
                }
            );
        },
        mounted() {
            this.showroomItem = null; // clear showroom item data if component is re-mounted
            this.loadShowroomItem();
            this.setupStickyHeader();

            this.$store.subscribe(mutation => {
                if (this.pageChangeMutations.indexOf(mutation.type) >= 0) {
                    this.scrollIntoView();
                }
            });
        },
        methods: {
            /**
             * Loads up item to render in the showroom detail view
             */
            loadShowroomItem() {
                let model = this.$route.params && this.$route.params.model;
                if (model) {
                    NewEquipmentService.getNewMachineBySlug(this.dataSource, model, {
                        $expand: true,
                        forceUseDetailType: true
                    }).then(machine => {
                        this.showroomItem = machine;
                        this.showroomItem.BaseUrl = this.baseUrl;
                        this.showroomItem.HierarchyPath = this.$_hierarchyFacets.map(x => x.value.split("::")[1]);
                    });
                }
            },
            /**
             * Moves nav and get a quote buttons below #header-mobile so they will "stick" as the user scrolls
             */
            setupStickyHeader() {
                let mobileHeader = document.querySelector('#header-mobile') || document.querySelector('#header-mobile-rounded');
                this.$refs.mobileHeaderItems.parentElement.removeChild(this.$refs.mobileHeaderItems);
                mobileHeader.insertAdjacentElement('afterend', this.$refs.mobileHeaderItems);
            },
            scrollIntoView() {
                if (window.scrollY > 200) {
                    let showroomResults = this.$refs.showroomResults;

                    if (showroomResults) {
                        let contentPosition = showroomResults.getBoundingClientRect().top + window.scrollY;
                        let mobileHeaderHeight = document.querySelector('#header-mobile').offsetHeight;
                        let scrollPosition = contentPosition - mobileHeaderHeight;

                        window.scroll({ top: scrollPosition, behavior: 'smooth' });
                    }
                }
            },
            setMeta() {
                document.title = this.selectionTitle
                    ? `${this.showroomName} - ${this.selectionTitle}`
                    : this.showroomName;
                this.setCanonicalUrl(); // defined in utilities mixin
            }
        }
    };
</script>
