﻿//@ts-check
import axios from 'axios';
import constants from '../frontend/config/constants';
import { getDataPage } from '../services/lib/ODataService';

const deereIndustries = [
    {
        name: 'lawn-and-garden',
        categories: [
            'gator-utility-vehicles',
            'compact-utility-tractors',
            'residential-mowers',
            'lawn-tractors'
        ]
    },
    {
        name: 'agriculture',
        categories: [
            'loaders',
            'harvesting-equipment',
            'application-equipment-and-sprayers',
            'hay-and-forage-equipment',
            'tillage-equipment',
            'planting-equipment',
            'cutters-and-shredders',
            'gator-utility-vehicles',
            'precision-ag-technology',
            'scrapers',
            'tractors',
            'seeding-equipment'
        ]
    },
    {
        name: 'golf-and-sports-turf',
        categories: ['golf', 'sports-turf']
    },
    {
        name: 'landscaping-and-grounds-care',
        categories: [
            'cutters-and-shredders',
            'gator-utility-vehicles',
            'mowers-and-tractors',
            'worksite-equipment'
        ]
    }
];

var promotionMappings = {
    dealer: {
        baseUrl: '/api/default/dealerpromotions'
    },
    deere: {
        baseUrl: '/api/default/deerepromotions',
        eligibleEquipmentLinks: [
            'EligibleIndustries',
            'EligibleCategories',
            'EligibleSubcategories',
            'EligibleSeries',
            'EligibleSeriesCategories',
            'EligibleMachines'
        ],
        eligibleCategories: ['EligibleIndustries', 'EligibleCategories'],
        eligibleSeries: ['EligibleSubcategories', 'EligibleSeries', 'EligibleSeriesCategories'],
        eligibleMachines: ['EligibleMachines'],
        deereUrl: '/api/default/deere',
        machineUrl: '/api/default/deeremachines',
        showroomUrl: constants.elementConfig.SITEMAP?.DEERE,
        industries: deereIndustries
    },
    newholland: {
        baseUrl: '/api/default/newhollandpromotions',
        eligibleEquipmentLinks: ['EligibleCategories', 'EligibleSeries', 'EligibleMachines'],
        eligibleCategories: ['EligibleCategories'],
        eligibleSeries: ['EligibleSeries'],
        eligibleMachines: ['EligibleMachines'],
        newHollandUrl: '/api/default/newholland',
        machineUrl: '/api/default/newhollandmachines',
        showroomUrl: constants.elementConfig.SITEMAP?.NEW_HOLLAND_AG
    },
    new_holland_ag: {
        baseUrl: '/api/default/newhollandpromotions',
        eligibleEquipmentLinks: ['EligibleCategories', 'EligibleSeries', 'EligibleMachines'],
        eligibleCategories: ['EligibleCategories'],
        eligibleSeries: ['EligibleSeries'],
        eligibleMachines: ['EligibleMachines'],
        newHollandUrl: '/api/default/newholland',
        machineUrl: '/api/default/newhollandmachines',
        showroomUrl: constants.elementConfig.SITEMAP?.NEW_HOLLAND_AG
    },
    new_holland_construction: {
        baseUrl: '/api/default/newhollandpromotions',
        eligibleEquipmentLinks: ['EligibleCategories', 'EligibleSeries', 'EligibleMachines'],
        eligibleCategories: ['EligibleCategories'],
        eligibleSeries: ['EligibleSeries'],
        eligibleMachines: ['EligibleMachines'],
        newHollandUrl: '/api/default/newholland',
        machineUrl: '/api/default/newhollandconstructionmodels',
        showroomUrl: constants.elementConfig.SITEMAP?.NEW_HOLLAND_CONSTRUCTION
    }
};

function getIndustryFullUrl(cfg, industry) {
    return `${cfg.showroomUrl}/${industry.UrlName}`;
}

function getCategoryFullUrl(cfg, category) {
    let industryName = cfg.industries.find(industry => industry.categories.includes(category.UrlName)).name;
    return `${cfg.showroomUrl}/${industryName}/${category.UrlName}`;
}

function getMachineFullUrl(cfg, machine) {
    let path = '';

    if (machine.HierarchyPath) {
        let paths = machine.HierarchyPath.split('~');
        let pathFragments = paths.length > 1 ? paths[1] : paths[0];
        path = pathFragments.split('|').join('/');
    }

    return `${cfg.showroomUrl}/${path}/${machine.UrlName}/overview`;
}

export function getAllPromotions(manufacturer) {
    let cfg = promotionMappings[manufacturer];

    if (!cfg) {
        return new Promise(resolve => resolve([]));
    }

    let apiUrl = cfg.baseUrl;
    if (manufacturer === 'dealer') {
        apiUrl += '?$expand=Image';
    }

    return getDataPage(apiUrl, 0, true)
        .then(response => {
            const offers = response.data.value.filter(offer => !offer.EndDate || Date.parse(offer.EndDate) > Date.now());

            return offers;
        });
}

export function getPromotionIndustries(manufacturer) {
    const cfg = promotionMappings[manufacturer];

    if (!cfg) {
        return new Promise(resolve => resolve([]));
    }

    if (manufacturer === 'dealer') {
        return getDealerPromotionIndustries();
    }

    // TODO: we shouldn't have vendor specific items here. There should be a property called industriesUrl.
    const apiUrl = cfg.deereUrl + 'industries';

    return new Promise(resolve => {
        axios.get(apiUrl).then(response => {
            resolve(response.data.value.map(i => i.Title));
        });
    });
}

export function getDealerPromotionIndustries() {
    return new Promise(resolve => {
        axios.get(promotionMappings.dealer.baseUrl).then(response => {
            const dealerPromotions = response.data.value
                // get Industries as array of arrays
                .map(item => item.Industries.split(',').map(item => item.trim()))
                // flatten array
                .reduce((acc, val) => acc.concat(val), [])
                // remove duplicates
                .reduce((unique, item) => (unique.indexOf(item) !== -1 ? unique : unique.concat(item)), []);

            resolve(dealerPromotions);
        });
    });
}

export function getCategoriesByIndustry(manufacturer, industry) {
    const cfg = promotionMappings[manufacturer];

    if (!cfg) {
        return new Promise(resolve => resolve([]));
    }

    let apiUrl = cfg.baseUrl + `?$filter=contains(Industries, '${industry}')&$select=Category`;
    return new Promise(resolve => {
        axios.get(apiUrl).then(response => {
            const offers = [...new Set(response.data.value.map(item => item.Category))];
            resolve(offers);
        });
    });
}

/**
 * @param {string} dataSource
 * @param {string} promotionId
 * @returns
 */
export function getPromotionById(dataSource, promotionId) {
    let cfg = promotionMappings[dataSource];

    if (!cfg) {
        return new Promise(resolve => resolve([]));
    }

    let apiUrl = cfg.baseUrl + `?&$filter=Id eq ${promotionId}`;

    if (dataSource === 'dealer') {
        apiUrl += '&$expand=Image';
    }

    return new Promise((resolve, reject) => {
        axios.get(apiUrl).then(response => {
            let promo = (response.data.value && response.data.value[0]) || undefined;

            // make sure promo is still active
            if (promo && (!promo.EndDate || Date.parse(promo.EndDate) > Date.now())) {
                resolve(promo);
            } else {
                reject('That promotion has expired or been removed');
            }
        });
    });
}

export function getSelectedPromotions(manufacturer, promotionsIds) {
    let cfg = promotionMappings[manufacturer];

    if (!cfg) {
        return new Promise(resolve => resolve([]));
    }

    let apiUrl = `${cfg.baseUrl}?$filter=${promotionsIds.map(p => `Id eq ${p}`).join(' or ')}`;

    if (manufacturer === 'dealer') {
        apiUrl += '&$expand=Image';
    }

    return new Promise(resolve => {
        axios.get(apiUrl).then(response => {
            // filter to active promos
            let promotions = response.data.value.filter(
                p => !p.EndDate || Date.parse(p.EndDate) > Date.now()
            );

            // keeping the products ordered
            let orderedPromotions = promotionsIds.map((id) => promotions.find(promo => promo.Id == id));

            resolve(orderedPromotions);
        });
    });
}

export function getPromotionBySlug(dataSource, slug) {
    let apiUrl = `/api/default/${dataSource}promotions?$filter=UrlName eq '${slug}'`;

    // manual entry promos have a related image field
    if (dataSource === 'dealer') {
        apiUrl += '&$expand=Image';
    }

    return new Promise((resolve, reject) => {
        axios.get(apiUrl).then(response => {
            let promo = (response.data.value && response.data.value[0]) || undefined;

            // make sure promo is still active
            if (promo && (!promo.EndDate || Date.parse(promo.EndDate) > Date.now())) {
                resolve(promo);
            } else {
                reject('That promotion has expired or been removed.');
            }
        });
    });
}

export function getPromotionsByFilter(manufacturer, industry, category) {
    let cfg = promotionMappings[manufacturer];

    if (!cfg) {
        return new Promise(resolve => resolve([]));
    }

    let apiUrl = cfg.baseUrl;

    if (category) {
        apiUrl = `${apiUrl}?$filter=contains(Category, '${encodeURIComponent(category)}')`;
    } else {
        apiUrl = `${apiUrl}?$filter=contains(Industries, '${industry}')`;
    }

    if (manufacturer === 'dealer') {
        apiUrl += '&$expand=Image';
    }

    return new Promise(resolve => {
        axios.get(apiUrl).then(response => {
            // filter promos that are still valid
            const promos = response.data.value.filter(
                offer => !offer.EndDate || Date.parse(offer.EndDate) > Date.now()
            );
            resolve(promos);
        });
    });
}

export function getPromotionCategories(manufacturer, promo, options) {
    let cfg = promotionMappings[manufacturer];

    if (!cfg) {
        return new Promise(resolve => resolve([]));
    }

    let apiUrl = `${cfg.baseUrl}(${promo.Id})?$expand=${cfg.eligibleCategories.join()}`;

    return new Promise(resolve => {
        axios.get(apiUrl).then(response => {
            let categories = [];
            for (let i = 0; i < cfg.eligibleCategories.length; i++) {
                let responseCategories = response.data[cfg.eligibleCategories[i]];
                if (i === 0) {
                    responseCategories = responseCategories.map(cat => {
                        cat.FullUrl = getIndustryFullUrl(cfg, cat);
                        return cat;
                    });
                } else if (i === 1) {
                    responseCategories = responseCategories.map(cat => {
                        cat.FullUrl = getCategoryFullUrl(cfg, cat);
                        return cat;
                    });
                }

                categories = categories.concat(responseCategories);
            }

            if (options && options.$orderby) {
                categories.sort((a, b) => {
                    return a[options.$orderby] < b[options.$orderby] ? -1 : 1;
                });
            }

            resolve(categories);
        });
    });
}

export function getPromotionMachines(manufacturer, promo, options) {
    let cfg = promotionMappings[manufacturer];
    let promoUrl = `${cfg.baseUrl}(${promo.Id})?$expand=${cfg.eligibleSeries
        .concat(cfg.eligibleMachines)
        .join()}`;

    return new Promise(resolve => {
        axios.get(promoUrl).then(response => {
            let categories = [];
            for (let i = 0; i < cfg.eligibleSeries.length; i++) {
                categories = categories.concat(response.data[cfg.eligibleSeries[i]]);
            }

            let machines = [];
            for (let i = 0; i < cfg.eligibleMachines.length; i++) {
                machines = machines.concat(response.data[cfg.eligibleMachines[i]]);
            }

            if (categories.length === 0 && machines.length === 0) {
                return resolve([]);
            }

            let machineUrl = cfg.machineUrl + '?$filter=';
            for (let i = 0; i < categories.length; i++) {
                if (i > 0) {
                    machineUrl += ' or ';
                }
                machineUrl += `contains(Hierarchy, '${categories[i].Title}') eq true`;
            }
            for (let i = 0; i < machines.length; i++) {
                if (i < machines.length - 1) {
                    machineUrl += ` Title eq '${machines[i].Title}' or`;
                } else {
                    machineUrl += ` Title eq '${machines[i].Title}'`;
                }
            }

            getDataPage(machineUrl.trim(), 0, true).then(machineResponse => {
                let machineResults = machineResponse.data.value;
                for (let i = 0; i < machineResults.length; i++) {
                    machineResults[i].FullUrl = getMachineFullUrl(cfg, machineResults[i]);
                }

                if (options && options.$orderby) {
                    machineResults.sort((a, b) => {
                        return a[options.$orderby] < b[options.$orderby] ? -1 : 1;
                    });
                }

                resolve(machineResults);
            });
        });
    });
}

export function getMachinePromotions(manufacturer, machine, options) {
    let cfg = promotionMappings[manufacturer];

    if (!cfg) {
        return new Promise(resolve => resolve([]));
    }

    let apiUrl = `${cfg.baseUrl
        }?$filter=HasEligibleItems eq true&$expand=${cfg.eligibleEquipmentLinks.join()}`;

    if (options && options.$orderby) {
        apiUrl += `&$orderby=${options.$orderby}`;
    } else {
        apiUrl += '&$orderby=Id';
    }

    let combinedHierarchies = [];
    let machineHierarchies = machine.Hierarchy.split('~');

    for (let i = 0; i < machineHierarchies.length; i++) {
        let hierarchy = machineHierarchies[i].split('|');
        for (let j = 0; j < hierarchy.length; j++) {
            if (combinedHierarchies.length < j + 1) {
                combinedHierarchies.push([]);
            }
            if (combinedHierarchies[j].indexOf(hierarchy[j]) < 0) {
                combinedHierarchies[j].push(hierarchy[j]);
            }
        }
    }

    return new Promise(resolve => {
        getDataPage(apiUrl, 0, true).then(response => {
            let results = response.data.value
                .map(result => {
                    // Check for related industries/categories
                    for (
                        let i = 0;
                        i < cfg.eligibleEquipmentLinks.length && i < combinedHierarchies.length;
                        i++
                    ) {
                        let eligibleItems = result[cfg.eligibleEquipmentLinks[i]].map(link => link.Title);
                        for (let j = 0; j < combinedHierarchies[i].length; j++) {
                            if (eligibleItems.indexOf(combinedHierarchies[i][j]) > -1) {
                                return result;
                            }
                        }
                    }

                    // Check for related machines.
                    let machineIndex = cfg.eligibleEquipmentLinks[cfg.eligibleEquipmentLinks.length - 1];
                    if (result[machineIndex].map(m => m.Title).indexOf(machine.Title) > -1) {
                        return result;
                    }

                    return null;
                })
                .filter(val => val);

            resolve(results);
        });
    });
}

export default {
    getAllPromotions,
    getSelectedPromotions,
    getPromotionById,
    getPromotionBySlug,
    getPromotionCategories,
    getPromotionIndustries,
    getPromotionMachines,
    getMachinePromotions,
    getPromotionsByFilter,
    getCategoriesByIndustry
};
