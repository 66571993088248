﻿<template>
    <div class="build-your-own-builder px-lg-5 position-relative">
        <div v-if="!loading" class="d-lg-flex">
            <!--SIDEBAR-->
            <div
                class="builder-sidebar-options d-none d-lg-block bg-gray-200 p-0 border-top-0 position-relative"
            >
                <div v-if="sidebarSettings.length > 0">
                    <div id="sidebarMenu" class="accordion">
                        <div v-for="(mainSetting, mainSettingIndex) in sidebarSettings" :key="mainSetting.Id">
                            <!-- Header -->
                            <div class="card-container bg-primary p-3 d-flex align-items-center text-white">
                                <span
                                    class="order-box bg-1 bg-white text-dark d-flex justify-content-center align-items-center rounded-circle"
                                    >{{ mainSettingIndex + 1 }}</span
                                >
                                <span class="ml-2">{{ mainSetting.Name }}</span>
                            </div>
                            <div
                                v-for="(subsetting, subsettingIndex) in mainSetting.options"
                                :key="subsetting.Name"
                            >
                                <div class="card rounded-0 shadow-none">
                                    <!-- Settings -->
                                    <div
                                        :class="[
                                            'card-header border-0 w-100 pl-3 d-flex justify-content-between align-items-center cursor-pointer',
                                            { collapsed: !isFirstElement(mainSettingIndex, subsettingIndex) }
                                        ]"
                                        :aria-expanded="isFirstElement(mainSettingIndex, subsettingIndex)"
                                        :aria-controls="`collapse${subsetting.Id}`"
                                        data-toggle="collapse"
                                        :data-target="`#collapse${subsetting.Id}`"
                                    >
                                        <span class="pr-2">{{ subsetting.Name }}</span>
                                        <i class="fas fa-chevron-down card-header-caret"></i>
                                    </div>

                                    <div
                                        :id="`collapse${subsetting.Id}`"
                                        :class="[
                                            'collapse',
                                            { show: isFirstElement(mainSettingIndex, subsettingIndex) }
                                        ]"
                                        data-parent="#sidebarMenu"
                                    >
                                        <!-- Options -->
                                        <div class="card-body p-0 options-list">
                                            <div
                                                v-for="option in subsetting.options"
                                                :key="option.Id"
                                                :class="[
                                                    'card-body-item py-2 d-flex justify-content-between align-items-center pl-4 pr-3 cursor-pointer',
                                                    { 'selected-option': option.IsSelected }
                                                ]"
                                                @click="setOption(option)"
                                            >
                                                <div class="d-flex">
                                                    <div
                                                        class="card-body-item-icon rounded-circle mr-2 flex-shrink-0"
                                                        :style="{
                                                            backgroundImage: option.Icon
                                                                ? `url(${option.Icon.Url})`
                                                                : ''
                                                        }"
                                                    ></div>
                                                    <div
                                                        class="card-body-item-content d-flex justify-content-between text-wrap w-100"
                                                    >
                                                        {{ option.Name }}
                                                    </div>
                                                </div>
                                                <i
                                                    v-if="option.IsSelected"
                                                    class="fas fa-check text-primary"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-else
                    class="d-none d-lg-flex align-items-center justify-content-center position-absolute p-3 h5 text-center"
                    style="top: 40%"
                >
                    <p>There are no options to customize this machine</p>
                </div>
            </div>

            <!--MACHINE SIDE-->
            <div class="d-flex w-100 flex-column align-self-lg-stretch border-right">
                <!-- BREADCRUMB NAVIGATION -->
                <div class="row m-3 mb-0 d-none d-md-block">
                    <ol class="list-inline text-uppercase">
                        <li v-for="(path, i) in pathCollection" :key="i" class="list-inline-item">
                            <router-link tag="span" class="cursor-pointer" :to="path.urlName">{{
                                path.name
                            }}</router-link>
                            <span v-if="i + 1 < pathCollection.length">&nbsp;/&nbsp;</span>
                        </li>
                    </ol>
                </div>

                <div class="d-flex flex-column justify-content-end h-100">
                    <div class="row mx-0 mb-auto">
                        <!-- machine -->
                        <div
                            class="machine d-flex flex-column col-12 position-relative align-items-center"
                            :class="{ 'col-lg-7': sidebarSettings.length > 0 }"
                        >
                            <!-- machine TITLE -->
                            <div class="text-center mb-3">
                                <span class="text-primary h6">YOUR</span>
                                <div class="machine-title h4 text-center text-md-left">
                                    {{ machine.Name }}
                                </div>
                            </div>

                            <!--MACHINE IMAGE-->
                            <element-image
                                :alt="outputMachine.Name"
                                container-class="machine-image-container"
                                :src="getMachineImage"
                                :key="getColor"
                                :use-proxy="false"
                                @click.native="openLightboxOnSlide(getMachineImage)"
                            ></element-image>
                        </div>

                        <!-- MACHINE DETAILS -->
                        <div
                            v-if="sidebarSettings.length > 0"
                            class="machine-overview px-4 px-lg-2 col-12 col-lg-5 mt-3 mt-lg-0 position-relative"
                        >
                            <div class="hidden-print d-lg-none mb-3">
                                <eligible-discounts-modal
                                    v-if="outputMachine.Discounts.length"
                                    modal-id="eligible-discounts-modal-mobile"
                                    class="mb-2"
                                    :discounts="outputMachine.Discounts"
                                    :selected-customizations="outputMachine.Customization"
                                ></eligible-discounts-modal>
                                <mobile-sidebar :sidebar-settings="sidebarSettings"></mobile-sidebar>
                            </div>

                            <!-- SELECTED OPTIONS LIST -->
                            <span class="text-primary d-flex align-items-center font-weight-bold">
                                <span class="mr-2">OPTIONS</span>
                                <hr class="w-100" />
                            </span>
                            <div
                                v-if="outputMachine.Customization.length > 0"
                                class="machine-additional-options"
                            >
                                <div
                                    v-for="option in outputMachine.Customization"
                                    :key="option.Id"
                                    class="option d-flex align-items-center py-3"
                                >
                                    <element-image
                                        v-if="!!option.Icon"
                                        :alt="option.UrlName"
                                        container-class="mr-4 w-25"
                                        height="40"
                                        :src="option.Icon.Url"
                                        :use-proxy="false"
                                        @click.native="openLightboxOnSlide(option.Image.Url)"
                                    ></element-image>
                                    <div class="w-100 d-flex justify-content-between align-items-center pr-2">
                                        <div>
                                            <div class="font-weight-bold">{{ option.Name }}</div>
                                            <div v-if="option.StartingPrice && option.Price" class="text-black-50">
                                                Discounted from: {{ currencyFormat(option.Price) }}
                                            </div>
                                            <div class="text-black-50" :title="option.Description">
                                                {{ option.Description }}
                                            </div>
                                        </div>
                                        <div>
                                            <div class="font-weight-bold">
                                                {{ currencyFormat(option.StartingPrice || option.Price) }}
                                            </div>
                                            <div
                                                class="d-none d-lg-block cursor-pointer text-black-50"
                                                @click="changeOption(option)"
                                            >
                                                Change
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="h6 machine-additional-options">
                                Select additional options to customize your Deere
                            </div>
                            <!--ELIGIBLE DISCOUNTS  -->
                            <template v-if="discounts.length > 0">
                                <span class="text-primary d-flex align-items-center font-weight-bold mt-3">
                                    <span class="mr-2">DISCOUNTS</span>
                                    <hr class="w-100" />
                                </span>
                                <div
                                    v-for="discount in discounts"
                                    :key="discount.Id"
                                    class="discount d-flex align-items-center py-3"
                                >
                                    <i class="text-warning fas fa-star mr-3"></i>
                                    <div class="font-weight-bold mr-auto">{{ discount.Title }}</div>
                                    <div
                                        v-if="discount.DiscountType == DISCOUNT_TYPES.FIXED"
                                        class="font-weight-bold"
                                    >
                                        {{ `-${currencyFormat(Math.abs(discount.DiscountValue))}` }}
                                    </div>
                                    <div
                                        v-if="discount.DiscountType == DISCOUNT_TYPES.PERCENTAGE"
                                        class="font-weight-bold"
                                    >
                                        {{
                                            `-${currencyFormat(
                                                outputMachine.finalPrice *
                                                    Math.abs(discount.DiscountValue) *
                                                    0.01
                                            )}`
                                        }}
                                        {{ `(${Math.abs(discount.DiscountValue)}%)` }}
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div v-if="outputMachine.Discounts.length" class="row d-none d-lg-flex">
                        <div class="col-lg-7 text-center">
                            <eligible-discounts-modal
                                modal-id="eligible-discounts-modal-desktop"
                                :discounts="outputMachine.Discounts"
                                :selected-customizations="outputMachine.Customization"
                            />
                        </div>
                    </div>

                    <!--BOTTOM ELEMENTS-->
                    <div class="row align-items-end m-0 bottom-info border-bottom">
                        <!-- PRICE BOX -->
                        <div class="col-box col-12 col-lg-5 p-0 order-lg-1 align-self-lg-center">
                            <div class="price-box row h-100 text-center order-lg-1 m-0">
                                <div
                                    class="col-6 p-3 border-left border-right d-flex flex-column align-self-center align-items-center justify-content-center"
                                >
                                    <div class="font-weight-bold">As low as</div>
                                    <div v-if="showStartingPrice">
                                        <s class="text-danger">
                                            {{ currencyFormat(outputMachine.finalPrice) }}
                                        </s>
                                        <div
                                            class="h-50 border-bottom border-primary font-weight-bold pb-2 price"
                                        >
                                            {{ currencyFormat(finalStartingPriceWithDiscounts) }}
                                        </div>
                                    </div>
                                    <div
                                        v-else
                                        class="h-50 border-bottom border-primary font-weight-bold pb-2 price"
                                    >
                                        {{ currencyFormat(finalListPriceWithDiscounts) }}
                                    </div>
                                    <div class="price-description font-weight-bold mt-2 text-uppercase">
                                        {{ currentConfigurationLabel }}
                                    </div>
                                </div>
                                <div
                                    class="col-6 p-3 d-flex flex-column align-self-center align-items-center justify-content-center"
                                >
                                    <span
                                        class="monthly-payment h-50 border-bottom border-primary font-weight-bold pb-2 price"
                                    >
                                        <i class="fas fa-calculator text-primary mr-2 calculator-icon"></i>
                                        <span v-if="showStartingPrice">
                                            {{ currencyFormat(finalStartingPriceWithDiscounts / (financingYear * 12)) }}
                                        </span>
                                        <span v-else>
                                            {{ currencyFormat(finalListPriceWithDiscounts / (financingYear * 12)) }}
                                        </span>
                                    </span>
                                    <div class="h-50 price-description font-weight-bold mt-2">
                                        MONTHLY PAYMENT
                                    </div>
                                    <div
                                        class="d-flex flex-nowrap w-100 border rounded justify-content-between mt-3 font-weight-bold financing-years"
                                    >
                                        <button
                                            class="btn btn-primary"
                                            :disabled="financingYear == 1"
                                            @click="changeFinancingYears(financingYear - 1)"
                                        >
                                            -
                                        </button>
                                        <input
                                            class="text-center font-weight-bold px-0 border-0 w-50"
                                            :value="`${financingYear * 12} months`"
                                            readonly
                                        />
                                        <button
                                            class="btn btn-primary"
                                            :disabled="financingYear == maximumFinancingYears"
                                            @click="changeFinancingYears(financingYear + 1)"
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- CONTACT DEALER BOX -->
                        <div class="hidden-print col-box col-12 col-lg p-0 order-lg-0">
                            <div class="row h-100 m-0">
                                <a
                                    class="col-12 p-3 text-center d-flex flex-column align-items-center justify-content-end text-dark"
                                    :href="machine.StandardFeatureUrl"
                                    target="_blank"
                                >
                                    <i class="far fa-file-pdf detail-icon text-black-50 mb-2"></i>
                                    <div class="detail-description font-weight-bold">STANDARD FEATURES</div>
                                </a>
                                <a
                                    class="col-12 align-self-end btn btn-block btn-primary rounded-0 py-3"
                                    :href="dealerContact ? dealerContact : '#'"
                                    >CALL YOUR DEALER</a
                                >
                            </div>
                        </div>

                        <!-- ALL FINISHED BOX -->
                        <div class="hidden-print col-box col-12 border-left col-lg p-0 order-lg-2">
                            <div class="row m-0 text-center text-black-50">
                                <a class="col p-2 border-right tool-icon" @click="copy">
                                    <i class="far fa-copy"></i>
                                </a>
                                <share-network
                                    tabindex="-1"
                                    class="col p-2 border-right tool-icon"
                                    network="facebook"
                                    :title="SOCIAL_SHARING_DESCRIPTION"
                                    :url="socialSharingUrl"
                                    :quote="SOCIAL_SHARING_DESCRIPTION"
                                >
                                    <i class="fab fa-facebook-f"></i>
                                </share-network>
                                <share-network
                                    tabindex="-1"
                                    class="col p-2 border-right tool-icon"
                                    network="twitter"
                                    :title="SOCIAL_SHARING_DESCRIPTION"
                                    :url="socialSharingUrl"
                                    :description="SOCIAL_SHARING_DESCRIPTION"
                                >
                                    <i class="fab fa-twitter"></i>
                                </share-network>
                                <a :href="mailLink" class="col p-2 border-right tool-icon">
                                    <i class="fas fa-envelope"></i>
                                </a>
                                <div class="col p-2 tool-icon cursor-pointer" @click="print">
                                    <i class="fas fa-print"></i>
                                </div>
                            </div>
                            <div class="row m-0 border-top d-flex text-center">
                                <div
                                    class="col-6 builder-option px-2 py-3 border-right font-weight-bold cursor-pointer"
                                    @click="resetBuild"
                                >
                                    RESET BUILD
                                </div>
                                <router-link
                                    tag="div"
                                    to="/"
                                    class="col-6 builder-option px-2 py-3 font-weight-bold cursor-pointer"
                                    >START OVER</router-link
                                >
                            </div>
                            <button
                                class="btn btn-secondary btn-block rounded-0 py-3 text-secondary-yiq"
                                data-toggle="modal"
                                :data-target="`#${quoteFormModalId}`"
                            >
                                REQUEST A QUOTE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else style="min-height: 35rem" class="d-flex justify-content-center align-items-center">
            <div class="loading-spinner">
                <span class="fas fa-spinner fa-spin"></span>
            </div>
        </div>

        <!--
            Sources are not reactive. Using workaround proposed by the plugin
            https://fslightbox.com/vue/documentation/sources#custom-sources
        -->
        <fslightbox
            :key="counter"
            :captions="captions"
            :toggler="toggler"
            type="image"
            :sources="sources"
            :source="source"
        ></fslightbox>

        <!-- Modal for Sitefinity get a quote form widget. -->
        <modal-form
            v-if="useSitefinityForm"
            :id="quoteFormModalId"
            :field-values="formFieldValues"
            :form-selector="`.${quoteFormModalId}`"
            title="Get a Quote"
        ></modal-form>
        <!-- Renders a custom form that's specified by url within an iframe modal -->
        <modal-iframe
            v-else
            :id="quoteFormModalId"
            title="Get a Quote"
            :iframe-source="formUrl"
        ></modal-iframe>
    </div>
</template>

<script>
    // Utilities
    import constants from '../../config/constants';
    import utilities, { deepCopy } from '../../lib/utilities';
    import LocationMixin from '../mixins/LocationMixin';
    // Services
    import DeereBuildYourOwnService from '../../../services/DeereBuildYourOwnService';
    // Components
    import MobileSidebar from './BuildYourOwnMobileSidebar';
    import caption from './Caption';
    import EligibleDiscountsModal from './BuildYourOwnEligibleDiscountsModal';
    import Fslightbox from 'fslightbox-vue';
    import ModalForm from '../utility/ModalForm';
    import ModalIframe from '../utility/ModalIFrame';
    // Constants
    import {
        MACHINE_STORAGE_KEY,
        SOCIAL_SHARING_DESCRIPTION,
        DISCOUNT_TYPES,
        SELECTION_TYPES
    } from './constants';
    import { DEV_ENV_SERVER } from '../../config/constants';

    export default {
        name: 'BuildYourOwnBuilder',
        components: {
            EligibleDiscountsModal,
            Fslightbox,
            MobileSidebar,
            ModalForm,
            ModalIframe
        },
        mixins: [LocationMixin],
        props: {
            currentConfigurationLabel: {
                type: String,
                required: true
            },
            useSitefinityForm: {
                type: Boolean,
                default: true
            },
            formUrl: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                loading: true,
                selectedSetting: {},
                showSidebar: false,
                zoom: 1,
                defaultMachine: {},
                sidebarSettings: [],
                sidebarSettingsHierarchy: [],
                machine: null,
                storedMachineDetails: undefined,
                builderUrl: '',
                flickityInstance: undefined,
                toggler: false,
                source: '',
                counter: 0,
                caption,
                quoteFormModalId: 'send-your-build',
                financingYear: 6,
                maximumFinancingYears: 6,
                elementConfig: constants.elementConfig,
                SOCIAL_SHARING_DESCRIPTION,
                DISCOUNT_TYPES
            };
        },
        computed: {
            sources() {
                let sources = this.outputMachine.Customization.map(item =>
                    item.Image ? item.Image.Url : item.Icon.Url
                );

                if (
                    !!this.outputMachine.Images &&
                    !this.outputMachine.Customization.some(op => op.LayerLevel === 0)
                ) {
                    sources.push(this.outputMachine.Images.split(',')[0]);
                }

                return sources;
            },
            captions() {
                let captions = this.outputMachine.Customization.map(item => ({
                    component: caption,
                    props: {
                        title: item.Name,
                        description: item.Description
                    }
                }));

                if (
                    !!this.outputMachine.Images &&
                    !this.outputMachine.Customization.some(op => op.LayerLevel === 0)
                ) {
                    captions.push({
                        component: caption,
                        props: {
                            title: this.outputMachine.Name
                        }
                    });
                }

                return captions;
            },
            outputMachine() {
                let prod = deepCopy(this.defaultMachine);
                let price = 0;
                let startingPrice = 0;
                prod.Customization = [];
                prod.Discounts = prod.Discounts ?? [];
                this.sidebarSettings.forEach(category => {
                    category.options.forEach(type => {
                        type.options.forEach(op => {
                            if (op.IsSelected) {
                                price += op.Price;
                                startingPrice += op.StartingPrice || op.Price;
                                prod.Customization.push(op);
                            }
                        });
                    });
                });

                this.counter++;

                prod.StartingPrice = prod.StartingPrice || prod.BasePrice;

                prod.finalPrice = isNaN(price + prod.BasePrice) ? 0 : price + prod.BasePrice;
                prod.finalStartingPrice = isNaN(startingPrice + prod.StartingPrice)
                    ? 0
                    : startingPrice + prod.StartingPrice;
                return prod;
            },
            outputMachineIDsOnly() {
                let prod = deepCopy(this.defaultMachine);
                let price = 0;
                let startingPrice = 0;
                prod.Customization = [];
                prod.Discounts = prod.Discounts ?? [];
                // LOOPS THROUGH ALL POSSIBLE SETTINGS AND RETURNS ONLY SELECTED CUSTOMIZATIONS & DISCOUNTS
                this.sidebarSettings.forEach(category => {
                    category.options.forEach(type => {
                        type.options.forEach(op => {
                            if (op.IsSelected) {
                                price += op.Price;
                                startingPrice += op.StartingPrice || op.Price;
                                prod.Customization.push(op);
                            }
                        });
                    });
                });

                // return ids of selected customizations
                return prod.Customization.map(customization => customization.Id);
            },
            pathCollection() {
                let path = [
                    {
                        urlName: '/',
                        name: 'Home'
                    }
                ];
                let routeParams = this.$route.params;
                for (const [i, param] of Object.keys(routeParams).entries()) {
                    path.push({
                        urlName: `/${Object.values(routeParams)
                            .slice(0, i + 1)
                            .join('/')}`,
                        name: routeParams[param].replace(/-/g, ' ')
                    });
                }

                return path;
            },
            getMachineImage() {
                let spec = this.outputMachine.Customization.find(item => item.LayerLevel === 0);

                return spec?.Image?.Url ?? this.outputMachine.Images?.split(',')[0];
            },
            getColor() {
                let spec = this.outputMachine.Customization.find(item => item.LayerLevel === 0);

                return spec?.Name;
            },
            dealerContact() {
                return this.$_selectedLocation && this.$_selectedLocation.Phone
                    ? `tel:${this.$_selectedLocation.Phone}`
                    : `tel:${this.elementConfig.PHONE}`;
            },
            mailLink() {
                let email =
                    this.$_selectedLocation && !!this.$_selectedLocation.Email
                        ? this.$_selectedLocation.Email
                        : this.elementConfig.EMAIL;
                let lineBreak = '%0D%0A';

                return `mailto:${email}?subject=${SOCIAL_SHARING_DESCRIPTION}&body=Click here to see it:${lineBreak}${lineBreak}${encodeURIComponent(
                    this.socialSharingUrl
                )}${lineBreak}`;
            },
            socialSharingUrl() {
                return window.location.href.includes('localhost')
                    ? this.builderUrl.replace(window.location.origin, DEV_ENV_SERVER)
                    : this.builderUrl;
            },
            formFieldValues() {
                return [
                    {
                        selector: '.builder-link textarea',
                        value: this.socialSharingUrl
                    }
                ];
            },
            discounts() {
                this.outputMachine.Discounts.forEach(discount => {
                    discount.IsEligible = true;

                    for (var i = 0; i < discount.Customizations.length; i++) {
                        let isCustomizationSelected = this.outputMachine.Customization.some(
                            c => c.UrlName == discount.Customizations[i].UrlName
                        );

                        // Making sure all required customizations are selected
                        discount.IsEligible = discount.IsEligible && isCustomizationSelected;
                    }
                });

                return this.outputMachine.Discounts.filter(d => d.IsEligible);
            },
            finalListPriceWithDiscounts() {
                let discountsSum = 0;

                this.discounts
                    .filter(d => d.IsEligible)
                    .forEach(discount => {
                        if (discount.DiscountType == DISCOUNT_TYPES.FIXED) {
                            discountsSum += discount.DiscountValue;
                        } else if (discount.DiscountType == DISCOUNT_TYPES.PERCENTAGE) {
                            discountsSum += this.outputMachine.finalPrice * discount.DiscountValue * 0.01;
                        }
                    });

                return Math.max(0, this.outputMachine.finalPrice - discountsSum);
            },
            finalStartingPriceWithDiscounts() {
                let discountsSum = 0;

                this.discounts
                    .filter(d => d.IsEligible)
                    .forEach(discount => {
                        if (discount.DiscountType == DISCOUNT_TYPES.FIXED) {
                            discountsSum += discount.DiscountValue;
                        } else if (discount.DiscountType == DISCOUNT_TYPES.PERCENTAGE) {
                            discountsSum += this.outputMachine.finalStartingPrice * discount.DiscountValue * 0.01;
                        }
                    });

                return Math.max(0, this.outputMachine.finalStartingPrice - discountsSum);
            },
            showStartingPrice() {
                return (
                    this.finalStartingPriceWithDiscounts &&
                    this.finalStartingPriceWithDiscounts < this.outputMachine.finalPrice
                );
            }
        },
        watch: {
            outputMachine(newMachine) {
                window.localStorage.setItem(MACHINE_STORAGE_KEY, JSON.stringify(newMachine));
                this.setBuilderUrl();
            }
        },
        mounted() {
            if (window.localStorage.getItem(MACHINE_STORAGE_KEY)) {
                this.storedMachineDetails = JSON.parse(window.localStorage.getItem(MACHINE_STORAGE_KEY));
            }

            this.getMachineInfoDetails();
        },
        methods: {
            print() {
                window.print();
            },
            isFirstElement(index_main_setting, index_setting) {
                return index_main_setting === 0 && index_setting === 0;
            },
            setOption(selectedOption) {
                let setting = this.sidebarSettingsHierarchy.find(x => x.includes(selectedOption.Id));
                if (!setting) {
                    return;
                }

                let hierarchy = setting.split('|');
                let type = this.sidebarSettings
                    .find(x => x.Name == hierarchy[0]) // Category
                    .options.find(x => x.Name == hierarchy[1]); // Type

                if (type.options[0].Type.SelectionType == SELECTION_TYPES.SINGLE) {
                    for (let option of type.options) {
                        if (option.Id == selectedOption.Id) {
                            option.IsSelected = !option.IsSelected; // Option
                        } else {
                            option.IsSelected = false;
                        }
                    }
                } else {
                    let option = type.options.find(x => x.Id == selectedOption.Id); // Option
                    option.IsSelected = !option.IsSelected;
                }
            },
            resetBuild() {
                if (this.outputMachine.Customization.length > 0) {
                    this.buildSidebarSettings();
                }
            },
            buildSidebarSettings() {
                let machineClone = deepCopy(this.machine);
                let categories = utilities.sortWords([
                    ...new Set(machineClone.Customization.map(spec => spec.Type.Category))
                ]);
                let types = utilities.sortWords([
                    ...new Set(machineClone.Customization.map(spec => spec.Type.Name))
                ]);

                let sidebar = [];
                let hierarchy = [];
                for (const category of categories) {
                    let setting = {
                        Id: category.replace(' ', ''),
                        Name: category,
                        options: []
                    };

                    for (const type of types) {
                        let option = {
                            Id: type.replace(' ', ''),
                            Name: type,
                            options: machineClone.Customization.filter(spec => {
                                if (spec.Type.Category === category && spec.Type.Name === type) {
                                    return { ...spec, IsSelected: spec.IsDefault };
                                }
                            })
                        };

                        if (option.options.length > 0) {
                            option.options = utilities.sortByKey(option.options, 'Name');
                            setting.options.push(option);
                            hierarchy = hierarchy.concat(option.options.map(x => `${category}|${type}|${x.Id}`));
                        }
                    }

                    sidebar.push(setting);
                }

                this.sidebarSettings = sidebar;
                this.sidebarSettingsHierarchy = hierarchy;
            },
            changeOption(option) {
                // Close all Types and options
                let allTypes = document.querySelectorAll('[data-target]');
                let allOptions = document.querySelectorAll('[data-parent="#sidebarMenu"]');
                for (let type of allTypes) {
                    type.classList.add('collapsed');
                }
                for (let option of allOptions) {
                    option.classList.remove('show');
                }

                // Open selected Type and Options
                let selectedType = document.querySelector(
                    `[data-target="#collapse${option.Type.Name.split(' ').join('')}"]`
                );
                let typeOptions = document.querySelector(`#collapse${option.Type.Name.split(' ').join('')}`);
                selectedType.classList.remove('collapsed');
                typeOptions.classList.add('show');
            },
            currencyFormat(number) {
                return utilities.currencyFormat.format(number);
            },
            encodeMachine() {
                return btoa(unescape(encodeURIComponent(JSON.stringify(this.outputMachineIDsOnly))));
            },
            getMachineInfoDetails() {
                DeereBuildYourOwnService.getMachineInfo(this.$route.query.machine)
                    .then(data => {
                        if (data.length == 0) {
                            return;
                        }

                        this.machine = {
                            Id: data[0].Id,
                            Name: data[0].Title,
                            StandardFeatureUrl: data[0].Brochure1Url,
                            Images: data[0].Images,
                            BasePrice: data[0].ListPrice,
                            StartingPrice: data[0].StartingPrice,
                            Customization: data[0].Customization,
                            UrlName: data[0].UrlName,
                            Discounts: []
                        };

                        if (this.machine.Customization.length > 0) {
                            let query = this.machine.Customization.reduce((result, value) => {
                                return (result = [...result, `Id eq ${value.Id}`]);
                            }, []).join(' or ');

                            let customizationsPromise = DeereBuildYourOwnService.getCustomizationsByIds(query);
                            let discountsPromise = DeereBuildYourOwnService.getDiscountsByMachine(
                                this.$route.query.machine
                            );

                            // Discounts only apply to machines with specific customizations selected
                            Promise.all([customizationsPromise, discountsPromise]).then(response => {
                                let customizations = response[0];
                                let discounts = response[1];

                                this.machine.Customization = customizations.map(item => ({
                                    ...item,
                                    IsSelected: item.IsDefault
                                }));

                                this.machine.Discounts = discounts.map(item => ({
                                    ...item,
                                    IsEligible: false
                                }));

                                this.buildSidebarSettings();

                                this.defaultMachine = deepCopy({
                                    ...this.machine,
                                    Customization: this.machine.Customization.filter(item => item.IsDefault)
                                });

                                this.loadPreviousInformation();
                            });
                        } else {
                            this.defaultMachine = deepCopy(this.machine);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            loadPreviousInformation() {
                // Load selected settings if the information comes in the query string
                // the build details are decoded here
                if (this.$route.query.machineDetails) {
                    let machineDetails = JSON.parse(atob(this.$route.query.machineDetails));
                    let specs = this.machine.Customization
                        .filter(customization => machineDetails.includes(customization.Id));

                    if (specs.length > 0) {
                        for (const spec of specs) {
                            this.setOption(spec);
                        }
                    }
                } else if (this.storedMachineDetails) {
                    // THIS IS SET IF LOCALSTORAGE IS ALREADY HOLDING INFORMATION FROM A PREVIOUS BUILD IN THE SAME CPU'S BROWSER
                    if (
                        this.storedMachineDetails.UrlName === this.$route.query.machine &&
                        this.storedMachineDetails.Customization.length > 0
                    ) {
                        for (const spec of this.storedMachineDetails.Customization) {
                            this.setOption(spec);
                        }
                    }
                }
            },
            setBuilderUrl() {
                let base = this.$router.options.base;
                if (base.substring(base.length - 1, base.length) === '/') {
                    base = base.substring(0, base.length - 1);
                }

                let machineString = `?machine=${this.$route.query.machine}`;
                let machineDetailsString = '';
                if (this.sidebarSettings.length > 0) {
                    // the details are ONLY encoded here
                    machineDetailsString = `&machineDetails=${this.encodeMachine()}`;
                }
                let url = `${window.location.origin}${base}${
                    this.$route.path
                }${machineString}${machineDetailsString}`;

                this.builderUrl = url;
            },
            copy() {
                this.setBuilderUrl();
                utilities.copyTextToClipboad(this.builderUrl);
                alert('Link copied to clipboard');
            },
            openLightboxOnSlide(source) {
                this.source = source;
                this.toggler = !this.toggler;
            },
            changeFinancingYears(years) {
                this.financingYear = Math.max(1, years);
                this.financingYear = Math.min(years, this.maximumFinancingYears);
            }
        }
    };
</script>
