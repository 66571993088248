<template>
    <div id="header-mobile-rounded" class="d-lg-none">
        <!-- TOP BAR -->
        <div
            class="d-flex justify-content-between p-1"
            :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
        >
            <location-list-dropdown :rounded="true"></location-list-dropdown>
            <div class="d-flex justify-content-end">
                <brow-links class="menu-bricks-right" :weather-page="weatherPage"></brow-links>
                <saved-equipment class="ml-1" icon-class="text-primary" :rounded="true" />
            </div>
        </div>
        <!-- LOGO & MENU TRIGGER -->
        <div class="row align-items-center no-gutters">
            <div class="col-9">
                <span class="logo">
                    <a href="/">
                        <img :src="$_logoUrl" :alt="$_logoAlt" />
                    </a>
                </span>
            </div>
            <div class="col-3">
                <header-mobile-pusher />
            </div>
        </div>
        <!-- SEARCH -->
        <div class="border-top py-2 px-4 search-bar">
            <form class="suggestions-bar" @submit.prevent="$_mobileSearch(searchTerm)">
                <input
                    v-model="searchTerm"
                    class="w-100"
                    type="search"
                    :placeholder="$_mobileSearchPlaceholder"
                />
            </form>
        </div>
    </div>
</template>

<script>
    import BrowLinks from '../BrowLinks';
    import HeaderMobilePusher from './HeaderMobilePusher';
    import LocationListDropdown from '../LocationListDropdown';
    import SavedEquipment from '../../saved-equipment/SavedEquipment';

    import HeaderMixin from '../../mixins/HeaderMixin';
    import ThemeMixin from '../../mixins/ThemeMixin';

    export default {
        name: 'HeaderMobileRounded',
        components: {
            BrowLinks,
            HeaderMobilePusher,
            LocationListDropdown,
            SavedEquipment
        },
        mixins: [HeaderMixin, ThemeMixin],
        props: {
            weatherPage: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                searchTerm: ''
            };
        }
    };
</script>
