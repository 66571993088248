<!--
Used equipment filters within a modal for use on mobile devices.
-->
<template>
    <div class="col d-md-none">
        <div class="row">
            <search-status class="col" :filter-parameters="filterParameters"></search-status>
        </div>

        <!-- modal toggle -->
        <btn-icon
            :btn-style="$_isLightTheme ? 'btn-gray-200' : 'btn-gray-800'"
            class="mt-3"
            icon-left="fa-filter"
            data-toggle="modal"
            data-target="#sitewide-search-modal"
        >Refine</btn-icon>

        <modal-pane id="sitewide-search-modal" ref="searchModal" title="Refine Used Equipment">
            <search-status
                :class="[
                    'sticky-top',
                    { 'bg-gray-100 border-bottom': $_isLightTheme, 'bg-gray-900': $_isDarkTheme }
                ]"
                :filter-parameters="filterParameters"
            ></search-status>

            <div class="mt-3 px-3">
                <!-- keywords filter -->
                <div class="form-group">
                    <label for="modal-keywords-input" class="font-size-xs mb-0">Keywords</label>
                    <br />
                    <eb-suggestions
                        id="modal-keywords-input"
                    ></eb-suggestions>
                </div>
                <!-- custom filters -->
                <div v-if="filterOptions.length > 0" class="form-group">
                    <label for="filter-type">Filter By</label>
                    <br />
                    <eb-static-filters-buttons-list
                        id="filter"
                        btn-class="btn btn-primary mb-1 mr-1 rounded-0"
                        :filters="filterOptions"
                    ></eb-static-filters-buttons-list>
                </div>
                <!-- category filter -->
                <search-multi-select
                    field="ResultCategories"
                    field-type="collection"
                    label="Category"
                    parameter-name="category"
                ></search-multi-select>
                <!-- equipment type filter -->
                <search-multi-select
                    field="EquipmentType"
                    field-type="collection"
                    label="Equipment Type"
                    parameter-name="equipmenttype"
                ></search-multi-select>
                <!-- make filter -->
                <search-multi-select
                    field="Manufacturer"
                    label="Make"
                    parameter-name="make"
                ></search-multi-select>
                <!-- model filter -->
                <search-multi-select field="Model" label="Model" parameter-name="model"></search-multi-select>
                <!-- location filter -->
                <search-multi-select
                    field="ResultLocation"
                    label="Location"
                    parameter-name="location"
                ></search-multi-select>
                <!-- year range filter -->
                <eb-range-slider-filter
                    range-min-class="form-control mt-3"
                    range-max-class="form-control mt-3 ml-auto"
                    badge-min-class="badge badge-secondary"
                    badge-max-class="badge badge-secondary ml-auto"
                    slider-id="year-range-filter"
                    label="Year"
                    field="Year"
                    :exclude-zero="true"
                ></eb-range-slider-filter>
                <!-- price range filter -->
                <eb-range-slider-filter
                    range-min-class="form-control mt-3"
                    range-max-class="form-control ml-5 mt-3 ml-auto"
                    badge-min-class="badge badge-secondary"
                    badge-max-class="badge badge-secondary ml-auto"
                    slider-id="price-range-filter"
                    label="Price"
                    field="Price"
                    :is-money="true"
                ></eb-range-slider-filter>
            </div>

            <!-- modal footer -->
            <template #footer>
                <eb-reset
                    btn-class="btn btn-gray-100 flex-fill mr-0 py-3 rounded-0 font-width-condensed text-uppercase"
                >Clear Filters</eb-reset>
                <button
                    type="button"
                    class="btn btn-primary flex-fill ml-0 py-3 rounded-0 font-width-condensed text-uppercase"
                    data-dismiss="modal"
                >Done</button>
            </template>
        </modal-pane>
    </div>
</template>

<script>
    import BtnIcon from '../utility/BtnIcon';
    import ModalPane from '../utility/ModalPane';
    import SearchMultiSelect from '../search/SearchMultiSelect';
    import SearchStatus from '../search/SearchStatusMobile';
    import ThemeMixin from '../mixins/ThemeMixin';

    export default {
        name: 'UsedEquipmentModal',
        components: {
            BtnIcon,
            ModalPane,
            SearchMultiSelect,
            SearchStatus
        },
        mixins: [ThemeMixin],
        props: {
            /**
             * List of form state entries that should be shown in the filters badges list.
             */
            filterParameters: {
                type: Array,
                required: true
            },
            /**
             * Used to generate list of buttons for applying static filters
             */
            filterOptions: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                isSearchFormComponent: true
            };
        },
        methods: {
            /**
             * Execute applyFormState method of the form components in the modal
             * since Blaze Search doesn't recursively search for nested form fields.
             */
            applyFormState() {
                this.$refs.searchModal.$children.forEach(c => {
                    if (c.isSearchFormComponent) {
                        c.applyFormState();
                    }
                });
            }
        }
    };
</script>
