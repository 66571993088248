<template>
    <div class="equipment-box m-2">
        <div class="row no-gutters">
            <div class="col-6 d-flex align-items-center">
                <a v-if="machine.Images" class="d-flex w-100 p-2" :href="machine.FullUrl">
                    <element-image :src="machine.Images.split(',')[0]" alt="Machine Image" height="250" />
                </a>
            </div>
            <div class="col-6 d-flex flex-wrap justify-content-start p-3">
                <a :href="machine.FullUrl" class="w-100">
                    <h5 id="machine-title" class="text-left pb-1">{{ machine.Title }}</h5>
                </a>
                <a v-if="machine.Brochure1Url" :href="machine.Brochure1Url" class="text-left" target="_blank">
                    <strong>{{ machine.Brochure1Title }}</strong>
                </a>

                <!--THESE ARE ONLY FOR USED EQ-->
                <div v-if="machine.OperationHours || machine.EquipmentYear" class="d-flex flex-wrap pb-2">
                    <div v-if="machine.OperationHours" class="w-100">
                        <strong>Hours:</strong> {{ machine.OperationHours }}
                    </div>
                    <div v-if="machine.EquipmentYear"><strong>Year:</strong> {{ machine.EquipmentYear }}</div>
                </div>

                <a class="d-flex align-items-end w-100" :href="machine.FullUrl">
                    <button class="btn btn-primary text-primary-yiq">View Details</button>
                </a>
            </div>
        </div>
        <div class="d-flex cursor-pointer">
            <div class="d-flex justify-content-center w-50 py-3" @click="toggleCompare">
                <i v-if="machine.Compare" class="fa fa-check-square m-1"></i>
                <i v-else class="fa fa-square m-1"></i>
                Compare
            </div>
            <div
                :key="machine.Id"
                class="d-flex justify-content-center w-50 py-3 text-danger"
                @click="deleteMachine(machine)"
            >
                <i class="fa fa-trash m-1"></i>Delete
            </div>
        </div>
    </div>
</template>

<script>
    import { equipmentPlaceholderImage } from '../../config/constants';
    import SavedMachineService from '../../../services/SavedMachineService';

    export default {
        name: 'SavedEquipmentItem',
        props: {
            machine: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                placeholderImage: equipmentPlaceholderImage
            };
        },
        methods: {
            deleteMachine(machine) {
                SavedMachineService.deleteMachine(machine);
            },
            formatPrice(value) {
                return `${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            },
            toggleCompare() {
                SavedMachineService.toggleCompare(this.machine);
            }
        }
    };
</script>
