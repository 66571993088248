﻿<template>
    <div ref="wrapper" class="location-list">
        <div class="container">
            <!-- show the user's preferred location above the list, if selection has been made. -->
            <div v-if="$_selectedLocation">
                <div class="row">
                    <div class="col-lg-4 col-sm-12 align-self-center p-3 pt-md-4">
                        <h6 class="mb-3">
                            <span class="underline text-primary">Your Location</span>
                        </h6>
                        <div class="mb-md-3">
                            <h3>{{ $_selectedLocation.DealerLocationTitle }}</h3>
                            <h5
                                v-if="
                                    locationName($_selectedLocation) != $_selectedLocation.DealerLocationTitle
                                "
                            >
                                {{ locationName($_selectedLocation) }}
                            </h5>
                            <p v-html="locationFullAddress($_selectedLocation)"></p>
                            <a :href="telLink($_selectedLocation.Phone)" class="font-weight-bold">{{
                                $_selectedLocation.Phone
                            }}</a>
                            <div class="mt-3">
                                <router-link
                                    v-if="enableRouter"
                                    class="btn btn-primary"
                                    :to="{
                                        name: 'details',
                                        params: {
                                            slug: $_selectedLocation.UrlName,
                                            location: $_selectedLocation
                                        }
                                    }"
                                    >View Store Info</router-link
                                >
                                <a v-else class="btn btn-primary" :href="getLocationUrl($_selectedLocation)"
                                    >View Store Info</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-sm-12 featured-img-box p-2">
                        <div ref="selectedLocationMap" class="map"></div>
                    </div>
                </div>
            </div>
            <div v-show="!$_selectedLocation" class="row p-2">
                <div ref="allLocationsMap" class="map map-all-locations"></div>
            </div>
            <div v-if="dealerGroups && dealerGroups.length > 0" class="row p-2">
                <div class="dealer-location-filter d-flex align-items-center">
                    <label for="locationFilter" class="my-auto text-nowrap">Filter by Category:</label>
                    <select
                        id="locationFilter"
                        v-model="selectedFilter"
                        class="form-control"
                        @change="onLocationFilterChanged($event)"
                    >
                        <option value>Show All</option>
                        <option v-for="option in dealerGroups" :key="option.Id" :value="option.Id">
                            {{ option.Title }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div v-for="(location, i) in locations" :key="i" class="col-sm-12 col-md-4 mb-2 mb-md-5 p-2">
                    <card-standard
                        class="h-md-100"
                        :enable-image="location.Image.length > 0"
                        :image="{
                            src:
                                location.Image.length > 0 ? location.Image[location.Image.length - 1].Url : ''
                        }"
                        :title="location.DealerLocationTitle"
                        :subtitle="
                            locationName(location) != location.DealerLocationTitle
                                ? locationName(location)
                                : ''
                        "
                        :title-size="5"
                        :enable-button-link="true"
                        action-name="View Store Info"
                        :action-url="getLocationUrl(location)"
                        btn-style="btn-primary text-center"
                        :use-block-btn="true"
                        :use-router="enableRouter"
                        :route="{
                            name: 'details',
                            params: { slug: location.UrlName, location: location }
                        }"
                    >
                        <span v-html="locationFullAddress(location)"></span>
                        <br />
                        <a :href="telLink(location.Phone)" class="font-weight-bold">{{ location.Phone }}</a>
                        <span v-if="checkOpenNow(location)" class="text-primary">Open Now</span>
                    </card-standard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import constants from '../../config/constants';

    import CardStandard from '../cards/CardStandard';
    import LocationService from '../../../services/LocationService';
    import LocationMixin from '../mixins/LocationMixin';
    import UtilitiesMixin from '../mixins/UtilitiesMixin';

    export default {
        name: 'LocationList',
        components: {
            CardStandard
        },
        mixins: [LocationMixin, UtilitiesMixin],
        props: {
            backgroundImage: {
                type: String,
                required: true
            },
            excludedLocations: {
                type: String,
                required: true
            },
            enableRouter: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                locations: [],
                globalSettings: [],
                dealerGroups: [],
                selectedFilter: '',
                elementConfig: constants.elementConfig
            };
        },
        computed: {
            getLocationUrl() {
                return location => `${this.elementConfig.SITEMAP.LOCATIONS}/${location.UrlName}`;
            }
        },
        watch: {
            $_selectedLocation: {
                handler: function(newVal) {
                    // Next tick is necessary to give the map div time to load into the DOM if selectedLocation was previously undefined (or vise-versa)
                    this.$nextTick(() => {
                        if (newVal) {
                            // init map for the selected location
                            this.initMap(this.$refs.selectedLocationMap, [this.$_selectedLocation]);
                        } else {
                            // Reload the locations map if user removed their selected store
                            this.initMap(this.$refs.allLocationsMap, this.locations);
                        }
                    });
                },
                immediate: true
            }
        },
        mounted() {
            // This goes in the mounted hook to allow the DOM to load first
            this.setBackground(this.$refs.wrapper, this.backgroundImage);

            // Load the user's selected location on the map
            if (this.$_selectedLocation) {
                this.initMap(this.$refs.selectedLocationMap, [this.$_selectedLocation]);
            }

            // Get location filters
            this.getLocationFilters();

            this.setLocations();
        },
        methods: {
            setLocations(filter = '') {
                this.locations = [];

                LocationService.getLocations(undefined, undefined, filter).then(result => {
                    this.globalSettings = result.globalSettings;

                    // Remove excluded locations from the locations list
                    this.locations = result.locations.filter(
                        el =>
                            this.excludedLocations.split(',').indexOf(el.DealerLocationTitle) < 0 &&
                            !el.HideLocation
                    );

                    // Reload the markers in the locations map if it's currently shown
                    if (!this.$_selectedLocation) {
                        this.initMap(this.$refs.allLocationsMap, this.locations);
                    }
                });
            },
            onLocationFilterChanged(e) {
                this.selectedFilter = e.target.value;
                this.setLocations(e.target.value);
            },
            getLocationFilters() {
                this.dealerGroups = [];
                this.selectedFilter = '';
                let dgId = 0;

                // get dealer-group taxonomy id
                axios.get("/api/default/taxonomies?$filter=Name eq 'dealer-groups'").then(response => {
                    if (response.data.value && response.data.value.length > 0 && response.data.value[0].Id) {
                        dgId = response.data.value[0].Id;

                        // get all values for the dealer-group taxonomy
                        axios
                            .get('/api/default/flat-taxa?$select=Id,Title&$filter=TaxonomyId eq ' + dgId)
                            .then(resp => {
                                if (resp.data.value) {
                                    this.dealerGroups = resp.data.value;
                                }
                            });
                    }
                });
            }
        }
    };
</script>
