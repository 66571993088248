﻿import axios from 'axios';

function getVagueLocation() {
    return new Promise(function(resolve) {
        axios.get('https://ipapi.co/json/').then(function(data) {
            resolve({
                lat: data.data.latitude,
                lng: data.data.longitude
            });
        });
    });
}

export function getTemperature() {
    return new Promise(function(resolve) {
        getVagueLocation().then(function(data) {
            axios.get(`/api/weather/weather?lat=${data.lat}&lng=${data.lng}`).then(function(data) {
                resolve({
                    temp: data.data.currently.temperature,
                    icon: data.data.currently.icon
                });
            });
        });
    });
}

export default {
    getTemperature
};
