﻿<template>
    <div class="px-3 py-2 machine-detail-content">
        <h1 class="text-center">Specifications</h1>
        <div
            v-if="machine.Specifications"
            class="machine-specs my-3 mx-3 text-left"
            v-html="machine.Specifications"
        ></div>
        <div v-else-if="machine.SpecsImage" class="machine-specs-img">
            <img :src="machine.SpecsImage" alt="machine specifications" />
            <a
                v-if="machine.SpecsPdf"
                class="brochure btn text link w-100 mt-2"
                :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                style="max-width: 80%"
                :href="machine.SpecsPdf"
            >
                <span class="fas fa-download showroom-color-primary"></span>
                Download Specs
            </a>
        </div>
        <div v-if="machine.ViewSpecsUrl" class="text-left mx-3">
            <a class="specs-link" target="_blank" :href="machine.ViewSpecsUrl">View Full Specs</a>
        </div>
    </div>
</template>

<script>
    import ThemeMixin from '../mixins/ThemeMixin';

    export default {
        name: 'EquipmentDetailSpecifications',
        mixins: [ThemeMixin],
        props: {
            machine: {
                type: Object,
                default: () => ({})
            }
        }
    };
</script>
