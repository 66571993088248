<template>
    <div>
        <div class="ribbon-badge d-inline-flex overflow-hidden position-relative">
            <span
                class="ribbon-top-left-corner position-absolute"
                :class="[backgroundClass, { 'd-none': flattenLeft }]"
            ></span>
            <span
                class="ribbon-bottom-left-corner position-absolute"
                :class="[backgroundClass, { 'd-none': flattenLeft }]"
            ></span>
            <small
                class="ribbon-text font-weight-bold px-2 py-1 text-nowrap text-truncate overflow-hidden"
                :class="[backgroundClass, textClass]"
            >
                <slot />
            </small>
            <span class="ribbon-top-right-corner position-absolute" :class="backgroundClass"></span>
            <span class="ribbon-bottom-right-corner position-absolute" :class="backgroundClass"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RibbonBadge',
        props: {
            backgroundClass: {
                type: String,
                default: 'bg-secondary'
            },
            flattenLeft: {
                type: Boolean,
                default: false
            },
            flattenRight: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            textClass() {
                if (this.flattenLeft && this.flattenRight) {
                    return '';
                } else if (this.flattenLeft) {
                    return 'mr-4';
                } else if (this.flattenRight) {
                    return 'ml-4';
                }

                return 'mx-4';
            }
        }
    };
</script>
