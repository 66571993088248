<template>
    <div
        :class="['card card-horizontal mb-3 p-3 bg-light theme-dark__bg-gray-800', { shadow: enableShadow }]"
    >
        <div class="row no-gutters">
            <div v-if="badges && badges.length > 0" class="col-12 mb-1">
                <span v-for="(val, index) in badges" :key="index" class="badge badge-primary mr-1">{{
                    val
                }}</span>
            </div>
            <div v-if="image || showPlaceholderImage" class="col-md-4">
                <router-link v-if="useRouter" :to="url" :target="target">
                    <element-image
                        img-class="card-img align-self-start rounded-0 mb-3 mb-md-0"
                        :src="image"
                        :alt="title"
                    />
                </router-link>
                <a v-else :href="url" :target="target">
                    <element-image
                        img-class="card-img align-self-start rounded-0 mb-3 mb-md-0"
                        :src="image"
                        :alt="title"
                    />
                </a>
            </div>
            <!-- column width not set here so it can expand when image isn't available -->
            <div class="col align-left">
                <div class="card-body py-0">
                    <component :is="`h${titleSize}`" class="text-uppercase">
                        <router-link v-if="useRouter" :to="url" :target="target">{{ title }}</router-link>
                        <a v-else :href="url" :target="target" v-html="title"></a>
                    </component>
                    <slot></slot>
                </div>
            </div>
            <div class="col-md-auto d-flex" :class="buttonColumnClass">
                <router-link v-if="useRouter" :to="url" :target="target" class="btn btn-primary">
                    View Details
                </router-link>
                <a v-if="enableLink" :href="url" :target="target" class="btn btn-primary">{{
                    actionLabel
                }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardHorizontal',
        props: {
            badges: {
                default: () => [],
                type: Array
            },
            buttonColumnClass: {
                type: String,
                default: 'justify-content-center align-items-start'
            },
            image: {
                type: String,
                default: ''
            },
            /**
             * When false and no image exists,
             * middle column will expand to the left to fill available space.
             */
            showPlaceholderImage: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: ''
            },
            titleSize: {
                type: Number,
                default: 3
            },
            actionName: {
                type: String,
                default: ''
            },
            url: {
                type: String,
                default: ''
            },
            useRouter: {
                type: Boolean,
                default: false
            },
            enableShadow: {
                type: Boolean,
                default: true
            },
            openLinkInNewTab: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            actionLabel() {
                return this.actionName || 'View Details';
            },
            enableLink() {
                return !this.useRouter && this.url && this.actionLabel;
            },
            target() {
                return this.openLinkInNewTab ? '_blank' : '_self';
            }
        }
    };
</script>
