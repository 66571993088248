<template>
    <div class="card card-image-overlay card-rounded h-100">
        <div class="image-overlay position-absolute w-100" :style="background"></div>
        <div class="position-relative text-center py-5 px-3">
            <h3 class="card-title text-uppercase text-white" v-html="title"></h3>
            <h3 v-if="subtitle" class="card-subtitle font-italic mb-3 text-white" v-html="subtitle"></h3>
            <div v-if="enableLink" class="card-footer pt-3 pb-0">
              <a :href="actionUrl" :target="target">
                <div class="btn btn-secondary font-weight-bold">{{ actionName }}</div>
              </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { optimizeImage } from '../../lib/utilities';

    export default {
        name: 'CardImageOverlay',
        props: {
            actionName: {
                type: String,
                default: ''
            },
            actionUrl: {
                type: String,
                default: ''
            },
            enableShadow: {
                type: Boolean,
                default: true
            },
            image: {
                type: Object,
                required: true
            },
            subtitle: {
                type: String,
                default: ''
            },
            openLinkInNewTab: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            }
        },
        computed: {
            background() {
                let imgSrc = optimizeImage(this.image.src);
                return `background-image: url(${imgSrc});`;
            },
            enableLink() {
                return this.actionName && this.actionUrl;
            },
            target() {
                return this.openLinkInNewTab ? '_blank' : '_self';
            }
        }
    };
</script>
