﻿<script>
    import ContentLocatorService from '../../../services/ContentLocatorService';
    import constants from '../../config/constants';

    export default {
        data() {
            return {
                primaryContentTypes: [...searchConfig.activeNewEqContentTypes, searchConfig.usedEqContentType],
                secondaryContentTypes: [
                    ...searchConfig.activePromotionsContentTypes,
                    searchConfig.blogPostContentType,
                    searchConfig.eventItemContentType,
                    searchConfig.newsItemContentType,
                    searchConfig.pageContentType,
                    searchConfig.testimonialContentType
                ],
                $_showResultLocation: constants.elementConfig.HAS_MULTIPLE_LOCATIONS
            };
        },
        methods: {
            shorten(str, maxLen, separator = ' ') {
                if (str && str.length > maxLen) {
                    return str.substr(0, str.lastIndexOf(separator, maxLen)) + '...';
                }
                return str;
            },
            getResultBadges(item) {
                let badges = (Array.isArray(item.ResultTags) && item.ResultTags) || [];

                // if no badges are found, apply the content name, provided it doesn't match a equipment condition (new/used)
                // (some dealers don't want to list the eq condition)
                if (badges.length === 0) {
                    let contentName = this.getResultContentType(item.ContentType)?.description || 'Content';
                    if (!constants.inventoryConditions.includes(contentName)) {
                        badges.push(contentName);
                    }
                }

                return badges;
            },
            getResultContentType(typeName) {
                return [...this.primaryContentTypes, ...this.secondaryContentTypes].find(
                    x => x.typeName === typeName
                );
            },
            getResultImage(item) {
                if (item.ResultImages) {
                    let images = item.ResultImages.split(',');
                    if (images && images.length > 0 && images[0].length > 0) {
                        return images[0];
                    }
                }

                return constants.equipmentPlaceholderImage;
            },
            getResultTitle(item) {
                let title =
                    (item.Year &&
                        item.Manufacturer &&
                        item.Model &&
                        `${item.Year} ${item.Manufacturer} <span class="text-primary">${item.Model}</span>`) ||
                    item.Name ||
                    item.Title ||
                    item.ResultTitle;

                return this.shorten(title, 30);
            },
            getResultDescription(item) {
                return this.shorten(item.Summary, 100);
            },
            getResultUrl(item) {
                return ContentLocatorService.getUrl(item);
            }
        }
    };
</script>
