﻿<template>
    <div class="skeleton">
        <div v-if="useTitle" class="skeleton-title" :style="`width: ${titleLength}%`"></div>
        <div v-for="(line, i) in lines" :key="i" class="skeleton-item" :style="`width: ${line}%`"></div>
    </div>
</template>

<script>
    import { generateRandomNumber } from '../../lib/utilities';

    export default {
        name: 'ContentSkeleton',
        props: {
            useTitle: {
                type: Boolean,
                required: true
            },
            titleLength: {
                type: Number,
                default: 75
            },
            lineLengths: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            lines() {
                if (this.lineLengths.length > 0) {
                    return this.lineLengths;
                }

                return Array.from(Array(generateRandomNumber(3, 5))).map(x => generateRandomNumber(45, 100));
            }
        }
    };
</script>
