﻿<template>
    <div class="hero-wrapper">
        <div ref="heroImage" class="new-eq-showroom-hero showroom-bg-color-primary d-none d-md-block">
            <div class="hero-text" v-html="heroText"></div>
        </div>

        <span class="showroom-bg-color-primary text-uppercase d-block d-sm-block d-md-none pt-3"
            >Choose an industry</span
        >
        <div class="nav-wrapper d-flex d-md-none showroom-bg-color-primary">
            <router-link
                v-for="(category, i) in categories"
                :key="i"
                :to="category.UrlName"
                class="category-link m-2"
            >
                <img v-if="categoryImage(category)"
                     class="category-image"
                     :src="categoryImage(category)"
                     :alt="htmlDecode(category.Name)"
                     />
                <h5 class="my-3">{{ htmlDecode(category.Name) }}</h5>
            </router-link>
        </div>

        <div class="featured-item-wrapper d-none d-md-flex">
            <div class="featured-item showroom-bg-color-primary">
                <div class="featured-item-text">
                    <h2>{{ featuredItemTitle }}</h2>
                    <p v-html="featuredItemSummary"></p>
                    <router-link class="btn showroom-bg-color-primary" :to="featuredItemUrl"
                        >See Details</router-link
                    >
                </div>
                <div class="triangle-right showroom-color-primary"></div>
            </div>

            <div ref="itemImage" class="featured-item-image"></div>
        </div>
    </div>
</template>

<script>
    import NewEquipmentMixins from '../mixins/NewEquipmentMixin';

    export default {
        mixins: [NewEquipmentMixins],
        props: [
            'heroImage',
            'featuredItem',
            'heroText',
            'heroTitle',
            'level',
            'manufacturer',
            'selectionMode',
            'selectedIds'
        ],
        data() {
            return {
                categories: []
            };
        },
        computed: {
            featuredItemTitle() {
                const title = featuredItem && featuredItem.Title && featuredItem.Title.Value;
                return title ? this.htmlDecode(title) : '';
            },
            featuredItemSummary() {
                return featuredItem && featuredItem.Summary && featuredItem.Summary.Value;
            },
            featuredItemUrl() {
                let machine = this.featuredItem;
                if (machine.Hierarchy && machine.Hierarchy.Value) {
                    let hierarchy = machine.Hierarchy.Value.split('~')[1];
                    return `/${hierarchy
                        .split('|')[0]
                        .toLowerCase()
                        .replace(/\s/g, '-')}/${hierarchy
                        .split('|')[1]
                        .toLowerCase()
                        .replace(/\s/g, '-')}/${machine.UrlName.Value}/overview`;
                }
                return `/${machine.UrlName}`;
            }
        },
        watch: {
            heroImage: function(newValue) {
                if (newValue) {
                    this.$refs.heroImage.style.cssText = `background-image: url('${newValue}');`;
                }
            },
            featuredItem: function(newValue) {
                if (newValue && newValue.image) {
                    this.$refs.itemImage.style.cssText = `background-image: url('${newValue.image}');`;
                }
            }
        },
        created() {
            var options = { $expand: true, $orderby: 'Name' };

            // gets data for mobile view
            this.getNewEquipmentBySelection(
                this.selectionMode,
                this.manufacturer,
                this.level,
                this.selectedIds,
                options
            ).then(results => {
                this.categories = results;
            });
        },
        mounted() {
            if (!!this.heroImage && !!this.heroImage.ItemDefaultUrl) {
                this.$refs.heroImage.style.cssText = `background-image: url('${this.heroImage.ItemDefaultUrl.Value + this.heroImage.Extension}');`;
            }
            var featuredImage =
                this.featuredItem &&
                this.featuredItem.Images &&
                this.featuredItem.Images.Value &&
                this.featuredItem.Images.Value.split(',')[0];
            featuredImage =
                featuredImage || (this.featuredItem && this.featuredItem.Image && this.featuredItem.Image.Value);
            if (featuredImage) {
                this.$refs.itemImage.style.cssText = `background-image: url('${featuredImage}');`;
            }
        },
        methods: {
            categoryImage(category) {
                return (
                    (category.Image && category.Image.split(',')[0]) ||
                    (category.Images && category.Images.split(',')[0])
                );
            },
            htmlDecode(html) {
                var el = document.createElement('textarea');
                el.innerHTML = html;
                return el.value;
            }
        }
    };
</script>
