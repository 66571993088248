﻿<template>
    <div class="build-your-own-header -darkgrey edge--bottom p-3">
        <div class="container">
            <div class="stacked">
                <div class="col-md-6 col-sm-12">
                    <h4 class="main-text">Select a Product</h4>
                </div>
                <div class="col-md-12 col-sm-12">
                    <ol class="list-inline text-uppercase">
                        <li class="list-inline-item" v-for="(path, i) in pathCollection" :key="i">
                            <router-link tag="a" :to="path.urlName">{{ path.name }}</router-link>
                            <span v-if="i + 1 < pathCollection.length">&nbsp;/&nbsp;</span>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
	name: 'BuildYourOwnNavigationBar',
	props: {
		header: {
			default: () => '',
			type: String
		}
	},
	computed: {
		pathCollection() {
			let path = [
				{
					urlName: '/',
					name: 'Home'
				}
			];
			let routeParams = this.$route.params;
			for (const [i, param] of Object.keys(routeParams).entries()) {
				path.push({
					urlName: `/${Object.values(routeParams)
						.slice(0, i + 1)
						.join('/')}`,
					name: routeParams[param].replace(/-/g, ' ')
				});
			}

			return path;
		}
	}
};
</script>
