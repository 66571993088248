﻿<!--
Root component for Sitefinity widgets that have listing and detail components
-->
<template>
    <router-view />
</template>
<script>
    export default {
        name: 'RouterViewWrapper'
    };
</script>