<template>
    <div class="container pt-5">
        <div class="position-relative bg-primary">
            <h3 class="text-primary-yiq ml-3 py-4">Header Search Bar Example</h3>
            <search-bar />
        </div>
    </div>
</template>

<script>
    import searchBar from '../components/search/SearchBar.vue';
    export default {
        name: 'HomepageWidgets',
        components: {
            searchBar
        }
    };
</script>
