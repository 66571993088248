<template>
    <div class="d-md-none">
        <!-- modal trigger -->
        <btn-icon
            data-toggle="modal"
            data-target="#mobile-nav-modal"
            icon-left
            icon-right="fa-chevron-down"
            icon-color="#fff"
            btn-style="btn-primary rounded-0"
        >{{ selectedItem || 'Choose a category' }}</btn-icon>

        <!-- modal -->
        <modal-pane id="mobile-nav-modal" title="Choose a category">
            <eb-facets-tree-filter
                id="showroom-mobile-category-tree"
                wrapper-class="text-uppercase font-size-lg"
                hierarchy-field="ResultHierarchy"
                filter-field="ResultHierarchyPath"
                :use-isolated-facets-request="true"
                :form-fields-to-clear="['q', 'p']"
            ></eb-facets-tree-filter>
        </modal-pane>
    </div>
</template>
<script>
    import BtnIcon from '../utility/BtnIcon';
    import ModalPane from '../utility/ModalPane';

    export default {
        components: {
            BtnIcon,
            ModalPane
        },
        props: {
            selectedItem: {
                type: String
            }
        }
    };
</script>