﻿<template>
    <a :class="['card border-left-0 border-top-0 border-right-0 rounded-0 mt-3', textClass]" :href="url">
        <div class="row no-gutters">
            <div class="col-3 my-auto">
                <element-image
                    img-class="card-img align-self-start rounded-0 mb-3 mb-md-0"
                    :src="image"
                    width="434"
                    :alt="title"
                />
            </div>
            <div class="col align-left">
                <div class="card-body py-0">
                    <h5 class="text-uppercase" v-html="title"></h5>
                    <slot></slot>
                    <div class="d-flex flex-wrap align-items-center mb-2">
                        <span
                            v-for="(val, index) in badges"
                            :key="index"
                            class="badge badge-outline-primary text-uppercase mr-1 mb-1"
                        >
                            {{ val }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-2 mb-1 d-flex flex-column">
                <div class="d-flex flex-grow-1 h6 mx-auto text-primary text-uppercase">View</div>
                <div class="d-flex flex-grow-1 justify-content-center align-items-center">
                    <img v-if="manufacturerLogo" :src="manufacturerLogo" :alt="title" width="35" />
                </div>
            </div>
        </div>
    </a>
</template>

<script>
    import constants from '../../config/constants';

    export default {
        name: 'CardSearchResultPrimary',
        props: {
            badges: {
                type: Array,
                default: () => []
            },
            image: {
                type: String,
                default: ''
            },
            manufacturer: {
                type: String,
                default: 'default'
            },
            textClass: {
                type: String,
                default: 'text-dark'
            },
            title: {
                type: String,
                default: ''
            },
            url: {
                type: String,
                default: ''
            }
        },
        computed: {
            manufacturerLogo() {
                return this.manufacturer && constants.manufacturerLogos[this.manufacturer.toUpperCase()];
            }
        }
    };
</script>
