﻿<template>
    <div ref="pageListing" class="col-lg-3 page-listing d-flex justify-content-center flex-column my-5">
        <a ref="innerPageListing" class="d-block" :href="url" :target="target">
            <h6 class="mb-3">{{ title }}</h6>
            <element-image v-if="image" :src="image" :alt="title" />
        </a>
        <p v-for="(child, i) in children" :key="i" class="m-0">
            <a :href="child.Url.substr(1)" class="p-1" :target="child.Target">{{ child.Title }}</a>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'MegaSubPages',
        props: {
            children: {
                type: Array,
                default: () =>([])
            },
            image: {
                type: String,
                default: ''
            },
            target: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                required: true
            },
            url: {
                type: String,
                required: true
            }
        }
    };
</script>

<style scoped>
    h6:after {
        content: ' ';
        display: block;
        border: 1px solid #858585;
        margin: 0 auto;
        margin-top: 10px;
        width: 25px;
    }

    img {
        width: 100%;
        max-width: 215px;
    }

    .sublink {
        background-color: transparent;
    }
</style>
