<!--
Multiselect filter for Blaze Search that utilizes the native browser multiselect controls.
Useful for triggering the native scrollable control on mobile devices.
-->
<template>
    <div v-show="items.length > 0" class="form-group">
        <label class="d-block font-size-xs mb-0">
            {{ label }}
            <select v-model="selectedItems" class="form-control" multiple>
                <option v-for="(option, i) in items" :key="i" :value="option.value">{{ option.text }}</option>
            </select>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'SearchMultiSelect',
        props: {
            /**
             * The field to retrieve facet values from to populate the selection options
             * and apply filters against as selections are made.
             */
            field: {
                type: String,
                required: true
            },
            fieldType: {
                type: String,
                default: 'string',
                validator: function(value) {
                    // The value must match one of these strings
                    return ['string', 'number', 'collection'].indexOf(value) !== -1;
                }
            },
            label: {
                type: String,
                required: true
            },
            parameterName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                isSearchFormComponent: true,
                items: [],
                selectedItems: [],
                isExecutingFilter: false
            };
        },
        computed: {
            /**
             * Unique values for the specified field within the current search results.
             * Used to refresh the multiselect items list after a search is completed.
             */
            facetOptions() {
                let searchResponse = this.$store.state.search.searchResponse;
                let fieldFacetValues =
                    (searchResponse &&
                        searchResponse['@search.facets'] &&
                        searchResponse['@search.facets'][this.field]) ||
                    [];

                return fieldFacetValues.length > 0
                    ? fieldFacetValues
                          .filter(v => v.value)
                          .sort((a, b) => a.value.toString().localeCompare(b.value.toString()))
                          .map(v => ({ text: `${v.value} (${v.count})`, value: v.value }))
                    : [];
            },
            /**
             * Returns this component's values that exist in the form state.
             * Used to determine which items should be marked as selected.
             */
            stateValues() {
                let values = this.$store.state.search.formState[this.parameterName] || [];
                values = [].concat(values); // normalize to array, even when there's only one selection

                if (this.fieldType === 'number') {
                    values = values.map(Number);
                }

                return values;
            }
        },
        watch: {
            /**
             * Place selection changes in the form state.
             * This will trigger a search automatically if the <eb-index> component is configured to do so.
             */
            selectedItems: function(newValue, oldValue) {
                let oldSelectionCount = this.$store.state.search.formState[this.parameterName]?.length ?? 0;

                if (newValue.length !== oldSelectionCount) {
                    this.isExecutingFilter = true;
                    this.$store.commit('search/addFormValue', { [this.parameterName]: this.selectedItems });
                }
            }
        },
        created() {
            // listen for search completed event
            this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'search/applySearchResponse') {
                    this.onSearchCompleted();
                }
            });
        },
        methods: {
            /**
             * Called by Blaze Search when a change to the form state is detected.
             * Used here so the content filters can be applied before the search is executed.
             */
            applyFormState() {
                if (this.stateValues.length === 0) {
                    this.$store.commit('search/removeFilter', this.field);
                } else {
                    if (this.fieldType === 'collection') {
                        this.$store.commit('search/addFilter', {
                            conditions: [
                                {
                                    filterType: 'search.in',
                                    value: this.stateValues
                                }
                            ],
                            description: this.stateValues.join(' | '),
                            field: this.field,
                            fieldType: 'collection'
                        });
                    } else {
                        this.$store.commit('search/addFilter', {
                            conditions: this.stateValues.map(o => {
                                return {
                                    expression: 'eq',
                                    value: o
                                };
                            }),
                            conditionsOperator: 'or',
                            description: this.stateValues.join(' | '),
                            field: this.field,
                            fieldType: this.fieldType
                        });
                    }
                }
            },
            /**
             * Refresh the selected items and available options when search results are applied if:
             * 1. the search action originated outside this component instance, or
             * 2. the search operation removed the last remaining selection.
             *
             * Otherwise, don't refresh the `<select>` with the filtered/reduced available options
             * since that will prevent the user from making another selection from the same multiselect.
             */
            onSearchCompleted() {
                let lastSelectionRemoved = this.stateValues.length === 0;

                if (!this.isExecutingFilter || lastSelectionRemoved) {
                    this.items = this.facetOptions.slice();
                    this.selectedItems = this.stateValues;
                }

                this.isExecutingFilter = false;
            }
        }
    };
</script>
