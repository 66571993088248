﻿<!--
Utilizes Azure Search's blazing fast suggestions API to pull back suggested items as the user types.

Notes:
- It can do partial word matches but any completely spelled words must be in order for a match to be retrieved.
- If the user presses enter while typing, they'll be redirected to the full search page.
- The regular Azure Search API requires full word matches since fuzzy search isn't turned on,
so partially typed words will give zero results.

Primary Results:
The primary section (on the left) returns new and used equipment that match the keywords the user types.

Secondary Results:
The section to the right includes pages, blog posts, specials, etc. (see the search config for the exact list)
-->
<template>
    <div class="w-100">
        <div :class="['search-bar d-none d-lg-block', cssClass]">
            <button v-if="iconOnly" class="icon" aria-label="Search">
                <span class="fas fa-search text-primary"></span>
            </button>
            <div v-else class="search">
                <eb-index
                    :api-key="elementConfig.BLAZE_API_KEY"
                    :index-name="elementConfig.BLAZE_INDEX_NAME"
                    :service-name="elementConfig.BLAZE_SERVICE_NAME"
                    :persist-form-state="false"
                    :trigger-search-on-form-state-change="false"
                    :scroll-top-pagination="false"
                >
                    <eb-suggestions-bar
                        primary-suggestions-label="Equipment"
                        search-results-page="/search"
                        secondary-suggestions-label="Pages"
                        sort-by="Priority"
                        :is-external-results-page="true"
                        :placeholder="placeholder"
                        :primary-suggestions-filters="primarySuggestionsFilters"
                        :secondary-suggestions-filters="secondarySuggestionsFilters"
                        :select="fieldsToSelect"
                        :top="10"
                    >
                        <template slot="primary-suggestion" slot-scope="{ item }">
                            <card-search-result-primary
                                :badges="getResultBadges(item)"
                                :image="getResultImage(item)"
                                :manufacturer="item.Manufacturer"
                                :title="getResultTitle(item)"
                                :url="getResultUrl(item)"
                            >
                                <p v-html="getResultDescription(item)"></p>
                                <p v-if="item.Price">
                                    <strong>Price: {{ item.Price | money }}</strong>
                                </p>
                            </card-search-result-primary>
                        </template>
                        <template slot="secondary-suggestion" slot-scope="{ item, index }">
                            <card-search-result-secondary
                                :index="index"
                                :badges="getResultBadges(item)"
                                :title="getResultTitle(item)"
                                :url="getResultUrl(item)"
                            ></card-search-result-secondary>
                        </template>
                    </eb-suggestions-bar>
                </eb-index>
            </div>
        </div>
        <div v-if="enableMobileView" :class="['d-lg-none', cssClass]">
            <div class="search">
                <form @submit.prevent="search">
                    <input
                        v-model="searchTerm"
                        class="form-control text-center rounded-0"
                        type="search"
                        placeholder="Tap Here and Search"
                    />
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import constants from '../../config/constants';

    import CardSearchResultPrimary from '../cards/CardSearchResultPrimary';
    import CardSearchResultSecondary from '../cards/CardSearchResultSecondary';
    import SearchResultMixin from '../mixins/SearchResultMixin';

    export default {
        name: 'SearchBar',
        components: {
            CardSearchResultPrimary,
            CardSearchResultSecondary
        },
        mixins: [SearchResultMixin],
        props: {
            iconOnly: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: 'What are you looking for?'
            },
            enableMobileView: {
                type: Boolean,
                default: false
            },
            cssClass: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                elementConfig: constants.elementConfig,
                fieldsToSelect: [
                    'BaseUrl',
                    'ContentType',
                    'HierarchyPath',
                    'Manufacturer',
                    'Model',
                    'Price',
                    'ResultImages',
                    'ResultIndustries',
                    'ResultTags',
                    'ResultTitle',
                    'Summary',
                    'Title',
                    'Url',
                    'UrlName',
                    'Year'
                ].join(','),
                searchTerm: ''
            };
        },
        computed: {
            primarySuggestionsFilters() {
                return [this.getFilterFromContentTypes(this.primaryContentTypes)];
            },
            secondarySuggestionsFilters() {
                return [this.getFilterFromContentTypes(this.secondaryContentTypes)];
            }
        },
        methods: {
            getFilterFromContentTypes(types) {
                return {
                    conditions: types.map(x => ({
                        expression: 'eq',
                        value: x.typeName
                    })),
                    conditionsOperator: 'or',
                    field: 'ContentType',
                    fieldType: 'string'
                };
            },
            search() {
                document.location = `/search?q=${this.searchTerm}`;
            }
        }
    };
</script>
