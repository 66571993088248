<template>
    <build-your-own-content
        :collection="categories"
        :loading="loading"
        :machine-collection="machines"
    ></build-your-own-content>
</template>

<script>
    // Utilities
    import utilities from '../../lib/utilities';
    // Services
    import deereBuildYourOwnService from '../../../services/DeereBuildYourOwnService';
    // Components
    import BuildYourOwnContent from './BuildYourOwnContent';

    export default {
        name: 'BuildYourOwnCategorySelection',
        components: {
            BuildYourOwnContent
        },
        data() {
            return {
                categories: [],
                loading: true,
                machines: []
            };
        },

        mounted() {
            deereBuildYourOwnService.getCategories(this.$route.params.industry).then(categories => {
                this.categories = utilities.sortByKey(categories.Classifications, 'Name');
                this.machines = categories.Products;

                this.loading = false;
            });
        }
    };
</script>
