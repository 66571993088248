<!--
Used equipment search form and results listings for Used Equipment page
-->
<template>
    <eb-index
        id="main-grid-container"
        :api-key="elementConfig.BLAZE_API_KEY"
        :default-filters="usedEquipmentFilters"
        :form-persistence-provider="formPersistenceProvider"
        :index-name="elementConfig.BLAZE_INDEX_NAME"
        :service-name="elementConfig.BLAZE_SERVICE_NAME"
        class="mt-md-3"
    >
        <div v-if="loading">
            <used-equipment-skeleton />
        </div>

        <!-- mobile equipment filters modal -->
        <equipment-filters-modal
            :filter-parameters="filterParameters"
            :filter-options="filterOptions"
        ></equipment-filters-modal>

        <div class="container pt-md-3">
            <!-- search form top bar -->
            <div class="row pt-2 pt-md-3 pb-md-2 align-items-end">
                <!-- "sort by" dropdown -->
                <div class="col-6 col-lg-3">
                    <div class="form-group d-md-flex align-items-md-center mb-2">
                        <label for="results-sort" class="font-weight-bold font-weight-md-normal mb-0 mr-3">
                            Sort
                        </label>
                        <eb-sort-by-dropdown
                            id="results-sort"
                            class="form-control"
                            :options="sortOptions"
                            :form-state-handler="sortByformStateHandler"
                        ></eb-sort-by-dropdown>
                    </div>
                </div>

                <!-- results per page dropdown -->
                <div class="col-6 col-lg-3">
                    <div class="form-group d-md-flex align-items-md-center mb-2">
                        <label
                            for="results-per-page"
                            class="font-weight-bold font-weight-md-normal mb-0 mr-3"
                        >
                            Show
                        </label>
                        <eb-results-per-page-dropdown
                            id="results-per-page"
                            class="form-control"
                            :show="pageSizeOptions"
                        ></eb-results-per-page-dropdown>
                    </div>
                </div>

                <!-- top pagination for mobile -->
                <div class="col-12 d-block d-sm-none">
                    <div class="form-group my-2">
                        <eb-pagination
                            pagination-class="pagination pagination-sm justify-content-center mb-0"
                            :pagination-size="3"
                            item-class="page-item"
                        ></eb-pagination>
                    </div>
                </div>

                <!-- top pagination for desktops -->
                <div class="d-none d-sm-block col-12 col-lg-6">
                    <div class="form-group text-right">
                        <eb-pagination
                            pagination-class="align-self-end pagination justify-content-center justify-content-xl-end"
                            :pagination-size="4"
                        ></eb-pagination>
                    </div>
                </div>
            </div>
        </div>

        <hr class="mt-0 mt-md-2 mb-md-4" />

        <div class="used-equipment container">
            <div class="row">
                <!-- left sidebar (hidden on mobile) -->
                <div class="sidebar rounded col-md-4 p-3 d-none d-md-block">
                    <!-- keyword search -->
                    <div class="form-group">
                        <label for="keywords-input">Keywords</label>
                        <br />
                        <eb-suggestions id="keywords-input"></eb-suggestions>
                    </div>

                    <!-- static filter options -->
                    <div v-if="filterOptions.length > 0" class="form-group">
                        <label for="filter-type">Filter By</label>
                        <br />
                        <eb-static-filters-buttons-list
                            id="filter"
                            btn-class="btn btn-primary mb-1 mr-1 rounded-0"
                            :filters="filterOptions"
                        ></eb-static-filters-buttons-list>
                    </div>

                    <!-- form filter components -->
                    <eb-multi-select-filter
                        id="category"
                        label="Category"
                        field="ResultCategories"
                        field-type="collection"
                    ></eb-multi-select-filter>
                    <eb-multi-select-filter
                        id="equipmenttype"
                        label="Equipment Type"
                        field="EquipmentType"
                    ></eb-multi-select-filter>
                    <eb-multi-select-filter
                        id="make"
                        label="Make"
                        field="Manufacturer"
                    ></eb-multi-select-filter>
                    <eb-multi-select-filter id="model" label="Model" field="Model"></eb-multi-select-filter>
                    <eb-multi-select-filter
                        v-show="$data.$_showResultLocation"
                        id="location"
                        label="Location"
                        field="ResultLocation"
                    ></eb-multi-select-filter>
                    <eb-range-slider-filter
                        range-min-class="form-control mt-3"
                        range-max-class="form-control mt-3 ml-auto"
                        badge-min-class="badge badge-secondary"
                        badge-max-class="badge badge-secondary ml-auto"
                        slider-id="year-range-filter"
                        label="Year"
                        field="Year"
                        :exclude-zero="true"
                    ></eb-range-slider-filter>
                    <eb-range-slider-filter
                        range-min-class="form-control mt-3"
                        range-max-class="form-control mt-3 ml-auto"
                        badge-min-class="badge badge-secondary"
                        badge-max-class="badge badge-secondary ml-auto"
                        slider-id="price-range-filter"
                        label="Price"
                        field="Price"
                        :is-money="true"
                    ></eb-range-slider-filter>

                    <!-- reset button -->
                    <div class="form-group">
                        <eb-reset btn-class="btn btn-primary btn-lg btn-block">Clear Filters</eb-reset>
                    </div>
                </div>

                <!-- right side of form -->
                <div id="main-grid-view" class="col-md-8">
                    <!-- list out currently applied form selections -->
                    <eb-current-form-selections
                        class="d-none d-md-block"
                        :fields="filterParameters"
                        badge-class="badge badge-primary"
                        badge-all-items-class="badge badge-secondary"
                        show-results-label=" results matching"
                    ></eb-current-form-selections>

                    <!-- show search results -->
                    <eb-results
                        class="ml-md-1"
                        no-results-reset-text="Click to view our full inventory"
                        no-results-message="Click below to see our full used inventory"
                        no-results-title="We couldn't find any matching equipment"
                    >
                        <template slot-scope="{ item }">
                            <search-result-used-eq
                                :key="item.Id"
                                :item="item"
                                :use-router="true"
                                :badges="getResultBadges(item)"
                                :tier-icons="tierIcons"
                            ></search-result-used-eq>
                        </template>
                    </eb-results>

                    <!-- bottom pagination for phones -->
                    <div class="d-block d-sm-none">
                        <eb-pagination
                            pagination-class="pagination pagination-sm justify-content-center"
                            :pagination-size="3"
                            :use-router="true"
                        ></eb-pagination>
                    </div>

                    <!-- bottom pagination for tablets/desktops -->
                    <div class="d-none d-sm-block">
                        <eb-pagination
                            pagination-class="align-self-end pagination justify-content-center"
                            :pagination-size="4"
                        ></eb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </eb-index>
</template>

<script>
    import constants from '../../config/constants';
    import searchConfig from '../../config/search-config';
    import StoreConstants from '../../store/constants';
    import { QueryStringProvider } from '@teamsi/element-blaze';

    // components
    import EquipmentFiltersModal from './UsedEquipmentModal';
    import SearchResultMixin from '../mixins/SearchResultMixin';
    import SearchResultUsedEq from '../search/SearchResultUsedEq';
    import UsedEquipmentSkeleton from './UsedEquipmentSkeleton';

    export default {
        name: 'UsedEquipment',
        components: {
            EquipmentFiltersModal,
            SearchResultUsedEq,
            UsedEquipmentSkeleton
        },
        mixins: [SearchResultMixin],
        props: {
            defaultSortMethod: {
                type: Number,
                required: true
            },
            /**
             * Used to generate list of buttons for applying static filters
             */
            filterOptions: {
                type: Array,
                default: () => []
            },
            routeName: {
                type: String,
                required: true
            },
            sortOptions: {
                type: Array,
                required: true
            },
            tierIcons: {
                type: Object,
                default: () => ({})
            },
            pageSizeOptions: {
                type: Array,
                default: () => [10, 20, 50, 100]
            }
        },
        data() {
            return {
                elementConfig: constants.elementConfig,
                config: searchConfig,
                filterParameters: [
                    'category',
                    'filter',
                    'make',
                    'model',
                    'location',
                    'YearMin',
                    'YearMax',
                    'PriceMin',
                    'PriceMax',
                    'q'
                ],
                usedEquipmentFilters: [
                    {
                        conditions: [
                            {
                                expression: 'eq',
                                value: constants.contentTypeNames.USED_EQUIPMENT
                            }
                        ],
                        field: 'ContentType'
                    }
                ],
                formPersistenceProvider: new QueryStringProvider(this.routeName)
            };
        },
        computed: {
            loading() {
                return this.$store.state.search.isSearching;
            }
        },
        created() {
            this.$store.subscribe(mutation => {
                if (
                    mutation.type === 'search/addFormValue' ||
                    mutation.type === StoreConstants.SEARCH.MUTATIONS.RESET ||
                    mutation.type === 'search/changePage'
                ) {
                    this.applyScroll();
                }
            });
        },
        methods: {
            applyScroll() {
                setTimeout(() => {
                    let selector = '#main-grid-container';
                    if (window.innerWidth < 768) {
                        selector = '#main-grid-view';
                    }
                    document.querySelector(selector).scrollIntoView(true);
                }, 1000);
            },
            sortByformStateHandler(formState) {
                const sortBy = formState['sortBy'];
                return sortBy ? sortBy : this.defaultSortMethod;
            }
        }
    };
</script>
