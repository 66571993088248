<template>
    <div class="showroom-specials">
        <p v-if="!specialsLoaded" class="mb-0 text-center">Loading...</p>
        <div v-else-if="!specials || specials.length === 0" ref="signup">
            <h4 class="text-center mb-4">There are currently no specials on this item</h4>
        </div>
        <div v-else class="row row-cols-1 row-cols-md-3">
            <div
                v-for="(special, i) in specials"
                :key="i"
                :class="['col', { 'mt-4': i > 0 }, { 'mt-md-0': i < 3 }]"
            >
                <card-standard
                    class="h-md-100 rounded-0"
                    :title="special.Title"
                    :title-size="4"
                    :subtitle="special.Subtitle"
                    :enable-image="!!special.Image"
                    :image="{ src: special.Image, alt: special.Title }"
                    :enable-button-link="true"
                    :use-block-btn="true"
                    action-name="View Details"
                    :action-url="special.Url"
                    btn-style="btn-primary text-center"
                ></card-standard>
            </div>
        </div>
    </div>
</template>

<script>
    import standardCard from '../cards/CardStandard';
    import PromotionService from '../../../services/PromotionService';
    import config from '../../config/constants';

    export default {
        components: {
            'card-standard': standardCard
        },
        props: {
            /**
             * Item to retrieve specials for.
             */
            item: {
                type: Object,
                required: true
            },
            dataSource: {
                type: String,
                required: true
            },
            manufacturer: {
                type: String,
                required: true
            },
            showDealerPromotions: {
                type: Boolean,
                default: true
            },
            showDataSourcePromotions: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                specials: [],
                elementConfig: config.elementConfig,
                specialsLoaded: false,
                signupForm: null
            };
        },
        watch: {
            specials: function() {
                if (this.specialsLoaded && (!this.specials || this.specials.length === 0)) {
                    this.$nextTick(() => {
                        this.loadSignupForm();
                    });
                }
            }
        },
        mounted() {
            // reload specials if component is re-mounted
            this.loadSpecials();
        },
        beforeDestroy() {
            this.moveSignupFormToBody();
        },
        methods: {
            addUrlToSpecials(items, type) {
                let basePath = '';

                // TODO: add this to the content location service
                if (type === 'deere') {
                    basePath = this.elementConfig.SITEMAP.DEERE_SPECIALS;
                } else if (type === 'dealer') {
                    basePath = this.elementConfig.SITEMAP.DEALER_SPECIALS;
                }

                items.forEach(x => {
                    x.Url = `${basePath}/${x.UrlName}`;
                });
            },
            loadSpecials() {
                this.specials = [];
                this.specialsLoaded = false;

                if (!this.item) {
                    return;
                }

                // enables identifying the type of results in each promise response
                let specialsTypes = [];
                let specialsPromises = [];

                // add type and promise for dealer promotions
                if (
                    this.showDealerPromotions &&
                    this.item.DealerPromotions &&
                    this.item.DealerPromotions.length > 0
                ) {
                    let dealerSpecialsIds = this.item.DealerPromotions.map(p => p.Id);
                    let dealerSpecialsPromise = PromotionService.getSelectedPromotions(
                        'dealer',
                        dealerSpecialsIds
                    );

                    specialsTypes.push('dealer');
                    specialsPromises.push(dealerSpecialsPromise);
                }

                // add type and promise for data source promotions
                if (this.showDataSourcePromotions) {
                    let dataSourceSpecialsPromise = PromotionService.getMachinePromotions(
                        this.dataSource,
                        this.item,
                        { $orderby: 'Title' }
                    );

                    specialsTypes.push(this.dataSource);
                    specialsPromises.push(dataSourceSpecialsPromise);
                }

                Promise.all(specialsPromises)
                    .then(responses => {
                        // set url of each special
                        specialsTypes.forEach((specialsType, i) => {
                            let specialsOfType = responses[i];
                            this.addUrlToSpecials(specialsOfType, specialsType);
                        });

                        // convert image objects on dealer specials to simple url string
                        if (specialsTypes[0] === 'dealer') {
                            let dealerSpecials = responses[0];
                            dealerSpecials.forEach(s => {
                                s.Image = s.Image && s.Image[0] && s.Image[0].Url;
                            });
                        }

                        this.specials = [].concat(...responses); // flattens the arrays of results
                        this.specialsLoaded = true;
                    })
                    .catch(err => {
                        this.specials = [];
                        this.specialsLoaded = true;
                    });
            },
            /**
             * Copies the Sitefinity specials signup form into the signup wrapper
             * and fills the Equipment Interested In field.
             */
            loadSignupForm() {
                this.signupForm = document.querySelector('.special-offer-form');

                if (this.signupForm && this.$refs.signup) {
                    this.signupForm.parentElement.removeChild(this.signupForm);
                    this.$refs.signup.appendChild(this.signupForm);
                    this.signupForm.classList.remove('d-none');

                    let interestedEqField = this.signupForm.querySelector(
                        '.interested-eq input[class="form-control"]'
                    );

                    if (interestedEqField) {
                        let machineName = `${this.manufacturer} ${this.item.Title}`;
                        interestedEqField.value = machineName;
                    }
                }
            },
            /**
             * Maintains a copy of the form so it isn't destroyed
             * when the user clicks "back" to return to the showroom list view.
             */
            moveSignupFormToBody() {
                if (this.signupForm && this.$refs.signup.contains(this.signupForm)) {
                    this.$refs.signup.removeChild(this.signupForm);
                    this.signupForm.classList.add('d-none');
                    document.querySelector('body').appendChild(this.signupForm);
                }
            }
        }
    };
</script>
