<template>
    <div class="location-list-dropdown position-relative d-flex align-items-center h-100">
        <button v-if="locations.length" :class="buttonClasses" @click="toggleDetail()">
            <span class="font-weight-bold text-nowrap">CALL US <i :class="caretClass" class="ml-2" /></span>
        </button>
        <button v-else :class="buttonClasses">
            <a class="text-primary-yiq" :href="storeTelLink">CALL US</a>
        </button>
        <transition mode="out-in" name="drawer">
            <div v-show="expanded" class="location-menu">
                <div class="bg-primary mt-0 p-2">
                    <a
                        v-for="location in locations"
                        :key="location.Id"
                        class="dropdown-item font-weight-bold"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                        :href="`tel:${location.Phone}`"
                        >{{ location.DealerLocationTitle }} {{ location.Phone }}</a
                    >
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import constants from '../../config/constants';

    import LocationService from '../../../services/LocationService';

    import ThemeMixin from '../mixins/ThemeMixin';

    export default {
        name: 'LocationListDropDown',
        mixins: [ThemeMixin],
        props: {
            rounded: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                elementConfig: constants.elementConfig,
                locations: [],
                expanded: false
            };
        },
        computed: {
            buttonClasses() {
                let classes = 'btn toggler bg-primary bg-fixed text-primary-yiq';
                return `${classes} ${this.rounded ? 'rounded' : 'rounded-0 h-100'}`;
            },
            caretClass() {
                return this.expanded ? 'fas fa-caret-up' : 'fas fa-caret-down';
            },
            storeLocation() {
                return this.$_selectedLocation;
            },
            storeTelLink() {
                const tel = this.storeLocation?.Phone ?? this.elementConfig.PHONE;
                return 'tel:' + tel;
            }
        },
        mounted() {
            LocationService.getLocations().then(locations => {
                this.locations = locations.locations
                    .filter(location => location.Phone)
                    .map(location => {
                        return {
                            Id: location.Id,
                            DealerLocationTitle: location.DealerLocationTitle,
                            Phone: location.Phone
                        };
                    });
            });

            window.addEventListener('click', e => {
                if (
                    !e.target.classList.contains('toggler') &&
                    !e.target.parentNode?.classList.contains('toggler')
                ) {
                    this.expanded = false;
                }
            });
        },
        methods: {
            toggleDetail() {
                this.expanded = !this.expanded;
            }
        }
    };
</script>
