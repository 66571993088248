﻿<template>
    <div class="grid-wrapper">
        <div class="title-bar row" :class="$_isLightTheme ? 'bg-white' : 'bg-dark'">
            <ul class="breadcrumbs col-lg-6 col-12 d-none d-md-block">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li v-if="$route.params.type">></li>
                <li v-if="$route.params.type">
                    <router-link :to="route($route.params.type)">
                        {{ slugToTitle($route.params.type) }}
                    </router-link>
                </li>
            </ul>
            <div class="sort col-lg-3 col-8 py-1 form-inline">
                <label>Sort:</label>
                <select v-model="order" class="form-control form-control-sm ml-1 w-75">
                    <option value="name">Model Name</option>
                    <option value="newest">Newest</option>
                </select>
            </div>
            <div class="show col-lg-3 col-4 p-1">
                <label>Icons:</label>
                <ul>
                    <li>
                        <a @click="mode = 'large'">
                            <span
                                :class="mode === 'large' ? 'shortline-color-primary' : ''"
                                class="fas fa-th-large"
                            ></span
                        ></a>
                    </li>
                    <li>
                        <a @click="mode = 'med'">
                            <span
                                :class="mode === 'med' ? 'shortline-color-primary' : ''"
                                class="fas fa-th"
                            ></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="filter-bar my-2" :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'">
            <span class="float-left">Showing {{ filteredMachines.length }} Models</span>
            <span class="float-right">
                <select v-if="machineCategories.length" v-model="categoryChoice" class="mr-1">
                    <option value="">-- Select Subcategory --</option>
                    <option v-for="category in machineCategories" :key="category" :value="category">
                        {{ category }}
                    </option>
                </select>
                <input v-model="filterValue" placeholder="filter" />
                <span class="fas fa-search"></span>
            </span>
        </div>
        <div class="grid" :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'">
            <div
                v-for="(machine, i) in filteredMachines"
                :key="i"
                :class="[sizes[mode], { 'bg-white': $_isLightTheme, 'bg-dark': !$_isLightTheme }]"
                class="machine-list-item"
            >
                <div class="machine-item-body">
                    <div class="machine-image">
                        <router-link :to="getDetailUrl(machine)">
                            <img :src="machine.ImageUrl" :alt="machine.MachineTitle" />
                        </router-link>
                    </div>

                    <h5 :class="textSize(machine.MachineTitle)" class="text-center shortline-color-primary">
                        <router-link :to="getDetailUrl(machine)">
                            {{ htmlDecode(machine.MachineTitle) }}
                        </router-link>
                    </h5>
                </div>
                <div class="call-to-action w-100">
                    <router-link class="text" :to="getDetailUrl(machine)">Details</router-link>
                    <a class="shortline-bg-color-primary" @click="save(machine)">Save</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ThemeMixin from '../mixins/ThemeMixin';
    import UtilitiesMixin from '../mixins/UtilitiesMixin';

    function sortMachines(order, machines) {
        if (order === 'name') {
            return machines.sort(function(m1, m2) {
                return m1.MachineTitle > m2.MachineTitle ? 1 : -1;
            });
        } else {
            return machines.sort(function(m1, m2) {
                return m1.LastModified > m2.LastModified ? 1 : -1;
            });
        }
    }

    // TODO: Create mixin to combine logic with EquipmentShowroomGrid
    export default {
        name: 'ShortLineShowroomGrid',
        mixins: [ThemeMixin, UtilitiesMixin],
        props: {
            machines: {
                type: Array,
                required: true
            },
            save: {
                type: Function,
                required: true
            }
        },
        data() {
            return {
                mode: 'large',
                sizes: {
                    small: 'grid-cell-sm',
                    med: 'grid-cell',
                    large: 'grid-cell-lg'
                },
                order: 'name',
                filterValue: '',
                categoryChoice: ''
            };
        },
        computed: {
            filteredMachines() {
                var vm = this;
                var result = vm.machines;
                if (vm.filterValue) {
                    result = result.filter(function(machine) {
                        return machine.MachineTitle.toLowerCase().indexOf(vm.filterValue.toLowerCase()) > -1;
                    });
                }
                if (vm.categoryChoice) {
                    result = result.filter(function(machine) {
                        return machine.Category === vm.subcategoryChoice;
                    });
                }
                return sortMachines(vm.order, result);
            },
            machineCategories() {
                let categories = [];
                this.machines.map(function(machine) {
                    categories.concat(machine.CategoryNames.split('~'));
                });
                return categories
                    .filter(function(cat) {
                        return cat && cat !== '';
                    })
                    .sort();
            }
        },
        watch: {
            $route: function(newValue, oldValue) {},
            order: function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    // TODO: Is it worth refactoring this widget?
                    // It's currently modifying a prop
                    this.machines = sortMachines(newValue, this.machines);
                }
            }
        },
        methods: {
            route(p1, p2) {
                return p2 ? `/${p1}/${p2}` : `/${p1}`;
            },
            getDetailUrl(machine) {
                return `/${this.slugify(this.$route.params.type)}/${this.slugify(machine.MachineTitle)}/overview`;
            },
            firstImage(images) {
                return images.split(',')[0];
            },
            titleToSlug(title) {
                return title.toLowerCase();
            },
            // TODO: IMPROVE METHOD NAME.
            slugToTitle(slug) {
                return slug.replace(/-/g, ' ').replace(/\w\S*/g, function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            },
            // TODO: IMPROVE METHOD NAME.
            textSize(title) {
                return title.length > 30 ? 'compress' : '';
            },
            // TODO: IMPROVE METHOD NAME.
            htmlDecode(html) {
                var el = document.createElement('textarea');
                el.innerHTML = html;
                return el.value;
            }
        }
    };
</script>
