﻿<template>
    <div v-if="activeQuestion" class="machine-suggester position-relative">
        <div
            v-if="backgroundImage.src && !showResult"
            class="bg-image position-absolute w-100"
            :style="{ 'background-image': `url('${backgroundImage.src}')` }"
        ></div>
        <div v-else class="bg-image position-absolute w-100" :class="{ 'bg-white': !showResult }"></div>
        <div class="machine-suggester-container row no-gutters">
            <div class="col-12 col-md-6 col-lg-7 py-4 d-flex flex-column justify-content-between">
                <!-- NAVIGATION -->
                <div
                    class="d-flex flex-wrap justify-content-between align-items-center mx-3 text-primary-yiq"
                >
                    <div
                        class="nav-arrow d-md-none"
                        :class="{ invisible: questionIndex == 0 }"
                        @click="goToQuestion(questionIndex - 1)"
                    >
                        <i class="fa fa-arrow-left"></i>
                    </div>
                    <div class="h4 mb-0" :class="{ 'text-dark': showResult }">
                        STEP {{ `${currentStep}/${myQuestions.length + 1}` }}
                    </div>
                    <div
                        class="nav-arrow d-md-none"
                        :class="{ invisible: showResult }"
                        @click="goToQuestion(questionIndex + 1)"
                    >
                        <i class="fa fa-arrow-right"></i>
                    </div>
                </div>
                <!-- BODY -->
                <div v-if="!showResult" class="py-5 ml-3">
                    <transition name="fade" mode="out-in">
                        <div
                            v-show="showQuestion"
                            class="row no-gutters display-5 font-weight-bold text-primary-yiq"
                        >
                            <div class="col-2 d-md-none text-right text-md-left pr-2">
                                {{ questionIndex + 1 }}.
                            </div>
                            <div class="col-10 cold-md-12">
                                <span class="d-none d-md-inline">{{ questionIndex + 1 }}.</span>
                                <span>{{ activeQuestion.Text }}</span>
                                <div
                                    v-if="isQuestionOfType(questionIndex, answerType.CHECKBOX)"
                                    class="subtitle mt-2 text-primary-yiq"
                                >
                                    * Select all applicable
                                </div>
                            </div>
                        </div>
                    </transition>
                    <transition name="fade" mode="out-in">
                        <div v-show="showQuestion" class="pt-3">
                            <div
                                v-for="(answer, i) in activeAnswers"
                                :key="i"
                                class="row no-gutters my-1 cursor-pointer"
                            >
                                <div class="col-2 d-md-none"></div>
                                <div class="col-10 col-md-12">
                                    <div class="display-6">
                                        <input
                                            v-if="isQuestionOfType(questionIndex, answerType.TEXT)"
                                            v-model="answer.Text"
                                            class="form-control"
                                            type="text"
                                            @input="selectAnswer(answer)"
                                        />
                                        <div
                                            v-else
                                            class="d-flex align-items-center py-3"
                                            @click="selectAnswer(answer)"
                                        >
                                            <i
                                                v-if="answer.IsSelected"
                                                class="fa fa-check check-mark-filled"
                                            ></i>
                                            <i v-else class="fa fa-circle check-mark-empty"></i>
                                            <div class="pl-3 font-weight-bold text-primary-yiq">
                                                {{ answer.Text }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                <!-- RESULT -->
                <result-skeleton v-if="isSearching" class="py-4 mx-5 mx-md-3"></result-skeleton>
                <div v-else-if="showResult && resultMachine" class="py-4 mx-5 mx-md-3">
                    <div class="result mb-4">
                        <div class="d-md-inline">Your result is a</div>
                        <div class="d-md-inline text-primary">{{ resultMachine.Title }}</div>
                    </div>

                    <div class="row">
                        <div
                            class="col-12 d-flex align-items-center"
                            :class="{ 'col-lg-8': resultMachine.SuggestedImplements.length > 0 }"
                        >
                            <img :src="resultMachine.Image"
                                 :alt="resultMachine.Title"/>
                        </div>
                        <div class="col-lg-4 d-none d-md-flex">
                            <suggested-implements
                                class="mt-3"
                                :attachments="resultMachine.SuggestedImplements"
                            />
                        </div>
                    </div>

                    <button
                        class="btn btn-primary d w-100 rounded-0 d-md-none"
                        @click="toggleShowRequestAQuote()"
                    >
                        REQUEST A QUOTE
                    </button>
                    <request-a-quote-options
                        class="d-md-none"
                        :builder-path="builderPath"
                    ></request-a-quote-options>
                    <suggested-implements
                        class="d-md-none mt-5"
                        :attachments="resultMachine.SuggestedImplements"
                    />
                </div>
                <div v-else-if="showResult && !resultMachine" class="px-5">
                    <div class="font-weight-bold mt-4">No results for your search, try again</div>
                    <div
                        class="d-flex d-md-none flex-row my-2 border-top border-bottom border-dark cursor-pointer"
                    >
                        <div
                            class="w-50 text-center border-right border-dark p-3"
                            @click="goToQuestion(questionIndex - 1)"
                        >
                            GO BACK
                        </div>
                        <div class="w-50 text-center p-3" @click="resetQuestions()">START OVER</div>
                    </div>
                </div>
                <!-- PAGINATION -->
                <div class="d-flex justify-content-center justify-content-md-start w-100 mt-auto pb-3">
                    <div
                        v-for="(question, index) in questions"
                        :key="index"
                        :class="['brow rounded my-1', { visited: index <= questionIndex }]"
                    ></div>
                    <div class="brow rounded my-1" :class="{ visited: showResult }"></div>
                </div>
                <!-- SUMMARY MODAL -->
                <summary-modal v-if="!showResult" :answered-questions="answeredQuestions" />
            </div>
            <!-- SVG SEPARATOR -->
            <div class="col-md-1 d-none d-md-inline" dir="rtl" :style="cssVars">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 130 600"
                    class="position-relative h-100"
                    preserveAspectRatio="none"
                >
                    <path fill-opacity="1" d="M 80 0 C 200 240 0 360 120 600 L 130 600 L 130 0 Z" />
                </svg>
            </div>
            <!-- SUMMARY PANE -->
            <div
                v-if="!showResult"
                class="col-md-5 col-lg-4 d-none d-md-flex flex-column justify-content-between bg-white text-dark py-5 px-5"
            >
                <div class="text-primary font-weight-bold">SUMMARY</div>
                <hr class="w-100 mt-0" />
                <summary-content :answered-questions="answeredQuestions"></summary-content>
                <!-- NAVIGATION -->
                <div class="d-flex flex-wrap justify-content-end mt-auto">
                    <div
                        class="nav-arrow mr-3"
                        :class="{ invisible: questionIndex == 0 }"
                        @click="goToQuestion(questionIndex - 1)"
                    >
                        <i class="fa fa-arrow-left"></i>
                    </div>
                    <div
                        class="nav-arrow"
                        :class="{ invisible: showResult }"
                        @click="goToQuestion(questionIndex + 1)"
                    >
                        <i class="fa fa-arrow-right"></i>
                    </div>
                </div>
            </div>
            <!-- REQUEST A QUOTE -->
            <div
                :class="requestAQuoteClass"
                class="col-md-5 col-lg-4 flex-column text-dark py-5"
                :style="{ 'background-color': cssVars['--right-pane-background-color'] }"
            >
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <div class="d-md-none text-dark" @click="toggleShowRequestAQuote()">
                        <i class="fa fa-arrow-left"></i>
                    </div>
                    <div class="h4 mb-0 text-primary">REQUEST A QUOTE</div>
                    <div class="invisible">&nbsp;</div>
                </div>
                <form-loader
                    v-if="showResult && useSitefinityForm"
                    sitefinity-form-selector=".machine-suggester-get-a-quote"
                    :remove-sitefinity-form-labels="true"
                ></form-loader>
                <form-loader v-else-if="showResult" :iframe-source="formUrl"></form-loader>
                <request-a-quote-options v-if="!showRequestAQuoteOnTop" :builder-path="builderPath" />
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import FormLoader from '../utility/FormLoader';
    import RequestAQuoteOptions from './MachineSuggesterRequestAQuoteOptions';
    import ResultSkeleton from './MachineSuggesterResultSkeleton';
    import SummaryModal from './MachineSuggesterSummaryModal';
    import SuggestedImplements from './MachineSuggesterSuggestedImplements';
    import SummaryContent from './MachineSuggesterSummaryContent';
    import { deepCopy } from '../../lib/utilities';

    export default {
        name: 'MachineSuggester',
        components: {
            FormLoader,
            RequestAQuoteOptions,
            ResultSkeleton,
            SummaryModal,
            SummaryContent,
            SuggestedImplements
        },
        props: {
            backgroundImage: {
                type: Object,
                default: () => ({})
            },
            questions: {
                type: Array,
                required: true
            },
            buildYourOwnPage: {
                type: String,
                default: null
            },
            useSitefinityForm: {
                type: Boolean,
                required: true
            },
            formUrl: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                answerType: {
                    CHECKBOX: 'Checkbox',
                    RADIO_BUTTON: 'RadioButton',
                    TEXT: 'Text',
                    YES_NO: 'YesNo'
                },
                showQuestion: true,
                showRequestAQuoteOnTop: false,
                showResult: false,
                questionIndex: 0,
                myQuestions: [],
                fadeDelay: 300,
                resultMachine: null,
                windowWidth: null,
                isSearching: false
            };
        },
        computed: {
            activeQuestion() {
                return this.myQuestions[this.questionIndex];
            },
            activeAnswers() {
                return this.activeQuestion ? this.activeQuestion.Answers : [];
            },
            answeredQuestions() {
                return this.myQuestions.filter(qx => qx.IsAnswered);
            },
            isCurrentQuestionAnswered() {
                return this.isQuestionAnswered(this.questionIndex);
            },
            currentStep() {
                return this.showResult ? this.myQuestions.length + 1 : this.questionIndex + 1;
            },
            cssVars() {
                return {
                    '--right-pane-background-color': this.showResult ? '#eee' : '#fff'
                };
            },
            builderPath() {
                return this.buildYourOwnPage && this.resultMachine
                    ? `${this.buildYourOwnPage}/${this.resultMachine.BuildYourOwnPath}`
                    : null;
            },
            requestAQuoteClass() {
                let quoteClass;

                if (this.showResult && this.showRequestAQuoteOnTop) {
                    quoteClass = 'd-flex request-a-quote-on-top';
                } else if (this.showResult) {
                    quoteClass = 'd-none d-md-flex';
                } else {
                    quoteClass = 'd-none';
                }

                return this.useSitefinityForm
                    ? (quoteClass += ' px-5 px-md-3 px-lg-5')
                    : (quoteClass += ' px-3 pl-md-2 pr-md-4');
            }
        },
        watch: {
            isCurrentQuestionAnswered(newValue) {
                this.activeQuestion.IsAnswered = newValue;
            },
            showResult(show) {
                if (!show) {
                    return;
                }

                this.resultMachine = null;
                let apiBase = '/api/NewEquipment/GetMachineSuggesterResult';
                this.isSearching = true;
                axios.post(apiBase, this.answeredQuestions).then(response => {
                    this.resultMachine = response.data;

                    this.isSearching = false;
                });
            },
            showRequestAQuoteOnTop(show) {
                if (show) {
                    document.querySelector('body').style.overflow = 'hidden';
                } else {
                    document.querySelector('body').style.overflow = 'unset';
                }
            },
            windowWidth: function() {
                this.showRequestAQuoteOnTop = false;
            }
        },
        mounted() {
            this.resetQuestions();
            this.$nextTick(() => {
                window.addEventListener('resize', this.getWindowSize);

                setTimeout(() => {
                    if (document.querySelector('.get-a-quote-button button')) {
                        document.querySelector('.get-a-quote-button button').classList.add('w-100', 'rounded-0');
                    }
                    this.getWindowSize();
                }, 100);
            });
        },
        methods: {
            getWindowSize() {
                this.windowWidth = document.documentElement.clientWidth;
            },
            resetQuestions() {
                let vm = this;
                this.myQuestions = deepCopy(this.questions);

                this.myQuestions.forEach(question => {
                    if (question.Answers[0].Type == vm.answerType.YES_NO) {
                        let answerCloned = JSON.parse(JSON.stringify(question.Answers[0]));
                        question.Answers.push(answerCloned);

                        question.Answers[0].Text = 'Yes';
                        question.Answers[1].Text = 'No';
                    }
                });

                this.questionIndex = 0;
                this.showResult = false;

                this.scrollToTop()
            },
            goToQuestion(indexToGo) {
                let isAPreviousQuestion = indexToGo < this.questionIndex;

                this.scrollToTop();

                // Going back from the result page
                if (this.showResult) {
                    this.showResult = false;
                    return;
                }

                if (!isAPreviousQuestion) {
                    for (let i = this.questionIndex; i < indexToGo; i++) {
                        if (!this.isQuestionAnswered(i)) {
                            return;
                        }
                    }
                }

                // Going to the result page
                if (indexToGo == this.myQuestions.length) {
                    this.showResult = true;
                    return;
                }

                this.showResult = false;
                this.showQuestion = false;
                setTimeout(() => {
                    this.questionIndex = indexToGo;
                    this.showQuestion = true;
                }, this.fadeDelay);
            },
            selectAnswer(answer) {
                switch (answer.Type) {
                    case this.answerType.RADIO_BUTTON:
                    case this.answerType.YES_NO:
                        this.activeAnswers.map(answer => (answer.IsSelected = false));
                        answer.IsSelected = true;
                        break;
                    case this.answerType.CHECKBOX:
                        answer.IsSelected = !answer.IsSelected;
                        break;
                    case this.answerType.TEXT:
                        answer.Text = answer.Text.trimStart();
                        answer.IsSelected = !!answer.Text;
                        break;
                }
            },
            isQuestionOfType(qxIndex, qxType) {
                return this.myQuestions[qxIndex] && this.myQuestions[qxIndex].Answers[0].Type === qxType;
            },
            isQuestionAnswered(qxIndex) {
                return (
                    this.myQuestions[qxIndex] &&
                    this.myQuestions[qxIndex].Answers.some(answer => answer.IsSelected)
                );
            },
            toggleShowRequestAQuote() {
                this.showRequestAQuoteOnTop = !this.showRequestAQuoteOnTop;
            },
            scrollToTop() {
                window.scroll({
                    behavior: 'smooth',
                    left: 0,
                    top: 0
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    path {
        fill: var(--right-pane-background-color);
    }
</style>
