import Color from 'color';
import StoreConstants from '../../store/constants';

/**
 * Theme customizer singleton
 */
export default new function() {
    const that = this;

    // https://brandpalettes.com/john-deere-colors/
    this.primaryColors = {
        '#367C2B': 'Green', // Deere
        '#016938': 'Dark Green', // Vermeer
        '#f05624': 'Orange', // Kubota
        '#c2323a': 'Red', // Mahindra
        '#a63535': 'Dark Red', // CASE IH
        '#00539b': 'Blue' // New Holland
    };
    this.secondaryColors = {
        '#ffde00': 'Yellow', // Deere
        '#ffc425': 'Deep Yellow', // Vermeer
        '#6c757d': 'Gray',
        '#000000': 'Black'
    };

    this.selectedPrimaryColor = '';
    this.selectedSecondaryColor = '';
    this.selectedLinkColor = '' || 'Primary Color';

    this.changePrimaryColor = function(color, store) {
        store.dispatch(StoreConstants.THEME.ACTIONS.GENERATE_COLORS, { primary: color });
    };

    this.changeSecondaryColor = function(color, store) {
        store.dispatch(StoreConstants.THEME.ACTIONS.GENERATE_COLORS, { secondary: color });
    };

    this.toggleDarkTheme = function(store, activeTheme) {
        store.dispatch(
            StoreConstants.THEME.ACTIONS.SET_THEME,
            activeTheme === 'theme-light' ? 'theme-dark' : 'theme-light'
        );
    };

    this.changeLinkColor = function(color, store) {
        that.selectedLinkColor = that.primaryColors[color];
        const linkColor = Color(color || Object.keys(that.primaryColors)[0]);
        store.dispatch(StoreConstants.THEME.ACTIONS.APPLY_COLORS, { linkColor: linkColor });
    };
}();
