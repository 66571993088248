<!--
Card for use as an accordion item.
Nest it inside an accordion component.
-->
<template>
    <div class="card rounded-0">
        <div class="card-header bg-white p-0" :id="`heading-${itemId}`">
            <h4 class="mb-0">
                <button
                    :class="[
                        'btn btn-block rounded-0 py-2 d-flex align-items-center',
                        'text-left text-uppercase text-decoration-none font-weight-bold',
                        show ? 'btn-primary' : 'btn-link',
                        { collapsed: !show }
                    ]"
                    type="button"
                    :aria-expanded="show"
                    :aria-controls="`collapse-${itemId}`"
                    @click="toggle"
                >
                    <i :class="['fas fa-lg mr-2', icon]"></i>
                    {{ title }}
                    <i :class="['ml-auto fas', collapseIcon]"></i>
                </button>
            </h4>
        </div>
        <transition
            v-on:enter="onEnter"
            v-on:after-enter="onAfterEnter"
            v-on:leave="onLeave"
            v-on:after-leave="onAfterLeave"
        >
            <div
                v-show="show"
                ref="collapse"
                :id="`collapse-${itemId}`"
                :class="[transitioning ? 'collapsing' : 'collapse', { show: show && !transitioning }]"
                :aria-labelledby="`heading-${itemId}`"
            >
                <div class="card-body">
                    <slot />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'expanded',
            event: 'input'
        },
        props: {
            itemId: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            expanded: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: 'fa-check-circle'
            }
        },
        data() {
            return {
                show: this.expanded,
                transitioning: false,
                openedHeight: 'auto'
            };
        },
        computed: {
            collapseIcon() {
                return this.show ? 'fa-chevron-up' : 'fa-chevron-down';
            }
        },
        mounted() {
            // collapse item if sibling has been expanded
            this.$parent.$on('sibling_expanded', item => {
                if (item != this.itemId && this.show) {
                    this.show = false;
                }
            });
        },
        watch: {
            // apply changes from v-model
            expanded(value) {
                this.show = value;
            },
            // apply expand/collapse changes to v-model
            show() {
                this.$emit('input', this.show);
            }
        },
        methods: {
            toggle() {
                this.show = !this.show;

                if (this.show) {
                    // tell parent to direct other accordion items to collapse
                    this.$parent.$emit('child_expanded', this.itemId);
                }
            },
            onEnter(el) {
                this.transitioning = true;
                el.style.height = '0px';

                // give the DOM time to repaint after setting the height
                setTimeout(() => {
                    requestAnimationFrame(() => {
                        el.style.height = `${el.scrollHeight}px`;
                    });
                }, 100);
            },
            onAfterEnter(el) {
                this.transitioning = false;
                this.openedHeight = `${el.scrollHeight}px`;
                el.style.height = 'auto';
            },
            onLeave(el) {
                this.transitioning = true;
                el.style.height = this.openedHeight;

                // give the DOM time to repaint after setting the height
                setTimeout(() => {
                    requestAnimationFrame(() => {
                        el.style.height = '0px';
                    });
                }, 100);
            },
            onAfterLeave(el) {
                this.transitioning = false;
            }
        }
    };
</script>
