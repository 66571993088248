<template>
    <li :class="['nav-item', navItemClasses]">
        <!--
            NOTE: The router-link's event prop is intentionally set to an empty string to disable the default routing behavior.
            This enables conditionally routing to the parent node (collapsing the node) when the active node is clicked.
            See https://github.com/vuejs/vue-router/issues/916
        -->
        <router-link
            :class="['nav-link', navLinkClasses]"
            :to="link"
            :style="indent"
            :event="''"
            @click.native="routeToItem"
        >
            {{node.title}}
            <i
                v-if="hasChildren"
                :class="[`fas fa-chevron-${isExpanded ? 'up' : 'down'}`]"
            ></i>
        </router-link>
        <template v-if="isExpanded">
            <ul class="nav flex-column">
                <showroom-nav-item
                    v-for="(childNode, i) in node.children"
                    :key="i"
                    :node="childNode"
                ></showroom-nav-item>
            </ul>
        </template>
    </li>
</template>

<script>
    import showroomMixin from './ShowroomMixin.vue';
    export default {
        name: 'ShowroomNavItem',
        mixins: [showroomMixin],
        data() {
            return {
                navItemTierEvenClass: 'bg-gray-300',
                navItemTierOddClass: 'bg-gray-200',
                navItemTierOneClass: 'bg-gray-100',
                navLinkActiveEvenClass: 'bg-secondary bg-fixed text-secondary-yiq',
                navLinkActiveOddClass: 'bg-primary bg-fixed text-primary-yiq',
                navLinkNotActiveClass: 'text-gray-500'
            };
        },
        props: {
            node: {
                type: Object,
                required: true
            }
        },
        computed: {
            depth() {
                return this.node.path.split('/').length;
            },
            indent() {
                return { 'padding-left': `${this.depth * 10}px` };
            },
            isActive() {
                return this.node.path === this.$_currentFilterPath.replace(/\|/g, '/');
            },
            navItemClasses() {
                return [
                    this.itemTierClass,
                    {
                        dropdown: this.hasChildren
                    },
                    {
                        expanded: this.isExpanded
                    }
                ];
            },
            navLinkClasses() {
                return [
                    this.linkContextClass,
                    {
                        active: this.isActive
                    }
                ];
            },
            itemTierClass() {
                if (this.depth === 1) {
                    return this.navItemTierOneClass;
                } else if (this.depth % 2 === 0) {
                    return this.navItemTierEvenClass;
                } else {
                    return this.navItemTierOddClass;
                }
            },
            linkContextClass() {
                if (!this.isExpanded && !this.isActive) {
                    return this.navLinkNotActiveClass;
                } else if (this.depth % 2 === 0) {
                    return this.navLinkActiveEvenClass;
                } else {
                    return this.navLinkActiveOddClass;
                }
            },
            link() {
                let path = this.node.path && this.node.path.split('/');

                if (!path || path.length === 0) {
                    return null;
                }

                return {
                    name: this.listRouteName,
                    params: this.listRouteParams.reduce((params, key, i) => ({ ...params, [key]: path[i] }), {})
                };
            },
            isExpanded() {
                return this.hasChildren && this.$_currentFilterPath.replace(/\|/g, '/').includes(this.node.path);
            },
            hasChildren() {
                return this.node && this.node.children && this.node.children.length > 0;
            }
        },
        methods: {
            routeToItem() {
                if (!this.isExpanded) {
                    this.$router.push(this.link);
                } else {
                    // redirect to the parent node's path
                    let parentPath = `/${this.node.path
                        .split('/')
                        .slice(0, -1)
                        .join('/')}`;
                    this.$router.push(parentPath);
                }
            }
        }
    };
</script>