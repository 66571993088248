﻿<script>
    import constants from '../../config/constants';
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc'; // load on demand

    dayjs.extend(utc); // use plugin

    export default {
        props: {
            post: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            logoUrl: constants.elementConfig.LOGO_URL
        }),
        computed: {
            year() {
                return dayjs.utc(this.post.DateCreated).format('YYYY');
            },
            month() {
                return dayjs.utc(this.post.DateCreated).format('MM');
            },
            day() {
                return dayjs.utc(this.post.DateCreated).format('DD');
            },
            postImage() {
                return this.postImageExists ? this.post.HeroImage[0].Url : this.logoUrl;
            },
            postAltText() {
                return this.postImageExists ? this.post.HeroImage[0].AlternativeText : this.post.Title;
            },
            /**
             * Used to check if the blog post has an associated hero image.
             * @returns {boolean} true if post has image, false if not.
             */
            postImageExists() {
                return (
                    Array.isArray(this.post.HeroImage) &&
                    this.post.HeroImage.length > 0 &&
                    this.post.HeroImage[0].Url
                );
            }
        },
        methods: {
            getDate(date) {
                return dayjs.utc(date).format('MMM. D, YYYY');
            }
        }
    };
</script>
