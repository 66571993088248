﻿import constants from '../frontend/config/constants';

import NewEquipmentService from './NewEquipmentService';
import ShortLineService from './ShortLineService';

export function getUrl(item) {
    if (item.ContentType === constants.newEqTypeNames.SHORTLINE) {
        return ShortLineService.getShortlineEquipmentUrl(item);
    } else if (isNewEquipment(item)) {
        return NewEquipmentService.getNewEquipmentUrl(item);
    } else if (item.ContentType === constants.contentTypeNames.PAGES) {
        return getPageUrl(item);
    }

    return `${item.BaseUrl || ''}/${item.UrlName || ''}`;
}

function getPageUrl(item) {
    return (item.Url && item.Url.substring(1)) || '/';
}

function isNewEquipment(item) {
    return Object.keys(constants.newEqTypeNames).some(
        key => constants.newEqTypeNames[key] === item.ContentType
    );
}

export default {
    getUrl
};
