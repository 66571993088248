﻿<template>
    <div v-if="career" class="container mb-3">
        <router-link v-if="useRouter" class="btn btn-primary m-3" to="/">Go Back</router-link>
        <a v-else :href="baseUrl" class="btn btn-primary m-3">Go back</a>

        <h3 class="px-3">{{ career.Title }}</h3>
        <h4 class="px-3 my-3">{{ career.Subtitle }}</h4>
        <div class="border rounded mb-3 p-3" v-html="career.Description"></div>
        <hr />
        <div class="d-flex">
            <a
                data-toggle="modal"
                :data-target="`#${formModalId}`"
                class="btn btn-secondary btn-block font-weight-bold py-3 text-uppercase"
            >
                Upload Resume
            </a>
        </div>

        <!--  Modal for Sitefinity get a quote form widget. -->
        <modal-form
            :id="formModalId"
            :field-values="formFieldValues"
            :form-selector="`.${formModalId}`"
            title="Upload Resume"
        ></modal-form>
    </div>
</template>

<script>
    import ModalForm from '../utility/ModalForm';

    export default {
        name: 'CareerDetails',
        components: {
            ModalForm
        },
        props: {
            baseUrl: {
                type: String,
                required: true
            },
            slug: {
                type: String,
                required: true
            },
            useRouter: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                career: null,
                formModalId: 'career-form'
            };
        },
        computed: {
            formFieldValues() {
                return [
                    {
                        selector: '.interested-career input[class="form-control"]',
                        value: this.career.CareerId
                    }
                ];
            },
            jobLocation() {
                const address = this.career.Address;
                return address ? `${address.City}, ${address.StateCode} ${address.CountryCode}` : null;
            }
        },
        mounted() {
            this.getCareerData(this.slug);
        },
        methods: {
            getCareerData() {
                var careerEndpoint = '/api/default/careers';
                let apiUrl = `${careerEndpoint}?$filter=UrlName eq '${this.slug}'`;

                fetch(apiUrl)
                    .then(response => response.json())
                    .then(response => {
                        if (response.value && response.value.length > 0) {
                            this.career = response.value[0];
                        }
                    });
            }
        }
    };
</script>
