﻿<template>
    <div>
        <!-- NOTE: Check this template/component, we are using only the feather widget -->
        <div v-if="event">
            <div class="container my-5">
                <div class="row event-featured">
                    <div class="col-lg-4 col-sm-12 align-self-center p-3">
                        <div class="mb-md-3">
                            <h3>{{ event.Title }}</h3>
                            <h5>{{ event.City }}, {{ event.State }}</h5>
                        </div>
                        <div class="row align-items-center h-100 mb-3">
                            <div class="col-4">
                                <time :datetime="event.EventStart" class="icon">
                                    <!--day-->
                                    <em>{{ eventDay(event.EventStartWithOffset) }}</em>
                                    <!--month-->
                                    <strong>{{ eventMonth(event.EventStartWithOffset) }}</strong>
                                    <span>{{ eventDate(event.EventStartWithOffset) }}</span>
                                </time>
                            </div>
                            <div class="col-8">
                                <p>
                                    <i class="far fa-clock"></i>
                                    <span v-if="event.AllDayEvent == true">All Day</span>
                                    <span v-else>
                                        {{ eventTime(event.EventStartWithOffset) }} -
                                        {{ eventTime(event.EventEndWithOffset) }}
                                    </span>
                                </p>
                                <p class="mb-0">
                                    <i class="fas fa-map-marker-alt"></i>
                                    <a :href="directionsLink" target="_blank"
                                        >{{ event.Street }} {{ event.City }}, {{ event.State }}</a
                                    >
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-sm-12 featured-img-box">
                        <element-image
                            class="img-fluid featured-border"
                            :src="event.EventImage[event.EventImage.length - 1].Url"
                            :alt="event.EventImage[event.EventImage.length - 1].AlternativeText"
                        ></element-image>
                        <span>{{ event.EventImage.Url }}</span>
                    </div>
                </div>

                <div class="row my-5">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card disclaimer h-100">
                            <div class="card-body">
                                <h5 class="card-title">Details</h5>
                                <div>
                                    <div class="card-text" v-html="event.Content"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="share">
                            <div class="border-left border-content text-uppercase font-weight-bold mb-3">
                                Add to Your Calendar
                            </div>
                            <div class="share-buttons d-flex flex-row justify-content-between mb-3">
                                <a :href="calendarUrl" class="pt-3" target="_blank">
                                    <i class="fab fa-apple fa-2x"></i>
                                    <p>iCal</p>
                                </a>
                                <a :href="calendarUrl" class="pt-3" target="_blank">
                                    <i class="fab fa-microsoft fa-2x"></i>
                                    <p>Outlook</p>
                                </a>
                                <a :href="googleCalendarUrl" class="pt-3" target="_blank">
                                    <i class="fab fa-google fa-2x"></i>
                                    <p>Google</p>
                                </a>
                            </div>
                        </div>
                        <div class="share">
                            <div class="border-left border-content text-uppercase font-weight-bold mb-3">
                                Contact Us About This Event
                            </div>
                            <div class="share-buttons d-flex flex-row justify-content-between">
                                <a v-if="elementConfig.EMAIL" :href="`mailto:${elementConfig.EMAIL}`">
                                    <i class="fas fa-envelope fa-2x"></i>
                                    <p>Email</p>
                                </a>
                                <a v-if="elementConfig.PHONE" :href="`tel:${elementConfig.PHONE}`">
                                    <i class="fas fa-phone fa-2x"></i>
                                    <p>Call</p>
                                </a>
                                <a v-if="elementConfig.FACEBOOK" :href="elementConfig.FACEBOOK">
                                    <i class="fab fa-facebook-f fa-2x"></i>
                                    <p>Facebook</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="relatedEvents.length > 0" class="row">
                    <div class="col-12">
                        <h3 class="border-left border-content">Related Events You Might Want to Attend</h3>
                    </div>

                    <div v-for="(event, index) in relatedEvents" :key="index" class="col-md-6 col-sm-12">
                        <relatedEvents :event="event"></relatedEvents>
                    </div>
                </div>

                <hr />

                <div class="row">
                    <div class="col-12 mx-auto">
                        <div id="event-form-wrapper"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import constants from '../../config/constants';
    import eventService from '../../../services/EventService';
    import relatedEvents from './RelatedEvents';
    import eventMixin from '../mixins/EventMixin';

    export default {
        name: 'EventDetail',
        components: {
            relatedEvents: relatedEvents
        },
        mixins: [eventMixin],
        data() {
            return {
                elementConfig: constants.elementConfig,
                event: {},
                relatedEvents: []
            };
        },
        computed: {
            directionsLink() {
                return `//maps.google.com/maps?q=${this.event.Street} ${this.event.City} ${this.event.State}`;
            },
            googleCalendarUrl() {
                if (!this.event || !this.event.Id) {
                    return '';
                }

                const baseUrl = 'http://www.google.com/calendar/event?action=TEMPLATE';
                const title = encodeURIComponent(this.event.Title);
                const startDate = this.event.EventStart.replace(/[:-]+/g, '');
                const endDate = this.event.EventEnd.replace(/[:-]+/g, '');

                let location = '';
                let url = `${baseUrl}&text=${title}&dates=${startDate}/${endDate}&sprop=name:${title}`;

                if (this.event.Street) {
                    location += this.event.Street + ', ';
                }
                if (this.event.City) {
                    location += this.event.City + ', ';
                }
                if (this.event.State) {
                    location += this.event.State + ', ';
                }
                if (this.event.Country) {
                    location += this.event.Country + ', ';
                }
                if (location) {
                    url += `&location=${location}`;
                }
                if (this.event.ContactWeb) {
                    url += `&sprop=website:${encodeURIComponent(this.event.ContactWeb)}`;
                }
                if (this.event.Summary) {
                    url += `&details=${encodeURIComponent(this.event.Summary)}`;
                }
                if (this.event.RecurrenceExpression) {
                    url += `&recur=${this.event.RecurrenceExpression}`;
                }

                return url;
            },
            calendarUrl() {
                //outlookUrl and iCalUrl
                if (!this.event || !this.event.Id) {
                    return '';
                }

                const baseUrl = 'Sitefinity/Public/Services/ICalanderService/file.ics';
                const id = this.event.Id;
                return `/${baseUrl}/?id=${id}&provider=&uiculture=en`;
            }
        },
        watch: {
            $route: {
                deep: true,
                handler: function(to, from) {
                    this.getEvent();
                }
            }
        },
        created() {
            this.getEvent();
        },
        mounted() {
            var form = $('.event-form');
            var wrapper = $('#event-form-wrapper');
            wrapper.append(form.html());
        },
        methods: {
            getEvent() {
                var vm = this;
                this.event = this.$route.params.event;

                if (!this.event) {
                    var slug = this.$route.params.detail;

                    eventService.getEvent(slug).then(function(value) {
                        vm.event = value;
                        eventService.getRelatedEvents(vm.event).then(function(value) {
                            vm.relatedEvents = value;
                        });
                    });
                }
            }
        }
    };
</script>

<style scoped>
    .underline {
        border-bottom: 2px solid;
    }

    .btn {
        border-radius: 0;
    }

    .card-img-top {
        width: 100%;
        height: 15vw;
        object-fit: cover;
    }
</style>
