﻿<template>
    <build-your-own-content
        :collection="seriesCategories"
        :loading="loading"
        :machine-collection="machines"
    ></build-your-own-content>
</template>

<script>
    // Utilities
    import utilities from '../../lib/utilities';
    // Services
    import DeereBuildYourOwnService from '../../../services/DeereBuildYourOwnService';
    // Components
    import BuildYourOwnContent from './BuildYourOwnContent';

    export default {
        name: 'BuildYourOwnSeriesCategoriesSelection',
        components: {
            BuildYourOwnContent
        },
        data() {
            return {
                seriesCategories: [],
                machines: [],
                loading: true
            };
        },
        mounted() {
            DeereBuildYourOwnService.getSeriesCategories(
                this.$route.params.industry,
                this.$route.params.category,
                this.$route.params.subcategory,
                this.$route.params.series
            ).then(seriesCategories => {
                this.seriesCategories = utilities.sortByKey(seriesCategories.Classifications, 'Name');
                this.machines = seriesCategories.Products;

                this.loading = false;
            });
        }
    };
</script>
