﻿<template>
    <div id="new-featured-equipment" ref="wrapper">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <a :href="showroomPage" class="logo-wrapper">
                        <element-image
                            class="py-2 mx-auto"
                            img-class="logo"
                            :src="logoImage"
                            alt="Manufacturer logo for featured new equipment"
                        ></element-image>

                        <div class="showroom-link italic-text">
                            <span>Visit Showroom</span>
                        </div>
                        <element-image
                            img-class="equipment"
                            :src="equipmentImage"
                            alt="Featured new equipment photo"
                        />
                    </a>
                </div>
                <div class="col-6 d-none d-sm-block">
                    <div class="card-wrapper text-primary">
                        <splide
                            v-if="cards.length > 0"
                            class="pb-0"
                            :class="{ 'px-0': cards.length === 1 }"
                            :options="sliderOptions"
                        >
                            <splide-slide v-for="(card, i) in cards" :key="i">
                                <card-standard
                                    :enable-image="true"
                                    :image="{ src: card.Image.src || defaultImage }"
                                    :title="card.Title"
                                    :action-name="card.ActionName"
                                    :action-url="card.ActionUrl"
                                    :enable-button-link="true"
                                    btn-style="btn-primary text-center"
                                    :use-block-btn="true"
                                    :limit-image-height="true"
                                    class="h-100"
                                >
                                    {{ card.Summary }}
                                </card-standard>
                            </splide-slide>
                        </splide>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import constants from '../../config/constants';
    import { optimizeImage } from '../../lib/utilities';

    import CardStandard from '../cards/CardStandard';

    export default {
        name: 'NewEquipmentHomepage',
        components: {
            CardStandard
        },
        props: {
            backgroundImage: {
                type: String,
                default: ''
            },
            cards: {
                type: Array,
                default: () => []
            },
            equipmentImage: {
                type: String,
                required: true
            },
            logoImage: {
                type: String,
                required: true
            },
            showroomPage: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                defaultImage: constants.equipmentPlaceholderImage,
                sliderOptions: {
                    arrows: this.cards.length > 1,
                    autoplay: true,
                    gap: '1rem',
                    pagination: false,
                    rewind: true,
                    speed: 1000
                }
            };
        },
        mounted() {
            if (this.backgroundImage) {
                this.$refs.wrapper.style.cssText = `background: url(${optimizeImage(
                    this.backgroundImage,
                    document.documentElement.clientWidth
                )}); background-size: cover;`;
            }
        }
    };
</script>
