﻿// @ts-check
import '../frontend/config/equipment-types';
import constants from '../frontend/config/constants';
import { getDataPage } from './lib/ODataService';
import { buildODataIdFilter } from '../frontend/lib/utilities';

//#region Configuration Data

const newEquipmentUrlMappings = {
    casece: {
        baseUrl: [
            '/api/default/casececategories?',
            '/api/default/casecesubcategories?',
            '/api/default/casecemachines?'
        ],
        expand: ['Subcategories,Machines', 'Machines', 'DealerPromotions'],
        map: {
            0: obj => {
                obj.children = obj.Subcategories;
                return obj;
            },
            default: obj => obj
        }
    },
    caseih: {
        baseUrl: [
            '/api/default/caseihcategories?',
            '/api/default/caseihsubcategories?',
            '/api/default/caseihseries?',
            '/api/default/caseihseriescategories?',
            '/api/default/caseihmachines?'
        ],
        expand: [
            'Subcategories, Series',
            'Series',
            'SeriesCategories, Machines',
            'Machines',
            'DealerPromotions'
        ],
        map: {
            0: obj => {
                obj.children = obj.Subcategories;
                return obj;
            },
            default: obj => obj
        }
    },
    deere: {
        baseUrl: [
            '/api/default/deereindustries?',
            '/api/default/deerecategories?',
            '/api/default/deeresubcategories?',
            '/api/default/deereseries?',
            '/api/default/deereseriescategories?',
            '/api/default/deeremachines?'
        ],
        expand: [
            'Categories',
            'Subcategories,Products',
            'Series,Products',
            'SeriesCategories,Products',
            'Products',
            'DealerPromotions'
        ],
        map: {
            0: obj => {
                obj.children = sortByTitle(obj.Categories);
                return obj;
            },
            default: obj => obj
        }
    },
    kubota: {
        baseUrl: [
            '/api/default/kubotaproducts?',
            '/api/default/kubotacategories?',
            '/api/default/kubotaseries?'
        ],
        expand: ['Categories,DealerPromotions', 'Series'],
        map: {
            0: obj => {
                obj.children = obj.Categories;
                return obj;
            },
            default: obj => obj
        }
    },
    mahindra: {
        baseUrl: [
            '/api/default/mahindracategories?',
            '/api/default/mahindraseries?',
            '/api/default/mahindramachines?'
        ],
        expand: ['Series', 'Machines', 'DealerPromotions'],
        map: {
            0: obj => {
                obj.children = obj.Series;
                return obj;
            },
            default: obj => obj
        }
    },
    massey_ferguson: {
        baseUrl: [
            '/api/default/masseyfergusoncategories?',
            '/api/default/masseyfergusonsubcategories?',
            '/api/default/masseyfergusonseries?',
            '/api/default/masseyfergusonmachines?'
        ],
        expand: ['Subcategories', 'Series', 'DealerPromotions', 'DealerPromotions,Images'],
        map: {
            0: obj => {
                obj.children = obj.Subcategories;
                return obj;
            },
            default: obj => obj
        }
    },
    new_holland_ag: {
        baseUrl: [
            '/api/default/newhollandcategories?',
            '/api/default/newhollandseries?',
            '/api/default/newhollandmachines?'
        ],
        expand: ['Series', 'Machines', 'DealerPromotions'],
        map: {
            0: obj => {
                obj.children = obj.Series;
                return obj;
            },
            default: obj => obj
        }
    },
    new_holland_construction: {
        baseUrl: [
            '/api/default/newhollandconstructioncategories?',
            '/api/default/newhollandconstructionmodels?'
        ],
        expand: ['Models', 'DealerPromotions'],
        map: {
            0: obj => {
                obj.children = obj.Models;
                return obj;
            },
            default: obj => obj
        }
    },
    shortline: {
        baseUrl: [
            '/api/default/shortlinemachinetypes?',
            '/api/default/shortlinemachinecategories?',
            '/api/default/shortlinemachines?'
        ],
        expand: ['Categories', null, 'MachineImages'],
        map: {
            0: obj => {
                obj.children = obj.Categories;
                return obj;
            },
            default: obj => obj
        }
    },
    stihl: {
        baseUrl: ['/api/default/stihlproductcategories?', '/api/default/stihlproducts?'],
        expand: ['Products', 'DealerPromotions'],
        map: {
            0: obj => {
                obj.children = obj.Products;
                return obj;
            },
            default: obj => obj
        }
    },
    vermeer: {
        baseUrl: [
            '/api/default/vermeerindustries?',
            '/api/default/vermeercategories?',
            '/api/default/vermeermachines?'
        ],
        expand: ['Categories', 'Machines', 'DealerPromotions'],
        map: {
            0: obj => {
                obj.children = obj.Categories;
                return obj;
            },
            default: obj => obj
        }
    }
};

const showroomLogos = {
    caseih: '/images/default-source/default-album/caseih-logo.png',
    casece: '/images/default-source/default-album/case-construction.jpg',
    deere: '/images/default-source/default-album/johndeere_logo.png',
    deere_alt: '/ElementUI/dist/sitefinity/manufacturer-logos/john-deere-vertical-1200.png',
    kubota: '/images/default-source/default-album/kubota-logo.jpg',
    massey_ferguson: '/images/default-source/default-album/mf-logo.jpg',
    new_holland_ag: '/images/default-source/default-album/logo-agri.png',
    new_holland_construction: '/images/default-source/default-album/logo-construction.png',
    shortline: '/ElementUI/dist/sitefinity/manufacturer-logos/shortline-250.png',
    stihl: '/ElementUI/dist/sitefinity/manufacturer-logos/stihl-250.png',
    vermeer: '/images/default-source/default-album/vermeer-logo.png'
};

//#endregion Configuration Data

/**
 * Gets the specified manufacturer's machines/series REST API endpoint.
 * (some manufacturers only list by series).
 * @param {string} datasource
 */
function getDetailIndex(datasource) {
    return Object.keys(newEquipmentUrlMappings[datasource].baseUrl).length - 1;
}

/**
 * Orders an array of categories by the Title property.
 * @param {Category[]} items
 */
function sortByTitle(items) {
    return items && items.sort((a, b) => (a.Title > b.Title ? 1 : -1));
}

/**
 * Builds new equipment request and passes on to getDataPage to retrieve results from the Sitefinity REST API.
 * @param {string} dataSource
 * @param {number} level
 * @param {Options} options
 */
export function getNewEquipment(dataSource, level, options) {
    let dataSourceConfig = newEquipmentUrlMappings[dataSource];
    let apiUrl = dataSourceConfig.baseUrl[level];
    let queryParts = [];
    let mapper = dataSourceConfig.map[level] || dataSourceConfig.map.default;

    // recursion is the default so should only be false if options.recursive is defined and equates to false
    const recurse = !options || !options.hasOwnProperty('recursive') || options.recursive === true;
    const orderBy = options && options.$orderby ? options.$orderby : 'Id';

    queryParts.push(`$orderby=${orderBy}`);

    if (options && options.$expand) {
        let expandValue = dataSourceConfig.expand[level];
        if (expandValue) {
            queryParts.push(`$expand=${expandValue}`);
        }
    }

    if (options && options.$filter) {
        queryParts.push(`$filter=${options.$filter}`);
    }

    if (options && options.$select?.length > 0) {
        queryParts.push(`$select=${options.$select.join(',')}`);
    }

    apiUrl += queryParts.join('&');

    return new Promise(resolve => {
        getDataPage(apiUrl, 0, recurse)
            .then(response => {
                let results = response.data.value.map(result => {
                    return mapper(result);
                });

                resolve(results);
            })
            .catch(reason => {
                console.error(reason.response?.data?.error?.message);
            });
    });
}

/**
 * Builds the URL to a machine.
 * @param {Machine} machine
 */
export function getNewEquipmentUrl(machine) {
    if (machine.Url) {
        return machine.Url;
    }

    let pathParts = [];

    if (machine.BaseUrl) {
        pathParts.push(machine.BaseUrl);
    }

    if (machine.HierarchyPath?.length) {
        if (constants.elementConfig.SHOWROOM_VERSION == 2) {
            let hierarchyPath = machine.HierarchyPath[machine.HierarchyPath.length - 1].split('|');
            pathParts = pathParts.concat(hierarchyPath);
        }
        else {
            let hierarchyPath = (machine.HierarchyPath[1] || machine.HierarchyPath[0]).split('|');
            pathParts = pathParts.concat(hierarchyPath);
        }
    }

    pathParts = pathParts.concat([machine.UrlName, 'overview']);

    return pathParts.join('/');
}

/**
 * Retrieves equipment data for the specified data source and items that are selected.
 * @param {string} dataSource
 * @param {number} level - specifies the type of eq data to retrieve: industry, category, series, machine, etc.
 * @param {string[]} ids - array of selected item's ids to retrieve.
 * @param {string[]} fieldsToSelect - array of fields to select
 */
export function getNewEquipmentByIds(dataSource, level, ids, fieldsToSelect) {
    level = level || getDetailIndex(dataSource);

    return getNewEquipment(dataSource, level, {
        $filter: buildODataIdFilter(ids),
        $select: fieldsToSelect || []
    });
}

/**
 * Retrieves a new equipment item from the REST api when the manufacturer and slug are specified.
 * @param {string} datasource
 * @param {string} slug
 * @param {Options} options
 */
export function getNewMachineBySlug(datasource, slug, options) {
    options = options ? options : {};
    options.$filter = `UrlName eq '${slug}'`;

    let detailIndex = getDetailIndex(datasource, options);

    return new Promise((resolve, reject) => {
        getNewEquipment(datasource, detailIndex, options).then(result => {
            if (result && result.length) {
                let machine = result[0];

                // maps data from various image properties
                machine.Images = getMachineImages(machine);

                // Shortline properties fallback
                machine.Title = machine.Title || machine.MachineTitle;
                machine.Summary = machine.Summary || machine.MachineSpecs;

                resolve(machine);
            } else {
                reject(`No machine found for UrlName ${slug}`);
            }
        });
    });
}

/**
 * Returns machine images as comma delimited string from a variety of property names.
 * Gives preference to related image fields over string fields.
 * @param {*} machine
 * @returns {string} comma delimited image urls
 */
export function getMachineImages(machine) {
    // handle related MachineImages property
    if (Array.isArray(machine.MachineImages) && machine.MachineImages.length > 0) {
        return machine.MachineImages.map(i => i.Url).join(',');
    }

    // handle related Images property
    if (Array.isArray(machine.Images) && machine.Images.length > 0) {
        return machine.Images.map(i => i.Url).join(',');
    }

    // handle string delimited Images property
    if (machine.Images && typeof machine.Images === 'string') {
        return machine.Images;
    }

    // handle string delimited ImageUrl property
    if (machine.ImageUrl && typeof machine.ImageUrl === 'string') {
        return machine.ImageUrl;
    }

    return null;
}

/**
 * Retrieves all machines of a specified manufacturer and category from the SF REST API.
 * Subcategory may optionally be specified as well.
 * @param {string} manufacturer
 * @param {string} category
 * @param {string} [subcategory]
 */
export function getNewMachinesByCategory(manufacturer, category, subcategory) {
    let detailIndex = getDetailIndex(manufacturer);
    let filterQuery = subcategory ? `${category}|${subcategory}` : `${category}`;
    let options = {
        $filter: `contains(HierarchyPath, '${filterQuery}')`,
        $expand: true
    };

    return getNewEquipment(manufacturer, detailIndex, options);
}

/**
 * Retrieves the Showroom logo for the specified manufacturer.
 * @param {string} manufacturer
 */
export function getLogo(manufacturer) {
    return showroomLogos[manufacturer];
}

/**
 * Retrieves the Showroom base url for the specified dataSource.
 * @param {string} dataSource
 */
export function getShowroomUrl(dataSource) {
    return constants.elementConfig.SITEMAP[dataSource.toUpperCase()] ?? '';
}

export function getNewEquipmentCTPName(dataSource) {
    return constants.newEqTypeNames[dataSource.toUpperCase()];
}

export default {
    getNewEquipment,
    getNewEquipmentUrl,
    getNewEquipmentByIds,
    getNewMachineBySlug,
    getNewMachinesByCategory,
    getLogo,
    getShowroomUrl,
    getNewEquipmentCTPName
};
