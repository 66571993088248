const taxaAPI = '/api/default/hierarchy-taxa';

let getCategoriesFromIds = categoryIds => {
    let url = `${taxaAPI}?$filter=${categoryIds.map(id => `Id eq ${id}`).join(' or ')}`;

    return new Promise((resolve, reject) => {
        fetch(url)
            .then(response => response.json())
            .then(data => resolve(data))
            .catch(error => reject(error));
    });
};

export default {
    getCategoriesFromIds
};