<template>
    <build-your-own-content
        :collection="subcategories"
        :machine-collection="machines"
        :loading="loading"
    ></build-your-own-content>
</template>

<script>
    import DeereBuildYourOwnService from '../../../services/DeereBuildYourOwnService';
    import utilities from '../../lib/utilities';

    import BuildYourOwnContent from './BuildYourOwnContent';

    export default {
        name: 'BuildYourOwnSubCategorySelection',
        components: {
            BuildYourOwnContent
        },
        data() {
            return {
                loading: true,
                machines: [],
                subcategories: []
            };
        },
        mounted() {
            DeereBuildYourOwnService.getSubcategories(
                this.$route.params.industry,
                this.$route.params.category
            ).then(subcategories => {
                this.subcategories = utilities.sortByKey(subcategories.Classifications, 'Name');
                this.machines = subcategories.Products;

                this.loading = false;
            });
        }
    };
</script>
