﻿<template>
    <splide class="pb-0 px-0 px-lg-5" :options="sliderOptions">
        <splide-slide v-for="(slide, i) in slides" :key="i">
            <card-wide
                :title="slide.title"
                :description="slide.description"
                :image="slide.image && slide.image.src"
                :mobile-image="slide.mobileImage && slide.mobileImage.src"
                :action-name="slide.ctaText"
                :action-url="slide.ctaLink"
                :open-link-in-new-tab="slide.openLinkInNewTab"
            />
        </splide-slide>
    </splide>
</template>

<script>
    import CardWide from '../cards/CardWide';

    export default {
        name: 'HeroSliderClassic',
        components: {
            CardWide
        },
        props: {
            slides: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                sliderOptions: {
                    arrows: this.slides.length > 1,
                    autoplay: true,
                    breakpoints: {
                        991: {
                            arrows: false,
                            padding: {
                                left: '3rem',
                                right: '3rem'
                            }
                        },
                        767: {
                            arrows: false,
                            padding: {
                                left: '2.3rem',
                                right: '2.3rem'
                            }
                        }
                    },
                    gap: '1rem',
                    pagination: false,
                    rewind: true,
                    speed: 1000
                }
            };
        }
    };
</script>
