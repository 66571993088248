import './styles/main.scss';

import JQuery from 'jquery';
import 'bootstrap';
import 'magnific-popup';

import './lib/find-polyfill';
import './lib/Object.assign-polyfill';

import App from './App';
import constants from './config/constants';
import imagesLoaded from 'imagesloaded';
import LazyLoadDirective from './lib/LazyLoadDirective';
import masonry from './lib/masonry.js';
import searchConfig from './config/search-config';
import StoreConstants from './store/constants';
import Toasted from 'vue-toasted';
import Vue from 'vue';
import VueChartkick from 'vue-chartkick';
import VueRouter from 'vue-router';
import VueSocialSharing from 'vue-social-sharing';
import VueSplide from '@splidejs/vue-splide';

import { ElementBlaze } from '@teamsi/element-blaze';
import { store, searchStore } from './store/store';
import { toKebab } from './lib/utilities';

// global Vue components
import ElementImage from './components/utility/ElementImage';
import CardHorizontal from './components/cards/CardHorizontal';

Vue.use(ElementBlaze); // registers search form components
Vue.use(scrollTo);
Vue.use(Toasted);
Vue.use(VueChartkick);
Vue.use(VueRouter);
Vue.use(VueSocialSharing);
Vue.use(VueSplide);

// setup theme
store.dispatch(StoreConstants.THEME.ACTIONS.SET_THEME, constants.elementConfig.THEME);
store.dispatch(StoreConstants.THEME.ACTIONS.APPLY_COLORS, constants.elementConfig.COLORS);

// register global components
Vue.component('CardHorizontal', CardHorizontal);
Vue.component('ElementImage', ElementImage);

// register custom directives
Vue.directive('lazy-load', LazyLoadDirective);

// expose items to Sitefinity widgets
window.jq = JQuery;
window.constants = constants;
window.Masonry = masonry;
window.imagesLoaded = imagesLoaded;
window.searchConfig = searchConfig;
window.searchStore = searchStore;
window.store = store;
window.Vue = Vue;
window.VueRouter = VueRouter;

// Vue components that render Sitefinity widgets
const widgetComponents = {
    BlogPostDetail: require('./components/blog/BlogPostDetail').default,
    BlogPostFeatured: require('./components/blog/BlogPostFeatured').default,
    BlogPostHome: require('./components/blog/BlogPostHome').default,
    BlogPostList: require('./components/blog/BlogPostList').default,
    BuildYourOwnBuilder: require('./components/build-your-own/BuildYourOwnBuilder').default,
    BuildYourOwnCategorySelection: require('./components/build-your-own/BuildYourOwnCategorySelection').default,
    BuildYourOwnIndustrySelection: require('./components/build-your-own/BuildYourOwnIndustrySelection').default,
    BuildYourOwnSeriesCategoriesSelection: require('./components/build-your-own/BuildYourOwnSeriesCategoriesSelection').default,
    BuildYourOwnSeriesSelection: require('./components/build-your-own/BuildYourOwnSeriesSelection').default,
    BuildYourOwnSubcategorySelection: require('./components/build-your-own/BuildYourOwnSubcategorySelection').default,
    Careers: require('./components/careers/Careers').default,
    CareerDetail: require('./components/careers/CareerDetail').default,
    CompareEquipment: require('./components/compare-equipment/CompareEquipment').default,
    CTPEditor: require('./components/ctp-editor/CTPEditor').default,
    ElementImage: require('./components/utility/ElementImage').default,
    EmployeeList: require('./components/staff/EmployeeList').default,
    EventDetail: require('./components/events/EventDetail').default,
    EventsList: require('./components/events/EventsList').default,
    FeatureCard: require('./components/cards/CardFeature').default,
    FinanceCalculator: require('./components/finance-calculator/FinanceCalculator').default,
    HeaderCards: require('./components/header/HeaderCards').default,
    HeaderClassic: require('./components/header/HeaderClassic').default,
    HeaderMobile: require('./components/header/HeaderMobile/HeaderMobile').default,
    HeaderMobileRounded: require('./components/header/HeaderMobile/HeaderMobileRounded').default,
    HeaderRounded: require('./components/header/HeaderRounded/HeaderRounded').default,
    HeaderWide: require('./components/header/HeaderWide').default,
    Hero: require('./components/hero/Hero').default,
    HeroSearch: require('./components/hero-search/HeroSearch.vue').default,
    HomepageUsedEquipment: require('./components/used-equipment/HomepageUsedEquipment').default,
    HorizontalCard: require('./components/cards/CardHorizontal').default,
    LocationList: require('./components/locations/LocationList').default,
    LocationListRounded: require('./components/locations/LocationListRounded').default,
    LocationNearbyList: require('./components/locations/LocationNearbyList').default,
    LocationStoreDetail: require('./components/locations/LocationStoreDetail').default,
    MachineSuggester: require('./components/machine-suggester/MachineSuggester.vue').default,
    NewEquipmentDetail: require('./components/new-equipment/EquipmentShowroomDetail').default,
    NewEquipmentDetailBrochures: require('./components/new-equipment/EquipmentDetailBrochures').default,
    NewEquipmentDetailDefault: require('./components/new-equipment/EquipmentDetailDefault').default,
    NewEquipmentDetailFeatures: require('./components/new-equipment/EquipmentDetailFeatures').default,
    NewEquipmentDetailGallery: require('./components/new-equipment/EquipmentDetailGallery').default,
    NewEquipmentDetailModels: require('./components/new-equipment/EquipmentDetailModels').default,
    NewEquipmentDetailOverview: require('./components/new-equipment/EquipmentDetailOverview').default,
    NewEquipmentDetailSpecials: require('./components/new-equipment/EquipmentDetailSpecials').default,
    NewEquipmentDetailSpecifications: require('./components/new-equipment/EquipmentDetailSpecifications').default,
    NewEquipmentDetailVideos: require('./components/new-equipment/EquipmentDetailVideos').default,
    NewEquipmentHomepage: require('./components/new-equipment/NewEquipmentHomepage').default,
    NewsletterSubscription: require('./components/newsletter-subscription/NewsletterSubscription').default,
    Promotions: require('./components/promotions/Promotions.vue').default,
    PromotionsDetail: require('./components/promotions/PromotionDetail.vue').default,
    RouterViewWrapper: require('./components/utility/RouterViewWrapper').default,
    SearchSitewide: require('./components/search/SearchSitewide').default,
    Showroom: require('./components/showroom/Showroom.vue').default,
    SieqNewEquipmentGrid: require('./components/new-equipment/EquipmentShowroomGrid').default,
    SieqNewEquipmentHero: require('./components/new-equipment/EquipmentShowroomHero').default,
    SieqNewEquipmentShowroom: require('./components/new-equipment/NewEquipmentShowroom').default,
    SieqShortLineDetail: require('./components/short-lines/ShortLineShowroomDetail').default,
    SieqShortLineGrid: require('./components/short-lines/ShortLineShowroomGrid').default,
    SieqShortLineHero: require('./components/short-lines/ShortLineShowroomHero').default,
    SieqShortLineShowroom: require('./components/short-lines/ShortLineShowroom').default,
    SocialShareApp: require('./components/SieqxSocialShare').default,
    SocialStream: require('./components/social-stream/SocialStream').default,
    SpecialPromotionsApp: require('./components/specials/SpecialOffers').default,
    SpecialPromotionsDetailsApp: require('./components/specials/SpecialOffersDetail').default,
    SpecialPromotionsHomeApp: require('./components/specials/SpecialOffersHome').default,
    StandardCard: require('./components/cards/CardStandard').default,
    TestimonialsApp: require('./components/Testimonials').default,
    UsedEquipment: require('./components/used-equipment/UsedEquipment').default,
    UsedEquipmentDetail: require('./components/used-equipment/UsedMachineDetail').default,
    WideCard: require('./components/cards/CardWide').default
};

// expose components to Sitefinity widgets
Object.keys(widgetComponents).forEach(k => {
    window[k] = widgetComponents[k];
});

if (window.ElementConfig && window.ElementConfig.IN_UI_DEVELOPMENT_MODE) {
    // register widgets globally when in development mode
    Object.keys(widgetComponents).forEach(k => {
        Vue.component(toKebab(k), widgetComponents[k]);
    });

    let ElementsList = require('./styleguide/ElementsList').default;
    let Cards = require('./styleguide/Cards').default;
    let HomepageWidgets = require('./styleguide/HomepageWidgets').default;

    let router = new VueRouter({
        mode: 'history',
        routes: [
            {
                component: ElementsList,
                name: 'Elements',
                path: '/'
            },
            {
                component: Cards,
                name: 'Cards',
                path: '/cards'
            },
            {
                component: HomepageWidgets,
                name: 'HomepageWidgets',
                path: '/homepage-widgets'
            }
        ]
    });

    new Vue({
        el: '#app',
        router,
        render: h => h(App),
        store: window.store
    });
}
