<template>
    <div ref="wrapper" class="promotion-detail py-3">
        <div v-if="loading" class="container p-6">
            <promotion-detail-skeleton></promotion-detail-skeleton>
        </div>
        <eb-index
            v-else
            :api-key="elementConfig.BLAZE_API_KEY"
            :default-filters="defaultFilters"
            :index-name="elementConfig.BLAZE_INDEX_NAME"
            :service-name="elementConfig.BLAZE_SERVICE_NAME"
            :persist-form-state="false"
        >
            <div class="container pb-lg-5">
                <div class="row">
                    <!-- Image -->
                    <element-image
                        class="col-12 col-lg-6 order-lg-2"
                        :alt="promotion.Title"
                        :src="image"
                    ></element-image>

                    <!-- Content -->
                    <div class="col-12 col-lg-6 pt-3 order-lg-1">
                        <ribbon-badge v-if="isDealOfTheMonth" class="px-3" :flatten-left="true">
                            <span class="text-uppercase">Deal of the month</span>
                        </ribbon-badge>

                        <div class="px-3">
                            <h3 class="mt-3" v-html="promotion.Title"></h3>
                            <h4 class="text-muted" v-html="promotion.Subtitle"></h4>

                            <a class="d-block mt-5" target="_self" @click.prevent="scrollToDetailsSection">
                                <div class="btn btn-primary rounded px-5">View Details</div>
                            </a>
                        </div>
                    </div>

                    <div class="col-12 py-0 order-lg-3">
                        <hr class="mx-3 my-5" />
                    </div>

                    <div class="col-12 order-lg-4">
                        <div class="row px-3">
                            <!-- Buttons -->
                            <div class="col-12 col-lg-4 order-lg-3">
                                <btn-icon
                                    icon-left="fa-dollar-sign"
                                    data-toggle="modal"
                                    :data-target="`#${quoteFormModalId}`"
                                    >Get a Quote</btn-icon
                                >
                                <btn-icon icon-left="fa-phone-volume" :action-url="`tel:${dealerPhoneNumber}`"
                                    >Call Us {{ dealerPhoneNumber }}</btn-icon
                                >
                                <btn-icon
                                    v-if="promotion.FinancingUrl"
                                    icon-left="fa-hand-holding-usd"
                                    :action-url="promotion.FinancingUrl"
                                    >Apply For Financing</btn-icon
                                >
                                <btn-icon icon-left="fa-envelope" :href="mailLink"
                                    >Email me this promotion</btn-icon
                                >
                                <btn-icon icon-left="fa-print" @click="print()">Print</btn-icon>
                                <btn-icon
                                    data-toggle="modal"
                                    :data-target="`#${sharingModalId}`"
                                    icon-left="fa-share-alt"
                                    >Share</btn-icon
                                >
                            </div>

                            <!-- Details -->
                            <div class="col-12 col-lg-4 mt-5 mt-lg-0 order-lg-1">
                                <h4 id="promo-detail" class="text-primary text-uppercase">Details</h4>
                                <!-- Comes with -->
                                <ribbon-badge v-if="promotion.ComesWith" class="pb-3">
                                    {{ promotion.ComesWith }}
                                </ribbon-badge>
                                <div class="pb-3" v-html="summary"></div>
                            </div>

                            <!-- Description -->
                            <div class="col-12 col-lg-4 order-lg-2">
                                <div class="pb-3" v-html="description"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Disclaimer -->
                <div
                    v-if="promotion.Disclaimer"
                    class="mt-lg-5 px-3 small text-muted"
                    v-html="promotion.Disclaimer"
                ></div>
            </div>

            <!-- Location widget -->
            <promotion-locations class="mb-3"></promotion-locations>

            <!-- Offers -->
            <div v-if="showYouMayAlsoLike" class="container pt-2 pt-lg-5">
                <h3 class="text-center text-lg-left text-primary text-uppercase pb-4 mb-0">
                    You may also like
                </h3>
                <div class="row mb-3">
                    <div class="col-12 col-lg-5 mb-3 mb-lg-0">
                        <eb-suggestions
                            id="search-input"
                            class="w-100"
                            placeholder="Search..."
                        ></eb-suggestions>
                    </div>
                    <div class="col-6 col-lg-4">
                        <eb-sort-by-dropdown
                            id="sort-by-dropdown"
                            class="form-control"
                            :options="sortOptions"
                        ></eb-sort-by-dropdown>
                    </div>
                    <div class="col-6 col-lg-3">
                        <eb-reset btn-class="btn btn-primary btn-lg btn-block p-1">Clear Filters</eb-reset>
                    </div>
                </div>
                <div class="row m-0">
                    <eb-results results-wrapper-class="row">
                        <div slot-scope="{ item }" class="col-12 col-md-6 col-lg-4 mb-4">
                            <search-result-promotion
                                v-if="item.UrlName != promotionSlug"
                                :base-url="baseUrl"
                                class="h-100"
                                :item="item"
                                :badges="['Special']"
                                :use-router="false"
                            ></search-result-promotion>
                        </div>
                    </eb-results>
                </div>
                <eb-results-per-page-selector class="d-none" />
            </div>
        </eb-index>

        <modal-pane :id="sharingModalId" class="sharing-icons" title="Sharing options">
            <div class="p-4">
                <share-network
                    class="share-network-use-default-background"
                    network="facebook"
                    tabindex="-1"
                    :title="socialSharingDescription"
                    :url="socialSharingUrl"
                    :quote="socialSharingDescription"
                >
                    <i class="fab fa-facebook-f"></i>
                    <span>Facebook</span>
                </share-network>
                <br />
                <share-network
                    class="share-network-use-default-background"
                    network="twitter"
                    tabindex="-1"
                    :title="socialSharingDescription"
                    :url="socialSharingUrl"
                    :quote="socialSharingDescription"
                >
                    <i class="fab fa-twitter"></i>
                    <span>Twitter</span>
                </share-network>
            </div>
        </modal-pane>

        <modal-form
            v-if="useSitefinityForm"
            :id="quoteFormModalId"
            :field-values="[
                {
                    selector: `.interested-eq input[class='form-control']`,
                    value: promotionInterestedIn
                }
            ]"
            :form-selector="`.${quoteFormModalId}`"
            :title="quoteFormModalTitle"
        ></modal-form>

        <modal-iframe
            v-if="!useSitefinityForm && formUrl"
            :id="quoteFormModalId"
            :title="quoteFormModalTitle"
            :iframe-source="formUrl"
        ></modal-iframe>
    </div>
</template>

<script>
    import constants from '../../config/constants';
    import { DEV_ENV_SERVER } from '../../config/constants';
    import promotionService from '../../../services/PromotionService';
    import { getTextFromHtml, stripNbspTags, stripHeaderTags } from '../../lib/utilities';
    // Components
    import BtnIcon from '../utility/BtnIcon';
    import ModalForm from '../utility/ModalForm';
    import ModalIframe from '../utility/ModalIFrame.vue';
    import ModalPane from '../utility/ModalPane';
    import PromotionDetailSkeleton from './PromotionDetailSkeleton';
    import PromotionLocations from './PromotionLocations';
    import RibbonBadge from '../utility/RibbonBadge';
    import SearchResultPromotion from '../search/SearchResultPromotions';

    export default {
        name: 'PromotionDetail',
        components: {
            BtnIcon,
            PromotionDetailSkeleton,
            PromotionLocations,
            ModalForm,
            ModalIframe,
            ModalPane,
            RibbonBadge,
            SearchResultPromotion
        },
        props: {
            backgroundImageUrl: {
                type: String,
                default: ''
            },
            baseUrl: {
                type: String,
                required: true
            },
            dataSource: {
                type: String,
                required: true
            },
            featuredItemId: {
                type: String,
                default: ''
            },
            formUrl: {
                type: String,
                default: null
            },
            useSitefinityForm: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                sortOptions: [
                    {
                        field: 'Priority',
                        fieldDirection: 'asc',
                        name: 'Sort by: Default'
                    },
                    {
                        field: 'PublicationDate',
                        fieldDirection: 'desc',
                        name: 'Sort by: Recent'
                    },
                    {
                        field: 'Title',
                        fieldDirection: 'asc',
                        name: 'Sort by: Title A-Z'
                    },
                    {
                        field: 'Title',
                        fieldDirection: 'desc',
                        name: 'Sort by: Title Z-A'
                    }
                ],
                quoteFormModalId: 'promotions-get-a-quote',
                quoteFormModalTitle: 'Get a Quote',
                elementConfig: constants.elementConfig,
                socialSharingDescription: 'Look at this promo!',
                sharingModalId: 'sharing-pane',
                promotion: null
            };
        },
        computed: {
            dealerPhoneNumber() {
                if (this.$_selectedLocation && this.$_selectedLocation.Phone) {
                    return this.$_selectedLocation.Phone;
                } else {
                    return this.elementConfig.PHONE;
                }
            },
            defaultFilters() {
                return [
                    {
                        field: 'ContentType',
                        conditions: [
                            {
                                expression: 'eq',
                                value: constants.mappings[this.dataSource].promotion.type
                            }
                        ]
                    },
                    {
                        field: 'UrlName',
                        conditions: [
                            {
                                expression: 'ne',
                                value: this.promotionSlug
                            }
                        ]
                    },
                    {
                        field: 'EndDate',
                        fieldType: 'datetime',
                        conditions: [
                            {
                                expression: 'gt',
                                value: new Date().toISOString()
                            }
                        ]
                    },
                    {
                        field: 'ResultCategories',
                        fieldType: 'collection',
                        conditions: [
                            {
                                filterType: 'search.in',
                                value: this.promotion?.Category?.split(',').map(x => x.trim())
                            }
                        ]
                    }
                ];
            },
            promotionSlug() {
                return this.$route.params.detail;
            },
            socialSharingUrl() {
                return window.location.href.includes('localhost')
                    ? window.location.href.replace(window.location.origin, DEV_ENV_SERVER)
                    : window.location.href;
            },
            loading() {
                return !this.promotion;
            },
            image() {
                return Array.isArray(this.promotion.Image) && this.promotion.Image.length
                    ? this.promotion.Image[0].Url
                    : this.promotion.Image || constants.equipmentPlaceholderImage;
            },
            isDealOfTheMonth() {
                return this.promotion.Id == this.featuredItemId;
            },
            summary() {
                return stripHeaderTags(this.promotion.Summary);
            },
            mailLink() {
                let lineBreak = '%0D';

                return `mailto:?subject=${this.promotion.Title}&body=${this.socialSharingUrl}${lineBreak}`;
            },
            description() {
                let promDesc = this.promotion.Description;
                promDesc = stripNbspTags(promDesc);

                let elem = document.createElement('div');
                elem.innerHTML = promDesc;
                elem.querySelectorAll('button').forEach(button => button.setAttribute('type', 'button'));

                return elem.innerHTML;
            },
            promotionInterestedIn() {
                return (
                    this.promotion &&
                    getTextFromHtml(`${this.promotion.Title} ${this.promotion.Subtitle}`.replace(/&nbsp;/g, ' '))
                );
            },
            showYouMayAlsoLike() {
                return this.$store.getters['search/resultsCount'];
            }
        },
        mounted() {
            promotionService.getPromotionBySlug(this.dataSource, this.promotionSlug).then(promotion => {
                this.promotion = promotion;
            });

            setTimeout(() => {
                window.scroll({
                    behavior: 'smooth',
                    left: 0,
                    top: 0
                });
            }, 100);

            if (this.backgroundImageUrl) {
                this.$refs.wrapper.style.cssText = `background: url(${this.backgroundImageUrl}); background-size: cover`;
            }

            this.quoteFormModalTitle =
                document.querySelector(`.${this.quoteFormModalId}`).dataset.title || quoteFormModalTitle;
        },
        methods: {
            showDealOfTheMonthTag(item) {
                return this.featuredItemId == item.Id;
            },
            scrollToDetailsSection() {
                let detailPosition =
                    document.querySelector('#promo-detail').getBoundingClientRect().top + window.scrollY;
                let headerHeight = document.querySelector('#header-mobile').offsetHeight;
                let scrollPosition = detailPosition - headerHeight;

                // Should the header be position fixed?
                scrollPosition = detailPosition;

                window.scroll({ top: scrollPosition, behavior: 'smooth' });
            },
            print() {
                window.print();
            }
        }
    };
</script>
