<!--
Used to show a Sitefinity form widget in a Bootstrap modal by passing in the form CSS selector.
-->
<template>
    <div :id="id" ref="modal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div ref="modalBody" class="modal-body">
                    <div v-if="!formNode" class="text-center">
                        <h2>Something went wrong :(</h2>
                        <p>Need to reach us right away? Please give us a call at {{ elementConfig.PHONE }}.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import constants from '../../config/constants';
    import jq from 'jquery'; // use packaged version of jQuery

    export default {
        props: {
            /**
             * Id of the modal that will be used to activate it
             * via a button's data-target attribute.
             */
            id: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            /**
             * CSS selector of the form to show in the bodal body.
             */
            formSelector: {
                type: String,
                required: true
            },
            /**
             * Use to pre-fill form fields with default values.
             * Format should be an array of *form-relative* CSS selector and value pairs.
             */
            fieldValues: {
                type: Array,
                default: () => [{ selector: ".interested-eq input[class='form-control']", value: '' }]
            },
            /**
             * Selector for a form field who's value will be used in the title for the Google Analytics events.
             */
            analyticsTitleFieldSelector: {
                type: String,
                default: ".interested-eq input[class='form-control']"
            },
            removeSitefinityFormLabels: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formNode: null,
                elementConfig: constants.elementConfig
            };
        },
        mounted() {
            // ensure external form is loaded before querying it
            jq(() => {
                this.formNode = document.querySelector(this.formSelector);
                this.setupEventHandlers();
                this.populateFormFields();
                this.removeLabels();
            });
        },
        beforeDestroy() {
            this.closeModal();
        },
        methods: {
            closeModal() {
                ($ => {
                    $(this.$refs.modal).modal('hide');
                })(jq);
            },
            onModalOpened() {
                this.moveFormToModal();
                this.resetForm();
                this.populateFormFields();
            },
            onModalClosed() {
                this.moveFormBackToBody();
            },
            onFormSubmitted() {
                this.sendGoogleAnalyticsEvents();
            },
            moveFormToModal() {
                if (this.formNode && this.formNode.parentElement.contains(this.formNode)) {
                    this.formNode.parentElement.removeChild(this.formNode);
                    this.formNode.classList.remove('d-none');
                    this.$refs.modalBody.appendChild(this.formNode);
                }
            },
            moveFormBackToBody() {
                if (this.formNode && this.$refs.modalBody.contains(this.formNode)) {
                    this.$refs.modalBody.removeChild(this.formNode);
                    this.formNode.classList.add('d-none');
                    document.querySelector('body').appendChild(this.formNode);
                }
            },
            /**
             * Removes the form submission success or error message and re-enables the input fields.
             */
            resetForm() {
                if (this.formNode) {
                    let successMsgContainer = this.formNode.querySelector('[data-sf-role=success-message]');
                    let errorMsgContainer = this.formNode.querySelector('[data-sf-role=error-message]');
                    let inputsContainer = this.formNode.querySelector('[data-sf-role=fields-container]');

                    if (successMsgContainer) successMsgContainer.style.display = 'none';
                    if (errorMsgContainer) errorMsgContainer.style.display = 'none';
                    if (inputsContainer) inputsContainer.style.display = 'block';

                    let inputs = this.formNode.querySelectorAll('input[class="form-control"]');
                    inputs.forEach(el => {
                        // This avoids to trigger the required validation on the first run
                        if (el.value != '') {
                            el.value = '';
                        }
                    });
                }
            },
            /**
             * Fill form fields with default values as specified in the fieldValues prop
             */
            populateFormFields() {
                if (this.formNode && this.fieldValues) {
                    this.fieldValues.forEach(field => {
                        let fieldNode = this.formNode.querySelector(field.selector);
                        if (fieldNode) {
                            fieldNode.value = field.value;
                            fieldNode.readOnly = field.readOnly;
                        }
                    });
                }
            },
            setupEventHandlers() {
                // listen for Bootstrap 4's modal jQuery events
                ($ => {
                    $(this.$refs.modal).on('shown.bs.modal', e => {
                        this.onModalOpened();
                    });
                    $(this.$refs.modal).on('hidden.bs.modal', e => {
                        this.onModalClosed();
                    });
                })(jq);

                // listen for form submitted event
                let submitButtons = this.formNode && this.formNode.querySelectorAll('button[type=submit]');

                if (submitButtons) {
                    submitButtons.forEach(btn => {
                        btn.addEventListener('click', () => {
                            this.onFormSubmitted();
                        });
                    });
                }
            },
            sendGoogleAnalyticsEvents() {
                if (ga && this.elementConfig.GA_KEY) {
                    // query the form field that contains the analytics event title
                    let analyticsTitleField = this.formNode.querySelector(this.analyticsTitleFieldSelector);
                    let analyticsEventTitle = analyticsTitleField
                        ? `${this.title} - ${analyticsTitleField.value}`
                        : this.title;

                    ga('create', this.elementConfig.GA_KEY);
                    ga('send', 'event', 'Forms', 'submit', analyticsEventTitle);
                }
            },
            removeLabels() {
                if (this.removeSitefinityFormLabels) {
                    document.querySelectorAll(`${this.formSelector} label`).forEach(element => {
                        element.parentNode.removeChild(element);
                    });
                }
            }
        }
    };
</script>
