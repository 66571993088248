<template>
    <div id="header-rounded" class="d-none d-lg-block">
        <!-- TOP BAR -->
        <div :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <left-brow-links
                        class="d-flex align-items-center"
                        :additional-pages="additionalPages"
                        :contact-settings="contactSettings"
                        :rounded-call-us-button="true"
                    ></left-brow-links>
                    <div class="d-flex justify-content-end">
                        <right-brow-links
                            class="menu-bricks-right"
                            :brow-links="browLinks"
                            :social-bar="socialBar"
                            :weather-page="weatherPage"
                        ></right-brow-links>
                        <saved-equipment class="ml-1" icon-class="text-primary" :rounded="true" />
                    </div>
                </div>
            </div>
        </div>
        <!-- LOGO AND SEARCH -->
        <div>
            <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="logo">
                        <a href="/">
                            <img :src="$_logoUrl" :alt="$_logoAlt" />
                        </a>
                    </div>
                    <div class="d-flex align-items-center justify-content-end flex-grow-1">
                        <div class="w-75">
                            <search-bar css-class="search-bar-header" :input-only="false" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MEGA MENU -->
        <div :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'">
            <div class="container">
                <simple-menu v-if="useSimpleMenus" :main-nav-pages="mainNavPages"></simple-menu>
                <mega-menu
                    v-else
                    class="menu-bricks"
                    :main-nav-pages="mainNavPages"
                    :show-caret="true"
                ></mega-menu>
            </div>
        </div>
    </div>
</template>

<script>
    import LeftBrowLinks from './LeftBrowLinks';
    import MegaMenu from '../../menu/MegaMenu';
    import RightBrowLinks from '../BrowLinks';
    import SavedEquipment from '../../saved-equipment/SavedEquipment';
    import SearchBar from '../../search/SearchBar';
    import SimpleMenu from '../../menu/SimpleMenu';

    import HeaderMixin from '../../mixins/HeaderMixin';
    import ThemeMixin from '../../mixins/ThemeMixin';

    export default {
        name: 'HeaderRounded',
        components: {
            LeftBrowLinks,
            MegaMenu,
            RightBrowLinks,
            SavedEquipment,
            SearchBar,
            SimpleMenu
        },
        mixins: [HeaderMixin, ThemeMixin],
        props: {
            additionalPages: {
                type: Array,
                default: () => []
            },
            browLinks: {
                type: Array,
                default: () => []
            },
            contactSettings: {
                type: Object,
                default: () => ({})
            },
            mainNavPages: {
                type: Array,
                required: true
            },
            socialBar: {
                type: Object,
                default: () => ({})
            },
            useSimpleMenus: {
                type: Boolean,
                required: true
            },
            weatherPage: {
                type: Object,
                default: () => ({})
            }
        }
    };
</script>
