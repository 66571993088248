import Color from 'color';
import { toKebab } from '../../lib/utilities';

const state = {
    name: 'theme-light',
    colors: {
        primary: '#367C2B',
        secondary: '#FFDE00',
        warning: '#ffc107',
        inputBtnFocusColor: 'rgba(253, 218, 1, 0.3)',
        primaryDarken7: '#065900',
        primaryDarken10: '#065900',
        primaryDarken12: '#398c26',
        secondaryDarken7: '#c9a600',
        secondaryDarken10: '#c9a600',
        secondaryDarken12: '#fff31a',
        warningDarken7: '#ffc107',
        warningDarken10: '#ffc107',
        warningDarken12: '#ffda20',
        textPrimaryYiq: '#ffffff',
        textSecondaryYiq: '#212529',
        textWarningYiq: '#212529',
        linkColor: '#367C2B',
        linkHoverColor: '#065900'
    }
};

const getters = {
    themeName: state => {
        return state.name;
    }
};

const mutations = {
    /**
     * Updates the colors that are specified in the object property names.
     */
    applyColors: (state, colors) => {
        Object.keys(colors).forEach(name => {
            state.colors[name] = colors[name];
        });
    },
    setTheme: (state, theme) => {
        state.name = theme;
    }
};

const actions = {
    /**
     * Copies the colors to the state and applies to CSS variables
     */
    applyColors: ({ commit, state }, colors) => {
        if (!colors) return;

        // save colors to state
        commit('applyColors', colors);
        let rootStyle = document.documentElement.style;

        Object.keys(state.colors).forEach(colorName => {
            rootStyle.setProperty(`--${toKebab(colorName)}`, state.colors[colorName]);
        });
    },
    /**
     * Applies theme colors given an object containing only the primary and secondary colors.
     * Only used for testing since our backend Sitefinity code generates many of the color shades too.
     */
    generateColors: ({ dispatch, state }, colors) => {
        let primaryColor = Color(colors.primary || state.colors.primary);
        let secondaryColor = Color(colors.secondary || state.colors.secondary);
        let newColors = {
            primary: primaryColor,
            primaryDarken7: primaryColor.darken(0.2),
            primaryDarken10: primaryColor.darken(0.35),
            primaryDarken12: primaryColor.darken(0.45),
            secondary: secondaryColor,
            secondaryDarken7: secondaryColor.darken(0.2),
            secondaryDarken10: secondaryColor.darken(0.35),
            secondaryDarken12: secondaryColor.darken(0.45),
            textPrimaryYiq: primaryColor.isDark() ? '#ffffff' : '#111111',
            textSecondaryYiq: secondaryColor.isDark() ? '#ffffff' : '#111111'
        };

        dispatch('applyColors', newColors);
    },
    /**
     * Updates theme class name on body tag and mutates the state name property.
     */
    setTheme: ({ commit, state }, theme) => {
        if (!theme) return;

        let body = document.querySelector('body');

        body.classList.remove(state.name);
        body.classList.add(theme);

        commit('setTheme', theme);
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
};
