<script>
    import { mapGetters } from 'vuex';
    import StoreConstants from '../../store/constants';

    export default {
        computed: {
            $_isLightTheme() {
                return this.$_theme === 'theme-light';
            },
            $_isDarkTheme() {
                return this.$_theme === 'theme-dark';
            },
            ...mapGetters({
                $_theme: StoreConstants.THEME.GETTERS.THEME_NAME
            })
        }
    };
</script>
