﻿<template>
    <div class="new-equipment-showroom">
        <div class="new-equipment-showroom-wrapper" :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'">
            <div class="container">
                <div class="sidebar d-none d-md-flex">
                    <div class="brand-logo shortline-bg-color-primary">
                        <router-link to="/">
                            <img v-if="shortline.ShortLineLogo" :src="manufacturerLogo" alt="logo" />
                        </router-link>
                    </div>
                    <div class="category-list" :class="$_isLightTheme ? 'bg-white' : 'bg-dark'">
                        <ul class="categories shortline-color-primary w-100">
                            <li
                                v-for="(type, i) in sortedMachineTypes"
                                :key="i"
                                class="category shortline-hover"
                            >
                                <router-link :to="route(encodeURI(slugify(type.MachineTypeTitle)))">{{
                                    htmlDecode(type.MachineTypeTitle)
                                }}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="showroom">
                    <div class="d-block d-md-none logo-wrapper shortline-bg-color-primary">
                        <router-link to="/" @click.native="hideNav">
                            <img
                                v-if="shortline.ShortLineLogo"
                                :class="$_isLightTheme ? 'bg-white' : 'bg-dark'"
                                :src="manufacturerLogo"
                                alt="logo"
                            />
                        </router-link>
                    </div>
                    <div
                        v-if="$route.params.type && !showNavigation"
                        class="d-block d-md-none text-center w-100"
                        :class="$_isLightTheme ? 'bg-white' : 'bg-dark'"
                    >
                        <h3 v-if="$route.params.type" class="text category-title" @click="showNav">
                            {{ slugToTitle($route.params.type) }}
                            <span class="fas fa-chevron-down"></span>
                        </h3>
                    </div>
                    <div
                        v-if="showNavigation"
                        class="d-block d-md-none text-center w-100 mobile-navigation"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                    >
                        <!-- TODO: why repeat the nav here - can't the one above be used?? -->
                        <ul class="categories">
                            <li
                                v-for="(type, i) in sortedMachineTypes"
                                :key="i"
                                :class="encodeURI(slugify(type.MachineTypeTitle))"
                            >
                                <router-link :to="route(encodeURI(slugify(type.MachineTypeTitle)))">{{
                                    type.MachineTypeTitle
                                }}</router-link>
                            </li>
                            <li
                                class="text"
                                :class="$_isLightTheme ? 'bg-white' : 'bg-dark'"
                                @click="hideNav"
                            >
                                <h4 class="category-title py-0">
                                    <span class="fas fa-chevron-up"></span>
                                </h4>
                            </li>
                        </ul>
                    </div>
                    <router-view
                        v-show="!showNavigation"
                        :types="sortedMachineTypes"
                        :selected-machine-id="selectedMachineId"
                        :machines="filteredSortedMachines"
                        :save="save"
                        :call="call"
                        :email="email"
                    ></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import constants from '../../config/constants';

    import SavedMachineService from '../../../services/SavedMachineService';
    import ShortLineService from '../../../services/ShortLineService';

    import ThemeMixin from '../mixins/ThemeMixin';
    import UtilitiesMixin from '../mixins/UtilitiesMixin';

    // Source: Stack Overflow
    // https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
    function shadeHexColor(color, percent) {
        var f = parseInt(color.slice(1), 16),
            t = percent < 0 ? 0 : 255,
            p = percent < 0 ? percent * -1 : percent,
            R = f >> 16,
            G = (f >> 8) & 0x00ff,
            B = f & 0x0000ff;
        return (
            '#' +
            (
                0x1000000 +
                (Math.round((t - R) * p) + R) * 0x10000 +
                (Math.round((t - G) * p) + G) * 0x100 +
                (Math.round((t - B) * p) + B)
            )
                .toString(16)
                .slice(1)
        );
    }

    function getRelativePath(machine) {
        var currentLoc = window.location.href.split('/');
        var relativePath = '';
        for (let i = 3; i < currentLoc.length; i++) {
            relativePath += `/${currentLoc[i]}`;
        }

        if (!relativePath.includes(encodeURI(machine.MachineTitle.toLowerCase()))) {
            relativePath += '/' + encodeURI(machine.MachineTitle.toLowerCase()) + '/overview';
        }

        return relativePath;
    }

    export default {
        name: 'ShortLineShowroom',
        mixins: [ThemeMixin, UtilitiesMixin],
        props: {
            shortlineId: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                showNavigation: false,
                shortline: {},
                elementConfig: constants.elementConfig
            };
        },
        computed: {
            callNumber() {
                return this.elementConfig.PHONE;
            },
            email() {
                return this.elementConfig.EMAIL;
            },
            sortedMachineTypes() {
                if (!this.shortline.ShortLineMachineTypes) {
                    return [];
                }
                return this.shortline.ShortLineMachineTypes.sort((a, b) =>
                    a.MachineTypeTitle < b.MachineTypeTitle ? -1 : 1
                );
            },
            filteredSortedMachines() {
                if (!this.$route.params.type || !this.shortline.ShortLineMachines) {
                    return [];
                } else {
                    return this.shortline.ShortLineMachines.filter(machine =>
                        this.slugify(machine.MachineTypeNames).includes(this.slugify(this.$route.params.type))
                    ).sort((a, b) => (a.ShortLineMachineTitle < b.ShortLineMachineTitle ? -1 : 1));
                }
            },
            manufacturerLogo() {
                return this.shortline.ShortLineLogo && this.shortline.ShortLineLogo.Url;
            },
            call() {
                let storedLocation = this.$_selectedLocation;
                return 'tel:' + (storedLocation ? storedLocation.Phone : this.callNumber);
            },
            selectedMachineId() {
                if (this.$route.params.model && this.shortline.ShortLineMachines) {
                    var selectedMachine = this.shortline.ShortLineMachines.find(
                        machine => this.slugify(machine.MachineTitle) == this.slugify(this.$route.params.model)
                    );

                    if (selectedMachine) {
                        return selectedMachine.Id;
                    }
                }

                return undefined;
            }
        },
        watch: {
            'shortline.ShortLineColor': function(newValue) {
                if (newValue) {
                    var shortLineStyle = document.getElementById('short-line-style');

                    if (!shortLineStyle) {
                        shortLineStyle = document.createElement('style');
                        shortLineStyle.id = 'short-line-style';
                        document.getElementsByTagName('head')[0].appendChild(shortLineStyle);
                    }

                    var hoverColor = shadeHexColor(newValue.trim(), -0.1);

                    shortLineStyle.innerHTML += `.breadcrumbs a:hover { color: ${newValue.trim()}!important; }`;
                    shortLineStyle.innerHTML += `.shortline-bg-color-primary { background-color: ${newValue.trim()}!important; }`;
                    shortLineStyle.innerHTML += `.shortline-color-primary { color: ${newValue.trim()}!important; }`;
                    shortLineStyle.innerHTML += `.category a:hover, .category a.router-link-active { color: #fff!important; background-color: ${newValue.trim()}!important;}`;
                    shortLineStyle.innerHTML += `.sf-SubmitButton { border-color: ${newValue.trim()}!important; background-color: ${newValue.trim()}!important;  color: #fff!important;}`;
                    shortLineStyle.innerHTML += `.sf-SubmitButton:hover { background-color: ${hoverColor}!important; color: #fff!important; }`;
                    shortLineStyle.innerHTML += `.desktop-nav a.router-link-exact-active.shortline-bg-color-primary:hover { background-color: ${hoverColor}!important; color: #fff!important; }`;
                    shortLineStyle.innerHTML += `a.shortline-bg-color-primary:hover { background-color: ${hoverColor}!important; color: #fff!important; }`;
                }
            },
            $route: function(newValue) {
                if (newValue && newValue.params && newValue.params.type) {
                    this.showNavigation = false;
                }

                this.setMeta();
            }
        },
        created() {
            ShortLineService.getShortLine(this.shortlineId).then(data => {
                this.shortline = data;

                // redirect to 404 page if machine for detail view not found
                if (this.$route.params.model && !this.selectedMachineId) {
                    window.location.replace(constants.errorPage404);
                }
            });

            this.setMeta();
        },
        methods: {
            route(p1, p2) {
                return p2 ? `/${p1}/${p2}` : `/${p1}`;
            },
            slugToTitle(slug) {
                return slug
                    .replace(/-/g, ' ')
                    .replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
            },
            showNav() {
                this.showNavigation = true;
            },
            hideNav() {
                this.showNavigation = false;
            },
            htmlDecode(html) {
                var el = document.createElement('textarea');
                el.innerHTML = html;
                return el.value;
            },
            save(machine) {
                if (SavedMachineService.exists(machine.Id)) {
                    this.$toasted.show(`${machine.Title} has already been saved.`).goAway(1500);
                } else {
                    ShortLineService.getShortlineMachineById(machine.Id).then(shortlineMachine => {
                        machine.Title = shortlineMachine.MachineTitle;
                        machine.Images = shortlineMachine.Images;
                        machine.Specifications = shortlineMachine.Description;
                        machine.FullUrl = getRelativePath(machine);
                        SavedMachineService.saveMachine(machine);
                        this.$toasted.show(`Saved ${machine.Title} to your saved equipment!`).goAway(1500);
                    });
                }
            },
            setMeta() {
                this.setTitle();
                this.setCanonicalUrl(); // defined in mixin
            },
            setTitle() {
                const slug = this.$route.params.model || this.$route.params.type;
                document.title = slug ? `${this.title} ${this.slugToTitle(slug)}` : this.title;
            }
        }
    };
</script>
