﻿<template>
    <div class="px-3 py-2 machine-detail-content">
        <h1 class="text-center">Features</h1>
        <div class="text-left machine-features" v-html="machine.Features"></div>
    </div>
</template>

<script>
    export default {
        name: 'EquipmentDetailFeatures',
        props: {
            machine: {
                type: Object
            }
        },
        watch: {
            machine: function (newValue) {}
        }
    };
</script>
