<script>
    import constants from '../../config/constants';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    import dayjs from 'dayjs';
    import locationService from '../../../services/LocationService.js';
    import { mapGetters } from 'vuex';
    import mapService from '../../../services/GoogleMapService';
    import tz from '../../../services/TimezoneService';

    import AdvancedFormat from 'dayjs/plugin/advancedFormat';
    import LocalizedFormat from 'dayjs/plugin/localizedFormat';
    import StoreConstants from '../../store/constants';

    dayjs.extend(customParseFormat);
    dayjs.extend(AdvancedFormat);
    dayjs.extend(LocalizedFormat);

    export default {
        data() {
            return {
                selectedLocation: null,
                elementConfig: constants.elementConfig
            };
        },
        computed: {
            $_enterCityPlaceholder() {
                return 'ENTER CITY / ZIP';
            },
            /**
             * Used to retrieve the location the user has selected as their own.
             */
            $_selectedLocation() {
                return this.selectedLocation;
            },
            /**
             * Open hours of the user selected store
             */
            $_selectedLocationOpenHours() {
                return this.getOpenHours(this.$_selectedLocation);
            },
            /**
             * Use to determine if the user's selected store is open
             */
            $_isSelectedLocationOpen() {
                return this.checkOpenNow(this.$_selectedLocation);
            },
            ...mapGetters({
                $_selectedLocationId: StoreConstants.LOCATION.GETTERS.LOCATION_ID
            })
        },
        watch: {
            $_selectedLocationId: {
                handler: function(id) {
                    if (!!id) {
                        locationService
                            .getLocationById(id)
                            .then(location => {
                                this.selectedLocation = location;
                            })
                            .catch(reason => {});
                    } else {
                        this.selectedLocation = null;
                    }
                },
                immediate: true
            }
        },
        methods: {
            /**
             * This is a method instead of computed property
             * so can be used to build a locations list within a v-for loop
             */
            emailLink(location) {
                return 'mailto:' + location.Email;
            },
            /**
             * This is a method instead of computed property
             * so can be used to build a locations list within a v-for loop
             */
            telLink(number) {
                if (!number) {
                    return '';
                }
                return 'tel:' + number;
            },
            /**
             * This is a method instead of computed property
             * so can be used to build a locations list within a v-for loop
             */
            directionsLink(location) {
                var address = this.locationFullAddress(location);
                address = address.replace(/\<br\>/g, ' ');
                return `//maps.google.com/maps?q=${address}`;
            },
            currentDay(day) {
                return new Date().getDay() === day ? 'today brand-bg-color-primary-o25' : '';
            },
            showHours(hours) {
                if (hours.status === 'Open') {
                    return `${hours.openTime} — ${hours.closeTime}`;
                }
                return 'Closed';
            },
            getHolidayHours(day, location) {
                if (!Array.isArray(location.Holidays) || location.Holidays.length === 0) {
                    return null;
                }

                let holidayHours = location.Holidays.find(x => x.date === day.format('L'));
                if (holidayHours) {
                    holidayHours.day = day.format('dddd');
                }

                return holidayHours;
            },
            getRegularHours(day, location) {
                let locationHours = location && location.Hours;

                // make sure any existing location hours isn't a string
                if (locationHours && typeof locationHours === 'string') {
                    locationHours = JSON.parse(location.Hours); // NOTE: can be an object or array
                }

                // use default hours if location hours is invalid or not set
                if (!Array.isArray(locationHours) || locationHours.length === 0) {
                    locationHours = locationService.defaultLocation.Hours;
                }

                // return hours for the location on the specified day (Monday, etc.)
                return locationHours.find(x => x.day === day.format('dddd'));
            },
            checkOpenNow(location) {
                if (!location) {
                    return false;
                }

                let now = dayjs();
                let todaysHours = this.getOpenHours(location)[now.day()];

                if (!location.Timezone || location.Timezone < 0) {
                    location.Timezone = 15;
                }

                if (todaysHours && todaysHours.status === 'Open') {
                    let timezone = tz.timezones[location.Timezone];
                    let dummyDate = dayjs(now).format('MM/DD/YY');

                    let openTime = dayjs(dummyDate + ' ' + todaysHours.openTime + ' ' + timezone.gmtAdjustment, {
                        format: 'MM/DD/YY H:mm A Z'
                    });
                    let closeTime = dayjs(
                        dummyDate + ' ' + todaysHours.closeTime + ' ' + timezone.gmtAdjustment,
                        { format: 'MM/DD/YY H:mm A Z' }
                    );

                    openTime = openTime.add(tz.getDSTOffset(timezone), 'hour');
                    closeTime = closeTime.add(tz.getDSTOffset(timezone), 'hour');

                    return now >= openTime && now <= closeTime;
                }

                return false;
            },
            getOpenHours(location) {
                if (!location) {
                    return [];
                }

                var week = [];
                let today = dayjs();
                let startOfWeek = dayjs().subtract(today.day(), 'day');

                for (let index = 0; index < 7; index++) {
                    let dayOfWeek = startOfWeek.clone().add(index, 'day');
                    let todaysHours = this.getHolidayHours(dayOfWeek, location);
                    if (!todaysHours) {
                        todaysHours = this.getRegularHours(dayOfWeek, location);
                    }

                    week.push(todaysHours);
                }

                return week;
            },
            /**
             * Returns the url to the location detail page
             *
             * This takes a arg instad of referencing the location prop so it can be used within loops
             * TODO: this should call the content locator service since the locations page won't always be at this path
             */
            detailsLink(url) {
                return `${this.elementConfig.SITEMAP.LOCATIONS}/${url}`;
            },
            /**
             * This takes a location arg instad of referencing the location prop
             * so it can be used within a v-for loop of locations
             */
            locationFullAddress(location) {
                if (!location || !location.Address.City) {
                    return '';
                }

                let fullAddress = `${location.Address.Street || ''}<br>${location.Address.City}, ${location
                    .Address.StateCode || ''} ${location.Address.Zip || ''}`;

                return fullAddress;
            },
            /**
             * Builds the location name from the Address City field if set.
             * Otherwise, returns the location title.
             */
            locationName(location) {
                if (!location) {
                    return '';
                }

                return location.Address && location.Address.City
                    ? location.Address.City
                    : location.DealerLocationTitle;
            },
            timezoneAbbreviation(timezone) {
                let abbr = timezone && tz.getAbbreviation(tz.timezones[timezone]);
                return abbr ? `(${abbr})` : '';
            },
            /**
             * Sets up a map with a marker for each location
             * @param {HTMLElement} el
             * @param {Object[]} locations
             */
            async initMap(el, locations) {
                await mapService.init();

                if (el && Array.isArray(locations) && locations.length > 0 && locations[0].Address) {
                    const firstLocation = locations[0]; // let the first one be the center point

                    const centerCoords = new google.maps.LatLng(
                        firstLocation.Address.Latitude,
                        firstLocation.Address.Longitude
                    );

                    let locationsMap = mapService.createMap(el, {
                        center: centerCoords,
                        zoom: locations.length > 1 ? 4 : 15,
                        streetViewControl: false
                    });

                    this.loadLocationMarkers(locationsMap, locations);
                }
            },
            /**
             * @param {Object} map
             * @param {Object[]} locations
             */
            loadLocationMarkers(map, locations) {
                if (!map || !Array.isArray(locations)) {
                    return;
                }

                let bounds = new google.maps.LatLngBounds();
                //creating and passing infoWindow so that there is only on infoWindow at a time
                let infoWindow = new google.maps.InfoWindow();

                for (let location of locations) {
                    const lat = parseFloat(location.Address.Latitude);
                    const lng = parseFloat(location.Address.Longitude);
                    if (!lat || !lng || isNaN(lat) || isNaN(lng) || !location.Address) {
                        console.warn(
                            `${location.DealerLocationTitle} does not have a valid latitude or longitude to add marker in the map`
                        );
                        continue;
                    }

                    const marker = mapService.createMarker({
                        position: { lat: lat, lng: lng },
                        map
                    });

                    let loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
                    bounds.extend(loc);

                    mapService.createInfoWindow(
                        map,
                        marker,
                        location,
                        this.locationFullAddress(location),
                        infoWindow
                    );
                }

                map.fitBounds(bounds);

                google.maps.event.addListenerOnce(map, 'bounds_changed', function(event) {
                    if (this.getZoom() > 15) {
                        this.setZoom(15);
                    }
                });
            }
        }
    };
</script>
