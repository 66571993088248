﻿<template>
    <div class="new-equipment-showroom">
        <div
            class="new-equipment-showroom-wrapper"
            :class="[manufacturer, { 'bg-gray-200': $_isLightTheme, 'bg-gray-800': !$_isLightTheme }]"
        >
            <div class="container">
                <div class="sidebar d-none d-md-flex">
                    <div class="brand-logo showroom-bg-color-primary">
                        <router-link to="/">
                            <img :src="manufacturerLogo" alt="logo" />
                        </router-link>
                    </div>
                    <div class="category-list">
                        <ul class="categories showroom-color-primary w-100">
                            <li v-for="(category, i) in categories" :key="i" class="category">
                                <router-link :to="route(category.UrlName)" class="py-2">{{
                                    htmlDecode(category.Name)
                                }}</router-link>
                                <ul class="subcategories showroom-color-primary w-100 bg-gray-100">
                                    <li v-for="(subcategory, i) in category.children" :key="i">
                                        <router-link
                                            :to="route(category.UrlName, subcategory.UrlName)"
                                            class="subcategory"
                                            >{{ htmlDecode(subcategory.Name) }}</router-link
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="showroom">
                    <div class="d-block d-md-none logo-wrapper showroom-bg-color-primary">
                        <router-link to="/" @click.native="displayNav()">
                            <img
                                :class="$_isLightTheme ? 'bg-white' : 'bg-dark'"
                                :src="manufacturerLogo"
                                alt="logo"
                            />
                        </router-link>
                    </div>

                    <div
                        v-if="$route.params.category && !showNavigation"
                        class="d-block d-md-none showroom-bg-color-primary"
                    >
                        <!-- Mobile Breadcrumb -->
                        <div class="d-flex d-row justify-content-between shadow px-3">
                            <div class="py-3">
                                <h3 class="category-title text-left">
                                    {{ slugToTitle($route.params.category) }}
                                    <span v-if="$route.params.subcategory">
                                        > {{ slugToTitle($route.params.subcategory) }}
                                    </span>
                                </h3>
                            </div>
                            <i
                                class="fas fa-chevron-down fa-2x float-right align-self-center"
                                @click="displayNav"
                            ></i>
                        </div>
                        <div class="d-flex d-row justify-content-between shadow p-3">
                            <span @click="$router.go(-1)">
                                <i class="fas fa-chevron-left"></i> Back to
                                <span v-if="$route.params.subcategory">{{
                                    slugToTitle($route.params.category)
                                }}</span>
                                <span v-else>Home</span>
                            </span>
                        </div>
                    </div>

                    <div v-if="showNavigation" class="showroom-bg-color-primary">
                        <div class="d-flex d-row justify-content-between px-4">
                            <div class="pt-3">
                                <h3 class="category-title text-left">
                                    Change
                                    <span v-if="$route.params.category && !$route.params.subcategory">
                                        Industry?
                                    </span>
                                    <span v-else>Category?</span>
                                </h3>
                                <p>
                                    Currently Viewing
                                    <span
                                        v-if="$route.params.category && !$route.params.subcategory"
                                        class="showroom-color-secondary"
                                        >{{ slugToTitle($route.params.category) }}</span
                                    >
                                    <span v-else class="showroom-color-secondary">{{
                                        slugToTitle($route.params.subcategory)
                                    }}</span>
                                </p>
                            </div>
                            <i class="fas fa-times fa-2x align-self-center" @click="displayNav()"></i>
                        </div>

                        <!-- Industry Options -->
                        <div class="nav-wrapper d-flex d-md-none">
                            <template v-if="$route.params.category && !$route.params.subcategory">
                                <router-link
                                    v-for="(category, i) in categories"
                                    :key="i"
                                    :to="category.UrlName"
                                    class="category-link m-2"
                                    @click.native="displaySubnav()"
                                >
                                    <img
                                        v-if="categoryImage(category)"
                                        class="category-image"
                                        :src="categoryImage(category)"
                                        :alt="htmlDecode(category.Name)"
                                    />
                                    <h5 class="my-3">{{ htmlDecode(category.Name) }}</h5>
                                </router-link>
                            </template>

                            <template v-if="$route.params.category && $route.params.subcategory">
                                <router-link
                                    v-for="(subcategory, i) in subcategories[0].children"
                                    :key="i"
                                    :to="route($route.params.category, subcategory.UrlName)"
                                    class="category-link m-2"
                                    @click.native="
                                        displayNav();
                                        displaySubnav();
                                    "
                                >
                                    <img
                                        v-if="categoryImage(subcategory)"
                                        class="category-image"
                                        :src="categoryImage(subcategory)"
                                        :alt="htmlDecode(subcategory.Name)"
                                    />
                                    <h5 class="my-3">{{ htmlDecode(subcategory.Name) }}</h5>
                                </router-link>
                            </template>
                        </div>
                    </div>

                    <!-- Category Options -->
                    <div v-if="showMobileSubcategories">
                        <span class="text-uppercase d-block d-sm-block d-md-none pt-3">
                            Choose a category
                        </span>
                        <div class="nav-wrapper d-flex d-md-none">
                            <router-link
                                v-for="(subcategory, i) in subcategories[0].children"
                                :key="i"
                                :to="route($route.params.category, subcategory.UrlName)"
                                class="category-link m-2"
                                @click.native="displaySubnav()"
                            >
                                <img
                                    v-if="categoryImage(subcategory)"
                                    class="category-image"
                                    :src="categoryImage(subcategory)"
                                    :alt="htmlDecode(subcategory.Name)"
                                />
                                <h5 class="my-3">{{ htmlDecode(subcategory.Name) }}</h5>
                            </router-link>
                        </div>
                    </div>

                    <router-view
                        v-show="!showNavigation && showShowroom"
                        :email="email"
                        :manufacturer="manufacturer"
                    ></router-view>
                </div>

                <transition name="fade">
                    <button
                        v-show="scrolledDown"
                        class="top-button d-md-none btn back-to-top showroom-bg-color-primary"
                        @click="scrollToTop"
                    >
                        <span class="fas fa-arrow-up"></span>
                    </button>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import NewEquipmentService from '../../../services/NewEquipmentService';
    import NewEquipmentMixin from '../mixins/NewEquipmentMixin';

    import ThemeMixin from '../mixins/ThemeMixin';
    import Utilities from '../mixins/UtilitiesMixin';

    let debounce = false;

    export default {
        name: 'NewEquipmentShowroom',
        mixins: [NewEquipmentMixin, ThemeMixin, Utilities],
        props: {
            email: {
                type: String,
                required: true
            },
            level: {
                type: Number,
                required: true
            },
            manufacturer: {
                type: String,
                required: true
            },
            selectedIds: {
                type: Array,
                default: () => []
            },
            selectionMode: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                showNavigation: false,
                showSubnavigation: true,
                showShowroom: true,
                scrolledDown: false,
                categories: []
            };
        },
        computed: {
            manufacturerLogo() {
                return NewEquipmentService.getLogo(this.manufacturer);
            },
            subcategories() {
                return this.categories.filter(x => x.UrlName == this.$route.params.category);
            },
            showMobileSubcategories() {
                return (
                    this.subcategories.length > 0 &&
                    !this.showNavigation &&
                    this.showSubnavigation &&
                    !this.$route.params.model
                );
            }
        },
        watch: {
            $route: function() {
                this.setMeta();
                // push data to sharpspring if the _ss object exists
                window._ss && window._ss.push(['_trackPageView']);
            }
        },
        created() {
            var vm = this;
            var options = { $expand: true, $orderby: 'Name' };

            this.getNewEquipmentBySelection(
                this.selectionMode,
                this.manufacturer,
                this.level,
                this.selectedIds,
                options
            ).then(newEquipmentCategories => {
                this.categories = newEquipmentCategories;
            });

            window.addEventListener(
                'scroll',
                e => {
                    let yPos = window.scrollY;

                    if (!debounce) {
                        window.requestAnimationFrame(function() {
                            if (yPos > 400 && !vm.scrolledDown) {
                                vm.scrolledDown = true;
                            } else if (yPos < 400 && vm.scrolledDown) {
                                vm.scrolledDown = false;
                            }
                            debounce = false;
                        });
                    }

                    debounce = true;
                },
                { passive: true }
            );

            this.setMeta();
        },
        methods: {
            scrollToTop() {
                this.scrolledDown = false;
                $('html,body').animate({ scrollTop: 0 }, 500);
            },
            route(p1, p2) {
                return p2 ? `/${p1}/${p2}` : `/${p1}`;
            },
            slugToTitle(slug) {
                return slug.replace(/-/g, ' ').replace(/\w\S*/g, function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            },
            displayNav() {
                this.showNavigation = this.showNavigation ? false : true;
            },
            displaySubnav() {
                this.showSubnavigation = this.showSubnavigation ? false : true;
            },
            displayShowroom() {
                this.showShowroom = this.showShowroom ? false : true;
            },
            categoryImage(category) {
                return (
                    (category.Image && category.Image.split(',')[0]) ||
                    (category.Images && category.Images.split(',')[0])
                );
            },
            htmlDecode(html) {
                var el = document.createElement('textarea');
                el.innerHTML = html;
                return el.value;
            },
            setMeta() {
                this.setTitle();
                this.setCanonicalUrl(); // defined in mixin
            },
            setTitle() {
                const slug =
                    this.$route.params.model || this.$route.params.subcategory || this.$route.params.category;

                document.title = slug ? `${this.title} ${this.slugToTitle(slug)}` : this.title;
            }
        }
    };
</script>
