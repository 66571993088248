<template>
    <div>
        <card-standard
            action-name="View Details"
            :action-url="url"
            :badges="badges"
            btn-style="btn-primary text-center"
            class="card-promotion h-100"
            :enable-button-link="true"
            :enable-image="true"
            :image="image"
            :subtitle="item.Subtitle"
            :title="title"
            :title-size="4"
            :use-router="useRouter"
            :ribbon-body-text="isFeaturedItem ? 'DEAL OF THE MONTH' : null"
            :route="{
                name: 'details',
                params: {
                    detail: item.UrlName
                }
            }"
            :use-block-btn="true"
        ></card-standard>
    </div>
</template>

<script>
    import constants from '../../config/constants';
    import utilities from '../../lib/utilities';

    import CardStandard from '../cards/CardStandard';

    export default {
        name: 'SearchResultPromotion',
        components: {
            CardStandard
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            badges: {
                default: () => ['Special'],
                type: Array
            },
            baseUrl: {
                type: String,
                default: () => ''
            },
            isFeaturedItem: {
                type: Boolean,
                default: false
            },
            useRouter: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            title() {
                if (this.item.title || this.item.Title) {
                    return this.item.title ? this.item.title : this.item.Title;
                } else {
                    let titleParts = [];
                    this.item.Year && titleParts.push(this.item.Year);
                    this.item.Manufacturer && titleParts.push(this.item.Manufacturer);
                    this.item.Model && titleParts.push(this.item.Model);

                    return titleParts.join(' ');
                }
            },
            image() {
                let images = this.item.ResultImages?.split(',');

                return {
                    alt: this.title,
                    src: images?.length ? images[0] : constants.equipmentPlaceholderImage
                };
            },
            url() {
                return this.baseUrl.endsWith('/')
                    ? `${this.baseUrl}${this.item.UrlName}`
                    : `${this.baseUrl}/${this.item.UrlName}`;
            },
            summary() {
                let summary = utilities.stripHeaderTags(this.item.Summary);
                summary = utilities.stripLinkTags(summary);
                return summary;
            }
        }
    };
</script>
