﻿<template>
    <splide class="pb-0 px-0 px-lg-5" :options="sliderOptions">
        <splide-slide v-for="(slide, i) in slides" :key="i">
            <component
                :is="elementType(slide.ctaText, slide.ctaLink)"
                :href="getLink(slide.ctaText, slide.ctaLink)"
                :target="target(slide.openLinkInNewTab)"
                class="full-width-image d-flex justify-content-center w-100 h-100 rounded"
                :style="getBackground(slide.title, slide.description, slide.image.src, slide.mobileImage.src)"
            >
                <div
                    v-if="slide.title || slide.ctaText"
                    class="full-width-content d-lg-flex flex-column align-items-center justify-content-center text-center p-3 p-md-4 p-lg-5"
                >
                    <h2 v-if="slide.title" class="text-white hero-slider-wide-title">{{ slide.title }}</h2>
                    <p v-if="slide.description" class="text-white my-3 hero-slider-wide-description">
                        {{ slide.description }}
                    </p>
                    <a
                        v-if="enableLink(slide.ctaText, slide.ctaLink)"
                        :href="slide.ctaLink"
                        :target="target(slide.openLinkInNewTab)"
                        class="btn btn-primary"
                        >{{ slide.ctaText }}</a
                    >
                </div>
            </component>
        </splide-slide>
    </splide>
</template>

<script>
    import { optimizeImage } from '../../lib/utilities';

    export default {
        name: 'HeroSliderWide',
        props: {
            slides: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                sliderOptions: {
                    arrows: this.slides.length > 1,
                    autoplay: true,
                    breakpoints: {
                        991: {
                            arrows: false,
                            padding: {
                                left: '3rem',
                                right: '3rem'
                            }
                        },
                        767: {
                            arrows: false,
                            padding: {
                                left: '2.3rem',
                                right: '2.3rem'
                            }
                        }
                    },
                    gap: '1rem',
                    pagination: false,
                    rewind: true,
                    speed: 1000
                },
                windowWidth: document.documentElement.clientWidth
            };
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.setWindowSize);
            });
        },
        methods: {
            getBackground(slideTitle, slideDescription, slideImage, slideMobileImage) {
                return slideTitle || slideDescription
                    ? `background-image: linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url('${
                        this.windowWidth >= 768 ? optimizeImage(slideImage) : optimizeImage(slideMobileImage)
                    }')`
                    : `background-image: url('${this.windowWidth >= 768 ? optimizeImage(slideImage) : optimizeImage(slideMobileImage)}')`;
            },
            getLink(ctaText, ctaLink) {
                return !ctaText && ctaLink ? ctaLink : '#';
            },
            elementType(ctaText, ctaLink) {
                return !ctaText && ctaLink ? 'a' : 'div';
            },
            enableLink(ctaText, ctaLink) {
                return ctaText && ctaLink;
            },
            target(openLinkNewTab) {
                return openLinkNewTab ? '_blank' : '_self';
            },
            setWindowSize() {
                this.windowWidth = document.documentElement.clientWidth;
            }
        }
    };
</script>
