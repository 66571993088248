﻿import dayjs from 'dayjs';

let timezones = [
    {},
    {
        abbr: '',
        gmtAdjustment: '-12:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '-11:00',
        useDaylightTime: 0
    },
    {
        abbr: 'HST',
        gmtAdjustment: '-10:00',
        useDaylightTime: 0
    },
    {
        abbr: 'AKST',
        gmtAdjustment: '-09:00',
        useDaylightTime: 1,
        dstAbbr: 'AKDT'
    },
    {
        abbr: 'PST',
        gmtAdjustment: '-08:00',
        useDaylightTime: 1,
        dstAbbr: 'PDT'
    },
    {
        abbr: 'PST',
        gmtAdjustment: '-08:00',
        useDaylightTime: 1,
        dstAbbr: 'PDT'
    },
    {
        abbr: 'MST',
        gmtAdjustment: '-07:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '-07:00',
        useDaylightTime: 1
    },
    {
        abbr: 'MST',
        gmtAdjustment: '-07:00',
        useDaylightTime: 1,
        dstAbbr: 'MDT'
    },
    {
        abbr: '',
        gmtAdjustment: '-06:00',
        useDaylightTime: 0
    },
    {
        abbr: 'CST',
        gmtAdjustment: '-06:00',
        useDaylightTime: 1,
        dstAbbr: 'CDT'
    },
    {
        abbr: '',
        gmtAdjustment: '-06:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '-06:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '-05:00',
        useDaylightTime: 0
    },
    {
        abbr: 'EST',
        gmtAdjustment: '-05:00',
        useDaylightTime: 1,
        dstAbbr: 'EDT'
    },
    {
        abbr: 'EST',
        gmtAdjustment: '-05:00',
        useDaylightTime: 1,
        dstAbbr: 'EDT'
    },
    {
        abbr: 'AST',
        gmtAdjustment: '-04:00',
        useDaylightTime: 1,
        dstAbbr: 'ADT'
    },
    {
        abbr: '',
        gmtAdjustment: '-04:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '-04:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '-04:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '-03:30',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '-03:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '-03:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '-03:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '-03:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '-02:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '-01:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '-01:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+00:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+00:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+01:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+01:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+01:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+01:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+01:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+02:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+02:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+02:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+02:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+02:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+02:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+02:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+02:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+02:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+03:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+03:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+03:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+03:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+03:30',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+04:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+04:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+04:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+04:30',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+05:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+05:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+05:30',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+05:30',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+05:45',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+06:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+06:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+06:30',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+07:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+07:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+08:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+08:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+08:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+08:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+08:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+09:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+09:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+09:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+09:30',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+09:30',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+10:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+10:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+10:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+10:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+10:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+11:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+12:00',
        useDaylightTime: 1
    },
    {
        abbr: '',
        gmtAdjustment: '+12:00',
        useDaylightTime: 0
    },
    {
        abbr: '',
        gmtAdjustment: '+13:00',
        useDaylightTime: 0
    }
];

const usaDSTDateRanges = [
    {
        start: dayjs('2018-03-25', 'yyyy-MM-dd'),
        end: dayjs('2018-11-04', 'yyyy-MM-dd')
    },
    {
        start: dayjs('2019-03-31', 'yyyy-MM-dd'),
        end: dayjs('2019-10-27', 'yyyy-MM-dd')
    },
    {
        start: dayjs('2020-03-29', 'yyyy-MM-dd'),
        end: dayjs('2020-10-25', 'yyyy-MM-dd')
    },
    {
        start: dayjs('2021-03-28', 'yyyy-MM-dd'),
        end: dayjs('2021-10-31', 'yyyy-MM-dd')
    },
    {
        start: dayjs('2022-03-27', 'yyyy-MM-dd'),
        end: dayjs('2022-10-30', 'yyyy-MM-dd')
    },
    {
        start: dayjs('2023-03-26', 'yyyy-MM-dd'),
        end: dayjs('2023-10-29', 'yyyy-MM-dd')
    }
];

function isDstActive() {
    let today = dayjs();
    return usaDSTDateRanges.some(function(el) {
        return today > el.start && today < el.end;
    });
}

// function browserObservesDST() {
//     var thisYear = new Date().getFullYear();
//     var jan = new Date(thisYear, 0, 1);
//     var jul = new Date(thisYear, 6, 1);
//     var stdOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
//     return new Date().getTimezoneOffset() < stdOffset;
// }

function getDSTOffset(timezone) {
    if (isDstActive()) {
        if (timezone.useDaylightTime) {
            return -1;
        }
    }
    return 0;
}

function getAbbreviation(timezone) {
    if (isDstActive() && timezone.useDaylightTime) {
        return timezone.dstAbbr;
    }
    return timezone.abbr;
}

export default {
    timezones,
    isDstActive,
    getDSTOffset,
    getAbbreviation
};
