﻿import Vue from 'vue';
import Vuex from 'vuex';
import { ElementBlazeModule } from '@teamsi/element-blaze';
import locationModule from './modules/location';
import savedEquipmentModule from './modules/savedEquipment';
import themeModule from './modules/theme';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        search: ElementBlazeModule,
        location: locationModule,
        savedEquipment: savedEquipmentModule,
        theme: themeModule
    }
});

export const searchStore = new Vuex.Store({
    modules: {
        search: ElementBlazeModule,
        location: locationModule,
        savedEquipment: savedEquipmentModule,
        theme: themeModule
    }
});
