const getItem = (key, defaultValue) => {
    if (key && storageAvailable()) {
      const itemValue = localStorage.getItem(key);
      return itemValue ? JSON.parse(itemValue) : defaultValue;
    }

    return defaultValue;
};

const removeItem = key => {
    if (key && storageAvailable()) {
        localStorage.removeItem(key);
    }
}

const setItem = (key, value) => {
    if (value !== null && value !== undefined && storageAvailable()) {
        localStorage.setItem(key, JSON.stringify(value));
    }
};

// Determine whether local storage is available.
// Credit: MDN, https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
const storageAvailable = () => {
    const type = 'localStorage'
    try {
        var storage = window[type],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0
        );
    }
}

export default {
    getItem,
    removeItem,
    setItem
};
