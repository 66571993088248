﻿import Oidc from 'oidc-client';

let manager;

if (!window.location.origin) {
    window.location.origin =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '');
}

const settings = {
    authority: window.location.origin + '/Sitefinity/Authenticate/OpenID',
    client_id: 'sitefinity',
    response_type: 'id_token token',
    scope: 'openid',
    automaticSilentRenew: true
};

function initialize() {
    if (!manager) {
        manager = new Oidc.UserManager(settings);
        Oidc.Log.logger = console;
        Oidc.Log.level = Oidc.Log.ERROR;

        manager.events.addAccessTokenExpiring(function() {
            window.top.user = null;
        });
    }
}

export function getUser() {
    initialize();

    return new Promise(function(resolve, reject) {
        manager.getUser().then(function(user) {
            if (!user || user.expired) {
                manager
                    .signinSilent()
                    .then(function(user) {
                        window.top.user = user;
                        resolve(user);
                    })
                    .catch(function(err) {
                        reject(err);
                    });
            } else {
                window.top.user = user;
                resolve(user);
            }
        });
    });
}

export default {
    getUser
};
