<template>
    <splide class="hero-slider-rounded px-0 px-lg-5" :class="{ 'pb-0': windowWidth >= 992 }" :options="sliderOptions">
        <splide-slide v-for="(slide, i) in slides" :key="i">
            <card-wide
                v-if="windowWidth >= 992"
                :title="slide.title"
                :description="slide.description"
                :image="slide.image && slide.image.src"
                :mobile-image="slide.mobileImage && slide.mobileImage.src"
                :action-name="slide.ctaText"
                :action-url="slide.ctaLink"
                :open-link-in-new-tab="slide.openLinkInNewTab"
            ></card-wide>
            <card-standard
                v-else
                class="h-100"
                :enable-image="true"
                :enable-button-link="true"
                btn-style="d-flex justify-content-center btn-primary"
                :image="slide.image"
                :title="slide.title"
                :action-name="slide.ctaText"
                :action-url="slide.ctaLink"
                :rounded="true"
            >
                {{ slide.description }}
            </card-standard>
        </splide-slide>

        <template #controls>
            <splide-rounded-arrows />
        </template>
    </splide>
</template>

<script>
    import CardStandard from '../cards/CardStandard';
    import CardWide from '../cards/CardWide';
    import SplideRoundedArrows from '../utility/SplideRoundedArrows';

    export default {
        name: 'HeroSliderRounded',
        components: {
            CardStandard,
            CardWide,
            SplideRoundedArrows
        },
        props: {
            slides: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                sliderOptions: {
                    arrows: this.slides.length > 1,
                    autoplay: true,
                    breakpoints: {
                        991: {
                            arrows: false,
                            padding: {
                                left: '3rem',
                                right: '3rem'
                            },
                            pagination: 'slider'
                        },
                        767: {
                            arrows: false,
                            padding: {
                                left: '1.5rem',
                                right: '1.5rem'
                            },
                            pagination: 'slider'
                        }
                    },
                    gap: '3rem',
                    pagination: false,
                    rewind: true,
                    speed: 1000
                },
                windowWidth: document.documentElement.clientWidth
            };
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.setWindowSize);
            });
        },
        methods: {
            setWindowSize() {
                this.windowWidth = document.documentElement.clientWidth;
            }
        }
    };
</script>
