<!--
Description:
Vue wrapper for Bootstrap dropdown that contains buttons
Source:
https://getbootstrap.com/docs/4.3/components/dropdowns/#menu-items
-->
<template>
    <div class="dropdown">
        <button
            :class="['btn btn-primary dropdown-toggle', { 'rounded-0': rounded === false }]"
            type="button"
            data-toggle="dropdown"
        >
            <slot></slot>
        </button>
        <div class="dropdown-menu">
            <button
                v-for="(value, k) in buttons"
                :key="k"
                :class="['dropdown-item', { active: k === selectedValue }]"
                type="button"
                @click="onChange(k)"
            >
                {{ value }}
            </button>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            // should be key/value pairs
            buttons: {
                type: Object,
                required: true
            },
            rounded: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selectedValue: ''
            };
        },
        methods: {
            onChange(key) {
                this.selectedValue = key;
                this.$emit('change', key);
            }
        }
    };
</script>
