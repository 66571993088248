<script>
    // reusable configuration and methods shared between components of the showroom
    import config from '../../config/constants';

    export default {
        data() {
            return {
                filterField: 'ResultHierarchyPath',
                hierarchyField: 'ResultHierarchy',
                manufacturerField: 'Manufacturer',
                itemRouteName: 'showroom-item',
                listRouteName: 'showroom',
                listRouteParams: ['industry', 'category', 'subcategory', 'series', 'seriesCategory'],
                elementConfig: config.elementConfig
            };
        },
        computed: {
            $_currentFilterPath() {
                return this.listRouteParams
                    .map(p => this.$route.params[p])
                    .filter(p => p) // removes nulls
                    .join('|');
            },
            /**
             * Returns an array with two entries:
             * [0] has the hierarchy path node titles, separated by "|".
             * [1] has the path's nodes slugs, also separated by "|".
             */
            $_currentHierarchy() {
                return this.$_hierarchyValues.find(x => x[1] === this.$_currentFilterPath);
            },
            $_hierarchyFacets() {
                let facets = this.$store.state.search.searchResponse['@search.facets'];
                return (facets && facets[this.hierarchyField]) || [];
            },
            $_hierarchyValues() {
                return this.$_hierarchyFacets.map(x => x.value.split('::'));
            },
            $_isItemPage() {
                return this.$route.name === this.itemRouteName;
            },
            $_searchResults() {
                return this.$store.state.search.searchResponse && this.$store.state.search.searchResponse.value;
            }
        }
    };
</script>
