﻿<template>
    <div ref="wrapper" class="blog container my-4">
        <article class="row">
            <div v-if="post" class="blog-featured col-12 d-flex flex-md-row-reverse flex-column">
                <figure class="blog-img-container mb-0 featured-img-box d-flex flex-column bg-white">
                    <element-image
                        img-class="img-fluid featured-border mh-100"
                        container-class="element-image d-flex justify-content-center align-items-center h-md-100"
                        :src="heroImage.src"
                        :alt="heroImage.alt"
                    ></element-image>
                    <figcaption v-if="heroDescription" class="featured-border d-none d-sm-block">
                        {{ heroDescription }}
                    </figcaption>
                </figure>
                <div class="featured-info-box d-flex flex-column justify-content-end mb-2">
                    <div class="d-flex flex-column justify-content-between">
                        <h1 class="blog-title mt-3 mb-3">{{ post.Title }}</h1>
                        <div class="mb-1 justify-content-end mt-3">
                            <i class="far fa-calendar-alt fa-lg mr-2 blog-icon" aria-hidden="true"></i>
                            <small>
                                Published
                                <strong>{{ getDate(post.PublicationDate) }}</strong>
                            </small>
                        </div>
                        <div v-if="tags.length > 0" class="mb-1 justify-content-end mt-3">
                            <i class="fa fa-tag fa-lg fa-rotate-90 mr-2 blog-icon" aria-hidden="true"></i>
                            <span
                                v-for="tag in tags"
                                :key="tag.Title"
                                class="blog-tag border border-primary rounded py-1 px-3 mr-1 mt-1 d-inline-block"
                                >{{ tag.Title }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div ref="blogContent" :class="contentColClass">
                <div v-if="post" class="my-3" v-html="post.Content"></div>
            </div>
            <div v-if="relatedPost.length > 0" class="col-lg-4">
                <section class="my-4">
                    <h3 class="border-left border-content mb-0">Related Articles</h3>
                    <div class="flex-wrap d-flex justify-content-between mt-3">
                        <blog-post-item
                            v-for="post in relatedPost"
                            :key="post.Title"
                            class="related"
                            :post="post"
                        ></blog-post-item>
                    </div>
                </section>
            </div>
        </article>
    </div>
</template>
<script>
    import axios from 'axios';
    import constants from '../../config/constants';
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc'; // load on demand
    import blogPostItem from './BlogPostItem.vue';

    dayjs.extend(utc); // use plugin

    const taxaAPI = '/api/default/flat-taxa';
    const postAPI = '/api/default/blogposts';
    const selectFields =
        'Id, Tags, Category, PublicationDate, DateCreated, Title, UrlName, Summary, Content, ParentId';

    export default {
        name: 'BlogPostDetail',
        components: {
            blogPostItem
        },
        data() {
            return {
                post: {},
                relatedPost: [],
                tags: [],
                topRelatedArticles: 2,
                logoUrl: constants.elementConfig.LOGO_URL
            };
        },
        computed: {
            contentColClass() {
                return this.relatedPost.length > 0 ? 'col-lg-8' : 'col-lg-12';
            },
            heroImage() {
                let heroImage =
                    this.post.HeroImage && this.post.HeroImage.length > 0 && this.post.HeroImage[0].Url;

                return {
                    src: heroImage || this.logoUrl,
                    alt: (heroImage && this.post.HeroImage[0].AlternativeText) || this.post.Title
                };
            },
            heroDescription() {
                return (
                    (this.post &&
                    this.post.HeroImage &&
                    this.post.HeroImage.length > 0 &&
                    this.post.HeroImage[0].Description) ||
                    ''
                );
            }
        },
        watch: {
            $route: {
                deep: true,
                handler: function() {
                    this.getPost();
                    this.getTags();
                    this.getRelatedPost();
                }
            }
        },
        created() {
            this.getPost();
        },
        mounted() {
            this.getTags();
            this.getRelatedPost();
            if (this.post) {
                this.$nextTick(() => {
                    this.updateContentImages();
                });
            }
        },
        methods: {
            getPost() {
                this.post = this.$route.params.post;
                if (!this.post) {
                    var slug = this.$route.params.detail;
                    // TODO: use service here
                    axios
                        .get(
                            `${postAPI}?$expand=HeroImage,Parent&$select=${selectFields}&$filter=UrlName eq '${slug}'`
                        )
                        .then(response => {
                            this.post = response.data.value.shift();
                            this.getTags();
                            this.getRelatedPost();
                            this.$nextTick(() => {
                                this.updateContentImages();
                            });
                        });
                }
            },
            // TODO: Review this code. Seems this could be accomplished via Vue binding instead.
            updateContentImages() {
                jQuery(this.$refs.blogContent)
                    .find('img')
                    .each(function() {
                        const img = jQuery(this);
                        const caption = img.attr('alt')
                            ? `<figcaption class="d-none d-sm-block">${img.attr('alt')}</figcaption>`
                            : '';

                        const template = `<figure class="featured-img-box position-relative p-0"><img src="${img.attr(
                            'src'
                        )}" class="img-fluid" alt="${img.attr('alt') ||
                            'Blog Content Image'}">${caption}</figure>`;

                        jQuery(this).replaceWith(template);
                    });
            },
            getDate(date) {
                return dayjs.utc(date).format('MMM. D, YYYY');
            },
            getTags() {
                // TODO: refactor to use service
                this.tags = [];
                if (this.post && this.post.Tags.length > 0) {
                    axios
                        .get(`${taxaAPI}?$filter=` + this.post.Tags.map(tag => `Id eq ${tag}`).join(' or '))
                        .then(response => (this.tags = response.data.value));
                }
            },
            // TODO: refactor to use service
            getRelatedPost() {
                this.relatedPost = [];
                if (this.post && this.post.Id) {
                    let url = `${postAPI}?$top=${this.topRelatedArticles}&$expand=HeroImage,Parent&$select=${selectFields}&$filter=Id ne ${this.post.Id}`;

                    if (this.post && this.post.Category.length > 0) {
                        let relatedTax = this.post.Category.map(cat => {
                            return {
                                id: cat,
                                type: 'Category'
                            };
                        }).concat(
                            this.post.Tags.map(tag => {
                                return {
                                    id: tag,
                                    type: 'Tags'
                                };
                            })
                        );

                        if (relatedTax.length > 0) {
                            url =
                                `${url} and (` +
                                relatedTax.map(item => `${item.type}/any(x:x eq ${item.id})`).join(' or ') +
                                `) `;
                        }
                    }

                    axios.get(url).then(response => (this.relatedPost = response.data.value));
                }
            }
        }
    };
</script>
