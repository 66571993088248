﻿<template>
    <card-horizontal
        :badges="badges"
        :title="title"
        :url="url"
        :image="item.ResultImages"
        :show-placeholder-image="false"
        :enable-shadow="true"
    >
        <p v-html="item.Summary"></p>
    </card-horizontal>
</template>

<script>
    import SearchResultMixin from '../mixins/SearchResultMixin';
    import ContentLocatorService from '../../../services/ContentLocatorService';

    export default {
        name: 'SitewideSearchResultContent',
        mixins: [SearchResultMixin],
        props: {
            item: {
                type: Object,
                required: true
            },
            badges: {
                default: () => ['Content'],
                type: Array
            }
        },
        computed: {
            title() {
                return this.item.Name || this.item.Title;
            },
            url() {
                return ContentLocatorService.getUrl(this.item);
            }
        }
    };
</script>