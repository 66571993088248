﻿<template>
    <div>
        <div class="share">
            <p class="border-left border-content text-uppercase font-weight-bold mb-3">Contact Us</p>
            <div class="share-buttons list-inline mb-3">
                <a class="list-inline-item px-4 pt-3" :href="`mailto:${elementConfig.EMAIL}`">
                    <i class="fas fa-envelope fa-2x"></i>
                    <p>Email</p>
                </a>

                <a class="list-inline-item px-4 pt-3" :href="`tel:${elementConfig.PHONE}`">
                    <i class="fas fa-phone fa-2x"></i>
                    <p>Call</p>
                </a>
                <a class="list-inline-item pt-3" target="_blank" :href="elementConfig.FACEBOOK">
                    <i class="fab fa-facebook-f fa-2x"></i>
                    <p>Facebook</p>
                </a>
            </div>
        </div>
        <div class="share">
            <p class="border-left border-content text-uppercase font-weight-bold mb-3">Share</p>
            <div class="share-buttons list-inline">
                <a class="list-inline-item px-4 pt-3" :href="facebook">
                    <i class="fas fa-print fa-2x"></i>
                    <p>Print</p>
                </a>
                <a class="list-inline-item px-4 pt-3" :href="facebook">
                    <i class="fas fa-at fa-2x"></i>
                    <p>Email</p>
                </a>
                <a class="list-inline-item px-4 pt-3" :href="facebook">
                    <i class="fas fa-plus fa-2x"></i>
                    <p>Share</p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import constants from '../config/constants';

    export default {
        name: 'SieqxSocialShare',
        data() {
            return {
                elementConfig: constants.elementConfig
            };
        }
    };
</script>