import he from 'he';
import constants from '../config/constants';

export function sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });
}

export function getMonthNumber(monthName) {
    const months = {
        jan: 0,
        feb: 1,
        mar: 2,
        apr: 3,
        may: 4,
        jun: 5,
        jul: 6,
        aug: 7,
        sep: 8,
        oct: 9,
        nov: 10,
        dec: 11
    };
    return months[monthName.toLowerCase()];
}

export function sortWords(array) {
    return array.sort(function(a, b) {
        var x = a.split(' ').join('');
        var y = b.split(' ').join('');
        return x < y ? -1 : x > y ? 1 : 0;
    });
}

export function toKebab(value) {
    return value.replace(/([a-z])([A-Z0-9])/g, '$1-$2').toLowerCase();
}

export function toTitleCase(value) {
    return value
        ? value
              .toLowerCase()
              .split(' ')
              .map(s => s.charAt(0).toUpperCase() + s.slice(1))
        : '';
}

/**
 * Converts a string into a url friendly slug
 * Credit: https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
 */
export function toSlug(value) {
    const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_:;';
    const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz-----';
    const p = new RegExp(a.split('').join('|'), 'g');

    return (
        value
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            //eslint-disable-next-line
            .replace(/[^\w\-]+/g, '') // Remove all non-word characters
            //eslint-disable-next-line
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
    );
}

/**
 * Removes HTML tags and entities.
 * @param {string} html HTML text
 * @return {string} Text without HTML
 */
export function getTextFromHtml(html) {
    var strippedHtml = html.replace(/<[^>]+>/g, '');
    var decodedStripedHtml = he.decode(strippedHtml);

    return decodedStripedHtml;
}

export function copyTextToClipboad(text) {
    var input = document.createElement('textarea');
    document.body.appendChild(input);
    input.value = text;
    input.select();
    document.execCommand('Copy');
    input.remove();
}

/**
 * Use to make the first letter of a string uppercase, but not change the case of any of the other letters.
 */
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

export const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

export function generateRandomNumber(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Builds an ODATA filter expression for querying a set of machines by ID.
 * @param {string[]} ids
 */
export function buildODataIdFilter(ids) {
    return ids && ids.length ? ids.map(i => `Id eq ${i}`).join(' or ') : '';
}

/**
 * Creates a deep copy of an object.
 * @param {object} object Object to clone
 * @return {object} New cloned object
 */
export function deepCopy(object) {
    return JSON.parse(JSON.stringify(object));
}

export function isHtml(htmlString) {
    var doc = new DOMParser().parseFromString(htmlString || '', 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1 && node.nodeName !== "BR");
}
/**
 * Function for user input to be used as a literal string within a regular expression
 * @param {string} espString @param {string} term
 * @return {string}
 */
export function escapeRegExp(espString, term) {
    return espString?.replace(term,"\\$&");
}

/**
 * Function to find and replace specified term with replacement string
 * @param {string} [str=null]
 * @param {string} term
 * @param {string} replacement
 * @return {string}
 */
export function replaceAllText(str, term, replacement) {
    if (str === null || str==='')
    {
        return '';
    }
    return str?.replace(new RegExp(escapeRegExp(str,term), 'g'), replacement);
}
/**
 * Removes the <h#> tags from an HTML string.
 * @param {string} htmlString
 * @return {string}
 */
export function stripHeaderTags(htmlString) {
    return htmlString?.replace(/(<h([^>]+)>)/gi, '<p>').replace(/(<\/h([^>]+)>)/gi, '</p>') ?? '';
}

/**
 * Removes the <a> tags from an HTML string.
 * @param {string} htmlString
 * @return {string}
 */
export function stripLinkTags(htmlString) {
    return htmlString?.replace(/(<a([^>]+)>)/gi, '<span>').replace(/(<\/a([^>]+)>)/gi, '</span>') ?? '';
}

/**
 * Removes tags with the &nbsp; character from an HTML string.
 * @param {string} htmlString
 * @return {string}
 */
export function stripNbspTags(htmlString) {
    return htmlString?.replace(/(<\w+>\s*&nbsp;\s*<\/\w+>)/gi, '') ?? '';
}

// returns the image url/path tunnelled through Element's image proxy
export function optimizeImage(src, width = '') {
    let hasSpacesInFilename = src.indexOf(' ') !== -1 || src.indexOf('%20') !== -1;
    let isPNG = src.indexOf('.png') !== -1;

    // image proxy doesn't like spaces nor PNG images
    if (hasSpacesInFilename || isPNG) {
        return src;
    }

    const isRootRelative = src.indexOf('//') === -1;
    const isLocalhost = window.location.hostname === 'localhost';
    const isLocalhostUrl = src.indexOf('localhost') !== -1;
    const startsWithSlash = src.indexOf('/') === 0;
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    let baseUrl = '';

    // don't use image proxy for localhost images or root-relative images when on localhost
    if (isLocalhostUrl || (isRootRelative && isLocalhost)) {
        return src;
    }

    if (isRootRelative) {
        baseUrl = `${protocol}//${hostname}${startsWithSlash ? '' : '/'}`;
    } else if (src.indexOf('//') === 0) {
        baseUrl = 'https:';
    }

    let imageSrc = encodeURIComponent(`${baseUrl}${src}`);

    return `${constants.cfImageResizingUrl}/width=${width},format=auto,quality=80/${imageSrc}`;
};

export default {
    copyTextToClipboad,
    currencyFormat,
    getTextFromHtml,
    sortByKey,
    sortWords,
    toKebab,
    toTitleCase,
    toSlug,
    generateRandomNumber,
    buildODataIdFilter,
    deepCopy,
    isHtml,
    stripHeaderTags,
    stripLinkTags,
    stripNbspTags,
    getMonthNumber,
    replaceAllText,
    escapeRegExp
};
