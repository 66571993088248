<!--
Full-width button with icons to the left and right
-->
<template>
    <a
        :class="['btn btn-icon-flex', btnStyle]"
        :href="actionUrl"
        :target="target"
        :style="cssVars"
        @click="$emit('click', $event)"
    >
        <i v-if="iconLeftClass" class="mr-2" :class="iconLeftClass"></i>
        <slot></slot>
        <i v-if="iconRightClass" class="d-flex ml-auto" :class="iconRightClass"></i>
    </a>
</template>

<script>
    const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary');

    export default {
        props: {
            actionUrl: {
                type: String,
                default: '#'
            },
            btnStyle: {
                type: String,
                default: 'btn-gray-200'
            },
            iconLeft: {
                type: String,
                default: 'fa-star'
            },
            iconRight: {
                type: String,
                default: 'fa-chevron-right'
            },
            iconColor: {
                type: String,
                default: primaryColor
            },
            iconFocusedColor: {
                type: String,
                default: 'white'
            },
            openLinkInNewTab: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            cssVars() {
                return {
                    '--icon-color': this.iconColor,
                    '--icon-focused-color': this.iconFocusedColor
                };
            },
            iconLeftClass() {
                return this.iconLeft.includes('fab') ? this.iconLeft : `fas ${this.iconLeft}`;
            },
            iconRightClass() {
                return this.iconRight.includes('fab') ? this.iconRight : `fas ${this.iconRight}`;
            },
            target() {
                return this.openLinkInNewTab ? '_blank' : '_self';
            }
        }
    };
</script>

<style lang="scss" scoped>
    .btn {
        .fas,
        .fab,
        &:active .fas,
        &:active .fab {
            color: var(--icon-color) !important;
        }

        &:focus:not(:active):not(:hover) .fas,
        &:focus:not(:active):not(:hover) .fab {
            color: var(--icon-focused-color) !important;
        }
    }
</style>
