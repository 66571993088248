﻿<template>
    <div v-if="displayPosts.length > 0" class="social-stream container d-flex flex-column align-items-center">
        <h2 class="social-stream-heading icon-left-arrow icon-right-arrow mt-2 text-uppercase">
            TRENDING IN {{ trendingIn }}
        </h2>

        <div class="social-stream-selector d-flex mb-3">
            <div
                v-if="showAllSelector"
                class="selector selector-all pl-3 font-weight-bold active"
                data-social="All"
                @click="setAsActive"
            >
                All
            </div>
            <i
                v-if="everyPost.some(post => post.SocialStreamName === 'Facebook')"
                class="selector fab fa-facebook"
                data-social="Facebook"
                @click="setAsActive"
            ></i>
            <i
                v-if="everyPost.some(post => post.SocialStreamName === 'Twitter')"
                class="selector fab fa-twitter"
                data-social="Twitter"
                @click="setAsActive"
            ></i>
            <i
                v-if="everyPost.some(post => post.SocialStreamName === 'Instagram')"
                class="selector fab fa-instagram"
                data-social="Instagram"
                @click="setAsActive"
            ></i>
        </div>

        <div class="masonry">
            <div v-for="post in displayPosts" class="masonry-item">
                <card-facebook
                    v-if="post.SocialStreamName === 'Facebook'"
                    class="masonry-content"
                    :perma-link="post.PermaLink"
                    :profile-picture="post.ProfilePicture"
                    :screen-name="post.ScreenName"
                    :screen-name-link="post.ScreenNameLink"
                    :created-at="post.CreatedAtString"
                    :full-text="post.FullText"
                    :like-count="post.LikeCount"
                    :media="post.Media"
                ></card-facebook>
                <card-twitter
                    v-else-if="post.SocialStreamName === 'Twitter'"
                    class="masonry-content"
                    :post-id="post.PostId"
                    :profile-picture="post.ProfilePicture"
                    :name="post.Name"
                    :screen-name="post.ScreenName"
                    :screen-name-link="post.ScreenNameLink"
                    :created-at="post.CreatedAtString"
                    :full-text="post.FullText"
                    :like-count="post.LikeCount"
                    :media="post.Media"
                ></card-twitter>
                <card-instagram
                    v-else-if="post.SocialStreamName === 'Instagram'"
                    class="masonry-content"
                    :perma-link="post.PermaLink"
                    :profile-picture="post.ProfilePicture"
                    :screen-name="post.ScreenName"
                    :screen-name-link="post.ScreenNameLink"
                    :created-at="post.CreatedAtString"
                    :full-text="post.FullText"
                    :like-count="post.LikeCount"
                    :media="post.Media"
                ></card-instagram>
            </div>
        </div>
    </div>
</template>

<script>
    import CardFacebook from '../cards/CardFacebook.vue';
    import CardTwitter from '../cards/CardTwitter.vue';
    import CardInstagram from '../cards/CardInstagram.vue';

    export default {
        name: 'SocialStream',
        components: {
            CardFacebook,
            CardTwitter,
            CardInstagram
        },
        props: {
            trendingIn: String,
            facebookPosts: Array,
            twitterPosts: Array,
            instagramPosts: Array
        },
        data() {
            return {
                selectedSocial: 'All',
                everyPost: [...this.facebookPosts, ...this.twitterPosts, ...this.instagramPosts].sort(
                    (a, b) => b.CreatedAtUnixEpoch - a.CreatedAtUnixEpoch
                )
            };
        },
        computed: {
            displayPosts() {
                return this.everyPost.filter(
                    post => this.selectedSocial == 'All' || post.SocialStreamName == this.selectedSocial
                );
            },
            showAllSelector() {
                return [...new Set(this.everyPost.map(post => post.SocialStreamName))].length > 1;
            }
        },
        watch: {
            displayPosts() {
                this.runMansoryResize();
            }
        },
        mounted() {
            Masonry.waitForImages();
            window.addEventListener('resize', this.runMansoryResize);
        },
        methods: {
            setAsActive(event) {
                let selectorCollection = document.querySelectorAll('.social-stream-selector .selector');
                selectorCollection.forEach(e => e.classList.remove('active'));
                event.target.classList.add('active');

                this.selectedSocial = event.target.dataset.social;
            },
            runMansoryResize() {
                this.$nextTick(() => {
                    Masonry.waitForImages();
                });
            }
        }
    };
</script>
