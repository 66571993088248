<template>
    <div id="elements-list" class="container pt-5">
        <h1 class="display-2">Element UI Style Guide</h1>

        <blockquote class="blockquote">
            <p class="mb-0">
                <code>blockquote</code> element here. This page is a work in progress. Eventually it will
                demonstrate all the reusable elements that the Cards Theme provides, and also illustrate
                dynamically swapping out card themes and brand colors.
            </p>
        </blockquote>

        <h2 class="display-4 mt-5">Form Elements</h2>
        <hr />
        <p class="small">Bootstrap components added for testing theme customizations.</p>

        <div class="row">
            <div class="col">
                <h3>Pagination</h3>
                <nav>
                    <ul class="pagination">
                        <li class="page-item disabled">
                            <a class="page-link" href="#" tabindex="-1">Previous</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">1</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item active">
                            <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">4</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">5</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>

                <hr class="mt-4" />
                <h3>Input fields</h3>

                <form>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Label goes here</label>
                        <input
                            id="exampleInputEmail1"
                            type="text"
                            class="form-control"
                            placeholder="Keywords..."
                        />
                        <small
                            id="emailHelp"
                            class="form-text text-muted"
                        >Press enter to execute your query.</small>
                    </div>
                </form>
            </div>
            <div class="col">
                <h3>Button Group</h3>
                <div class="btn-group-toggle">
                    <label class="btn btn-primary rounded-0 mb-1">
                        <input type="checkbox" autocomplete="off" value="New" />New
                    </label>
                    <label class="btn btn-primary rounded-0 mb-1">
                        <input type="checkbox" autocomplete="off" value="Used" />Used
                    </label>
                    <label class="btn btn-primary rounded-0 mb-1">
                        <input type="checkbox" autocomplete="off" value="Offers &amp; Discounts" />
                        Offers &amp; Discounts
                    </label>
                    <label class="btn btn-primary rounded-0 mb-1">
                        <input type="checkbox" autocomplete="off" value="News" />News
                    </label>
                    <label class="btn btn-primary rounded-0 mb-1">
                        <input type="checkbox" autocomplete="off" value="Blogs" />Blogs
                    </label>
                    <label class="btn btn-primary rounded-0 mb-1">
                        <input type="checkbox" autocomplete="off" value="Pages" />Pages
                    </label>
                    <label class="btn btn-primary rounded-0 mb-1">
                        <input type="checkbox" autocomplete="off" value="Events" />
                        Events
                    </label>
                </div>
            </div>
        </div>

        <h2 class="display-4 mt-5">Typography</h2>
        <hr class="pb-4" />

        <div class="row">
            <div class="col-md-6">
                <div class="mt-4" v-html="borderedHeading"></div>

                <figure class="highlight">
                    <code class="language-html" data-lang="html">{{ borderedHeading }}</code>
                </figure>

                <hr />

                <h1>First Heading</h1>
                <h2>Second Heading</h2>
                <h3>Third Heading</h3>
                <h4>Fourth Heading</h4>
                <h5>Fifth Heading</h5>
                <h6>Sixth Heading</h6>
            </div>
            <!-- end left col -->
            <div class="col-md-6">
                <h2 class="mt-4">H2 heading with a paragraph below it.</h2>
                <p>
                    In pitch fork sunflower cowpies mice. Haybine carrots soybeans, owls duck raising or,
                    cheep in plows. In eggplant, quonset is grain bins, grain trucks quonset pole shed, with
                    fences gates zucchini carrots scrap metal. Combine Harvester swather, baler as haybine
                    parsley, melon in hay rake. Gate wind, moonshine horse.
                </p>
                <h2>Paragraph with left border</h2>
                <p class="border-left border-content">
                    In pitch fork sunflower cowpies mice. Haybine carrots soybeans, owls duck raising or,
                    cheep in plows. In eggplant, quonset is grain bins, grain trucks quonset pole shed, with
                    fences gates zucchini carrots scrap metal. Combine Harvester swather, baler as haybine
                    parsley, melon in hay rake. Gate wind, moonshine horse.
                </p>
                <h2>Paragraph with right border</h2>
                <p class="border-right border-content">
                    In pitch fork sunflower cowpies mice. Haybine carrots soybeans, owls duck raising or,
                    cheep in plows. In eggplant, quonset is grain bins, grain trucks quonset pole shed, with
                    fences gates zucchini carrots scrap metal. Combine Harvester swather, baler as haybine
                    parsley, melon in hay rake. Gate wind, moonshine horse.
                </p>
                <p>
                    Here is a
                    <a href="#">link</a>.
                </p>
                <h2>
                    Heading with a primary badge
                    <span class="badge badge-primary">Tractors</span>
                </h2>
                <h2>
                    Heading with a secondary badge
                    <span class="badge badge-secondary">Combines</span>
                </h2>

                <h2>Inline badges</h2>

                <p>
                    <a href="#" class="badge badge-primary">Primary</a>
                    <a href="#" class="badge badge-secondary">Secondary</a>
                    <a href="#" class="badge badge-success">Success</a>
                    <a href="#" class="badge badge-danger">Danger</a>
                    <a href="#" class="badge badge-warning">Warning</a>
                    <a href="#" class="badge badge-info">Info</a>
                    <a href="#" class="badge badge-light">Light</a>
                    <a href="#" class="badge badge-dark">Dark</a>
                </p>
            </div>
            <!-- end right col -->
            <div class="col-md-12">
                <h2>Custom theme color for links</h2>
                <hr />

                <element-dropdown-buttons
                    class="ml-auto"
                    :buttons="ThemeCustomizer.primaryColors"
                    :rounded="false"
                    @change="ThemeCustomizer.changeLinkColor($event, $store)"
                >{{ThemeCustomizer.selectedLinkColor}}</element-dropdown-buttons>
                <p>
                    By default links will take the primary color if a custom is not specified.
                    Hover color will be based on Primary
                    <code
                        class="language-css"
                        data-lang="css"
                    >--primary-darken-12</code>
                </p>
                <a href="#">I am a link</a>
            </div>
        </div>

        <h2 class="display-4 mt-5">Color Utilities</h2>
        <hr class="pb-3" />

        <div class="row">
            <div class="col">
                <h2>Grays</h2>
                <div v-for="i in 9" :key="i" :class="['p-3', 'bg-gray-' + i * 100]">
                    {{ i * 100 }}
                    <br />
                    <small>$gray-{{ i * 100 }}, var(--gray-{{ i * 100 }}), or .bg-gray-{{ i * 100 }}</small>
                </div>
            </div>
            <div class="col">
                <h2>Brand Color Backgrounds</h2>
                <div class="px-5 py-5 bg-primary text-center text-primary-yiq">.bg-primary</div>
                <div class="px-5 py-5 bg-secondary text-center text-secondary-yiq">.bg-secondary</div>

                <h2 class="pt-4">Text Contrast</h2>
                <p>
                    Use these utility classes when you need your text to either be light or dark to stand out
                    when placed over the primary or secondary brand color.
                </p>
                <div class="p-5 bg-primary text-center text-primary-yiq">.text-primary-yiq</div>
                <div class="p-5 bg-secondary text-center text-secondary-yiq">.text-secondary-yiq</div>
            </div>
            <div class="col">
                <h2>Brand Color Text</h2>
                <p class="text-primary">
                    Primary color text using
                    <code>.text-primary</code> class.
                </p>
                <p class="text-secondary">
                    Secondary color text using
                    <code>.text-secondary</code> class.
                </p>

                <h2>Brand Color on Button and Link Backgrounds</h2>
                <p>
                    Bootstrap adds contextual (hover/focus) colors to buttons and links that have
                    <code>.bg-[theme-color]</code> classes.
                </p>
                <p>
                    <a href="#" class="bg-primary text-primary-yiq p-2">.bg-primary</a>
                    <a href="#" class="bg-secondary text-secondary-yiq p-2">.bg-secondary</a>
                </p>
                <p>
                    To disable contextual colors, use the Element
                    <code>.bg-fixed</code> utility.
                </p>
                <p>
                    <a
                        href="#"
                        class="bg-primary text-primary-yiq bg-fixed p-2"
                    >.bg-primary.bg-fixed</a>
                    <a
                        href="#"
                        class="bg-secondary text-secondary-yiq bg-fixed p-2"
                    >.bg-secondary.bg-fixed</a>
                </p>
                <h2 class="pt-3">Theme colors</h2>
                <div class="mt-1 p-3 bg-accent-1 border">
                    <code>.bg-accent-1</code>
                </div>
                <div class="mt-1 p-3 bg-accent-2 border">
                    <code>.bg-accent-2</code>
                </div>
                <div class="mt-1 p-3 bg-accent-3 border">
                    <code>.bg-accent-3</code>
                </div>
                <div class="mt-1 p-3 bg-accent-4 border">
                    <code>.bg-accent-4</code>
                </div>
                <div class="mt-1 p-3 bg-accent-5 border">
                    <code>.bg-accent-5</code>
                </div>
            </div>
        </div>

        <h2 class="display-4 mt-5">Other Utilities</h2>
        <hr class="pb-3" />

        <div class="row">
            <div class="col">
                <h3>Border Size</h3>
                <p class="small">Custom utility class provided by Element.</p>
                <div v-for="i in 5" :key="i" :class="['border-bottom mb-2', 'border-' + i]">
                    <code>.border-bottom.border-{{ i }}</code>
                </div>
            </div>
            <div class="col"></div>
        </div>

        <h2 class="display-4 mt-5">Buttons</h2>
        <hr class="pb-3" />

        <div class="row">
            <div class="col">
                <h4>Hollow Button</h4>
                <p v-html="hollowBtn"></p>

                <figure class="highlight">
                    <code class="language-html" data-lang="html">{{ hollowBtn }}</code>
                </figure>

                <p>
                    <strong>
                        Or, using the Element
                        <code>.btn-hollow-primary</code> utility class:
                    </strong>
                </p>

                <p>
                    <span v-html="btnPrimaryHollow"></span>
                    <span v-html="btnSecondaryHollow"></span>
                </p>

                <figure class="highlight">
                    <code class="language-html" data-lang="html">
                        {{ btnPrimaryHollow }}
                        <br />
                        {{ btnSecondaryHollow }}
                    </code>
                </figure>
            </div>

            <div class="col">
                <h4>Basic Buttons</h4>
                <p>
                    <a href="#" class="btn btn-outline-primary mt-2">Primary outline button</a>
                    <a href="#" class="btn btn-outline-secondary mt-2">Secondary outline button</a>
                    <a href="#" class="btn btn-primary mt-2">Primary brand color button</a>
                    <a href="#" class="btn btn-secondary mt-2">Secondary brand color button</a>
                </p>
            </div>
        </div>

        <h2 class="display-4 mt-5">Navigation Tree</h2>
        <hr class="pb-3" />

        <p class="small">
            Uses Bootstrap
            <code>.list-group</code> component. The
            <code>FacetsTree</code> Vue component for
            building these is in Element Blaze. HTML representation used here for theming purposes.
        </p>

        <div class="row">
            <div class="col">
                <div class="list-group list-group-flush" style="clear: both;">
                    <div>
                        <a
                            href="#"
                            class="list-group-item list-group-item-action d-flex align-items-center"
                            style="padding-left: 10px;"
                        >
                            <span class="text-secondary">+&nbsp;</span>
                            Agriculture
                            <span
                                class="badge badge-secondary badge-pill ml-auto"
                            >433</span>
                        </a>
                    </div>
                    <div>
                        <a
                            href="#"
                            class="list-group-item list-group-item-action d-flex align-items-center active"
                            style="padding-left: 10px;"
                        >
                            <span class="text-light">−&nbsp;</span>
                            Landscaping and Grounds Care
                            <span
                                class="badge badge-secondary badge-pill ml-auto"
                            >163</span>
                        </a>
                        <span>
                            <div>
                                <a
                                    href="#"
                                    class="list-group-item list-group-item-action d-flex align-items-center"
                                    style="padding-left: 20px;"
                                >
                                    <span class="text-secondary">+&nbsp;</span>
                                    Cutters and Shredders
                                    <span
                                        class="badge badge-secondary badge-pill ml-auto"
                                    >22</span>
                                </a>
                            </div>
                            <div>
                                <a
                                    href="#"
                                    class="list-group-item list-group-item-action d-flex align-items-center"
                                    style="padding-left: 20px;"
                                >
                                    <span class="text-secondary">+&nbsp;</span>
                                    Gator Utility Vehicles
                                    <span
                                        class="badge badge-secondary badge-pill ml-auto"
                                    >36</span>
                                </a>
                            </div>
                            <div>
                                <a
                                    href="#"
                                    class="list-group-item list-group-item-action d-flex align-items-center"
                                    style="padding-left: 20px;"
                                >
                                    <span class="text-secondary">+&nbsp;</span>
                                    Mowers and Tractors
                                    <span
                                        class="badge badge-secondary badge-pill ml-auto"
                                    >105</span>
                                </a>
                            </div>
                        </span>
                    </div>
                    <div>
                        <a
                            href="#"
                            class="list-group-item list-group-item-action d-flex align-items-center"
                            style="padding-left: 10px;"
                        >
                            <span class="text-secondary">+&nbsp;</span>
                            Lawn and Garden
                            <span
                                class="badge badge-secondary badge-pill ml-auto"
                            >101</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <h2 class="display-4 mt-5">JS Components</h2>
        <hr class="pb-3" />

        <div class="row">
            <div class="col">
                <h4>NoUISlider</h4>
                <p
                    class="small"
                >Brought in by the Element Blaze library. Shown here for theming purposes.</p>

                <h6>Enabled</h6>
                <div id="noui-slider-enabled"></div>
                <h6 class="mt-5">Disabled</h6>
                <div id="noui-slider-disabled"></div>
            </div>
        </div>

        <h2 class="display-4 mt-5">Vue Components</h2>
        <hr class="pb-3" />

        <div class="row">
            <div class="col">
                <h3>Dropdown Buttons</h3>
                <element-dropdown-buttons
                    :buttons="ThemeCustomizer.primaryColors"
                    @change="ThemeCustomizer.changePrimaryColor($event, $store)"
                >Colors</element-dropdown-buttons>

                <hr class="mt-4" />

                <h3>Google Charts</h3>
                <column-chart
                    :data="chartData"
                    legend="top"
                    label="Inventory by year"
                    :colors="chartColumnColors"
                    height="250px"
                    :library="chartConfig"
                ></column-chart>
            </div>
            <div class="col">
                <h3>Multiselect</h3>
                <multiselect
                    id="test"
                    v-model="multiselectModel"
                    :close-on-select="true"
                    :multiple="true"
                    :options="multiselectOptions"
                    :searchable="true"
                    :show-labels="false"
                    class="multi-select-dropdown"
                    label="text"
                    placeholder="Select one"
                    track-by="value"
                ></multiselect>

                <h3 class="mt-3">Element Image</h3>
                <element-image
                    src="https://assets.cnhindustrial.com/caseih/NAFTA/NAFTAASSETS/owners/products/product-images/30-Series-Combine-Phoenix-0611-2759.jpg"
                    width="500"
                    q="80"
                    alt="Hello"
                ></element-image>

                <p>
                    <small>Image With Spaces In Path</small>
                    <br />
                    <element-image
                        src="https://assets.cnhindustrial.com/caseih/NAFTA/NAFTAASSETS/Products/Application-Equipment/Trident%20Combination%20Applicator/Images/Trident%205550%20Dry_1128_06-17.jpg?width=620&height=310"
                        width="500"
                        q="80"
                        alt="Hello"
                    ></element-image>
                </p>

                <p>
                    <small>Broken Image</small>
                    <br />
                    <element-image
                        src="https://assets.cnhindustrial.com/broken.jpg"
                        width="500"
                        q="80"
                        alt="Hello"
                    ></element-image>
                </p>
            </div>
        </div>

        <hr class="pb-4" />
    </div>
</template>

<script>
    import ElementDropdownButtons from '../components/DropdownButtons';
    import ThemeCustomizer from './lib/theme-customizer.js';
    import noUiSlider from 'nouislider';
    export default {
        components: {
            ElementDropdownButtons
        },
        data() {
            return {
                ThemeCustomizer,
                borderedHeading: `<h1 class="border-left border-content">Bordered Heading</h1>`,
                hollowBtn: `<a href="#" class="btn btn-outline-primary rounded-pill btn-sm px-4 py-2">See All Stores</a>`,
                btnPrimaryHollow: `<a href="#" class="btn btn-hollow-primary">Using a utility class</a>`,
                btnSecondaryHollow: `<a href="#" class="btn btn-hollow-secondary">Using a utility class</a>`,
                multiselectModel: [],
                multiselectOptions: [
                    {
                        text: 'One',
                        value: 'one'
                    },
                    {
                        text: 'Two',
                        value: 'two'
                    },
                    {
                        text: 'Three',
                        value: 'three'
                    },
                    {
                        text: 'Four',
                        value: 'four'
                    }
                ],
                selectedPrimaryColor: '',
                selectedSecondaryColor: '',
                chartConfig: {
                    chartArea: { width: '84%' },
                    fontName: 'Roboto, sans-serif'
                },
                chartData: [
                    [2009, 8],
                    [2010, 7],
                    [2011, 8],
                    [2012, 10],
                    [2013, 32],
                    [2014, 82],
                    [2015, 85],
                    [2016, 49],
                    [2017, 59],
                    [2018, 27]
                ]
            };
        },
        computed: {
            chartColumnColors() {
                const primaryColor =
                    window.getComputedStyle(document.documentElement).getPropertyValue('--primary') || 'blue';

                return [primaryColor.trim()];
            }
        },
        mounted() {
            var sliderEnabled = document.getElementById('noui-slider-enabled');
            var sliderDisabled = document.getElementById('noui-slider-disabled');

            noUiSlider.create(sliderEnabled, {
                start: [20, 80],
                connect: true,
                range: {
                    min: 0,
                    max: 100
                }
            });

            noUiSlider.create(sliderDisabled, {
                start: [20, 80],
                connect: true,
                range: {
                    min: 0,
                    max: 100
                }
            });

            sliderDisabled.setAttribute('disabled', true);
        }
    };
</script>
