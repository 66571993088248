// @ts-check
import '../frontend/config/equipment-types';
import { buildODataIdFilter } from '../frontend/lib/utilities';
import { getDataPage } from './lib/ODataService';

const usedEquipmentUrl = '/api/default/usedequipments?';
const usedEquipmentCustomUrl = '/api/usedequipment/';

export function GetMachineCountByLocation(locationId) {
    return new Promise((resolve, reject) => {
        fetch(`${usedEquipmentCustomUrl}GetMachineCountByLocation?Id=${locationId}`)
            .then(response => {
                resolve(response.json());
            })
            .catch(reason => reject(reason));
    });
}

/**
 * Retrieves used equipments by Id.
 * @param {string[]} ids - array of selected item's ids to retrieve.
 * @param {string[]} fieldsToSelect - array of fields to select
 * @param {boolean} expandLocation - whether or not to expand the location related data
 */
export function GetMachinesByIds(ids, fieldsToSelect, expandLocation) {
    let apiUrl = `${usedEquipmentUrl}$filter=${buildODataIdFilter(ids)}`;

    if (fieldsToSelect?.length) {
        apiUrl += `&$select=${fieldsToSelect.join(',')}`;
    }

    if (expandLocation) {
        apiUrl += '&$expand=Location';
    }

    return new Promise((resolve, reject) => {
        getDataPage(apiUrl, 0, false)
            .then(response => {
                let results = (response.data && response.data.value) || [];
                resolve(results);
            })
            .catch(reason => reject(reason));
    });
}

/**
 * Retrieves a used machine from the REST api when the slug is specified.
 * @param {string} slug
 * @param {Options} [options] Optional additional settings for the request.
 * @returns {Promise<Object>}
 */
export function GetMachineBySlug(slug, options) {
    options = options ? options : {};
    options.$filter = `UrlName eq '${slug}'`;

    return new Promise((resolve, reject) => {
        getMachines(options)
            .then(response => {
                if (response && response.length > 0) {
                    resolve(response[0]);
                } else {
                    reject(`No machine found for slug ${slug}`);
                }
            })
            .catch(reason => reject(reason));
    });
}

/**
 * Retrieves a list of used machines in the same category as the provided machine.
 * Prioritizes machines with images.
 * @param {Object} machine
 * @returns {Promise<Object[]>}
 */
export function GetSimilarMachines(machine) {
    let options = {
        // exclude current machine, filter to machines in same category
        $filter: `UrlName ne '${machine.UrlName}' and Category eq '${encodeURIComponent(machine.Category)}'`,
        // prioritize machines with images
        $orderby: 'Images desc',
        // don't need a large list of related machines
        recursive: false
    };

    return new Promise((resolve, reject) => {
        getMachines(options)
            .then(response => {
                if (response && response.length > 0) {
                    resolve(response);
                } else {
                    reject(`No machines found for category ${machine.Category}`);
                }
            })
            .catch(reason => reject(reason));
    });
}

/**
 * Builds used equipment request to retrieve results from the Sitefinity REST API.
 * @param {Options} options
 * @returns {Promise<Object[]>}
 */
function getMachines(options) {
    let apiUrl = usedEquipmentUrl;
    let orderBy = options && options.$orderby ? options.$orderby : 'Id';
    let queryParts = [`$orderby=${orderBy}`];

    // recursion is the default so should only be false if options.recursive is defined and equates to false
    let recurse = !options || !options.hasOwnProperty('recursive') || options.recursive === true;

    if (options && options.$expand) {
        queryParts.push(`$expand=Location`); // location is only related data used eq has aside from images
    }

    if (options && options.$filter) {
        queryParts.push(`$filter=${options.$filter}`);
    }

    apiUrl += queryParts.join('&');

    return new Promise((resolve, reject) => {
        getDataPage(apiUrl, 0, recurse)
            .then(response => {
                let results = (response.data && response.data.value) || [];
                resolve(results);
            })
            .catch(reason => reject(reason));
    });
}

export default {
    GetMachineCountByLocation,
    GetMachinesByIds,
    GetMachineBySlug,
    GetSimilarMachines
};
