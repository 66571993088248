﻿import LocalStorageService from '../../../services/LocalStorageService';
import StoreConstants from '../constants';

const state = {
    savedEquipment: []
};

const getters = {
    machines: state => {
        return state.savedEquipment;
    },
    machinesCounter: state => {
        return state.savedEquipment.length;
    },
    compareMachinesCounter: state => {
        return state.savedEquipment.filter(x => x.Compare).length;
    }
};

const mutations = {
    addSavedEquipment(state, machine) {
        state.savedEquipment.push(machine);
    },
    deleteSavedEquipment(state, machine) {
        const index = state.savedEquipment.findIndex(x => x.OriginalItemId === machine.OriginalItemId);
        state.savedEquipment.splice(index, 1);
    },
    updateSavedEquipment(state, machine) {
        const index = state.savedEquipment.findIndex(x => x.OriginalItemId === machine.OriginalItemId);
        state.savedEquipment.splice(index, 1, machine);
    },
    replaceSavedEquipment(state, machines) {
        state.savedEquipment = machines;
    }
};

const actions = {
    load(context) {
        const machines = LocalStorageService.getItem(StoreConstants.EQUIPMENT.LOCAL_STORAGE_KEY, []);
        context.commit('replaceSavedEquipment', machines);
    },
    add(context, machine) {
        if (!state.savedEquipment.some(x => x.OriginalItemId === machine.OriginalItemId)) {
            context.commit('addSavedEquipment', machine);
            LocalStorageService.setItem(StoreConstants.EQUIPMENT.LOCAL_STORAGE_KEY, state.savedEquipment);
        }
    },
    delete(context, machine) {
        context.commit('deleteSavedEquipment', machine);
        LocalStorageService.setItem(StoreConstants.EQUIPMENT.LOCAL_STORAGE_KEY, state.savedEquipment);
    },
    update(context, machine) {
        context.commit('updateSavedEquipment', machine);
        LocalStorageService.setItem(StoreConstants.EQUIPMENT.LOCAL_STORAGE_KEY, state.savedEquipment);
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
};
