﻿<template>
    <div class="related-offers">
        <!-- SKELETON -->
        <related-special-offers-skeleton v-if="loading"></related-special-offers-skeleton>

        <!-- MESSAGE -->
        <div v-else-if="warningMessage" class="my-3">
            <div class="alert alert-primary">{{ warningMessage }}</div>
        </div>

        <!-- DROPDOWNS -->
        <div v-if="hasCategories && !selectedPromotionsIds" class="row text-center mx-0">
            <div class="col-md-6 col-sm-12 mx-auto mb-3">
                <label>Category:</label>
                <div class="dropdown my-2">
                    <select v-model="selectedIndustry" class="btn filter-list dropdown-toggle">
                        <option value>Select All</option>
                        <option v-for="(industry, i) in industries" :key="i" :value="industry">
                            {{ industry }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 mx-auto mb-3">
                <label>Subcategory:</label>
                <div class="dropdown my-2">
                    <select
                        v-model="selectedCategory"
                        :disabled="!selectedIndustry"
                        class="btn filter-list dropdown-toggle"
                    >
                        <option v-if="categories.length == 0" value>Select a Category</option>
                        <option v-else value>Select All</option>
                        <option
                            v-for="(category, i) in categories"
                            :key="i"
                            :value="category"
                            v-html="category"
                        ></option>
                    </select>
                </div>
            </div>
        </div>

        <!-- PROMOTIONS -->
        <div v-if="displayedPosts.length > 0" class="row mx-0">
            <div
                v-for="offer in displayedPosts"
                :key="offer.Id"
                class="offer-card-container col-md-4 col-sm-12 mb-5"
            >
                <card-standard
                    v-if="dealerPromotion"
                    class="h-100"
                    :enable-image="!!offer.Image && offer.Image.length > 0"
                    :image="{
                        src:
                            !!offer.Image && offer.Image.length > 0
                                ? offer.Image[offer.Image.length - 1].Url
                                : null
                    }"
                    :title="offer.Title"
                    :title-size="5"
                    :subtitle="offer.Subtitle"
                    action-name="Details"
                    :action-url="`${baseUrl}/${offer.UrlName}`"
                    :enable-button-link="true"
                    btn-style="bg-primary text-white text-center"
                    :use-block-btn="true"
                    :use-router="useRouter"
                    :route="{ name: 'details', params: { detail: offer.UrlName, offer: offer } }"
                ></card-standard>

                <card-standard
                    v-else
                    class="h-100"
                    :enable-image="!!offer.Image"
                    :image="{ src: offer.Image }"
                    :title="offer.Title"
                    :title-size="5"
                    :subtitle="offer.Subtitle"
                    action-name="Details"
                    :action-url="`${baseUrl}/${offer.UrlName}`"
                    :enable-button-link="true"
                    btn-style="bg-primary text-white text-center"
                    :use-block-btn="true"
                    :use-router="useRouter"
                    :route="{ name: 'details', params: { detail: offer.UrlName, offer: offer } }"
                ></card-standard>
            </div>
        </div>

        <!-- PAGINATION -->
        <div v-if="showPagination && numberOfPages > 1" class="col-12">
            <paginate
                v-model="page"
                :click-handler="onPaginationChange"
                :container-class="'pagination justify-content-center'"
                :margin-pages="paginationMarginPages"
                :next-link-class="'page-link'"
                :next-text="'>'"
                :page-class="'page-item'"
                :page-count="numberOfPages"
                :page-link-class="'page-link'"
                :page-range="paginationPageRange"
                :prev-link-class="'page-link'"
                :prev-text="'<'"
            ></paginate>
        </div>
    </div>
</template>

<script>
    import CardStandard from '../cards/CardStandard';
    import Paginate from 'vuejs-paginate';
    import PromoService from '../../../services/PromotionService';
    import RelatedSpecialOffersSkeleton from './RelatedSpecialOffersSkeleton';

    export default {
        name: 'RelatedSpecialOffers',
        components: {
            CardStandard,
            Paginate,
            RelatedSpecialOffersSkeleton
        },
        props: {
            /**
             * Utilized as the path to detail pages when useRouter === false
             */
            baseUrl: {
                type: String,
                required: true
            },
            excludedPromotionUrl: {
                type: String,
                default: ''
            },
            filteredByMachines: {
                type: Boolean,
                required: true
            },
            filteredByPromotions: {
                type: Boolean,
                required: true
            },
            manufacturer: {
                required: true,
                type: String
            },
            selectedPromotionsIds: {
                type: Array,
                default: () => []
            },
            showPagination: {
                type: Boolean,
                required: true
            },
            useRouter: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                PROMOTIONS_PER_PAGE: 9,
                dealerPromotion: false,
                filterValue: '',
                industries: [],
                isFirstLoad: true,
                loading: true,
                offers: [],
                page: 1,
                paginationMarginPages: 2,
                paginationPageRange: 4,
                selectedCategory: '',
                selectedIndustry: ''
            };
        },
        computed: {
            numberOfPages() {
                return Math.ceil(this.filteredPromotions.length / this.perPage);
            },
            displayedPosts() {
                let from = this.page * this.perPage - this.perPage;
                let to = this.page * this.perPage;
                return this.filteredPromotions.slice(from, to);
            },
            filteredPromotions() {
                let filteredPromos = this.offers;

                if (this.selectedIndustry) {
                    filteredPromos = filteredPromos.filter(
                        offer => offer.Industries != null && offer.Industries.includes(this.selectedIndustry)
                    );
                }
                if (this.selectedCategory) {
                    filteredPromos = filteredPromos.filter(
                        offer => offer.Category != null && offer.Category.includes(this.selectedCategory)
                    );
                }
                if (this.excludedPromotionUrl) {
                    filteredPromos = filteredPromos.filter(offer => offer.UrlName != this.excludedPromotionUrl);
                }

                return filteredPromos;
            },
            categories() {
                let promotionsByIndustry = this.offers.filter(
                    offer => offer.Industries != null && offer.Industries.includes(this.selectedIndustry)
                );
                return this.getStringPropertyAsArray(promotionsByIndustry, 'Category');
            },
            hasCategories() {
                return this.industries.some(value => !!value);
            },
            warningMessage() {
                if (this.filteredByMachines && this.selectedPromotionsIds.length == 0) {
                    return `The selected machines have no current promotions available`;
                } else if (this.filteredByPromotions && this.selectedPromotionsIds.length == 0) {
                    return 'No selected promotions to display';
                } else if (this.offers.length == 0) {
                    return 'No promotions currently available';
                }

                return null;
            },
            perPage() {
                return this.showPagination
                    ? this.PROMOTIONS_PER_PAGE
                    : this.filteredPromotions.length;
            }
        },
        watch: {
            filteredPromotions() {
                this.resetPagination();
            },
            categories() {
                this.selectedCategory = '';
            }
        },
        mounted() {
            if (this.manufacturer === 'dealer') {
                this.dealerPromotion = true;
            }

            if (this.filteredByMachines || this.filteredByPromotions) {
                if (this.selectedPromotionsIds.length > 0) {
                    PromoService.getSelectedPromotions(this.manufacturer, this.selectedPromotionsIds).then(
                        result => {
                            this.offers = result.filter(offer => !!offer);

                            this.loading = false;
                        }
                    );
                }
            } else {
                PromoService.getAllPromotions(this.manufacturer).then(value => {
                    this.offers = value;
                    this.industries = this.getStringPropertyAsArray(this.offers, 'Industries');

                    this.loading = false;
                });
            }

            this.setPaginationSize();
            window.addEventListener('resize', this.setPaginationSize);

            this.page = parseInt(this.$route.query.page ?? 1);
        },
        methods: {
            getStringPropertyAsArray(objectArray, stringAttribute) {
                let allValues = objectArray
                    .filter(f => f[stringAttribute] != null)
                    .map(obj => obj[stringAttribute]);
                let uniqueValues = new Set();

                allValues.forEach(valueList => {
                    valueList.split(',').forEach(value => {
                        uniqueValues.add(value.trim());
                    });
                });

                return Array.from(uniqueValues);
            },
            resetPagination() {
                if (!this.isFirstLoad && this.page != 1) {
                    this.page = 1;
                }
                this.isFirstLoad = false;
            },
            setPaginationSize() {
                let windowWidth = document.documentElement.clientWidth;

                if (windowWidth <= 576) {
                    this.paginationMarginPages = 1;
                    this.paginationPageRange = 3;
                } else {
                    this.paginationMarginPages = 2;
                    this.paginationPageRange = 10;
                }
            },
            onPaginationChange(page) {
                if (this.$route.query.page && page == 1) {
                    this.$router.push({ query: { page: undefined } });
                } else if (this.$route.query.page != page) {
                    this.$router.push({ query: { page: page } });
                }
            }
        }
    };
</script>
