import { buildODataIdFilter } from '../frontend/lib/utilities';
import { getDataPage } from './lib/ODataService';

/**
 * TODO: Query by location
 * $filter=EmployeeLocation/any(x:contains(x/DealerLocationTitle, 'Conway'))
 * SF OData does not seem to support this kind of queries
 * It is supported by OData https://www.odata.org/getting-started/basic-tutorial/
 * Check $filter and Lambda Operators
 */
export function getEmployees(options) {
    let apiUrl = `/api/default/staffs?$expand=EmployeeLocation,EmployeeImage&`;
    let queryParts = [];

    if (options && options.$filter) {
        queryParts.push(`$filter=${options.$filter}`);
    }

    if (options && options.$orderby) {
        queryParts.push(`$orderby=${options.$orderby}`);
    } else {
        queryParts.push('$orderby=LastName');
    }

    apiUrl += queryParts.join('&');

    return new Promise(resolve => {
        getDataPage(apiUrl, 0, true).then(response => {
            let employees = response.data.value.filter(x => x.EmployeeLocation.length);

            if (options.locationId) {
                employees = employees.filter(x => x.EmployeeLocation.some(y => y.Id === options.locationId));
            }

            resolve(employees);
        });
    });
}

export function getEmployeesByIds(ids) {
    return getEmployees({
        $filter: buildODataIdFilter(ids)
    });
}

export default {
    getEmployees,
    getEmployeesByIds
};
