﻿<template>
    <build-your-own-content :collection="industries" :loading="loading"></build-your-own-content>
</template>

<script>
    // Utilities
    import utilities from '../../lib/utilities';
    // Services
    import DeereBuildYourOwnService from '../../../services/DeereBuildYourOwnService';
    // Components
    import BuildYourOwnContent from './BuildYourOwnContent';

    export default {
        name: 'BuildYourOwnIndustrySelection',
        components: {
            BuildYourOwnContent
        },
        data() {
            return {
                industries: [],
                loading: true
            };
        },

        mounted() {
            DeereBuildYourOwnService.getIndustries().then(industries => {
                this.industries = utilities.sortByKey(industries.Classifications, 'Name');

                this.loading = false;
            });
        }
    };
</script>
