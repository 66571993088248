﻿<template>
    <div class="machine-detail-content"></div>
</template>
<script>
    export default {
        name: 'EquipmentDetailDefault',
        props: {
            machine: {
                type: Object
            }
        },
        created() {
            this.$router.replace({ path: `${this.$route.params.model}/overview` });
        }
    };
</script>
