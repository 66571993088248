<template>
    <card-standard
        class="card-rounded h-100"
        :enable-image="location.Image.length > 0"
        :image="{
            src: location.Image.length > 0 ? location.Image[location.Image.length - 1].Url : ''
        }"
        :title="location.DealerLocationTitle.toUpperCase()"
        :subtitle="locationName(location) != location.DealerLocationTitle ? locationName(location) : ''"
        :title-size="3"
        :action-url="`${elementConfig.SITEMAP.LOCATIONS}/${location.UrlName}`"
        :use-router="enableRouter"
        :route="getRoute(location)"
    >
        <span class="text-uppercase" v-html="locationFullAddress(location)"></span>
        <br />
        <div v-if="location.Phone" class="mt-1">
            <i class="fas fa-phone"></i>
            <span class="mx-1">Phone:</span>
            <u>
                <a :href="telLink(location.Phone)" class="text-primary font-weight-bold">{{
                    location.Phone
                }}</a>
            </u>
        </div>
        <div v-if="checkOpenNow(location)" class="mt-1">
            <i class="fas fa-clock"></i>
            <span class="mx-1">Hours:</span>
            <span>{{ todaysOpenHours(location) }}</span>
            <span class="text-uppercase text-primary font-weight-bold">Open Now</span>
        </div>

        <div class="d-flex mt-4">
            <a :href="directionsLink(location)" target="_blank">
                <button class="btn btn-primary rounded-pill mr-2">Get Directions</button>
            </a>

            <router-link v-if="enableRouter" class="d-inline-block" :to="getRoute(location)">
                <button class="btn btn-outline-primary rounded-pill">Store Details</button>
            </router-link>
            <a v-else class="d-inline-block" :href="`${elementConfig.SITEMAP.LOCATIONS}/${location.UrlName}`">
                <button class="btn btn-outline-primary rounded-pill">Store Details</button>
            </a>
        </div>
    </card-standard>
</template>

<script>
    import constants from '../../config/constants';

    import CardStandard from '../cards/CardStandard';
    import LocationMixin from '../mixins/LocationMixin';

    export default {
        name: 'LocationListRoundedCard',
        components: {
            CardStandard
        },
        mixins: [LocationMixin],
        props: {
            enableRouter: {
                type: Boolean,
                required: true
            },
            location: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                elementConfig: constants.elementConfig
            };
        },
        methods: {
            getRoute(location) {
                return {
                    name: 'details',
                    params: { slug: location.UrlName, location: location }
                };
            },
            todaysOpenHours(location) {
                let openHours = this.getOpenHours(location)[new Date().getDay()];

                return openHours.status !== 'Closed' ? `${openHours.openTime} - ${openHours.closeTime}` : '-';
            }
        }
    };
</script>
