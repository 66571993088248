import axios from 'axios';
import auth from './AuthenticationService';
import constants from '../frontend/config/constants';
import dayjs from 'dayjs';
import maps from './GoogleMapService';

export const defaultLocation = {
    PublicationDate: dayjs().format(),
    UrlName: '',
    Phone: '',
    Cell: '',
    Timezone: 0,
    Fax: '',
    Email: '',
    Facebook: '',
    Twitter: '',
    Holidays: [],
    Hours: [
        {
            day: 'Sunday',
            openTime: '',
            openClose: '',
            status: 'Closed'
        },
        {
            day: 'Monday',
            openTime: '8:00 AM',
            closeTime: '5:00 PM',
            status: 'Open'
        },
        {
            day: 'Tuesday',
            openTime: '8:00 AM',
            closeTime: '5:00 PM',
            status: 'Open'
        },
        {
            day: 'Wednesday',
            openTime: '8:00 AM',
            closeTime: '5:00 PM',
            status: 'Open'
        },
        {
            day: 'Thursday',
            openTime: '8:00 AM',
            closeTime: '5:00 PM',
            status: 'Open'
        },
        {
            day: 'Friday',
            openTime: '8:00 AM',
            closeTime: '5:00 PM',
            status: 'Open'
        },
        {
            day: 'Saturday',
            openTime: '',
            closeTime: '',
            status: 'Closed'
        }
    ],
    AfterHours: [],
    Address: {
        Id: '00000000-0000-0000-0000-000000000000',
        CountryCode: 'US',
        StateCode: '',
        City: '',
        Zip: '',
        Street: '',
        MapZoomLevel: 1
    },
    DealerLocationDescription: '',
    UseGlobalHours: true,
    UseGlobalHolidays: true,
    DealerLocationTitle: 'unnamed location'
};

let cachedLocations;
const locationEndpoint = '/api/default/dealerlocations';
const globalSettingName = 'GlobalSettings';

/////////////////////////////////
////// AUTHENTICATED CALLS //////
/////////////////////////////////
export function updateLocation(location) {
    return new Promise((resolve, reject) => {
        auth.getUser().then(user => {
            let config = {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            };
            axios
                .patch(`${locationEndpoint}(${location.Id})`, flattenLocation(location), config)
                .then(response => {
                    resolve(response);
                })
                .catch(reason => {
                    console.error(`updateLocation failed: ${reason.response.data.error.code}: ${reason.response.data.error.message}`);
                    reject(reason);
                });
        });
    });
}

export function updateGlobalSettings(location) {
    return new Promise((resolve, reject) => {
        auth.getUser().then(user => {
            let config = {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            };
            axios
                .patch(`${locationEndpoint}(${location.Id})`, flattenLocation(location), config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    });
}

export function deleteLocation(location) {
    return new Promise((resolve, reject) => {
        auth.getUser().then(user => {
            let config = {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            };
            axios
                .delete(`${locationEndpoint}(${location.Id})`, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    });
}

export function createNewLocation() {
    return new Promise((resolve, reject) => {
        auth.getUser().then(user => {
            let config = {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            };
            axios
                .post(`${locationEndpoint}`, flattenLocation(defaultLocation), config)
                .then(response => {
                    resolve(expandLocation(response.data));
                })
                .catch(err => {
                    reject(err);
                });
        });
    });
}

/////////////////////////////////
////// ANONYMOUS CALLS     //////
/////////////////////////////////

export function getLocationById(locationId) {
    let apiUrl = `${locationEndpoint}?$expand=Image&$filter=Id eq ${locationId} or UrlName eq '${globalSettingName}'`;

    return new Promise((resolve, reject) => {
        axios
            .get(apiUrl)
            .then(response => {
                let globalSettings = getGlobalSettings(response.data.value, globalSettingName);

                if (response.data.value.length > 0) {
                    let selectedLocation = response.data.value[0];

                    setHours(selectedLocation, globalSettings);
                    setAfterHours(selectedLocation);
                    resolve(selectedLocation);
                }

                reject('Location not found');
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function getLocationBySlug(slugUrl) {
    let apiUrl = `${locationEndpoint}?$expand=Image&$filter=UrlName eq '${slugUrl}' or UrlName eq '${globalSettingName}'`;

    return new Promise((resolve, reject) => {
        axios
            .get(apiUrl)
            .then(response => {
                let globalSettings = getGlobalSettings(response.data.value, globalSettingName);
                let selectedLocation = response.data.value.find(item => item.UrlName === slugUrl);
                if (selectedLocation) {
                    setHours(selectedLocation, globalSettings);
                    setAfterHours(selectedLocation);
                    resolve(selectedLocation);
                }

                reject('Location not found');
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function getLocations(orderBy = 'DealerLocationTitle', skip = 0, filter = '', top = 0) {
    let apiUrl = `${locationEndpoint}?$expand=Image&$orderby=${orderBy}&$count=true&$skip=${skip}`;

    if (top != 0) {
        apiUrl += `&$top=${top}`
    }

    if (filter) {
        apiUrl = apiUrl + `&$filter=dealergroups/any(t: t eq ${filter})`;
    }

    return new Promise((resolve, reject) => {
        axios
            .get(apiUrl)
            .then(response => {
                let globalSettings = getGlobalSettings(response.data.value, globalSettingName);
                let locations = [];
                response.data.value.forEach(location => {
                    if (location.DealerLocationTitle === globalSettingName) {
                        return;
                    }

                    setHours(location, globalSettings);
                    setAfterHours(location);
                    locations.push(location);
                });

                if (response.data.value.length == top || response.data['@odata.count'] <= response.data.value.length + skip) {
                    cachedLocations = {
                        // TODO: Remove globalSettings, I haven't found a particular use on other places
                        globalSettings,
                        locations
                    };
                    resolve(cachedLocations);
                } else {
                    getLocations(orderBy, skip + 50).then(moreLocations => {
                        locations = locations.concat(moreLocations.locations);
                        cachedLocations = {
                            // TODO: Remove globalSettings, I haven't found a particular use on other places
                            globalSettings,
                            locations
                        };
                        resolve(cachedLocations);
                    });
                }
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function getClosestLocation(address, showFirst = true) {
    let coordsPromise;
    let locationsPromise = getLocations();

    if (address) {
        coordsPromise = maps.getCoordinatesFromAddress(address, constants.elementConfig.GOOGLE_MAPS_KEY);
    } else {
        coordsPromise = maps.detectCoordinates();
    }

    return new Promise((resolve, reject) => {
        Promise.all([coordsPromise, locationsPromise])
            .then(values => {
                let coords = values[0];
                let locations = values[1].locations;

                if (locations.length > 0) {
                    const closestLocation = locations.sort(
                        (a, b) =>
                            distance(getLocationPosition(a), coords) -
                            distance(getLocationPosition(b), coords)
                    );

                    if (showFirst) {
                        resolve(closestLocation[0]);
                    } else {
                        resolve(closestLocation);
                    }
                }
                reject('Could not find any locations');
            })
            .catch(error => {
                reject(error);
            });
    });
}

export default {
    defaultLocation,
    updateLocation,
    updateGlobalSettings,
    deleteLocation,
    createNewLocation,
    getLocationById,
    getLocationBySlug,
    getLocations,
    getClosestLocation
};

// PRIVATE METHODS

function setHours(location, globalSettings) {
    if (!location.Hours || location.UseGlobalHours) {
        location.Hours = globalSettings.Hours;
    } else {
        location.Hours = JSON.parse(location.Hours);
    }

    if (!location.Holidays || location.UseGlobalHolidays) {
        location.Holidays = globalSettings.Holidays;
    } else {
        location.Holidays = JSON.parse(location.Holidays);
    }
}

function setAfterHours(location) {
    if (!location.AfterHours) {
        location.AfterHours = [];
    } else {
        location.AfterHours = JSON.parse(location.AfterHours);
    }
}

function getGlobalSettings(data, globalSettingName) {
    let globalSettings = data.find(el => el.DealerLocationTitle === globalSettingName);

    if (!globalSettings) {
        return defaultLocation;
    }

    globalSettings.Hours = globalSettings.Hours ? JSON.parse(globalSettings.Hours) || [] : [];

    if (globalSettings.Hours.length === 0) {
        globalSettings.Hours = defaultLocation.Hours;
    }

    globalSettings.Holidays = globalSettings.Holidays ? JSON.parse(globalSettings.Holidays) || [] : [];

    return globalSettings;
}

function flattenLocation(location) {
    var locationCopy = JSON.parse(JSON.stringify(location));
    locationCopy.Hours = JSON.stringify(location.Hours);
    locationCopy.AfterHours = JSON.stringify(location.AfterHours);
    locationCopy.Holidays = JSON.stringify(location.Holidays);
    locationCopy.Timezone = Number.parseFloat(location.Timezone);
    delete locationCopy['Image'];

    return locationCopy;
}

function expandLocation(location) {
    var locationCopy = JSON.parse(JSON.stringify(location));
    locationCopy.Hours = JSON.parse(location.Hours);
    locationCopy.AfterHours = JSON.parse(location.AfterHours);
    locationCopy.Holidays = JSON.parse(location.Holidays);
    delete locationCopy['@odata.context'];

    return locationCopy;
}

// function uuidv4() {
//     let crypto = window.crypto || window.msCrypto;
//     return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
//         (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
//     );
// }

function distance(point1, point2) {
    return haversine(point1.lat, point1.lng, point2.lat, point2.lng);
}

// Credit: StackOverflow Noorul
// Haversine formulae for calculating distance on a globe.
function haversine(lat1, long1, lat2, long2) {
    lat1 = (lat1 * 2.0 * Math.PI) / 60.0 / 360.0;
    long1 = (long1 * 2.0 * Math.PI) / 60.0 / 360.0;
    lat2 = (lat2 * 2.0 * Math.PI) / 60.0 / 360.0;
    long2 = (long2 * 2.0 * Math.PI) / 60.0 / 360.0;

    // use to different earth axis length
    var a = 6378137.0; // Earth Major Axis (WGS84)
    var b = 6356752.3142; // Minor Axis
    var f = (a - b) / a; // "Flattening"
    var e = 2.0 * f - f * f; // "Eccentricity"

    var beta = a / Math.sqrt(1.0 - e * Math.sin(lat1) * Math.sin(lat1));
    var cos = Math.cos(lat1);
    var x = beta * cos * Math.cos(long1);
    var y = beta * cos * Math.sin(long1);
    var z = beta * (1 - e) * Math.sin(lat1);

    beta = a / Math.sqrt(1.0 - e * Math.sin(lat2) * Math.sin(lat2));
    cos = Math.cos(lat2);
    x -= beta * cos * Math.cos(long2);
    y -= beta * cos * Math.sin(long2);
    z -= beta * (1 - e) * Math.sin(lat2);

    return Math.sqrt(x * x + y * y + z * z) / 1000;
}

function getLocationPosition(location) {
    return {
        lat: location.Address.Latitude,
        lng: location.Address.Longitude
    };
}
