﻿<template>
    <div ref="card" class="h-100 w-100" :class="['card-flippable d-flex', { flipped }]">
        <div class="front d-flex w-100">
            <div class="card shadow">
                <div class="card-body text-center p-2">
                    <a :href="card.ctaLink" :target="target">
                        <element-image
                            v-if="!card._useVideo"
                            class="justify-content-center"
                            img-class="align-self-start mb-3"
                            :alt="card.image.alt"
                            :src="card.image.src"
                        ></element-image>
                        <div v-else class="embed-responsive embed-responsive-16by9 mb-3">
                            <iframe
                                v-if="card._embedUrl"
                                class="embed-responsive-item"
                                :src="card._embedUrl"
                                frameborder="0"
                                allow="autoplay;"
                            ></iframe>
                            <iframe
                                v-else
                                class="embed-responsive-item"
                                :src="card.video.MediaUrl"
                                frameborder="0"
                                allow="autoplay;"
                            ></iframe>
                        </div>
                    </a>
                    <h5 class="card-title mb-1">{{ card.title }}</h5>
                    <p :class="[card.maxWidth, 'm-auto', 'card-text']">{{ card.description }}</p>
                </div>
                <a
                    v-if="enableLink"
                    class="d-block card-footer text-center"
                    :href="ctaLink"
                    :target="target"
                    @click="handleLinkClick($event)"
                >
                    {{ card.ctaText }}
                </a>
            </div>
        </div>
        <div class="back d-flex w-100">
            <div class="card shadow">
                <div class="card-body text-center p-2">
                    <h5 class="card-title m-3">{{ card.flipTitle }}</h5>
                    <p class="card-text m-3">{{ card.flipDescription }}</p>
                    <a v-if="card.ctaFlip" class="btn btn-primary" :href="card.ctaLink" :target="target">
                        {{ card.ctaFlip }}
                    </a>
                </div>
                <a @click="flip()">
                    <div class="card-footer text-center">Go Back</div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardFlippable',
        props: {
            card: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                slides: {},
                flipped: false
            };
        },
        computed: {
            ctaLink() {
                return this.card.flippable ? '#' : this.card.ctaLink;
            },
            enableLink() {
                return this.ctaLink && this.card.ctaText;
            },
            target() {
                return this.card.openLinkInNewTab ? '_blank' : '_self';
            }
        },
        methods: {
            /**
             * @param {Event} e
             */
            handleLinkClick(e) {
                if (this.card.flippable) {
                    e.preventDefault();
                    this.flip();
                }
            },
            flip() {
                this.flipped = !this.flipped;
            }
        }
    };
</script>
