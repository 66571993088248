<!--
Wrapper for AccordionItems
Passes "expanded" events to children to ensure that only one accordion item is opened at a time.
-->
<template>
    <div class="accordion">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        mounted() {
            // listen for child event
            this.$on('child_expanded', item => {
                // pass the event and item name back to child accordion items
                this.$emit('sibling_expanded', item);
            });
        }
    };
</script>