﻿<template>
    <eb-index
        id="main-grid-container"
        :api-key="elementConfig.BLAZE_API_KEY"
        :persist-form-state="!isDesignMode"
        :default-filters="config.defaultFilters"
        :form-persistence-provider="formPersistenceProvider"
        :index-name="elementConfig.BLAZE_INDEX_NAME"
        :scoring-profiles="[config.scoringProfile]"
        :service-name="elementConfig.BLAZE_SERVICE_NAME"
        :trigger-search-on-form-state-change="!isDesignMode"
        class="mt-md-3"
    >
        <!-- in design-mode message -->
        <div v-if="isDesignMode" class="alert alert-primary text-center">
            Note: search controls are inactive in design mode
        </div>

        <!-- mobile search filters modal -->
        <search-sitewide-modal
            :content-filters="contentFilters"
            :filter-parameters="filterParameters"
        ></search-sitewide-modal>

        <div class="container pt-md-3">
            <!-- search form top bar -->
            <div class="row pt-2 pt-md-3 pb-md-2 align-items-end">
                <!-- "sort by" dropdown -->
                <div class="col-6 col-lg-3">
                    <div class="form-group d-md-flex align-items-md-center mb-2">
                        <label for="results-sort" class="font-weight-bold font-weight-md-normal mb-0 mr-3">
                            Sort
                        </label>
                        <eb-sort-by-dropdown
                            id="results-sort"
                            class="form-control"
                            :options="config.sortByOptions"
                        ></eb-sort-by-dropdown>
                    </div>
                </div>

                <!-- results per page dropdown -->
                <div class="col-6 col-lg-3">
                    <div class="form-group d-md-flex align-items-md-center mb-2">
                        <label
                            for="results-per-page"
                            class="font-weight-bold font-weight-md-normal mb-0 mr-3"
                        >
                            Show
                        </label>
                        <eb-results-per-page-dropdown
                            id="results-per-page"
                            class="form-control"
                            :show="[10, 20, 50, 100]"
                        ></eb-results-per-page-dropdown>
                    </div>
                </div>

                <!-- top pagination for mobile -->
                <div v-show="!isSearching" class="col-12 d-block d-sm-none">
                    <div class="form-group mb-2">
                        <eb-pagination
                            pagination-class="pagination pagination-sm justify-content-center mb-0"
                            :pagination-size="3"
                            item-class="page-item"
                        ></eb-pagination>
                    </div>
                </div>

                <!-- top pagination for desktops -->
                <div v-show="!isSearching" class="d-none d-sm-block col-12 col-lg-6">
                    <div class="form-group text-right mb-2">
                        <eb-pagination
                            pagination-class="align-self-end pagination justify-content-center justify-content-xl-end mb-0"
                            :pagination-size="4"
                        ></eb-pagination>
                    </div>
                </div>
            </div>
        </div>

        <hr class="mt-0 mt-md-2 mb-md-4" />

        <div class="search-sitewide container">
            <div class="row">
                <!-- left sidebar (hidden on mobile) -->
                <div class="d-none d-md-block col-md-4">
                    <div class="form-group">
                        <label for="keywords-input">Keywords</label>
                        <br />
                        <eb-suggestions id="keywords-input"></eb-suggestions>
                    </div>
                    <div class="form-group">
                        <label for="filter-type">Type</label>
                        <br />
                        <eb-static-filters-buttons-list
                            id="filter-type"
                            btn-class="btn btn-primary mb-1 mr-1 rounded-0"
                            :filters="contentFilters"
                        ></eb-static-filters-buttons-list>
                    </div>
                    <eb-multi-select-filter
                        id="industry"
                        label="Industry"
                        field="ResultIndustries"
                        field-type="collection"
                    ></eb-multi-select-filter>
                    <eb-multi-select-filter
                        id="category"
                        label="Category"
                        field="ResultCategories"
                        field-type="collection"
                    ></eb-multi-select-filter>
                    <eb-multi-select-filter
                        id="subcategory"
                        label="Subcategory"
                        field="ResultSubcategories"
                        field-type="collection"
                    ></eb-multi-select-filter>
                    <eb-multi-select-filter
                        id="series"
                        label="Series"
                        field="ResultSeries"
                        field-type="collection"
                    ></eb-multi-select-filter>
                    <eb-multi-select-filter
                        id="make"
                        label="Make"
                        field="Manufacturer"
                    ></eb-multi-select-filter>
                    <eb-multi-select-filter id="model" label="Model" field="Model"></eb-multi-select-filter>
                    <eb-multi-select-filter
                        v-show="$data.$_showResultLocation"
                        id="location"
                        label="Location"
                        field="ResultLocation"
                    ></eb-multi-select-filter>

                    <eb-range-slider-filter
                        range-min-class="form-control mt-3"
                        range-max-class="form-control mt-3 ml-auto"
                        badge-min-class="badge badge-secondary"
                        badge-max-class="badge badge-secondary ml-auto"
                        slider-id="year-range-filter"
                        label="Year"
                        field="Year"
                        :exclude-zero="true"
                    ></eb-range-slider-filter>
                    <eb-range-slider-filter
                        range-min-class="form-control mt-3"
                        range-max-class="form-control ml-5 mt-3 ml-auto"
                        badge-min-class="badge badge-secondary"
                        badge-max-class="badge badge-secondary ml-auto"
                        slider-id="price-range-filter"
                        label="Price"
                        field="Price"
                        :is-money="true"
                    ></eb-range-slider-filter>

                    <div class="form-group">
                        <eb-reset btn-class="btn btn-primary btn-lg btn-block">Clear Filters</eb-reset>
                    </div>
                </div>

                <div v-if="isSearching" class="col-md-8">
                    <card-horizontal-skeleton v-for="index in 4" :key="index"></card-horizontal-skeleton>
                </div>

                <!--
                    NOTE: it's important to use v-show here instead of v-if to keep pagination components mounted
                    while searches are executed. This ensures the components can apply the proper page number
                    to the search query to prevent infinite loops when there are no results for the specified page.
                -->
                <div v-show="!isSearching" id="main-grid-view" class="col-md-8">
                    <eb-current-form-selections
                        class="d-none d-md-block"
                        :fields="filterParameters"
                        badge-class="badge badge-primary"
                        badge-all-items-class="badge badge-secondary"
                        show-results-label=" results matching"
                    ></eb-current-form-selections>
                    <eb-results class="ml-md-1">
                        <template slot-scope="{ item }">
                            <component
                                :is="getResultComponent(item.ContentType)"
                                :key="item.Id"
                                :item="item"
                                :badges="getResultBadges(item)"
                                :tier-icons="tierIcons"
                            ></component>
                        </template>
                    </eb-results>
                    <div class="d-block d-sm-none">
                        <eb-pagination
                            pagination-class="pagination pagination-sm justify-content-center"
                            link-class="page-link"
                            :pagination-size="3"
                        ></eb-pagination>
                    </div>
                    <div class="d-none d-sm-block">
                        <eb-pagination
                            pagination-class="align-self-end pagination justify-content-center"
                            link-class="page-link"
                            :pagination-size="4"
                        ></eb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </eb-index>
</template>

<script>
    import constants from '../../config/constants';
    import searchConfig from '../../config/search-config';
    import StoreConstants from '../../store/constants';
    import { QueryStringProvider } from '@teamsi/element-blaze';

    // components
    import CardHorizontalSkeleton from '../cards/CardHorizontalSkeleton';
    import SearchResultMixin from '../mixins/SearchResultMixin';
    import SearchResultNewEquipment from './SearchResultNewEq';
    import SearchResultUsedEquipment from './SearchResultUsedEq';
    import SearchResultContent from './SearchResultContent';
    import SearchSitewideModal from './SearchSitewideModal';

    export default {
        name: 'SitewideSearch',
        components: {
            CardHorizontalSkeleton,
            SearchResultNewEquipment,
            SearchResultUsedEquipment,
            SearchResultContent,
            SearchSitewideModal
        },
        mixins: [SearchResultMixin],
        props: {
            contentFilters: {
                type: Array,
                required: true
            },
            // things get a little wild in design mode with SF adding things to the querystring, triggering repeated searches
            isDesignMode: {
                required: true,
                type: Boolean
            },
            routeName: {
                type: String,
                required: true
            },
            tierIcons: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                elementConfig: constants.elementConfig,
                config: searchConfig,
                filterParameters: [
                    'filter-type',
                    'Hierarchy',
                    'industry',
                    'category',
                    'subcategory',
                    'series',
                    'make',
                    'model',
                    'location',
                    'YearMin',
                    'YearMax',
                    'PriceMin',
                    'PriceMax',
                    'q'
                ],
                formPersistenceProvider: new QueryStringProvider(this.routeName)
            };
        },
        computed: {
            isSearching() {
                return this.$store.state.search.isSearching;
            }
        },
        created() {
            this.$store.subscribe(mutation => {
                if (
                    mutation.type === 'search/addFormValue' ||
                    mutation.type === StoreConstants.SEARCH.MUTATIONS.RESET ||
                    mutation.type === 'search/changePage'
                ) {
                    this.applyScroll();
                }
            });
        },
        methods: {
            getResultComponent(typeName) {
                const contentType = this.getResultContentType(typeName);
                return contentType && contentType.resultsComponent;
            },
            applyScroll() {
                setTimeout(() => {
                    let selector = '#main-grid-container';
                    if (window.innerWidth < 768) {
                        selector = '#main-grid-view';
                    }
                    document.querySelector(selector).scrollIntoView(true);
                }, 1000);
            }
        }
    };
</script>
