﻿<template>
    <div class="card card-twitter">
        <div class="card-body p-0">

            <a :href="screenNameLink" target="_blank" class="card-title row mx-0 px-3 pt-3 pb-0">
                <span class="pr-2">
                    <img class="avatar"
                         :src="profilePicture"
                         alt="Avatar">
                </span>
                <span class="col px-0">
                    <span class="d-block text-dark font-weight-bold">{{ name }}</span>
                    <span class="d-block screen-name font-weight-light">{{ screenName }}</span>
                </span>
                <span class="px-0 pt-1">
                    <i class="twitter-icon fab fa-twitter"></i>
                </span>
            </a>

            <div class="card-text px-3">
                <div v-html="fullText"></div>

                <img v-if="image"
                     class="mt-3 rounded object-fit-cover"
                     :src="image"
                     :alt="screenName" />

                <div class="info row p-3">
                    <a :href="`https://twitter.com/intent/like?tweet_id=${postId}`" target="_blank" class="px-0">
                        <div class="tw-like d-flex">
                            <div class="position-relative">
                                <div class="position-absolute tw-like-shadow"></div>
                                <svg viewBox="0 0 24 24">
                                    <g><path d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"></path></g>
                                </svg>
                            </div>
                            <span class="mt-1">{{ likeCount }}</span>
                        </div>
                    </a>
                    <div class="col px-0 mt-1 text-center">
                        <span>{{ createdAt }}</span>
                    </div>
                    <a class="px-0 mt-1 text-right" href="https://support.twitter.com/articles/20175256" target="_blank">
                        <i class="fas fa-info-circle"></i>
                    </a>
                </div>
            </div>

            <a :href="screenNameLink" target="_blank" class="card-footer row mx-0">
                <span class="col px-0">
                    <i class="far fa-user pr-1"></i>
                    <span>See {{ name }}'s other Tweets</span>
                </span>
                <span class="col-1 px-0 text-right">
                    <i class="fas fa-chevron-right"></i>
                </span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardTwitter',
        props: {
            postId: String,
            profilePicture: String,
            name: String,
            screenName: String,
            screenNameLink: String,
            createdAt: String,
            fullText: String,
            likeCount: Number,
            media: Array
        },
        computed: {
            image() {
                if (!!this.media && !!this.media[0]) {
                    return this.media[0].MediaUrl;
                }
                return null;
            }
        }
    };
</script>
