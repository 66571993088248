﻿<template>
    <div class="build-your-own-content mb-3">
        <div class="container">
            <div class="row row-cols-1 row-cols-md-4 row-cols-lg-5 justify-content-center px-3">
                <router-link
                    v-for="item in collection"
                    :key="item.Id"
                    tag="div"
                    :to="getPath(item.UrlName)"
                    class="card shadow col mb-2 mx-md-2 d-flex flex-column align-items-center p-1 cursor-pointer fade-in"
                >
                    <img
                        :src="getMachineImage(item)"
                        :alt="item.Name"
                    />
                    <div class="card-body text-center d-flex justify-content-center align-items-center">
                        <p
                            class="card-title d-flex justify-content-center align-items-center py-3 m-0 font-weight-bold"
                        >
                            {{ item.Name }}
                        </p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            collection: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            getMachineImage() {
                return item => {
                    if (!!item.Image) {
                        return item.Image.replace(/&nbsp;/g, '').split(',')[0];
                    } else {
                        return 'https://via.placeholder.com/250x150.png';
                    }
                };
            }
        },
        methods: {
            getPath(UrlName) {
                let currentPath = this.$route.path;
                return currentPath === '/' ? UrlName : `${currentPath}/${UrlName}`;
            }
        }
    };
</script>
