﻿<template>
    <div class="position-relative" @mouseleave="imageHovered = false" @mouseover="imageHovered = true">
        <div v-if="tierIcon" class="tier-icon position-absolute" :class="{ 'd-md-none': imageHovered }">
            <img :src="tierIcon" :alt="`${item.Tier} Tier`" />
        </div>
        <card-horizontal
            :badges="badges"
            button-column-class="align-items-end"
            class="mb-4"
            :image="previewImage"
            :title="title"
            :url="url"
            :use-router="useRouter"
            :enable-shadow="true"
        >
            <div v-if="rentalPrices">
                <h5 class="text-uppercase">Rental Prices:</h5>
                <ul>
                    <li v-for="(rPrice, i) in rentalPrices" :key="i">{{ rPrice }}</li>
                </ul>
            </div>
            <h5>
                <strike v-if="suggestedPrice" class="text-danger">{{ suggestedPrice | money }}</strike>
                <span v-if="price" class="font-weight-bold">{{ price | money }}</span>
                <span v-else class="font-weight-bold">Call For Price</span>
            </h5>
            <ul class="list-unstyled">
                <li v-if="!item.EquipmentType && categories">
                    <span class="font-weight-bold">Category:</span> {{ categories }}
                </li>
                <li v-if="item.EquipmentType">
                    <span class="font-weight-bold">Type:</span> {{ item.EquipmentType }}
                </li>
                <li v-if="item.StockNumber">
                    <span class="font-weight-bold">Stock #:</span> {{ item.StockNumber }}
                </li>
                <li v-if="item.SerialNumber">
                    <span class="font-weight-bold">Serial #:</span> {{ item.SerialNumber }}
                </li>
                <li v-if="item.OperationHours">
                    <span class="font-weight-bold">Operation Hours:</span> {{ item.OperationHours }}
                </li>
                <li v-if="item.SeparatorHours">
                    <span class="font-weight-bold">Separator Hours:</span> {{ item.SeparatorHours }}
                </li>
                <li v-if="$data.$_showResultLocation && item.ResultLocation">
                    <span class="font-weight-bold">Location:</span> {{ item.ResultLocation }}
                </li>
                <li v-if="item.ResultPhoneNumber">
                    <span class="font-weight-bold">Phone:</span>
                    <a :href="`tel:${item.ResultPhoneNumber}`">{{ item.ResultPhoneNumber }}</a>
                </li>
            </ul>
        </card-horizontal>
    </div>
</template>

<script>
    import constants from '../../config/constants';

    import SearchResultMixin from '../mixins/SearchResultMixin';
    import ContentLocatorService from '../../../services/ContentLocatorService';

    export default {
        name: 'SitewideSearchResultUsedEq',
        mixins: [SearchResultMixin],
        props: {
            item: {
                type: Object,
                required: true
            },
            badges: {
                type: Array,
                default: () => ['Used']
            },
            tierIcons: {
                type: Object,
                default: () => ({})
            },
            useRouter: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                imageHovered: false
            };
        },
        computed: {
            categories() {
                return this.item.ResultCategories && this.item.ResultCategories.join(', ');
            },
            previewImage() {
                if (this.item.ResultImages) {
                    const images = this.item.ResultImages.split(',');
                    if (images && images.length > 0 && images[0].length > 0) {
                        return images[0];
                    }
                }

                return constants.equipmentPlaceholderImage;
            },
            tierIcon() {
                let selectedTier = this.item.Tier ?? 'None'; // Choice key name is saved in Blaze
                return this.tierIcons[selectedTier];
            },
            title() {
                if (this.item.Title) {
                    return this.item.Title;
                } else {
                    let titleParts = [];
                    this.item.Year && titleParts.push(this.item.Year);
                    this.item.Manufacturer && titleParts.push(this.item.Manufacturer);
                    this.item.Model && titleParts.push(this.item.Model);

                    return titleParts.join(' ');
                }
            },
            url() {
                return this.useRouter ? `/${this.item.UrlName}` : ContentLocatorService.getUrl(this.item);
            },
            price() {
                return this.item.Price && this.item.SuggestedPrice
                    ? Math.min(this.item.Price, this.item.SuggestedPrice)
                    : this.item.Price ?? this.item.SuggestedPrice;
            },
            rentalPrices() {
                if (this.item.RentalPrices) {
                    return this.item.RentalPrices.split('|').map(x => x.trim());
                }

                return null;
            },
            suggestedPrice() {
                return this.item.Price && this.item.SuggestedPrice
                    ? Math.max(this.item.Price, this.item.SuggestedPrice)
                    : null;
            }
        }
    };
</script>
