﻿<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: 'SpecialOffersHome',
        props: {
            backgroundImage: {
                type: String,
                default: ''
            },
            manufacturer: {
                type: String,
                required: true
            }
        }
    };
</script>
