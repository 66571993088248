﻿<template>
    <ul class="mainnav menu-bricks h-100">
        <simple-menu-submenu
            v-for="page in pages"
            :key="page.Url"
            :page="page"
            :is-first-level="true"
        ></simple-menu-submenu>
    </ul>
</template>

<script>
    import PageService from '../../../services/PageService';
    import SimpleMenuSubmenu from './SimpleMenuSubMenu';

    export default {
        name: 'SimpleMenu',
        components: {
            SimpleMenuSubmenu
        },
        props: {
            mainNavPages: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                pages: []
            };
        },
        created() {
            PageService.mapPages(this.mainNavPages).then(result => (this.pages = result));
        }
    };
</script>

<style>
    .theme-dark .mainnav .subs {
        background-color: #474747;
    }

    .mainnav .dropdown {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: ease-out 0.35s;
        -moz-transition: ease-out 0.35s;
        -webkit-transition: ease-out 0.35s;
        padding-left: 0;
        white-space: nowrap;
        display: flex;
        flex-flow: column;
    }

    .mainnav li {
        float: left;
    }

    .mainnav li:first-child {
        border: none;
    }

    .mainnav li a {
        display: inline-block;
        text-transform: uppercase;
    }

    .mainnav li:hover {
        background: white;
        transition: ease-in 0.35s;
        -moz-transition: ease-in 0.35s;
        -webkit-transition: ease-in 0.35s;
    }

    .mainnav li:hover a {
        color: var(--primary);
        transition: ease-in 0.35s;
        -moz-transition: ease-in 0.35s;
        -webkit-transition: ease-in 0.35s;
    }

    .mainnav .hassubs li,
    .mainnav .subs li {
        background-color: var(--primary);
    }

    .mainnav .subs {
        position: relative;
        top: 0px;
        border-left: none !important;
        border-bottom: 1px dotted var(--secondary) !important;
    }

    .mainnav .subs:last-child {
        border: none !important;
    }

    .mainnav .hassubs:hover .dropdown,
    .mainnav .hassubs .hassubs:hover .dropdown {
        opacity: 1;
        visibility: visible;
        transition: ease-in 0.35s;
        -moz-transition: ease-in 0.35s;
        -webkit-transition: ease-in 0.35s;
    }

    .mainnav li:hover ul a,
    .mainnav li:hover ul li ul li a {
        color: white;
        width: 100%;
    }

    .mainnav li ul li:hover,
    .mainnav li ul li ul li:hover {
        background: white;
        transition: ease-in-out 0.35s;
        -moz-transition: ease-in-out 0.35s;
        -webkit-transition: ease-in-out 0.35s;
    }

    .mainnav li ul li:hover a,
    .mainnav li ul li ul li:hover a {
        color: var(--primary);
        transition: ease-in-out 0.35s;
        -moz-transition: ease-in-out 0.35s;
        -webkit-transition: ease-in-out 0.35s;
    }

    .mainnav .hassubs .hassubs .dropdown .subs {
        position: relative;
        top: 0px;
    }

    .mainnav .hassubs .hassubs .dropdown {
        position: absolute;
        top: 0px;
        left: calc(100%);
        opacity: 0;
        visibility: hidden;
        transition: ease-out 0.35s;
        -moz-transition: ease-out 0.35s;
        -webkit-transition: ease-out 0.35s;
    }
</style>
