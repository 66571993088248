<template>
    <div class="container">
        <!-- Filters -->
        <div class="row">
            <div class="col-12 col-lg-2 skeleton">
                <div class="skeleton-item mt-2 py-3"></div>
            </div>
            <div class="col-6 col-lg-3 skeleton">
                <div class="skeleton-item mt-2 py-3"></div>
            </div>
            <div class="col-6 col-lg-2 skeleton">
                <div class="skeleton-item mt-2 py-3"></div>
            </div>
            <div class="col-6 col-lg-2 offset-3 offset-lg-0 skeleton">
                <div class="skeleton-item mt-2 py-3"></div>
            </div>
            <div class="col-6 col-lg-3 offset-3 offset-lg-0 skeleton">
                <div class="skeleton-item mt-2 py-3"></div>
            </div>
        </div>
        <!-- Promotions -->
        <div class="row mt-3">
            <div v-for="index in 3" :key="index" class="col-12 col-lg-4 mb-3">
                <card-standard-skeleton button-classes="px-3 pb-3" class="h-100" :enable-badges="true" />
            </div>
        </div>
    </div>
</template>

<script>
    import CardStandardSkeleton from '../cards/CardStandardSkeleton';

    export default {
        name: 'PromotionsSkeleton',
        components: {
            CardStandardSkeleton
        }
    };
</script>
