﻿<template>
    <div class="px-3 py-2 machine-detail-content text-center w-100">
        <h1>Specials</h1>
        <!-- if no specials, show specials sign-up form -->
        <div v-show="offersLoaded && !offers.length">
            <h6 class="text-center">There are no specials currently on this item</h6>
            <div id="special-form-wrapper" class="text-left card p-3"></div>
        </div>

        <span v-if="!offersLoaded" class="fas fa-spinner fa-spin"></span>

        <splide v-else :options="sliderOptions" class="px-0">
            <splide-slide v-for="(offer, i) in offers" :key="i">
                <div class="specials-card card-body p-0" :class="$_isLightTheme ? 'bg-white' : 'bg-dark'">
                    <div
                        v-if="offer.Image"
                        class="specials-image-container"
                        :style="offerImageStyle(offer.Image)"
                    ></div>
                    <h5 class="card-title" v-html="offer.Title"></h5>
                    <div class="subtitle" v-html="offer.Subtitle"></div>
                    <a data-target="#detailModal" data-toggle="modal" @click="showDetail(offer)">
                        <div class="mt-3 card-footer text-center showroom-bg-color-primary">View Details</div>
                    </a>
                </div>
            </splide-slide>
        </splide>
        <div id="detailModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="exampleModalLabel" class="modal-title">Terms and Conditions</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-left pt-0">
                        <div class="summary" v-html="selectedOffer.Summary"></div>
                        <div class="description" v-html="selectedOffer.Description"></div>
                        <div class="disclaimer" v-html="selectedOffer.Disclaimer"></div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn showroom-bg-color-primary" data-dismiss="modal">
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { optimizeImage } from '../../lib/utilities';

    const SIGNUP_FORM_SELECTOR = '.special-offer-form';

    export default {
        name: 'EquipmentDetailSpecials',
        beforeRouteLeave(to, from, next) {
            $(SIGNUP_FORM_SELECTOR).addClass('d-none').detach().appendTo('body');
            next();
        },
        props: {
            machine: {
                type: Object,
                required: true
            },
            manufacturer: {
                type: String,
                default: ''
            },
            offers: {
                type: Array,
                default: () => []
            },
            offersLoaded: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selectedOffer: {},
                sliderOptions: {
                    autoplay: false,
                    arrows: false,
                    breakpoints: {
                        576: {
                            height: '300px'
                        },
                        991: {
                            height: '400px'
                        }
                    },
                    gap: '1rem',
                    height: '500px',
                    pagination: false,
                    rewind: true,
                    speed: 1000
                }
            };
        },
        watch: {
            machine: function () {
                this.populateSignupForm();
            }
        },
        mounted() {
            $(document).ready(() => {
                $(SIGNUP_FORM_SELECTOR).detach().appendTo('#special-form-wrapper').removeClass('d-none');
            });

            this.populateSignupForm();
        },
        methods: {
            showDetail(offer) {
                this.selectedOffer = offer;
            },
            offerImageStyle(imgUrl) {
                // don't use the image proxy locally
                if (window.location.hostname !== 'localhost') {
                    imgUrl = optimizeImage(imgUrl, 360);
                }

                return `background-image: url("${imgUrl}")`;
            },
            populateSignupForm() {
                let interestedEqField = document.querySelector(
                    `${SIGNUP_FORM_SELECTOR} .interested-eq input[class='form-control']`
                );

                if (interestedEqField) {
                    let interestedMachine = this.machine.Title;

                    if (this.manufacturer) {
                        interestedMachine = `${this.manufacturer.capitalize()} ${interestedMachine}`;
                    }

                    interestedEqField.setAttribute('value', interestedMachine);
                }
            }
        }
    };
</script>
