﻿<template>
    <div class="card card-instagram">
        <div class="card-body p-0">
            <a :href="screenNameLink" class="card-title row mx-0 mb-0 p-2" target="_blank">
                <span class="pr-2 d-flex align-items-center">
                    <img class="avatar"
                         :src="profilePicture"
                         :alt="screenName">
                </span>
                <span class="col d-flex align-items-center px-0 text-dark">
                    <span class="d-block font-weight-bold">{{ screenName }}</span>
                </span>
                <span class="px-0 d-sm-none d-md-flex align-items-center">
                    <span class="cta btn btn-primary px-1 py-1 font-weight-bold w-100"> View Profile </span>
                </span>
            </a>

            <img :src="image"
                 :alt="screenName"
                 class="object-fit-cover mb-2" />

            <a
                class="d-block mx-2 pb-2 border-bottom font-weight-bold"
                :href="`https://www.instagram.com/${screenName}/?utm_source=ig_embed`"
                target="_blank"
                >View More on Instagram</a
            >

            <div class="feedback px-2 pt-2">
                <i class="ig-like d-inline-block px-2"></i>
                <i class="ig-comment d-inline-block px-2"></i>
                <i class="ig-share d-inline-block px-2"></i>
                <i class="ig-bookmark d-inline-block px-2 float-right"></i>
            </div>

            <div class="social-proof px-2">
                <a :href="permaLink" class="d-block text-dark font-weight-bold" target="_blank">
                    {{ likeCount }} likes
                </a>
                <a :href="screenNameLink" class="text-dark font-weight-bold" target="_blank">
                    {{ screenName }}
                </a>
            </div>

            <div class="card-text px-2 py-3">
                <div v-html="fullText"></div>
                <div class="date-created pt-3">{{ createdAt }}</div>
            </div>

            <a :href="permaLink" class="card-footer row p-2 mx-0 border-top" target="_blank">
                <span class="add-comment col px-0"> Add a comment... </span>
                <span class="col-2 p-0 text-right">
                    <i class="instagram-icon fab fa-instagram"></i>
                </span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardInstagram',
        props: {
            permaLink: String,
            profilePicture: String,
            screenName: String,
            screenNameLink: String,
            createdAt: String,
            fullText: String,
            likeCount: Number,
            media: Array
        },
        computed: {
            image() {
                if (!!this.media && !!this.media[0]) {
                    return this.media[0].ImageUrl;
                }
                return null;
            }
        }
    };
</script>
