<template>
    <div ref="wrapper" class="location-store-detail">
        <div v-if="storeLocation && storeLocation.Id" class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-12 location-info pt-5">
                    <h6 v-if="$_selectedLocation" class="mb-3">
                        <span
                            v-if="storeLocation.Id == $_selectedLocation.Id"
                            class="underline text-primary underline"
                        >Your Location</span>
                    </h6>
                    <div class="mb-md-3 mt-4 mt-lg-5">
                        <h3>{{ storeLocation.DealerLocationTitle }}</h3>
                        <h5 v-if="locationName(storeLocation) != storeLocation.DealerLocationTitle">
                            {{ locationName(storeLocation) }}
                        </h5>
                        <span v-html="locationFullAddress(storeLocation)"></span>
                        <br />
                        <div class="my-3 btn-group-vertical">
                            <a
                                :href="directionsLink(storeLocation)"
                                target="_blank"
                                class="btn btn-primary rounded text-uppercase mb-2 google-maps-directions"
                            >Get Directions</a>
                            <a
                                v-if="storeHasEquipment"
                                :href="equipmentUrl"
                                class="btn btn-primary rounded text-uppercase mb-2"
                            >View Equipment</a>
                            <a
                                v-if="storeLocation.DisplayWebsiteLink && storeLocation.Website"
                                :href="storeLocation.Website"
                                class="btn btn-primary rounded text-uppercase mb-2"
                                target="_blank"
                            >{{ storeLocation.ViewSiteCustomLabel || 'View Link' }}</a>
                            <button
                                v-if="
                                    !storeLocation.HideLocationHandler &&
                                        (!$_selectedLocation || storeLocation.Id != $_selectedLocation.Id)
                                "
                                class="btn btn-primary rounded text-uppercase"
                                @click="changeLocation(storeLocation)"
                            >Set as Location</button>
                        </div>

                        <div class="mb-4">
                            <h5 class="font-weight-bold">Phone</h5>
                            <a class="text" :href="telLink(storeLocation.Phone)">{{ storeLocation.Phone }}</a>
                        </div>

                        <div v-if="storeLocation.AfterHours" class="mb-4">
                            <h5 class="font-weight-bold">After Hours Phone Numbers</h5>
                            <div v-for="(phone, i) in storeLocation.AfterHours" :key="i">
                                <h6 class="mb-0">{{ phone.title }}</h6>
                                <a class="text d-block" :href="telLink(phone.phoneNumber)">
                                    {{ phone.phoneNumber }}
                                </a>
                            </div>
                        </div>

                        <div v-if="storeLocation.Fax" class="mb-4">
                            <h5 class="font-weight-bold">Fax</h5>
                            <a class="text" :href="telLink(storeLocation.Fax)">{{ storeLocation.Fax }}</a>
                        </div>

                        <div v-if="storeLocation.Email" class="mb-4">
                            <h5 class="font-weight-bold">Email</h5>
                            <a class="text font-weight-bold" :href="emailLink(storeLocation)">
                                {{ storeLocation.Email }}
                            </a>
                        </div>

                        <div class="mb-4">
                            <h5 class="mb-3">
                                Operating Hours | {{ timezoneAbbreviation(storeLocation.Timezone) }}
                                <span
                                    v-if="checkOpenNow(storeLocation)"
                                    class="location-open-closed text-primary"
                                >Open Now</span>
                                <span v-else class="location-open-closed text-danger">Closed</span>
                            </h5>

                            <div
                                v-for="(day, index) in getOpenHours(storeLocation)"
                                :key="index"
                                :class="currentDay(index)"
                                class="row mb-3"
                            >
                                <div class="col-3 text text-left">{{ day.day }}</div>
                                <div class="col-9 text text-right">{{ showHours(day) }}</div>
                            </div>
                        </div>
                        <div class="share">
                            <a
                                v-if="!storeLocation.Facebook"
                                class="btn btn-lg rounded-0 horizontal-links text-facebook"
                                :href="elementConfig.FACEBOOK"
                            >
                                <i class="fab fa-facebook-f fa-2x"></i>
                                <span class="pt-2">Facebook</span>
                            </a>
                            <a
                                v-else
                                class="btn btn-lg rounded-0 horizontal-links text-facebook"
                                :href="storeLocation.Facebook"
                            >
                                <i class="fab fa-facebook-f fa-2x"></i>
                                <span class="pt-2">Facebook</span>
                            </a>
                            <a
                                v-if="!storeLocation.Twitter"
                                class="btn btn-lg rounded-0 horizontal-links text-twitter"
                                :href="elementConfig.TWITTER"
                            >
                                <i class="fab fa-twitter fa-2x"></i>
                                <span class="pt-2">Twitter</span>
                            </a>
                            <a
                                v-else
                                class="btn btn-lg rounded-0 horizontal-links text-twitter"
                                :href="storeLocation.Twitter"
                            >
                                <i class="fab fa-twitter fa-2x"></i>
                                <span class="pt-2">Twitter</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-sm-12 featured-img-box px-3 py-0">
                    <div class="mb-3">
                        <div v-if="storeLocation.Image && storeLocation.Image.length > 0">
                            <img
                                :src="storeLocation.Image[storeLocation.Image.length - 1].Url"
                                style="border: 15px solid white"
                                class="mb-3 w-100"
                                :alt="locationName(store)"
                            />
                        </div>
                        <div v-else>
                            <div ref="storeLocationMap" class="map mb-3"></div>
                        </div>
                        <p v-html="storeLocation.DealerLocationDescription"></p>
                    </div>

                    <div v-if="employeeList.length > 0" class="mb-3">
                        <h4 class="font-weight-bold mt-5 mb-3">{{ locationName(storeLocation) }} Staff</h4>
                        <div v-if="employeeList" class="row">
                            <div
                                v-for="(employee, i) in employeeList"
                                :key="i"
                                class="col-lg-4 col-md-6 col-sm-12 mb-4"
                            >
                                <card-standard
                                    class="h-100"
                                    :enable-image="true"
                                    :image="getEmployeeImage(employee)"
                                    :title="employee.FullName"
                                    :subtitle="employee.Position"
                                    :title-size="5"
                                    :limit-image-height="true"
                                >
                                    <a
                                        v-if="employee.ContactEmail"
                                        class="d-block employee-email mb-1"
                                        :href="'mailto:' + employee.ContactEmail"
                                    >
                                        <i class="fas fa-envelope mr-2"></i>
                                        {{ employee.ContactEmail }}
                                    </a>
                                    <a
                                        v-if="employee.ContactPhone"
                                        class="d-block"
                                        :href="'tel:' + employee.ContactPhone"
                                    >
                                        <i class="fas fa-phone mr-2"></i>
                                        {{ employee.ContactPhone }}
                                    </a>
                                </card-standard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import constants from '../../config/constants';

    import CardStandard from '../cards/CardStandard';
    import DealerSettingService from '../../../services/DealerSettingService';
    import ElementLocationService from '../../../services/LocationService';
    import ElementEmployeeService from '../../../services/EmployeeService';
    import LocationMixin from '../mixins/LocationMixin';
    import StoreConstants from '../../store/constants';
    import Utilities from '../mixins/UtilitiesMixin';
    import UsedEquipmentService from '../../../services/UsedEquipmentService';

    export default {
        name: 'LocationStoreDetail',
        components: {
            CardStandard
        },
        mixins: [LocationMixin, Utilities],
        props: {
            backgroundImage: {
                type: String,
                required: true
            },
            slug: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                employees: [],
                elementConfig: constants.elementConfig,
                staffOrder: [],
                STAFF_ORDER_DEALER_SETTING_KEY: 'LocationStaffOrder',
                storeLocation: undefined,
                storeHasEquipment: false
            };
        },
        computed: {
            equipmentUrl() {
                return `/search/?filter-type=Used&location=${this.storeLocation.DealerLocationTitle}`;
            },
            employeeList() {
                return this.employees
                    .map(employee => {
                        const i = this.staffOrder.indexOf(employee.Position);
                        return { ...employee, index: i >= 0 ? i : 10000000000 };
                    })
                    .sort((a, b) => a.index - b.index || a.FullName.localeCompare(b.FullName));
            }
        },
        watch: {
            storeLocation: function() {
                // wait to init the map because it's placeholder div won't be loaded into the DOM
                // until after the next tick if the storeLocation was previously undefined
                this.$nextTick(() => {
                    if (this.$refs.storeLocationMap) {
                        this.initMap(this.$refs.storeLocationMap, [this.storeLocation]);
                    }
                });
            }
        },
        created() {
            this.getStaffOrder();
            this.getLocation();
        },
        mounted() {
            // this goes in the mounted hook to allow the DOM to load first
            this.setBackground(this.$refs.wrapper, this.backgroundImage);
        },
        methods: {
            changeLocation(location) {
                this.$store.dispatch(StoreConstants.LOCATION.ACTIONS.SET_LOCATION_ID, location.Id);
            },
            getEmployeeImage(employee) {
                return {
                    src: employee.EmployeeImage && employee.EmployeeImage.Url || constants.employeePlaceholderImage,
                    alt: employee.EmployeeImage && employee.EmployeeImage.AlternativeText
                };
            },
            getLocation() {
                ElementLocationService.getLocationBySlug(this.slug)
                    .then(result => {
                        this.storeLocation = result;
                        UsedEquipmentService.GetMachineCountByLocation(this.storeLocation.Id).then(response => {
                            this.storeHasEquipment = response > 0;
                        });
                        this.getEmployees();
                    })
                    .catch(() => {
                        window.location.replace(constants.errorPage404);
                    });
            },
            getStaffOrder() {
                DealerSettingService.getSetting(this.STAFF_ORDER_DEALER_SETTING_KEY).then(response => {
                    this.staffOrder = response.Value?.split(',').map(position => position.trim()) ?? [];
                });
            },
            getEmployees() {
                ElementEmployeeService.getEmployees({ locationId: this.storeLocation.Id }).then(result => {
                    this.employees = result || [];
                });
            }
        }
    };
</script>
