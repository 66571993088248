﻿<template>
    <div class="hero-search bgcolor">
        <div class="hero-search-bg w-100" :style="background"></div>
        <div class="hero-search-bg w-100 overlay"></div>
        <div class="hero-search-fg w-100">
            <div class="container">
                <div class="header">
                    <h1>{{ headerText }}</h1>
                    <p>{{ subText }}</p>
                </div>
                <div class="search w-100">
                    <search-bar
                        :placeholder="placeholderText"
                        :enable-mobile-view="true"
                        :css-class="'search-bar-hero-with-search'"
                    />
                </div>
                <div class="buttons">
                    <a
                        v-for="button in buttons.filter(btn => btn.Text)"
                        :key="button.Text"
                        :href="button.Link"
                    >
                        <span class="innerText">{{ button.Text }}</span>
                    </a>
                </div>
                <splide class="pt-3" :class="{ 'px-0': cards.length <= 3 }" :options="sliderOptions">
                    <splide-slide v-for="(card, i) in cards" :key="i">
                        <card-flippable :card="card" />
                    </splide-slide>
                </splide>
            </div>
        </div>
    </div>
</template>

<script>
    import CardFlippable from '../cards/CardFlippable';
    import SearchBar from '../search/SearchBar';

    export default {
        name: 'HeroSearch',
        components: {
            CardFlippable,
            SearchBar
        },
        props: {
            backgroundImage: {
                type: String,
                default: ''
            },
            buttons: {
                type: Array,
                default: () => []
            },
            cards: {
                type: Array,
                default: () => []
            },
            headerText: {
                type: String,
                default: ''
            },
            subText: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                windowWidth: 0
            };
        },
        computed: {
            background() {
                return this.backgroundImage ? `background-image: url(${this.backgroundImage});` : '';
            },
            placeholderText() {
                return this.windowWidth <= 768
                    ? 'Tap Here and Search'
                    : 'Looking for something specific? Start typing here!';
            },
            sliderOptions() {
                return {
                    arrows: this.cards.length > 3,
                    autoplay: true,
                    breakpoints: {
                        767: {
                            pagination: false,
                            perPage: 1
                        }
                    },
                    gap: '1rem',
                    pagination: this.cards.length > 3,
                    perPage: Math.min(3, this.cards.length),
                    rewind: true,
                    speed: 1000
                };
            }
        },
        mounted() {
            this.$nextTick(function() {
                this.getWindowWidth();
            });
        },
        methods: {
            getWindowWidth() {
                this.windowWidth = document.documentElement.clientWidth;
            }
        }
    };
</script>
