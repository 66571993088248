﻿<template>
    <div>
        <button type="button" class="btn btn-primary btn-sticky-left" v-on:click="modal.open = true">
            OFFERS
        </button>
        <div class="modal-backdrop fade show" v-if="modal.open"></div>
        <div class="modal newsletter-modal fade left show" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document" v-bind:class="{ show: modal.open }">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="row">
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                v-on:click="closeModal()"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="offset-md-1 col-10">
                                <h5 class="text-center">
                                    {{ uiTags.HeaderText || 'Stay up to date with the latest...' }}
                                </h5>
                                <br />
                            </div>
                        </div>
                        <form v-on:submit.prevent="subscribe">
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-center">
                                        <strong>{{
                                            uiTags.ContactInfoHeaderText || 'Your contact info'
                                        }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <small>{{ uiTags.FirstNamePlaceHolderText || 'First Name' }}</small>
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="subscriber.FirstName"
                                        />
                                        <p class="m-0 text-center">
                                            <small class="text-danger" v-show="error.firstname">{{
                                                error.firstname
                                            }}</small>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <small>{{ uiTags.LastNamePlaceHolderText || 'Last Name' }}</small>
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="subscriber.LastName"
                                        />
                                        <p class="m-0 text-center">
                                            <small class="text-danger" v-show="error.lastname">{{
                                                error.lastname
                                            }}</small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <small>{{ uiTags.EmailAddressPlaceHolderText || 'Email' }}</small>
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="subscriber.EmailAddress"
                                        />
                                        <p class="m-0 text-center">
                                            <small class="text-danger" v-show="error.email">{{
                                                error.email
                                            }}</small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-center">
                                        <strong>{{
                                            uiTags.PreferencesHeaderText || 'Your email preferences'
                                        }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div
                                        v-for="(mailingList, i) in mailingListCollection"
                                        class="row"
                                        :key="i"
                                    >
                                        <div class="col-12">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    v-model="mailingList.OptIn"
                                                    :id="mailingList.Id"
                                                    v-on:change="validate()"
                                                />
                                                <label class="form-check-label" :for="mailingList.Id">
                                                    <span
                                                        ><small>{{ mailingList.Name }}</small></span
                                                    >
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="m-0 text-center">
                                                <small class="text-danger" v-show="error.mailingList">{{
                                                    error.mailingList
                                                }}</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="row"
                                v-show="
                                    modal.saveStatus === saveStatusOptions.NONE ||
                                        modal.saveStatus === saveStatusOptions.SAVING
                                "
                            >
                                <div class="col-12">
                                    <hr />
                                    <p class="text-center">
                                        <small>{{
                                            uiTags.DisclosureText ||
                                                'By subscribing to these list, you agree to our Privacy Police and Terms of Use'
                                        }}</small>
                                    </p>
                                </div>
                                <div class="col-12">
                                    <div class="text-center">
                                        <button
                                            type="submit"
                                            class="btn btn-primary"
                                            :disabled="modal.saveStatus === saveStatusOptions.SAVING"
                                        >
                                            {{ uiTags.SubmitButtonText || 'SUBSCRIBE' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-show="modal.saveStatus === saveStatusOptions.SUCCESS">
                                <div class="col-12">
                                    <hr />
                                    <div class="alert alert-success mb-0" role="alert">
                                        <p class="text-center mb-0">
                                            {{
                                                uiTags.SaveSuccessText ||
                                                    'Your communication preferences had been saved!'
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-show="modal.saveStatus === saveStatusOptions.FAILED">
                                <div class="col-12">
                                    <hr />
                                    <div class="alert alert-danger mb-0" role="alert">
                                        <p class="text-center mb-0">
                                            {{ uiTags.SaveFailedText || 'Sorry, something went wrong' }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import constants from '../../config/constants';

    const saveStatusOptions = constants.saveStatusOptions;
    export default {
        data() {
            return {
                error: {},
                subscriber: {
                    EmailAddress: ''
                },
                modal: {
                    open: false,
                    saveStatus: saveStatusOptions.NONE
                },
                saveStatusOptions: saveStatusOptions
            };
        },
        props: ['mailingListCollection', 'uiTags'],
        methods: {
            validate() {
                var emailValid = false;
                var mailingListValid = false;
                var firstNameValid = false;
                var lastnameValid = false;

                this.error = {};

                firstNameValid = this.subscriber.FirstName && this.subscriber.FirstName !== '';
                if (!firstNameValid) {
                    this.error.firstname = this.uiTags.NoFirstNameValidationMsg || 'Enter your name';
                }

                lastnameValid = this.subscriber.LastName && this.subscriber.LastName !== '';
                if (!lastnameValid) {
                    this.error.lastname = this.uiTags.NoLastNameValidationMsg || 'Enter your lastname';
                }

                emailValid = this.subscriber.EmailAddress && this.subscriber.EmailAddress !== '';
                if (!emailValid) {
                    this.error.email = this.uiTags.NoEmailAddressValidationMsg || 'Enter an email to subscribre';
                }

                mailingListValid = this.mailingListCollection.find(el => el.OptIn === true);
                if (!mailingListValid) {
                    this.error.mailingList =
                        this.uiTags.NoMailingListSelectedValidationMsg ||
                        'Select at least one email communication from the list';
                }

                return firstNameValid && lastnameValid && emailValid && mailingListValid;
            },
            subscribe() {
                var isValid = this.validate();
                if (!isValid) return;

                let data = {
                    EmailAddress: this.subscriber.EmailAddress,
                    FirstName: this.subscriber.FirstName,
                    LastName: this.subscriber.LastName,
                    MailingListCollection: this.mailingListCollection
                };

                this.modal.saveStatus = saveStatusOptions.SAVING;
                axios
                    .post('/api/NewsletterSubscription/Subscribe', data)
                    .then(response => (this.modal.saveStatus = saveStatusOptions.SUCCESS))
                    .catch(error => (this.modal.saveStatus = saveStatusOptions.FAILED));
            },
            closeModal() {
                this.error = {};
                this.subscriber.EmailAddress = '';
                this.modal.open = false;
                this.modal.saveStatus = this.saveStatusOptions.NONE;
                this.mailingListCollection.forEach((item, i) => (item.OptIn = false));
            }
        }
    };
</script>