<template>
    <div :class="backgroundClass" class="promotion-locations row p-4">
        <!-- Header -->
        <div class="col-12 col-md-6 d-flex align-items-center order-md-1">
            <h4 class="text-primary text-uppercase mb-3">Nearest store</h4>
        </div>

        <!-- Selected location -->
        <div v-if="$_selectedLocation" class="col-12 col-md-4 order-md-3 mb-3 mb-md-0">
            <card-standard
                action-name="View Details"
                :action-url="locationUrl"
                btn-style="btn-primary text-center"
                class="card-promotion h-100"
                :enable-button-link="true"
                :ribbon-title-text="$_isSelectedLocationOpen ? 'OPEN NOW' : ''"
                :title="$_selectedLocation.DealerLocationTitle"
                :use-block-btn="true"
            >
                <div class="text-muted" v-html="locationFullAddress($_selectedLocation)"></div>
                <div v-if="$_selectedLocation.Phone" class="display-6 font-weight-bold pt-3 text-primary">
                    {{ $_selectedLocation.Phone }}
                </div>
            </card-standard>
        </div>

        <!-- Google Map -->
        <div class="col-12 order-md-4" :class="$_selectedLocation && 'col-md-8'">
            <div ref="googleMap" class="map h-100"></div>
        </div>

        <!-- Locations list -->
        <div v-if="!$_selectedLocation" class="col-12 pt-3 order-md-5">
            <div class="row">
                <div v-for="location in locations" :key="location.Id" class="col-12 col-md-6 col-lg-4 mb-4">
                    <card-standard
                        action-name="View Details"
                        :action-url="`${allLocationsUrl}${location.UrlName}`"
                        btn-style="btn-primary text-center"
                        class="card-promotion h-100"
                        :enable-button-link="true"
                        :title="location.DealerLocationTitle"
                        :use-block-btn="true"
                    >
                        <div class="text-muted" v-html="locationFullAddress(location)"></div>
                        <div v-if="location.Phone" class="display-6 font-weight-bold pt-3 text-primary">
                            {{ location.Phone }}
                        </div>
                    </card-standard>
                </div>
            </div>
        </div>

        <!-- Location buttons -->
        <div
            class="col-12 col-md-6 d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-lg-end mt-4 mb-md-4 mt-md-0 order-md-2"
        >
            <a :href="allLocationsUrl" class="d-block mr-0 mr-md-2 order-md-2">
                <div class="btn btn-primary text-center px-5 px-md-4 px-lg-5">
                    View All Locations
                </div>
            </a>
            <a class="d-block mt-2 mt-md-0 mr-md-3 order-md-1" @click="detectLocation()">
                <div class="btn btn-gray-200 px-5 px-md-4 px-lg-5">
                    <span>Detect Location</span>
                    <span class="fas fa-crosshairs"></span>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
    // Utilities
    import constants from '../../config/constants';
    import locationService from '../../../services/LocationService.js';

    // Components
    import CardStandard from '../cards/CardStandard';
    import LocationMixin from '../mixins/LocationMixin';
    import StoreConstants from '../../store/constants';

    export default {
        name: 'PromotionLocations',
        components: {
            CardStandard
        },
        mixins: [LocationMixin],
        props: {
            backgroundClass: {
                type: String,
                default: 'bg-gray-300'
            }
        },
        data() {
            return {
                locations: []
            };
        },
        computed: {
            allLocationsUrl() {
                return `${constants.elementConfig.SITEMAP.LOCATIONS}/`;
            },
            locationUrl() {
                return this.$_selectedLocation ? `${this.allLocationsUrl}${this.$_selectedLocation.UrlName}` : '';
            }
        },
        watch: {
            $_selectedLocation: {
                handler: function (newVal) {
                    // next tick is necessary to give the map div time to load into the DOM if selectedLocation was previously undefined (or vise-versa)
                    this.$nextTick(() => {
                        if (newVal) {
                            // init map for the selected location
                            this.initMap(this.$refs.googleMap, [this.$_selectedLocation]);
                        } else if (this.locations.length > 0) {
                            // reload the locations map if user removed their selected store
                            this.initMap(this.$refs.googleMap, this.locations);
                        }
                    });
                },
                immediate: true
            }
        },
        mounted() {
            this.getLocations();
        },
        methods: {
            detectLocation() {
                locationService
                    .getClosestLocation()
                    .then(location => {
                        this.$store.dispatch(StoreConstants.LOCATION.ACTIONS.SET_LOCATION_ID, location.Id);
                    })
                    .catch(reason => {
                        console.log(reason);
                    });
            },
            getLocations() {
                locationService.getLocations(undefined, undefined, undefined, 3).then(result => {
                    this.locations = result.locations;

                    if (!this.$_selectedLocation) {
                        this.$nextTick(() => {
                            this.initMap(this.$refs.googleMap, this.locations);
                        });
                    }
                });
            }
        }
    };
</script>
