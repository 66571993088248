﻿<template>
    <div class="px-3 py-2 machine-detail-content">
        <h1 class="text-center">Models</h1>
        <div class="text-left machine-features">
            <div v-for="(model, i) in machine.Machines" :key="i">
                <h3>{{ model.Name }}</h3>
                <table
                    v-if="model.Specifications"
                    class="mb-3 table table-striped"
                    v-html="model.Specifications.trim()"
                ></table>
                <div v-else-if="model.Summary" class="mb-3" v-html="model.Summary.trim()"></div>
            </div>
        </div>
    </div>
</template>

<style>
</style>

<script>
    export default {
        name: 'EquipmentDetailModels',
        props: {
            machine: {
                type: Object
            }
        }
    };
</script>