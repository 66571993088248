﻿<template>
    <article v-if="post && post.Id" class="blog-card border d-flex flex-column justify-content-between mb-3">
        <div>
            <element-image
                img-class="img-fluid featured-border mh-100"
                container-class="blog-card-container d-flex justify-content-center align-items-center"
                :placeholder="logoUrl"
                :src="postImage"
                :alt="postAltText"
            ></element-image>
            <div class="p-4 p-sm-3">
                <h3 class="blog-title">{{ post.Title }}</h3>
                <div class="mb-1">
                    <i class="far fa-calendar-alt blog-icon"></i>
                    <small>Posted {{ getDate(post.PublicationDate) }}</small>
                </div>
                <p v-if="post.Summary.length > 145" class="mb-0">{{ post.Summary.substring(0, 145) }} ...</p>
                <p v-else class="mb-0">{{ post.Summary }}</p>
            </div>
        </div>
        <div class="card-footer">
            <router-link class="d-flex justify-content-between align-items-baseline" :to="routeData">
                <strong>Read Article</strong>
                <span class="blog-icon">></span>
            </router-link>
        </div>
    </article>
</template>

<script>
    import blogPostMixin from '../mixins/BlogPostMixin';

    export default {
        name: 'BlogPostItem',
        mixins: [blogPostMixin],
        computed: {
            routeData() {
                return {
                    name: 'details',
                    params: {
                        blog: this.post.Parent.UrlName,
                        year: this.year,
                        month: this.month,
                        day: this.day,
                        detail: this.post.UrlName,
                        post: this.post
                    }
                };
            }
        }
    };
</script>
