﻿<template>
    <ul>
        <li v-for="browLink in browLinks" :key="browLink.Url">
            <a v-if="browLink.Title" :href="browLink.Url" class="d-inline-block" :target="browLink.Target">
                <span>{{ browLink.Title }}</span>
            </a>
        </li>
        <li v-for="link in links" :key="link.href">
            <a :href="link.href" class="d-inline-block" target="_blank">
                <span v-if="link.showText && (typeof link.text) !== 'undefined'">{{ link.text }}</span>
                <span
                    v-if="link.showIcon && (typeof link.icon) !== 'undefined'"
                    :class="link.icon"
                    class="text-primary"
                ></span>
                <span v-if="!link.showText" class="sr-only">{{ link.text }}</span>
            </a>
        </li>
    </ul>
</template>

<script>
    import constants from '../../config/constants';
    import weatherService from '../../../services/WeatherService';

    export default {
        name: 'BrowLinks',
        props: {
            browLinks: {
                type: Array,
                default: () => []
            },
            socialBar: {
                type: Object,
                default: () => ({})
            },
            weatherPage: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                temperature: ''
            };
        },
        computed: {
            links: function() {
                var weatherLink = this.weatherPage ? this.weatherPage.FullUrl : '/';

                const links = [
                    {
                        href: weatherLink,
                        text: this.temperature,
                        showText: true,
                        showIcon: false
                    }
                ];

                const socialLinks = this.socialBar.SocialItems
                    ? this.socialBar.SocialItems.map(i => {
                        return {
                            href: i.Url,
                            icon: constants.socialItemsClass[i.Title],
                            text: i.Title,
                            showText: this.socialBar.ShowSocialText,
                            showIcon: this.socialBar.ShowSocialIcon
                        };
                    })
                    : [];

                return [...links, ...socialLinks];
            }
        },
        created() {
            this.setTemperature();
        },
        methods: {
            setTemperature() {
                let vm = this;
                weatherService.getTemperature().then(function(data) {
                    vm.temperature = Math.round(data.temp).toString() + '°';
                });
            }
        }
    };
</script>
