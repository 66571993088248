export const MACHINE_STORAGE_KEY = 'MACHINE_DETAILS';
export const SOCIAL_SHARING_DESCRIPTION = 'Look what I built!';
export const DISCOUNT_TYPES = {
    FIXED: 1,
    PERCENTAGE: 2
};
export const SELECTION_TYPES = {
    SINGLE: 1,
    MULTIPLE: 2
};
