﻿<template>
    <div class="px-3 py-2 machine-detail-content">
        <h1 class="text-center">Overview</h1>
        <div class="text-left machine-features" v-html="machine.Description || machine.Summary"></div>
    </div>
</template>

<style>
</style>

<script>
    export default {
        name: 'EquipmentDetailOverview',
        props: {
            machine: {
                type: Object
            }
        },
        data() {
            return {};
        },
        watch: {
            machine: function (newValue) {}
        }
    };
</script>