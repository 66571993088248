<template>
    <div class="row h-100">
        <div v-for="i in 6" :key="i" class="col-4 mb-3">
            <card-standard-skeleton class="h-100"></card-standard-skeleton>
        </div>
    </div>
</template>

<script>
    import CardStandardSkeleton from '../cards/CardStandardSkeleton';

    export default {
        name: 'RelatedSpecialOffersSkeleton',
        components: {
            CardStandardSkeleton
        }
    };
</script>
