﻿<template>
    <div>
        <build-your-own-navigation-bar></build-your-own-navigation-bar>

        <template v-if="loading">
            <div key="loading" class="container h-50 d-flex justify-content-center align-items-center">
                <div class="loading-spinner">
                    <span class="fas fa-spinner fa-spin"></span>
                </div>
            </div>
        </template>

        <template
            v-else-if="
                (collection && collection.length > 0) ||
                (machineCollection && machineCollection.length > 0)
            "
        >
            <template v-if="collection && collection.length > 0">
                <build-your-own-selection-grid
                    key="collecion-grid"
                    :collection="collection"
                ></build-your-own-selection-grid>
            </template>
            <template v-if="machineCollection && machineCollection.length > 0">
                <build-your-own-product-selection-grid
                    key="product-grid"
                    :machine-collection="machineCollection"
                ></build-your-own-product-selection-grid>
            </template>
        </template>

        <template v-else-if="collection.length === 0 && machineCollection.length === 0">
            <div
                key="collection-empty"
                class="no-options-message container d-flex justify-content-center align-items-center"
            >
                <h2>We do not currently offer customization for this machine</h2>
            </div>
        </template>
    </div>
</template>

<script>
    import BuildYourOwnSelectionGrid from './BuildYourOwnSelectionGrid';
    import BuildYourOwnNavigationBar from './BuildYourOwnNavigationBar';
    import BuildYourOwnProductSelectionGrid from './BuildYourOwnProductSelectionGrid';

    export default {
        components: {
            'build-your-own-selection-grid': BuildYourOwnSelectionGrid,
            'build-your-own-navigation-bar': BuildYourOwnNavigationBar,
            'build-your-own-product-selection-grid': BuildYourOwnProductSelectionGrid
        },
        props: {
            collection: {
                type: Array,
                default: () => []
            },
            machineCollection: {
                type: Array,
                default: () => []
            },
            loading: {
                type: Boolean
            }
        }
    };
</script>

<style scoped>
    .no-options-message {
        height: 20rem;
    }
</style>
