<template>
    <div class="ctp-editor container py-3">
        <h4>{{ pascalCaseToSentence(shortName) }}</h4>

        <div id="ctp-editor-grid"></div>
    </div>
</template>

<script>
    import axios from 'axios';

    const DATATYPES = {
        DECIMAL: 'Edm.Decimal',
        STRING: 'Edm.String'
    };

    export default {
        name: 'CTPEditor',
        props: {
            fullName: {
                type: String,
                required: true
            },
            pageSize: {
                type: Number,
                required: true
            },
            properties: {
                type: Array,
                required: true
            },
            shortName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                dataSource: null,
                endpoint: '',
                gridColumns: [
                    {
                        field: 'UrlName',
                        width: '10rem'
                    }
                ],
                schemaModelFields: {
                    Id: {
                        type: 'string'
                    },
                    UrlName: {
                        type: 'string',
                        editable: false
                    }
                }
            };
        },
        async mounted() {
            this.endpoint = await this.getCTPEndpoint(this.shortName);
            this.initializeSchemaModelFields();
            this.initializeDataSource();
            this.initializeColumns();
            this.initializeGrid();
        },
        methods: {
            // Async methods lose context
            async getCTPEndpoint(ctpShortName) {
                let response = await axios.get('/api/default');
                return response.data?.value.find(x => x.name == `${ctpShortName}s`.toLowerCase())?.url;
            },
            initializeSchemaModelFields() {
                for (let prop of this.properties) {
                    this.schemaModelFields[prop.Name] = {
                        type: prop.Type == DATATYPES.DECIMAL ? 'number' : 'string',
                        validation: {
                            required: prop.IsRequired || prop.Name == 'Title' || prop.Name == 'Name'
                        }
                    };

                    if (prop.Type == DATATYPES.DECIMAL) {
                        this.schemaModelFields[prop.Name].validation.min = 0;
                    }

                    if (prop.Type == DATATYPES.STRING) {
                        this.schemaModelFields[prop.Name].validation.maxLength = prop.MaxValue;
                    }
                }
            },
            initializeDataSource() {
                this.dataSource = new kendo.data.DataSource({
                    transport: {
                        read: {
                            url: `/api/default/${this.endpoint}`,
                            datatype: 'json'
                        },
                        update: {
                            url: '/api/CTPEditor/UpdateRecord',
                            data: {
                                ContentTypeFullName: this.fullName,
                                PropertiesToUpdate: this.properties.map(x => x.Name)
                            },
                            datatype: 'json',
                            contentType: 'application/json',
                            type: 'POST'
                        },
                        parameterMap: function(data, type) {
                            if (type == 'read') {
                                let parameters = {
                                    $top: data.take,
                                    $skip: data.skip,
                                    $count: true
                                };

                                if (data.sort?.length > 0) {
                                    parameters.$orderby = data.sort.map(x => `${x.field} ${x.dir}`).join(', ');
                                }

                                return parameters;
                            }
                            if (type == 'update') {
                                return kendo.stringify(data);
                            }
                        }
                    },
                    schema: {
                        model: {
                            id: 'Id',
                            fields: this.schemaModelFields
                        },
                        data: 'value',
                        total: response => {
                            return response['@odata.count'];
                        }
                    },
                    error: e => {
                        console.error(e);
                    },
                    pageSize: this.pageSize,
                    serverFiltering: true,
                    serverPaging: true,
                    serverSorting: true,
                    requestEnd: function(e) {
                        if (e.type === 'update') {
                            // reload from server after update to get server data instead of text from user input
                            this.read();
                        }
                    }
                });
            },
            initializeColumns() {
                for (let prop of this.properties) {
                    let column = {
                        field: prop.Name,
                        title: this.pascalCaseToSentence(prop.Name)
                    };

                    if (prop.Type == DATATYPES.DECIMAL) {
                        column.format = `{0:n${prop.DecimalPlaces}}`;
                    }

                    this.gridColumns.push(column);
                }

                this.gridColumns.push({
                    command: ['edit'],
                    title: '&nbsp;',
                    width: '100px'
                });
            },
            initializeGrid() {
                $('#ctp-editor-grid').kendoGrid({
                    dataSource: this.dataSource,
                    editable: 'inline',
                    pageable: true,
                    sortable: true,
                    filterable: {
                        operators: {
                            string: {
                                eq: 'Is equal to',
                                contains: 'Contains'
                            },
                            number: {
                                eq: 'Is equal to',
                                gt: 'Is greater than',
                                lt: 'Is less than'
                            }
                        },
                        extra: false
                    },
                    columns: this.gridColumns
                });
            },
            pascalCaseToSentence(text) {
                return text
                    .replace(/([A-Z])/g, ' $1')
                    .replace(' I H ', ' IH ')
                    .replace(' C E ', ' CE ');
            }
        }
    };
</script>
