<template>
    <div class="bg-gray-100">
        <div class="container row pb-3 pt-5 mx-auto">
            <div class="col-12 col-md-6">
                <payment-calculator />
            </div>
            <div class="col-12 col-md-6 border-left">
                <affordability-calculator />
            </div>

            <small class="px-5 text-center text-muted">
                Non-numeric values will cause errors. *= Required Enter only numeric values (no commas), using
                decimal points where needed. This pricing estimate is for research purposes only. The pricing
                above is not a price quote. Actual prices and payments may vary. See your local dealer for
                information and details regarding John Deere Financial and other financing programs.
            </small>
        </div>
    </div>
</template>

<script>
    import AffordabilityCalculator from './AffordabilityCalculator';
    import PaymentCalculator from './PaymentCalculator';

    export default {
        name: 'FinanceCalculator',
        components: {
            AffordabilityCalculator,
            PaymentCalculator
        }
    };
</script>
