﻿<template>
    <card-horizontal
        :badges="badges"
        :image="previewImage"
        :title="item.Title"
        :url="url"
        :use-router="false"
        :enable-shadow="true"
    >
        <p v-html="item.Summary"></p>
    </card-horizontal>
</template>

<script>
    import constants from '../../config/constants';

    import ContentLocatorService from '../../../services/ContentLocatorService';
    import SearchResultMixin from '../mixins/SearchResultMixin';

    export default {
        name: 'SitewideSearchResultNewEq',
        mixins: [SearchResultMixin],
        props: {
            item: {
                type: Object,
                required: true
            },
            badges: {
                default: () => ['New'],
                type: Array
            }
        },
        computed: {
            previewImage() {
                if (this.item.ResultImages) {
                    const images = this.item.ResultImages.split(',');
                    if (images && images.length > 0 && images[0].length > 0) {
                        return images[0];
                    }
                }

                return constants.equipmentPlaceholderImage;
            },
            url() {
                return ContentLocatorService.getUrl(this.item);
            }
        }
    };
</script>
