﻿<template>
    <div class="grid-wrapper">
        <div class="d-sm-block d-lg-none">
            <div class="d-flex mobile-filter">
                <div v-if="!showFilters" class="input-group">
                    <input
                        v-model="filterValue"
                        class="border-0 form-control px-3 py-4"
                        placeholder="Filter"
                    />
                    <span class="input-group-text">
                        <i class="fas fa-search align-self-center"></i>
                    </span>
                    <span class="align-self-center" @click="openFilters">
                        <i class="fas fa-filter float-right px-4"></i>
                    </span>
                </div>

                <div v-else class="title-bar col-12 p-3">
                    <div class="show col-3 align-self-center p-0">
                        <ul>
                            <li>
                                <a @click="mode = 'large'">
                                    <span
                                        :class="mode === 'large' ? 'showroom-color-primary' : ''"
                                        class="fas fa-th-large grid-icon px-1"
                                    ></span>
                                </a>
                            </li>
                            <li>
                                <a @click="mode = 'med'">
                                    <span
                                        :class="mode === 'med' ? 'showroom-color-primary' : ''"
                                        class="fas fa-th grid-icon px-1"
                                    ></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-8 align-self-center form-inline">
                        <label class="m-0 p-2">
                            <small>Sort:</small>
                        </label>
                        <select v-model="order" class="form-control form-control-sm w-75 p-2">
                            <option value="name">Model Name</option>
                            <option value="newest">Newest</option>
                        </select>
                    </div>
                    <span class="col-1 align-self-center" @click="openFilters">
                        <i class="fas fa-times"></i>
                    </span>
                </div>
            </div>
        </div>

        <div
            class="row title-bar d-none d-md-flex"
            :class="$_isLightTheme ? 'bg-white' : 'bg-dark'"
        >
            <ul class="breadcrumbs col-lg-6 col-12">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li v-if="$route.params.category">></li>
                <li v-if="$route.params.category">
                    <router-link :to="route($route.params.category)">{{
                        slugToTitle($route.params.category)
                    }}</router-link>
                </li>
                <li v-if="$route.params.subcategory">></li>
                <li v-if="$route.params.subcategory">
                    <router-link :to="route($route.params.category, $route.params.subcategory)">{{
                        slugToTitle($route.params.subcategory)
                    }}</router-link>
                </li>
            </ul>

            <div class="sort col-lg-3 col-8 py-1 align-self-center form-inline">
                <label>Sort:</label>
                <select v-model="order" class="form-control form-control-sm w-75 ml-1">
                    <option value="name">Model Name</option>
                    <option value="newest">Newest</option>
                    <!--<option value="featured">Featured</option>-->
                </select>
            </div>
            <div class="show col-lg-3 col-4 p-1 align-self-center form-inline">
                <label>Icons:</label>
                <ul>
                    <li>
                        <a @click="mode = 'large'">
                            <span
                                :class="mode === 'large' ? 'showroom-color-primary' : ''"
                                class="fas fa-th-large"
                            ></span>
                        </a>
                    </li>
                    <li>
                        <a @click="mode = 'med'">
                            <span
                                :class="mode === 'med' ? 'showroom-color-primary' : ''"
                                class="fas fa-th"
                            ></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="filter-bar my-2" :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'">
            <div v-show="!loading" class="float-left">Showing {{ filteredMachines.length }} Models</div>
            <div v-show="loading" class="float-left">Loading Results...</div>
            <div v-show="!loading">
                <select
                    v-if="subcategories.length && $route.params.subcategory"
                    v-model="selectedSubcategory"
                    class="mr-1 form-control form-control-sm"
                >
                    <option value>-- Select Subcategory --</option>
                    <option v-for="(subcategory, i) in subcategories" :key="i" :value="subcategory">
                        {{ subcategory }}
                    </option>
                </select>
            </div>
            <div v-show="!loading" class="float-right d-none d-md-block">
                <input v-model="filterValue" placeholder="filter" />
                <span class="fas fa-search"></span>
            </div>
        </div>
        <div
            id="main-grid-view"
            :class="{
                'fade-in': !loading,
                'fade-out': loading,
                'bg-gray-200': $_isLightTheme,
                'bg-gray-800': !$_isLightTheme
            }"
            class="grid"
        >
            <div
                v-for="machine in filteredMachines"
                :key="machine.Id"
                :class="[sizes[mode], { 'bg-white': $_isLightTheme, 'bg-dark': !$_isLightTheme }]"
                class="bg-danger machine-list-item"
            >
                <div class="machine-item-body">
                    <div class="machine-image">
                        <router-link :to="getDetailUrl(machine)">
                            <element-image
                                :src="firstImage(machine.Thumbnail || machine.Images)"
                                width="360"
                            ></element-image>
                        </router-link>
                    </div>
                    <h5 :class="textSize(machine.Title)" class="text-center showroom-color-primary">
                        <router-link :to="getDetailUrl(machine)">{{ htmlDecode(machine.Title) }}</router-link>
                    </h5>
                </div>
                <div class="call-to-action w-100">
                    <router-link class="text" :to="getDetailUrl(machine)">Details</router-link>
                    <a class="showroom-bg-color-primary" @click="saveMachine(machine, manufacturer)">Save</a>
                </div>
            </div>
        </div>
        <div :class="{ 'fade-in': loading, 'fade-out': !loading }" class="grid loading-spinner">
            <span class="fas fa-spinner fa-spin"></span>
        </div>
    </div>
</template>

<script>
    function sortMachines(order, machines) {
        if (order === 'name') {
            return machines.sort(function(m1, m2) {
                return m1.Title > m2.Title ? 1 : -1;
            });
        } else {
            return machines.sort(function(m1, m2) {
                return m1.LastModified > m2.LastModified ? 1 : -1;
            });
        }
    }

    import newEquipmentService from '../../../services/NewEquipmentService';

    import NewEquipmentMixin from '../mixins/NewEquipmentMixin';
    import ThemeMixin from '../mixins/ThemeMixin';

    // TODO: Create mixin to combine logic with shortlineshowroom
    export default {
        name: 'EquipmentShowroomGrid',
        mixins: [NewEquipmentMixin, ThemeMixin],
        props: {
            manufacturer: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                mode: 'large',
                sizes: {
                    small: 'grid-cell-sm',
                    med: 'grid-cell',
                    large: 'grid-cell-lg'
                },
                order: 'name',
                filterValue: '',
                selectedSubcategory: '',
                loading: true,
                machines: [],
                showFilters: false
            };
        },
        computed: {
            filteredMachines() {
                var result = this.machines;

                if (this.filterValue) {
                    result = result.filter(machine => {
                        return (
                            machine.Title.toLowerCase().indexOf(this.filterValue.toLowerCase()) > -1 ||
                            machine.Hierarchy.toLowerCase().indexOf(this.filterValue.toLowerCase()) > -1
                        );
                    });
                }

                if (this.selectedSubcategory) {
                    result = result.filter(machine => {
                        return machine.Hierarchy.indexOf(this.selectedSubcategory) > -1;
                    });
                }

                return sortMachines(this.order, result);
            },
            subcategories() {
                const subcats = this.machines.map(machine => {
                    const hierarchyStrings = machine.Hierarchy.split('~');
                    const lastHierarchy = hierarchyStrings[hierarchyStrings.length - 1].split('|');

                    if (lastHierarchy.length > 2) {
                        return lastHierarchy[2];
                    } else {
                        return [];
                    }
                });

                // remove null/undefined/empty and duplicate items
                const filteredSubcats = subcats.filter((s, index) => s && subcats.indexOf(s) === index);

                return filteredSubcats;
            }
        },
        watch: {
            $route: function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    const category = newValue.params.category;
                    const subcategory = newValue.params.subcategory;
                    this.loading = true;
                    this.loadMachines(category, subcategory, 1000, false);
                    this.filterValue = this.$route.query.search || '';
                }
            },
            subcategories: function() {
                if (this.selectedSubcategory && filteredSubcats.indexOf(this.selectedSubcategory) === -1) {
                    this.selectedSubcategory = '';
                }
            },
            order: function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.machines = sortMachines(newValue, this.machines);
                }
            }
        },
        created() {
            const category = this.$route.params.category;
            const subcategory = this.$route.params.subcategory;
            this.loadMachines(category, subcategory, 500, true);
            this.filterValue = this.$route.query.search || '';
        },
        methods: {
            route(p1, p2) {
                return p2 ? `/${p1}/${p2}` : `/${p1}`;
            },
            getDetailUrl(machine) {
                if (this.$route.params.category && this.$route.params.subcategory) {
                    return `/${this.$route.params.category}/${this.$route.params.subcategory}/${machine.UrlName}/overview`;
                } else if (this.$route.params.category) {
                    const needle = this.$route.params.category;
                    const haystack = machine.HierarchyPath.split('~');

                    // try to find subcategory/series for the given category within the hierarchy path
                    for (let i = 0; i < haystack.length; i++) {
                        const category = haystack[i]
                            .split('|')[0]
                            .toLowerCase()
                            .replace(/\s/g, '-');

                        if (category === needle && haystack[i].split('|').length > 1) {
                            // found subcategory!
                            let subcategory = haystack[i]
                                .split('|')[1]
                                .toLowerCase()
                                .replace(/\s/g, '-');

                            subcategory = subcategory === machine.UrlName ? 'series' : subcategory;

                            return `/${category}/${subcategory}/${machine.UrlName}/overview`;
                        }
                    }
                    const hierarchy = machine.HierarchyPath.split('~')[1];
                    return `/${hierarchy
                        .split('|')[0]
                        .toLowerCase()
                        .replace(/\s/g, '-')}/series/${machine.UrlName}/overview`;
                } else {
                    const hierarchy = machine.HierarchyPath.split('~')[1];
                    return `/${hierarchy
                        .split('|')[0]
                        .toLowerCase()
                        .replace(/\s/g, '-')}/${hierarchy
                        .split('|')[1]
                        .toLowerCase()
                        .replace(/\s/g, '-')}/${machine.UrlName}/overview`;
                }
            },
            firstImage(images) {
                return (images && images.split(',')[0]) || '';
            },
            // TODO: IMPROVE METHOD NAME.
            slugToTitle(slug) {
                return slug.replace(/-/g, ' ').replace(/\w\S*/g, function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            },
            // TODO: IMPROVE METHOD NAME.
            textSize(title) {
                return title.length > 30 ? 'compress' : '';
            },
            // TODO: IMPROVE METHOD NAME.
            htmlDecode(html) {
                var el = document.createElement('textarea');
                el.innerHTML = html;
                return el.value;
            },
            openFilters() {
                this.showFilters = !this.showFilters;
            },
            loadMachines(category, subcategory, delay, isSorted = true) {
                newEquipmentService
                    .getNewMachinesByCategory(this.manufacturer, category, subcategory)
                    .then(machines => {
                        if (isSorted) {
                            this.machines = sortMachines(this.order, machines);
                        } else {
                            this.machines = machines;
                        }
                        $('#main-grid-view').animate({
                            scrollTop: 0
                        });
                        setTimeout(() => (this.loading = false), delay);
                    });
            }
        }
    };
</script>

<style>
    .fade-in {
        opacity: 1;
        transition: opacity ease-in 0.5s;
        z-index: 1;
    }
    .fade-out {
        opacity: 0;
        transition: opacity ease-out 0.5s;
        z-index: -1;
    }
</style>
