<!--
Showroom detail page
-->
<template>
    <div class="showroom-item mb-3 px-3">
        <div class="d-inline-flex flex-wrap flex-sm-nowrap align-items-center justify-items-center">
            <!-- item title -->
            <h1
                class="h3 my-3 mx-auto font-italic font-weight-bold text-center text-md-left"
                v-html="item.Title"
            ></h1>
            <!-- item rating -->
            <a
                v-if="rating"
                class="d-flex align-items-center justify-content-center mx-auto ml-sm-4"
                :href="ratingLink"
                target="_blank"
            >
                <i v-for="(star, i) in stars" :key="i" :class="`fa-2x ${star} text-warning`"></i>
                <h4 class="ml-2 my-0">{{ rating }}</h4>
            </a>
        </div>
        <div class="row mt-4">
            <!-- main item photo -->
            <div class="col-md mr-auto">
                <img
                    class="showroom-item-img w-100 mb-5 mb-md-0 cursor-pointer"
                    :src="image"
                    :alt="item.Title"
                    @click="openLightboxOnSlide(0)"
                />
            </div>

            <div
                v-if="showPricing"
                class="col-md-12 order-md-last my-3 d-flex d-md-block flex-column align-items-center"
            >
                <s v-if="item.StartingPrice" class="text-danger">
                    <span class="h5 text-black-50">MSRP: </span>
                    <span class="h4 font-weight-bold">{{ currencyFormat(item.ListPrice) }}</span>
                </s>
                <span v-else>
                    <span class="h5 text-black-50">MSRP: </span>
                    <span class="h4 font-weight-bold">{{ currencyFormat(item.ListPrice) }}</span>
                </span>
                <div v-if="item.StartingPrice" class="h4 font-weight-bold">
                    {{ currencyFormat(item.StartingPrice) }}
                </div>
            </div>

            <div class="showroom-item-buttons col-md">
                <!-- specials button -->
                <btn-icon
                    class="text-uppercase"
                    btn-style="btn-secondary"
                    icon-color="#000000"
                    icon-left="fa-star"
                    action-url="#"
                    @click.prevent="showSpecials"
                >
                    Click to See Specials
                </btn-icon>
                <!-- get a quote button -->
                <btn-icon
                    data-toggle="modal"
                    :data-target="`#${quoteFormModalId}`"
                    :icon-color="brandColor"
                    icon-left="fa-dollar-sign"
                >
                    Get a Quote
                </btn-icon>
                <!-- CtaUrl -->
                <btn-icon
                    v-if="item.CtaUrlButtonText && item.CtaUrl"
                    :action-url="item.CtaUrl"
                    :icon-color="brandColor"
                    :icon-left="ctaUrlIcon"
                    :open-link-in-new-tab="true"
                >
                    {{ item.CtaUrlButtonText }}
                </btn-icon>
                <!-- call us button for mobile only -->
                <btn-icon
                    :action-url="`tel:${dealerPhoneNumber}`"
                    class="d-flex d-md-none"
                    :icon-color="brandColor"
                    icon-left="fa-phone-volume"
                >
                    Call Us
                </btn-icon>
                <!-- contact us button for tablet / desktop views -->
                <btn-icon
                    :action-url="dealerLocationPage"
                    class="d-none d-md-flex"
                    :icon-color="brandColor"
                    icon-left="fa-phone-volume"
                >
                    Contact Us
                </btn-icon>
                <!-- save button -->
                <btn-icon :icon-color="brandColor" icon-left="fa-star" @click="clickToCompare()"
                    >Click To Compare</btn-icon
                >
                <!-- print button -->
                <btn-icon :icon-color="brandColor" icon-left="fa-print" @click="print()">Print</btn-icon>
                <!-- Builder -->
                <btn-icon
                    v-if="builderPath"
                    :action-url="builderPath"
                    :icon-color="brandColor"
                    icon-left="fa-wrench"
                >
                    Build Your Own
                </btn-icon>
            </div>
        </div>

        <!-- item description -->
        <div
            v-if="item.Summary"
            class="showroom-item-overview text-gray-600 mt-5"
            :class="{ 'mt-md-0': showPricing }"
        >
            <h4 class="text-uppercase">Overview</h4>
            <div v-html="item.Summary"></div>
        </div>

        <accordion class="mt-5">
            <!-- features -->
            <accordion-item v-if="item.Features" item-id="showroom-features" title="Features">
                <div v-html="item.Features"></div>
            </accordion-item>
            <!-- specifications -->
            <accordion-item
                v-if="item.Specifications"
                item-id="showroom-specs"
                title="Specifications"
                icon="fa-clipboard-list"
            >
                <div v-html="item.Specifications"></div>
            </accordion-item>
            <!-- gallery -->
            <accordion-item
                v-if="images.length > 1"
                item-id="showroom-gallery"
                title="Gallery"
                icon="fa-images"
            >
                <div class="row row-cols-2 row-cols-xl-4 mb-n4">
                    <div v-for="(slide, i) in images" :key="i" class="col">
                        <img
                            :src="slide"
                            :alt="item.Title"
                            class="img-thumbnail cursor-pointer mb-4"
                            @click="openLightboxOnSlide(i)"
                        />
                    </div>
                </div>
            </accordion-item>
            <!-- brochures -->
            <accordion-item
                v-if="brochures.length > 0"
                item-id="showroom-brochures"
                title="Brochures"
                icon="fa-book"
            >
                <h4 v-for="(brochure, i) in brochures" :key="i">
                    <a :href="brochure.url" target="_blank">{{ brochure.title }}</a>
                </h4>
            </accordion-item>
            <!-- specials -->
            <accordion-item
                ref="showroomSpecials"
                v-model="expandSpecials"
                :data-source="dataSource"
                class="h-100"
                item-id="showroom-specials"
                title="Specials"
                icon="fa-star"
            >
                <showroom-item-specials
                    :item="item"
                    :data-source="dataSource"
                    :manufacturer="manufacturer"
                ></showroom-item-specials>
            </accordion-item>
        </accordion>

        <!-- lightbox for photo gallery -->
        <fslightbox
            v-if="images && images.length > 0"
            :toggler="toggleLightbox"
            :source-index="lightboxSourceIndex"
            :sources="images"
            :show-thumbs-on-mount="images.length > 1"
            type="image"
        ></fslightbox>

        <!--
            Modal for Sitefinity get a quote form widget.
            - Populates the form's Interested Eq field.
            - Activated by the Bootstrap `data-target` attribute on the buttons above.
        -->
        <modal-form
            v-if="!useEmbeddedQuoteForm"
            :id="quoteFormModalId"
            title="Get a Quote"
            form-selector=".showroom-get-a-quote"
            :field-values="formFieldValues"
        ></modal-form>
        <!--
            Renders a custom form that's specified by url within an iframe modal.
            - Activated by the Bootstrap `data-target` attribute on the buttons above.
        -->
        <modal-iframe
            v-else
            :id="quoteFormModalId"
            title="Get a Quote"
            :iframe-source="`${baseUrl}/${embeddedFormScriptsAction}/?item-name=${itemName}`"
        ></modal-iframe>
    </div>
</template>

<script>
    import { currencyFormat } from '../../lib/utilities';

    import Accordion from '../accordion/Accordion';
    import AccordionItem from '../accordion/AccordionItem';
    import BtnIcon from '../utility/BtnIcon';
    import Fslightbox from 'fslightbox-vue';
    import LocationMixin from '../mixins/LocationMixin';
    import ModalForm from '../utility/ModalForm';
    import ModalIframe from '../utility/ModalIFrame';
    import NewEquipmentMixin from '../mixins/NewEquipmentMixin';
    import ShowroomItemSpecials from './ShowroomItemSpecials';
    import ShowroomMixin from './ShowroomMixin';

    export default {
        components: {
            Accordion,
            AccordionItem,
            BtnIcon,
            Fslightbox,
            ModalForm,
            ModalIframe,
            ShowroomItemSpecials
        },
        mixins: [LocationMixin, NewEquipmentMixin, ShowroomMixin],
        props: {
            baseUrl: {
                type: String,
                required: true
            },
            brandColor: {
                type: String,
                required: true
            },
            buildYourOwnPage: {
                type: String,
                default: null
            },
            dataSource: {
                type: String,
                required: true
            },
            item: {
                type: Object,
                required: true
            },
            manufacturer: {
                type: String,
                required: true
            },
            showPricing: {
                type: Boolean,
                default: false
            },
            /**
             * If true, the Get a Quote form will be loaded as an iframe, replacing the Get a Quote form widget.
             */
            useEmbeddedQuoteForm: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            COMPARE_LINK: '/Compare',
            embeddedFormScriptsAction: 'GetQuoteFormScripts',
            expandSpecials: false,
            isSearchFormComponent: true,
            lightboxSourceIndex: 0,
            quoteFormModalId: 'showroom-get-a-quote',
            rating: 0,
            ratingLink: '',
            stars: [],
            toggleLightbox: false
        }),
        computed: {
            builderPath() {
                return this.buildYourOwnPage
                    ? `${this.buildYourOwnPage}${this.$route.path.replace(`${this.$route.params.model}/overview`, `builder?machine=${this.$route.params.model}`)}`
                    : '';
            },
            categoryAndSubcategory() {
                return (
                    this.$_currentHierarchy &&
                    `${this.$_currentHierarchy[0].split('|')[1]} - ${this.$_currentHierarchy[0].split('|')[2]}`
                );
            },
            dealerLocationPage() {
                let url = this.elementConfig.SITEMAP.LOCATIONS;

                if (this.$_selectedLocation) {
                    url += `/${this.$_selectedLocation.UrlName}`;
                }

                return url;
            },
            dealerPhoneNumber() {
                if (this.$_selectedLocation && this.$_selectedLocation.Phone) {
                    return this.$_selectedLocation.Phone;
                } else {
                    return this.elementConfig.PHONE;
                }
            },
            images() {
                return this.getImages();
            },
            image() {
                return this.getImages()[0];
            },
            itemName() {
                return `${this.manufacturer.capitalize()} ${this.item.Title}`;
            },
            brochures() {
                return (
                    (this.item &&
                        [1, 2, 3, 4]
                            .map(i => ({
                                title: this.item[`Brochure${i}Title`],
                                url: this.item[`Brochure${i}Url`]
                            }))
                            .filter(b => b.title && b.url)) ||
                    []
                );
            },
            formFieldValues() {
                return [
                    {
                        selector: `.interested-eq input[class='form-control']`,
                        value: this.itemName
                    },
                    {
                        selector: '.category-field input[type="text"]',
                        value: this.categoryAndSubcategory
                    }
                ];
            },
            ctaUrlIcon() {
                // Show financing icon if word "finance" or "financing" is found in the CTA text
                return this.item.CtaUrlButtonText &&
                    this.item.CtaUrl &&
                    this.item.CtaUrlButtonText.includes('Financ')
                    ? 'fa-hand-holding-usd'
                    : 'fas fa-external-link-alt';
            }
        },
        mounted() {
            this.setupDeereAccordions();
            this.setupRating();
        },
        methods: {
            /**
             * Hooks into Blaze Search's form state change detection
             * to allow filters to be applied before the search is executed.
             */
            applyFormState() {
                if (this.$route.name === 'showroom-item') {
                    this.$store.commit('search/addFilter', {
                        conditions: [
                            {
                                expression: 'eq',
                                value: this.$route.params.model
                            }
                        ],
                        field: 'UrlName'
                    });
                } else {
                    this.$store.commit('search/removeFilter', 'UrlName');
                }
            },
            clickToCompare() {
                this.saveMachine(this.item, this.dataSource);
                window.location.href = this.COMPARE_LINK;
            },
            currencyFormat(number) {
                return currencyFormat.format(number);
            },
            getImages() {
                if (this.item && this.item.Images) {
                    return this.item.Images.split(',').map(image => {
                        if (image.includes('localhost/')) {
                            let index = image.indexOf('localhost') + 9;
                            return image.substr(index);
                        }

                        return image;
                    });
                }

                // retrieve image from search results if it doesn't exist on the content type
                return (
                    (this.$_searchResults &&
                        this.$_searchResults[0] &&
                        this.$_searchResults[0].ResultImages &&
                        this.$_searchResults[0].ResultImages.split(',')) ||
                    []
                );
            },
            /**
             * Expands the specials accordion and scrolls the user to it
             */
            showSpecials(event) {
                this.expandSpecials = true;

                // give the acordion time to expand before calculating the scroll position
                setTimeout(() => {
                    let specialsAccordion = this.$refs.showroomSpecials;

                    if (specialsAccordion) {
                        let specialsPosition = specialsAccordion.$el.getBoundingClientRect().top + window.scrollY;
                        let headerHeight = document.querySelector('#header-mobile').offsetHeight;
                        let scrollPosition = specialsPosition - headerHeight;

                        window.scroll({ top: scrollPosition, behavior: 'smooth' });
                    }
                }, 250);
            },
            openLightboxOnSlide(number) {
                this.lightboxSourceIndex = number;
                this.toggleLightbox = !this.toggleLightbox;
            },
            print() {
                window.print();
            },
            setupDeereAccordions() {
                let itemSelector = '.expand-collapse-component-item';
                let contentSelector = '.expand-collapse-component-item-content';
                let accordionHeadings = document.querySelectorAll(`${itemSelector} > h4`);

                if (accordionHeadings && accordionHeadings.length > 0) {
                    accordionHeadings.forEach(heading => {
                        heading.addEventListener('click', event => {
                            event.preventDefault(); // stops page from jumping if heading contains an anchor tag
                            let accordionItem = event.currentTarget.parentElement;
                            let accordionContent = accordionItem.querySelector(contentSelector);

                            if (accordionItem.classList.contains('active')) {
                                accordionItem.classList.remove('active');
                                accordionContent.style.height = '0';
                            } else {
                                accordionItem.classList.add('active');
                                accordionContent.style.height = `${accordionContent.scrollHeight}px`;
                            }
                        });
                    });
                }
            },
            setupRating() {
                this.rating = this.item.Rating ? JSON.parse(this.item.Rating).Rating.toFixed(1) : 0;

                for (let i = 0; i < 5; i++) {
                    if (this.rating - i > 1) {
                        this.stars.push('fas fa-star');
                    } else if (this.rating - i > 0) {
                        this.stars.push('fas fa-star-half-alt');
                    } else {
                        this.stars.push('far fa-star');
                    }
                }

                this.ratingLink = `${this.item.SourceUrl}#reviews-ratings`;
            }
        }
    };
</script>
