﻿<template>
    <div v-if="employees == null && location && location.Phone" class="text-center">
        <div class="row">
            <div class="col-12">
                <h6>Call to talk to one our team members today!</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a :href="'tel:' + location.Phone">{{ location.Phone }}</a>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="employee-list card-columns position-relative card-grid">
            <div
                v-for="employee in employees"
                class="card testimonial-card-full"
                :key="employee.FullName"
            >
                <div class="d-flex justify-content-start p-4 p-sm-4">
                    <div v-if="employee.EmployeeImage">
                        <img class="rounded-circle"
                             :src="employee.EmployeeImage.ThumbnailUrl"
                             :alt="employee.FullName"/>
                    </div>
                    <div class="align-self-center mx-auto text-center">
                        <h5>{{ employee.FullName }}</h5>
                        <div class="subtitle">{{ employee.Position }}</div>
                    </div>
                </div>
                <div
                    class="employee-info border-top px-2 pt-2 pb-3"
                    v-if="renderCardBody(employee)"
                >
                    <p v-if="employee.Bio" class="p-2" v-html="employee.Bio"></p>
                    <a
                        v-if="employee.ContactEmail"
                        :href="`mailto:${employee.ContactEmail}`"
                        class="d-block px-2"
                    >
                        <i class="fas fa-envelope pr-2"></i>
                        {{ employee.ContactEmail }}
                    </a>
                    <a
                        v-if="employee.ContactPhone"
                        :href="`tel:${employee.ContactPhone}`"
                        class="d-block px-2"
                    >
                        <i class="fas fa-phone pr-2"></i>
                        {{ employee.ContactPhone }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EmployeeService from '../../../services/EmployeeService.js';
    import LocationMixin from '../mixins/LocationMixin';

    export default {
        name: 'EmployeeList',
        mixins: [LocationMixin],
        props: {
            selectedEmployeeIds: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                employees: []
            };
        },
        mounted() {
            var location = this.$_selectedLocation;
            this.loadEmployees(location);
        },
        methods: {
            loadEmployees(location) {
                EmployeeService.getEmployeesByIds(this.selectedEmployeeIds).then(result => {
                    var employeeCollection = [];

                    if (location) {
                        var locationId = location.Id;

                        result.forEach(employee => {
                            var employeeLocation = employee.EmployeeLocation.find(
                                empLoc => empLoc.Id === locationId
                            );
                            if (employeeLocation) employeeCollection.push(employee);
                        });

                        if (employeeCollection.length == 0) this.employees = null;
                    }

                    this.employees = employeeCollection.length > 0 ? employeeCollection : result;
                });
            }
        },
        computed: {
            renderCardBody() {
                return employee => employee.Bio || employee.ContactEmail || employee.ContactPhone;
            }
        },
        watch: {
            $_selectedLocation: function(newVal) {
                this.loadEmployees(newVal);
            }
        }
    };
</script>
