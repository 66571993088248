<!--
Template for the header location details dropdown.
-->
<template>
    <div
        v-if="location && location.Id"
        class="location-details"
        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
    >
        <div v-if="location.LocationImage" class="location-image">
            <img :src="location.LocationImage.Url" :alt="location.LocationImage.AltText" />
        </div>

        <div class="location-info">
            <div class="row">
                <div class="col-6 text-left text my-auto">
                    <span v-html="locationFullAddress(location)"></span>
                </div>
                <div class="col-6 text-center">
                    <a
                        :href="directionsLink(location)"
                        class="btn btn-primary px-1 py-2 mb-2 google-maps-directions"
                    >
                        GET DIRECTIONS
                    </a>
                    <a :href="detailsLink(location.UrlName)" class="btn btn-primary px-1 py-2">
                        VIEW LOCATION
                    </a>
                </div>
            </div>

            <div class="row mt-2">
                <div v-if="location.Phone" class="col-6 text-left text">
                    <span class="font-weight-bold">OFFICE</span>
                    <br />
                    <a class="font-weight-bold" :href="telLink(location.Phone)">{{ location.Phone }}</a>
                </div>
                <div v-if="location.LocationFax" class="col-6 text-left text">
                    <span class="font-weight-bold">FAX</span>
                    <br />
                    <a class="font-weight-bold" :href="telLink(location.LocationFax)">{{ location.Fax }}</a>
                </div>
                <div
                    v-if="location.LocationTollFree && location.LocationTollFree !== 'N/A'"
                    class="col-6 text-left text"
                >
                    <span class="font-weight-bold">TOLL-FREE</span>
                    <br />
                    <a class="font-weight-bold" :href="telLink(location.LocationFax)">{{
                        location.TollFree
                    }}</a>
                </div>
                <div
                    v-if="location.LocationAfterHours && location.LocationAfterHours !== 'N/A'"
                    class="col-6 text-left text"
                >
                    <span class="font-weight-bold">AFTER HOURS</span>
                    <br />
                    <a class="font-weight-bold" :href="telLink(location.AfterHoursPhone)">
                        {{ location.AfterHoursPhone }}
                    </a>
                </div>
            </div>

            <div v-if="location.LocationEmail" class="row mt-1">
                <div class="col-xs-12 text-left text">
                    <span class="font-weight-bold">EMAIL</span>
                    <br />
                    <a class="font-weight-bold" :href="emailLink(location)">{{ location.Email }}</a>
                </div>
            </div>

            <div class="row mt-2">
                <h6 class="text text-left col-7">Store Hours</h6>
                <h6 v-if="$_isSelectedLocationOpen" class="text-primary col-5 text-right">Open Now</h6>
                <h6 v-else class="text-danger col-5 text-right">Now Closed</h6>
            </div>

            <div
                v-for="(day, index) in $_selectedLocationOpenHours"
                :key="index"
                :class="['row', currentDay(index)]"
            >
                <div class="col-5 text text-left">{{ day.day }}</div>
                <div class="col-7 text text-right">{{ showHours(day) }}</div>
            </div>
            <div class="mb-2"></div>

            <div v-if="location.PartsStoreURL" class="row">
                <div class="col-12 text-left">
                    <a class="btn btn-primary" :href="location.PartsStoreURL">SHOP PARTS</a>
                </div>
            </div>

            <div v-if="hasMultipleLocations" class="row">
                <div class="col-6 pr-1">
                    <button class="btn btn-primary btn-sm w-100" @click="toggleInput">CHANGE LOCATION</button>
                </div>
                <div class="col-6 pl-1">
                    <a :href="elementConfig.SITEMAP.LOCATIONS" class="btn btn-primary btn-sm w-100">
                        ALL LOCATIONS
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import '../../lib/find-polyfill';
    import constants from '../../config/constants';

    import LocationMixin from '../mixins/LocationMixin';
    import ThemeMixin from '../mixins/ThemeMixin';

    export default {
        name: 'LocationDetails',
        mixins: [LocationMixin, ThemeMixin],
        props: {
            location: {
                type: Object,
                default: () => ({})
            },
            toggleInput: {
                type: Function,
                required: true
            }
        },
        data() {
            return {
                hasMultipleLocations: constants.elementConfig.HAS_MULTIPLE_LOCATIONS
            };
        }
    };
</script>
