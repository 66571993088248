﻿<template>
    <div :class="['card card-standard', { shadow: enableShadow, 'card-rounded': rounded }]">
        <router-link v-if="useRouter" class="d-block" :to="route" :target="target">
            <element-image
                v-if="enableImage"
                :alt="image.alt"
                :img-class="`align-self-start card-img-top${limitImageHeight ? ' object-fit-cover' : ''}`"
                :src="image.src"
            />
        </router-link>
        <a v-else :href="actionLink" class="d-block" :target="target">
            <element-image
                v-if="enableImage"
                :alt="image.alt"
                :img-class="`align-self-start card-img-top${limitImageHeight ? ' object-fit-cover' : ''}`"
                :src="image.src"
            />
        </a>
        <ribbon-badge v-if="ribbonBodyText" class="mt-3" :flatten-left="true">
            {{ ribbonBodyText }}
        </ribbon-badge>
        <div v-if="badges && badges.length > 0" class="px-3 mt-3">
            <span v-for="(val, index) in badges" :key="index" class="badge badge-primary mr-1">
                {{ val }}
            </span>
        </div>
        <div class="card-body">
            <router-link
                v-if="useRouter"
                class="d-block"
                :class="{ 'w-75': ribbonTitleText }"
                :target="target"
                :to="route"
            >
                <component :is="`h${titleSize}`" v-if="title" class="card-title" v-html="title"></component>
            </router-link>
            <a
                v-else-if="actionLink"
                class="d-block"
                :class="{ 'w-75': ribbonTitleText }"
                :href="actionLink"
                :target="target"
            >
                <component :is="`h${titleSize}`" v-if="title" class="card-title" v-html="title"></component>
            </a>
            <component :is="`h${titleSize}`" v-else-if="title" class="card-title" v-html="title"></component>
            <ribbon-badge
                v-if="ribbonTitleText"
                background-class="bg-primary text-primary-yiq"
                class="ribbon-title-text position-absolute"
                :flatten-right="true"
            >
                {{ ribbonTitleText }}
            </ribbon-badge>
            <h6 v-if="subtitle" class="card-subtitle mb-3 text-muted" v-html="subtitle"></h6>
            <div class="card-text">
                <slot></slot>
            </div>
        </div>
        <div v-if="enableLink" class="card-footer p-0">
            <router-link v-if="useRouter" class="d-block" :to="route" :target="target">
                <div :class="btnClasses">{{ actionName }}</div>
            </router-link>
            <a v-else :href="actionLink" :target="target" class="d-block">
                <div :class="btnClasses">{{ actionName }}</div>
            </a>
        </div>
    </div>
</template>

<script>
    // defined outside the component properties scope so it's available to props
    const defaultBtnStyle = 'btn-secondary';

    import RibbonBadge from '../utility/RibbonBadge';

    export default {
        name: 'CardStandard',
        components: { RibbonBadge },
        props: {
            badges: {
                type: Array,
                default: () => []
            },
            enableImage: {
                type: Boolean,
                default: false
            },
            image: {
                type: Object,
                default: () => ({})
            },
            title: {
                type: String,
                default: ''
            },
            titleSize: {
                type: Number,
                default: 3
            },
            subtitle: {
                type: String,
                default: ''
            },
            enableButtonLink: {
                type: Boolean,
                default: false
            },
            actionName: {
                type: String,
                default: ''
            },
            actionUrl: {
                type: String,
                default: ''
            },
            btnStyle: {
                type: String,
                default: defaultBtnStyle
            },
            enableShadow: {
                type: Boolean,
                default: true
            },
            limitImageHeight: {
                type: Boolean,
                default: false
            },
            useBlockBtn: {
                type: Boolean,
                default: false
            },
            useRouter: {
                type: Boolean,
                default: false
            },
            route: {
                type: Object,
                default: () => ({})
            },
            openLinkInNewTab: {
                type: Boolean,
                default: false
            },
            ribbonBodyText: {
                type: String,
                default: ''
            },
            ribbonTitleText: {
                type: String,
                default: ''
            },
            rounded: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            enableLink() {
                return this.enableButtonLink && this.actionName && this.actionUrl;
            },
            btnClasses() {
                let classes = ['btn', this.btnStyle || defaultBtnStyle];

                if (this.useBlockBtn) {
                    classes.push('btn-block text-left rounded-0 pl-3');
                } else {
                    classes.push('m-3');
                }

                return classes;
            },
            actionLink() {
                return this.enableLink ? this.actionUrl : '#';
            },
            target() {
                return this.openLinkInNewTab ? '_blank' : '_self';
            }
        }
    };
</script>
