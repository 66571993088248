/*
* Lazy load images. Only applicable to img tags
* Provides 404 fallback through directive object literals arguments
* MUST: data-url attribute with the image src url
* OPTIONAL: Images must have height for the threshold to work on values greater than 0
*
* Usage example
* <img
    v-lazy-load="{ placeholder: '/path/to/placeholder.jpg', width: 35 }"
    data-url="/path/to/image.jpg"
    width="250" />
*/
import { optimizeImage } from './utilities';

export default {
    inserted: (el, binding) => {
        if (el.nodeName !== 'IMG') {
            return;
        }

        if (window['IntersectionObserver']) {
            createObserver();
        } else {
            loadImage();
        }

        function createObserver() {
            const options = {
                root: null,
                threshold: 0
            };
            const observer = new IntersectionObserver(handleIntersect, options);
            observer.observe(el);
        }

        function handleIntersect(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting && entry.intersectionRatio >= observer.thresholds[0]) {
                    loadImage();
                    observer.unobserve(el);
                }
            });
        }

        function loadImage() {
            let parentElement = el.parentElement.parentElement;

            let width =
                binding.value?.width ||
                (parentElement.classList.value.includes('element-image') && parentElement.clientWidth
                    ? parentElement.clientWidth
                    : el.clientWidth);

            el.src = optimizeImage(el.dataset.url, width);

            el.addEventListener('error', () => {
                if (binding.value?.placeholder) {
                    el.src = binding.value.placeholder;
                }
            });
        }
    }
};
