<!--
Showroom results list item
-->
<template>
    <!-- NOTE: add this code to CardGridItem component? -->
    <div class="card rounded-0">
        <element-image
            :src="previewImage"
            :alt="item.Title"
            img-class="card-img-top rounded-0 align-self-start"
            width="528"
        ></element-image>
        <div class="card-body p-2">
            <h5 class="card-title text-center mb-0" v-html="item.Title"></h5>
        </div>
        <div class="card-footer p-0 d-flex">
            <router-link
                :to="{
                    name: 'showroom-item',
                    params: linkParams
                }"
                class="btn btn-light rounded-0 flex-fill stretched-link"
            >Details</router-link>
            <!-- position / z-index prevents the .stretched-link from affecting this button -->
            <button
                type="button"
                class="btn btn-primary rounded-0 flex-fill position-relative z-index-1"
                @click="saveMachine(item, dataSource)"
            >Save</button>
        </div>
    </div>
</template>

<script>
    import newEquipmentMixin from '../mixins/NewEquipmentMixin';
    import showroomMixin from './ShowroomMixin';

    export default {
        mixins: [newEquipmentMixin, showroomMixin],
        props: {
            /**
             * Path that the showroom exists at.
             */
            baseUrl: {
                type: String,
                required: true
            },
            dataSource: {
                type: String,
                required: true
            },
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            previewImage() {
                let photos = this.item.ResultImages;

                if (photos) {
                    let images = photos.split(',');
                    if (images && images.length > 0 && images[0].length > 0) {
                        return images[0];
                    }
                }

                return 'https://via.placeholder.com/400x267';
            },
            /**
             * Builds the params for the Vue route to the ShowroomItem (detail page)
             */
            linkParams() {
                let filteredHierarchy = this.item.ResultHierarchy.filter(p =>
                    p.includes(this.$_currentFilterPath)
                );

                // longest path for the item that includes the current filter path
                let path =
                    (filteredHierarchy &&
                        filteredHierarchy.length > 0 &&
                        filteredHierarchy
                            .pop()
                            .split('::')[1]
                            .split('|')) ||
                    [];

                let params = this.listRouteParams.reduce((params, key, i) => ({ ...params, [key]: path[i] }), {});
                params.model = this.item.UrlName;

                return params;
            }
        }
    };
</script>
