<!--
Full screen Bootstrap modal that appears from the right and attaches to the right side of the device screen.
Toggle the modal using data attributes or jQuery. See https://getbootstrap.com/docs/4.5/components/modal/#via-data-attributes
-->
<template>
    <div
        :id="id"
        ref="modalPane"
        class="modal fade modal-right-pane"
        tabindex="-1"
        role="dialog"
        :aria-labelledby="`${id}-label`"
        aria-hidden="true"
    >
        <div class="modal-dialog h-100 m-0 modal-dialog-scrollable">
            <div :class="['modal-content rounded-0', { 'bg-gray-800': $_isDarkTheme }]">
                <div
                    :class="['modal-header d-flex align-items-center p-2', {'border-dark': $_isDarkTheme }]"
                >
                    <h4 :id="`${id}-label`" class="modal-title">{{title}}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times-circle"></i>
                    </button>
                </div>
                <div :class="['modal-body p-0', { 'bg-gray-100': $_isLightTheme }]">
                    <slot></slot>
                </div>
                <div class="modal-footer p-0">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ThemeMixin from '../mixins/ThemeMixin.vue';

    export default {
        name: 'ModalPane',
        mixins: [ThemeMixin],
        props: {
            /**
             * Use this id externally to toggle the modal (see Bootstrap docs link above)
             */
            id: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            }
        },
        mounted() {
            // allows modal to render above the site header
            this.$refs.modalPane.parentElement.removeChild(this.$refs.modalPane);
            document.querySelector('body').appendChild(this.$refs.modalPane);
        }
    };
</script>
