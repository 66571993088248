﻿<template>
    <div class="hero-wrapper">
        <div ref="heroImage" class="new-eq-showroom-hero shortline-bg-color-primary d-md-flex d-none">
            <div class="hero-text" v-html="heroText"></div>
        </div>

        <!-- mobile nav -->
        <div class="nav-wrapper d-flex d-md-none shortline-bg-color-primary">
            <router-link
                v-for="(type, i) in types"
                :key="i"
                class="shortline-bg-color-primary category-link"
                :to="slugify(type.MachineTypeTitle)"
            >
                <h5>{{ htmlDecode(type.MachineTypeTitle) }}</h5>
            </router-link>
        </div>

        <div class="d-none d-md-flex shortline-bg-color-primary"></div>
    </div>
</template>

<script>
    import Utilities from '../mixins/UtilitiesMixin';

    export default {
        mixins: [Utilities],
        props: {
            heroImage: {
                type: String,
                default: ''
            },
            heroText: {
                type: String,
                required: true
            },
            heroTitle: {
                type: String,
                required: true
            },
            types: {
                type: Array,
                default: () => []
            }
        },
        watch: {
            heroImage: function(newValue) {
                if (newValue) {
                    this.$refs.heroImage.style.cssText = `background-image: url('${newValue}');`;
                }
            }
        },
        mounted() {
            if (this.heroImage) {
                this.$refs.heroImage.style.cssText = `background-image: url('${this.heroImage}');`;
            }
        },
        methods: {
            categoryImage(category) {
                return (
                    (category.Image && category.Image.split(',')[0]) ||
                    (category.Images && category.Images.split(',')[0])
                );
            },
            htmlDecode(html) {
                var el = document.createElement('textarea');
                el.innerHTML = html;
                return el.value;
            }
        }
    };
</script>
