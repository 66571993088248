export default {
    EQUIPMENT: {
        LOCAL_STORAGE_KEY: 'SAVED_EQUIPMENT',
        GETTERS: {
            SAVED_EQUIPMENT_MACHINES: 'savedEquipment/machines',
            SAVED_EQUIPMENT_COUNTER: 'savedEquipment/machinesCounter',
            COMPARE_EQUIPMENT_COUNTER: 'savedEquipment/compareMachinesCounter'
        },
        ACTIONS: {
            LOAD_SAVED_EQUIPMENT: 'savedEquipment/load',
            ADD_SAVED_EQUIPMENT: 'savedEquipment/add',
            UPDATE_SAVED_EQUIPMENT: 'savedEquipment/update',
            DELETE_SAVED_EQUIPMENT: 'savedEquipment/delete'
        }
    },
    LOCATION: {
        LOCAL_STORAGE_KEY: 'LOCATION_ID',
        GETTERS: {
            LOCATION_ID: 'location/locationId',
            LOCATION_CLEARED: 'location/locationCleared'
        },
        ACTIONS: {
            LOAD_LOCATION_ID: 'location/load',
            DELETE_LOCATION_ID: 'location/delete',
            SET_LOCATION_ID: 'location/set'
        }
    },
    SEARCH: {
        MUTATIONS: {
            APPLY_SEARCH_RESPONSE: 'search/applySearchResponse',
            RESET: 'search/reset'
        }
    },
    THEME: {
        GETTERS: {
            THEME_NAME: 'theme/themeName'
        },
        ACTIONS: {
            APPLY_COLORS: 'theme/applyColors',
            GENERATE_COLORS: 'theme/generateColors',
            SET_THEME: 'theme/setTheme'
        }
    }
};
