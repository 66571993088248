﻿<template>
    <iframe
        v-if="isYoutube || isVimeo"
        :class="{ 'pe-none': preventEvents }"
        class="embed-responsive-item"
        :src="src"
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
    ></iframe>
    <video
        v-else
        :class="{ 'pe-none': preventEvents }"
        controls
        :autoplay="autoplay"
        width="640"
        height="360"
    >
        <source :src="src" />
    </video>
</template>

<script>
    export default {
        name: 'ElementIframeVideo',
        props: {
            autoplay: {
                type: Boolean,
                default: true
            },
            src: {
                type: String,
                default: ''
            },
            preventEvents: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isYoutube() {
                return this.src.includes('youtube.com');
            },
            isVimeo() {
                return this.src.includes('vimeo.com');
            }
        }
    };
</script>
