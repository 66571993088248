<template>
    <div class="d-none d-md-block form-group border-top font-weight-semi-bold text-uppercase font-size-sm">
        <!--
            Default tree, created from the search results facet values of the ResultHierarchy field.
            Used when dealer has not configured and enabled a custom hierarchy.
        -->
        <eb-facets-tree-filter
            v-if="!useDealerHierarchy"
            id="category-tree"
            ref="facetsTreeFilter"
            wrapper-class
            :hierarchy-field="hierarchyField"
            :filter-field="filterField"
            :use-isolated-facets-request="true"
            :form-fields-to-clear="['q', 'p']"
        ></eb-facets-tree-filter>

        <!-- navtree with support for custom hierarchy and sorting of nodes -->
        <ul v-else class="nav flex-column">
            <showroom-nav-item v-for="(node, i) in navTree" :key="i" :node="node"></showroom-nav-item>
        </ul>
    </div>
</template>

<script>
    import axios from 'axios';
    import showroomNavItem from './ShowroomNavItem';
    import showroomMixin from './ShowroomMixin.vue';

    export default {
        name: 'ShowroomNav',
        components: { showroomNavItem },
        mixins: [showroomMixin],
        props: {
            dataSource: {
                type: String,
                required: true
            },
            manufacturer: {
                type: String,
                required: true
            },
            hierarchyRootNodes: {
                type: Array,
                required: true
            },
            /**
             * When true, will pull the showroom nav tree data from the /api/showroom/nav endpoint
             * instead of using the ResultHierarchy field's facet values.
             */
            useDealerHierarchy: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                navTree: [],
                isSearchFormComponent: true // instructs Blaze Search to call the applyFormState method
            };
        },
        created() {
            this.requestNavTree();
        },
        methods: {
            /**
             * Called by Blaze Search to apply the filter for the selected path before executing the search for items.
             */
            applyFormState() {
                if (!this.useDealerHierarchy) {
                    // execute applyFormState method of the facets tree filter
                    // since Blaze Search doesn't look for form fields in child components.
                    this.$refs.facetsTreeFilter.applyFormState();
                } else {
                    if (!this.$_currentFilterPath) {
                        this.$store.commit('search/removeFilter', this.filterField);
                    } else {
                        this.$store.commit('search/addFilter', {
                            conditions: [
                                {
                                    expression: 'eq',
                                    value: this.$_currentFilterPath
                                }
                            ],
                            field: this.filterField,
                            fieldType: 'collection'
                        });
                    }
                }
            },
            requestNavTree() {
                if (this.useDealerHierarchy && this.hierarchyRootNodes.length > 0) {
                    let apiUrl = `/api/showroom/nav?datasource=${this.dataSource}`;

                    let rootNodeIds = this.hierarchyRootNodes.map(n => n.id).join(',');
                    apiUrl += `&rootNodeIds=${rootNodeIds}`;

                    axios.get(apiUrl).then(response => {
                        this.navTree = response.data;
                    });
                }
            }
        }
    };
</script>