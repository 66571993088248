<template>
    <div class="search-status-mobile pt-3 pb-2 px-2">
        <h4 class="text-uppercase text-center text-gray-500">Currently showing</h4>

        <!-- current filter selections as list of badges -->
        <div class="mb-1 text-center">
            <span
                class="badge badge-outline-primary badge-pill m-1"
                v-for="(filter, i) in filterConditions"
                :key="i"
            >{{filter}}</span>
        </div>

        <!-- search results count -->
        <p v-if="resultsCount > 0" class="mb-2 text-center">
            <span class="font-weight-bold">{{ resultsCount.toLocaleString() }}</span>
            {{ resultsCount > 1 ? 'results' : 'result' }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'SearchStatusMobile',
        props: {
            /**
             * List of form state entries that should be shown in the filters badges list.
             */
            filterParameters: {
                type: Array,
                required: true
            }
        },
        computed: {
            filterConditions() {
                let formState = this.$store.state.search.formState;
                let conditionsKeys = Object.keys(formState).filter(x => this.filterParameters.indexOf(x) > -1);
                let results = conditionsKeys.reduce((prev, key, i) => prev.concat(formState[key]), []);

                return results.length > 0 ? results : ['All Items'];
            },
            resultsCount() {
                let searchResponse = this.$store.state.search.searchResponse;
                return (searchResponse && searchResponse['@odata.count']) || 0;
            }
        }
    };
</script>