﻿<template>
    <div class="card card-facebook">
        <div>
            <img class="object-fit-cover" :src="image" :alt="screenName"/>
        </div>
        <div class="card-body pb-0">

            <a :href="screenNameLink" class="card-title row mx-0 p-2 w-100" target="_blank">
                <span class="px-2 d-flex align-items-center">
                    <img class="avatar" :src="profilePicture" alt="User Avatar">
                </span>
                <span class="col px-0">
                    <span class="name d-block font-weight-bold">{{ screenName }}</span>
                    <span class="date-created d-block">{{ createdAt }}</span>
                </span>
                <span class="facebook-icon col-2 d-flex justify-content-end px-0">
                    <i class="fab fa-facebook"></i>
                </span>
            </a>

            <div :class="['card-text mb-3', { 'mt-3': !image }]"
                 v-html="fullText">
            </div>
        </div>
        <div class="card-footer row px-0 mx-3 font-weight-bold">
            <a :href="permaLink" target="_blank" class="mr-2">
                <span class="col px-0">
                    <i class="fb-like d-inline-block"></i>
                    <span>{{ likeCount }}</span>
                </span>
            </a>
            <a :href="permaLink" target="_blank" class="mr-2">
                <span class="col px-0">
                    <i class="fb-comment d-inline-block"></i>
                    <span>Comment</span>
                </span>
            </a>
            <a :href="permaLink" target="_blank">
                <span class="col px-0">
                    <i class="fb-share d-inline-block"></i>
                    <span>Share</span>
                </span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardFacebook',
        props: {
            permaLink: String,
            profilePicture: String,
            screenName: String,
            screenNameLink: String,
            createdAt: String,
            fullText: String,
            likeCount: Number,
            media: Array
        },
        computed: {
            image() {
                if (!!this.media && !!this.media[0]) {
                    return this.media[0].ImageUrl;
                }
                return null;
            }
        }
    }
</script>
