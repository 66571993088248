﻿<template>
    <div id="header-mobile" ref="headerWrapper" class="header-wrapper d-lg-none">
        <!--STORE FINDER-->
        <div :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'">
            <store-finder
                :contact-settings="contactSettings"
                :show-events-icon-mobile="showEventsIcon"
                :use-legacy-location-dropdown="useLegacyLocationDropdown"
                :weather-page="weatherPage"
            />
        </div>
        <!--LOGO & MENU TRIGGER-->
        <div>
            <div class="row align-items-center no-gutters">
                <div class="col-9">
                    <span class="logo">
                        <a href="/">
                            <img :src="$_logoUrl" :alt="$_logoAlt" />
                        </a>
                    </span>
                </div>
                <div class="col-3">
                    <header-mobile-pusher />
                </div>
            </div>
        </div>
        <!--SAVE, SEARCH, & COMPARE-->
        <div :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'">
            <div class="row no-gutters">
                <ul class="menu-bricks text-small w-100">
                    <li class="search text-center no-hover d-flex align-items-center">
                        <span class="fas text-primary fa-search px-0"></span>
                        <form class="flex-fill" @submit.prevent="$_mobileSearch(searchTerm)">
                            <input
                                v-model="searchTerm"
                                class="px-2 py-2"
                                type="search"
                                :placeholder="$_mobileSearchPlaceholder"
                            />
                        </form>
                    </li>
                    <li class="saved no-hover">
                        <saved-equipment class="text w-100" icon-class="text-primary" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderMobilePusher from './HeaderMobilePusher';
    import SavedEquipment from '../../saved-equipment/SavedEquipment';
    import StoreFinder from '../StoreFinder';

    import HeaderMixin from '../../mixins/HeaderMixin';
    import ThemeMixin from '../../mixins/ThemeMixin';

    export default {
        name: 'HeaderMobile',
        components: {
            HeaderMobilePusher,
            SavedEquipment,
            StoreFinder
        },
        mixins: [HeaderMixin, ThemeMixin],
        props: {
            contactSettings: {
                default: () => ({}),
                type: Object
            },
            showEventsIcon: {
                type: Boolean,
                default: false
            },
            useLegacyLocationDropdown: {
                type: Boolean,
                default: false
            },
            weatherPage: {
                default: () => ({}),
                type: Object
            }
        },
        data() {
            return {
                searchTerm: ''
            };
        }
    };
</script>
