﻿<template>
    <a
        :class="['card card-search-result-secondary justify-content-center flex-grow-1 row flex-nowrap border-left-0 border-top-0 border-right-0 rounded-0', textClass, bgClass]"
        :href="url"
    >
        <div class="row no-gutters">
            <div class="col-3 my-auto">
                <div class="d-flex flex-column text-right pr-2 mb-2">
                    <div class="font-weight-bold mb-1">{{ badges[0] }}</div>
                    <div class="badge-icon">
                        <i :class="iconClass"></i>
                    </div>
                </div>
            </div>
            <div class="col align-left">
                <div class="card-body py-0">
                    <p class="mb-2" v-html="title"></p>
                    <h6 class="text-primary text-uppercase">View</h6>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        name: 'CardSearchResultSecondary',
        props: {
            index: {
                type: Number,
                required: true
            },
            badges: {
                type: Array,
                default: () => []
            },
            title: {
                type: String,
                required: true
            },
            url: {
                type: String,
                required: true
            },
            textClass: {
                type: String,
                default: 'text-dark'
            }
        },
        computed: {
            bgClass() {
                return `bg-${(this.index % 3) + 1}`;
            },
            iconClass() {
                let faClass = 'fas fa-star';

                switch (this.badges[0]) {
                    case 'Special':
                        faClass = 'fas fa-certificate';
                        break;
                    case 'Page':
                        faClass = 'fas fa-file-alt';
                        break;
                    case 'Event':
                        faClass = 'fas fa-calendar-alt';
                        break;
                }

                return faClass;
            }
        }
    };
</script>
