﻿<template>
    <div class="px-3 py-2 machine-detail-content text-center">
        <h1 class="text-center">Brochures</h1>
        <div class="w-100 px-3">
            <a
                v-if="machine.Brochure1Title && machine.Brochure1Url"
                :href="machine.Brochure1Url"
                class="brochure btn text link w-100"
                :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
            >
                <span class="fas fa-download showroom-color-primary"></span>
                {{ machine.Brochure1Title }}
            </a>
            <a
                v-if="machine.Brochure2Title && machine.Brochure2Url"
                :href="machine.Brochure2Url"
                class="brochure btn text link w-100 mt-2"
                :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
            >
                <span class="fas fa-download showroom-color-primary"></span>
                {{ machine.Brochure2Title }}
            </a>
            <a
                v-if="machine.Brochure3Title && machine.Brochure3Url"
                :href="machine.Brochure3Url"
                class="brochure btn text link w-100 mt-2"
                :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
            >
                <span class="fas fa-download showroom-color-primary"></span>
                {{ machine.Brochure3Title }}
            </a>
            <a
                v-if="machine.Brochure4Title && machine.Brochure4Url"
                :href="machine.Brochure4Url"
                class="brochure btn text link w-100 mt-2"
                :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
            >
                <span class="fas fa-download showroom-color-primary"></span>
                {{ machine.Brochure4Title }}
            </a>
        </div>
    </div>
</template>

<script>
    import ThemeMixin from '../mixins/ThemeMixin';

    export default {
        name: 'EquipmentDetailBrochures',
        mixins: [ThemeMixin],
        props: {
            machine: {
                type: Object,
                default: () => ({})
            }
        }
    };
</script>
