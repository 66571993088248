﻿<!--
Provides a fallback for 404's
-->
<template>
    <span ref="container" class="element-image d-flex flex-column" :class="containerClass">
        <slot v-if="src && !isLoaded && !isErrored">
            <img
                class="mx-auto"
                src="../../assets/icons/loading-ripple.gif"
                width="50"
                height="50"
                alt="Loading..."
            />
        </slot>
        <span>
            <transition name="fade">
                <img
                    v-if="src"
                    v-lazy-load="{ placeholder: placeholderImg, width: width }"
                    :class="imgClass"
                    :data-url="src || placeholderImg"
                    :key="id"
                    :width="width"
                    :alt="alt"
                    @error="onError"
                    @load="onLoaded"
                />
                <img v-else :src="placeholderImg" :alt="alt" :class="imgClass" :width="width" />
            </transition>
        </span>
    </span>
</template>

<script>
    import constants from '../../config/constants';

    import UtilitiesMixin from '../mixins/UtilitiesMixin';

    export default {
        name: 'ElementImage',
        mixins: [UtilitiesMixin],
        props: {
            src: {
                type: String,
                default: ''
            },
            alt: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            width: {
                type: [String, Number],
                default: ''
            },
            imgClass: {
                type: String,
                default: ''
            },
            containerClass: {
                type: [String],
                default: ''
            },
            id: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                isErrored: false,
                isLoaded: false
            };
        },
        computed: {
            placeholderImg() {
                return this.placeholder || constants.equipmentPlaceholderImage;
            }
        },
        methods: {
            onError() {
                this.isErrored = true;
            },
            onLoaded() {
                this.isLoaded = true;
            }
        }
    };
</script>
