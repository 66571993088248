<template>
    <div class>
        <!-- TITLE & MACHINE -->
        <div class="skeleton">
            <div class="skeleton-title"></div>
            <div class="skeleton-item"></div>
            <div class="skeleton-item my-4 p-5"></div>
        </div>
        <!-- BUTTONS -->
        <div class="skeleton">
            <div class="skeleton-item"></div>
            <div class="skeleton-item"></div>
            <div class="skeleton-item"></div>
        </div>
        <!-- GO BACK & START OVER -->
        <div class="skeleton skeleton-inline pt-3">
            <div class="skeleton-item py-3"></div>
            <div class="skeleton-item py-3"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MachineSuggesterResultSkeleton'
    };
</script>