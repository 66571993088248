﻿<template>
    <section class="blog container my-4">
        <featured-blog-post v-if="featuredPost.Id" :post="featuredPost"></featured-blog-post>
        <div v-if="showHeader" class="d-flex flex-wrap justify-content-between mt-5">
            <h3 class="border-left border-content mb-0">{{ headerTitle }}</h3>
            <a :href="listingUrl" class="btn btn-hollow-primary mt-3 mt-sm-0">See All News</a>
        </div>
        <div v-if="showFilter" class="d-flex flex-wrap justify-content-between mb-md-5 mt-md-5">
            <button
                v-for="category in featuredCategories"
                :key="category.Id"
                href="#"
                class="starred-button d-none d-sm-block p-2 px-lg-4"
                :class="{ selected: selectedCategory === category.Id }"
                @click="getPostsByCategory(category.Id)"
            >
                <i class="fas fa-star pr-2"></i>
                <strong>{{ category.Title }}</strong>
            </button>
            <div
                v-if="moreCategoryIds && moreCategoryIds.length > 0"
                class="select d-none d-sm-block ml-sm-3"
            >
                <select
                    v-model="selectedMoreCategory"
                    class="blog-select py-3 px-5"
                    @change="getPostsByMoreCategory()"
                >
                    <option value>More</option>
                    <option v-for="category in moreCategories" :key="category.Id" :value="category.Id">
                        {{ category.Title }}
                    </option>
                </select>
            </div>
            <div class="select d-block d-sm-none">
                <select v-model="selectedCategory" class="blog-select py-3 px-5" @change="getPosts()">
                    <option value>Recent Articles</option>
                    <option v-for="category in featuredCategories" :key="category.Id" :value="category.Id">
                        {{ category.Title }}
                    </option>
                    <option v-for="category in moreCategories" :key="category.Id" :value="category.Id">
                        {{ category.Title }}
                    </option>
                </select>
            </div>
            <div class="select ml-sm-3">
                <select v-model="selectedArchive" class="blog-select py-3 px-5" @change="getPosts()">
                    <option value>Archive</option>
                    <option v-for="archive in archives" :key="archive" :value="archive">{{ archive }}</option>
                </select>
            </div>
        </div>
        <div class="flex-wrap d-flex justify-content-between mt-3">
            <blog-post-item v-for="post in posts" :key="post.Title" :post="post"></blog-post-item>
        </div>
        <div v-if="posts.length === 0" class="text-center">No items to display</div>
    </section>
</template>

<script>
    import BlogService from '../../../services/BlogService';
    import TaxaService from '../../../services/TaxaService';

    import BlogPostItem from './BlogPostItem.vue';
    import FeaturedBlogPost from './BlogPostFeatured.vue';

    import { getMonthNumber } from '../../lib/utilities';

    export default {
        name: 'BlogPostList',
        components: {
            BlogPostItem,
            FeaturedBlogPost
        },
        props: {
            headerTitle: {
                type: String,
                default: ''
            },
            featuredCategoryIds: {
                type: Array,
                default: () => []
            },
            featuredPostId: {
                type: String,
                default: ''
            },
            listingUrl: {
                type: String,
                required: true
            },
            moreCategoryIds: {
                type: Array,
                default: () => []
            },
            showHeader: {
                type: Boolean,
                default: false
            },
            showFilter: {
                type: Boolean,
                default: false
            },
            totalItems: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                archives: [],
                featuredCategories: [],
                featuredPost: {},
                moreCategories: [],
                posts: [],
                selectedArchive: '',
                selectedCategory: '',
                selectedMoreCategory: ''
            };
        },
        computed: {
            filterPostsByCategory() {
                return this.showFilter && this.featuredCategoryIds.length > 0;
            }
        },
        mounted() {
            this.getArchives();
            this.getCategoryData();
            this.getFeaturedBlogPost();

            if (!this.filterPostsByCategory) {
                this.getPosts();
            }
        },
        methods: {
            getArchives() {
                BlogService.getArchives()
                    .then(response => (this.archives = response))
                    .catch(error => console.error(error));
            },
            getCategoryData() {
                if (this.featuredCategoryIds.length > 0) {
                    TaxaService.getCategoriesFromIds(this.featuredCategoryIds)
                        .then(response => {
                            this.featuredCategories = response.value;

                            if (this.filterPostsByCategory) {
                                this.getPostsByCategory(this.featuredCategories[0].Id);
                            }
                        })
                        .catch(error => console.error(error));
                }

                if (this.moreCategoryIds.length > 0) {
                    TaxaService.getCategoriesFromIds(this.moreCategoryIds)
                        .then(response => {
                            this.moreCategories = response.value;
                        })
                        .catch(error => console.error(error));
                }
            },
            getFeaturedBlogPost() {
                if (this.featuredPostId) {
                    BlogService.getBlogpostFromId(this.featuredPostId)
                        .then(response => (this.featuredPost = response))
                        .catch(error => console.error(error));
                }
            },
            getPosts() {
                let month = null,
                    year = null;

                if (this.selectedArchive) {
                    let splitArchive = this.selectedArchive.split(' ');
                    month = getMonthNumber(splitArchive[0]);
                    year = splitArchive[1];
                }

                BlogService.getPosts(
                    month,
                    year,
                    this.selectedCategory || this.selectedMoreCategory,
                    this.totalItems
                ).then(response => (this.posts = response.value));
            },
            getPostsByCategory(id) {
                this.selectedCategory = id;
                const more = this.moreCategories.find(item => item.Id === id);
                this.selectedMoreCategory = more || '';
                this.getPosts();
            },
            getPostsByMoreCategory() {
                this.selectedCategory = this.selectedMoreCategory;
                this.getPosts();
            }
        }
    };
</script>
