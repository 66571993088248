﻿<template>
    <div class="container my-5">
        <div v-for="(main, index) in events.slice(0, 1)" :key="index">
            <div class="row">
                <div class="col-lg-4 col-sm-12 align-self-end p-3">
                    <h6 class="mb-md-3">
                        <span class="text-primary underline">Upcoming Event</span>
                    </h6>
                    <div class="row align-items-center h-100 mb-3">
                        <div class="col-4">
                            <time :datetime="main.EventStart" class="icon">
                                <em>{{ eventDay(main.EventStartWithOffset) }}</em>
                                <strong>{{ eventMonth(main.EventStartWithOffset) }}</strong>
                                <span>{{ eventDate(main.EventStartWithOffset) }}</span>
                            </time>
                        </div>
                        <div class="col-8">
                            <h3>{{ main.Title }}</h3>
                            <h5>{{ main.City }}, {{ main.State }}</h5>
                        </div>
                    </div>

                    <p v-if="main.EventStart">
                        <i class="far fa-clock"></i>
                        <span v-if="main.AllDayEvent == true">All Day</span>
                        <span v-else>{{ eventTime(main.EventStartWithOffset) }} - {{ eventTime(main.EventEndWithOffset) }}</span>
                    </p>
                    <p>
                        <i class="fas fa-map-marker-alt"></i>
                        <span>{{ main.Street }} {{ main.City }}, {{ main.State }}</span>
                    </p>
                    <div>
                        <router-link
                            class="btn btn-primary"
                            :to="{
                                name: 'details',
                                params: {
                                    year: getYear(main),
                                    month: getMonth(main),
                                    day: getDay(main),
                                    calendar: main.Parent.UrlName,
                                    detail: main.UrlName,
                                    event: main,
                                    events: events
                                }
                            }"
                        >
                            View Details
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-8 col-sm-12 featured-img-box">
                    <element-image
                        class="img-fluid featured-border"
                        :src="main.EventImage[main.EventImage.length - 1].Url"
                        :alt="main.EventImage[main.EventImage.length - 1].AlternativeText"
                    ></element-image>
                </div>
            </div>
        </div>

        <div class="row">
            <div v-for="(event, index) in events" :key="index" class="col-md-6 col-sm-12">
                <relatedEvents :event="event"></relatedEvents>
            </div>
        </div>
    </div>
</template>

<script>
    import eventService from '../../../services/EventService';
    import relatedEvents from './RelatedEvents.vue';
    import eventMixin from '../mixins/EventMixin';

    export default {
        name: 'EventsList',
        components: {
            relatedEvents
        },
        mixins: [eventMixin],
        data() {
            return {
                events: []
            };
        },
        computed: {
            address() {
                if (!this.events) {
                    return '';
                }
                return this.events.Street + ' ' + this.events.City + ', ' + this.events.State;
            },
            detail() {
                return this.$route && this.$route.path.indexOf('/detail') > -1;
            }
        },
        mounted() {
            eventService.getAllEvents().then(value => this.events = value);
        }
    };
</script>

<style scoped>
    .underline {
        border-bottom: 2px solid;
        padding: 5px 0;
        text-decoration: none;
    }

    .event-link {
        flex-wrap: wrap;
    }

    .event-link a {
        color: black;
    }

    .card-title {
        color: black;
    }
</style>
