<template>
    <div ref="wrapper" class="location-list-rounded">
        <h1 class="text-primary text-center mb-5">FIND A LOCATION</h1>
        <div ref="map" class="map w-100"></div>
        <div v-if="locations.length > 0" class="px-4 px-xl-5 py-5">
            <splide v-if="windowWidth >= 768" class="pb-0 px-5" :options="sliderOptions">
                <splide-slide v-for="location in locations" :key="location.Id">
                    <location-list-rounded-card :enable-router="enableRouter" :location="location" />
                </splide-slide>

                <template #controls>
                    <splide-rounded-arrows />
                </template>
            </splide>

            <div v-else class="row">
                <div v-for="location in locations.slice(0, 3)" :key="location.Id" class="col-12 card-wrapper">
                    <location-list-rounded-card :enable-router="enableRouter" :location="location" />
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center pb-5">
            <a :href="elementConfig.SITEMAP.LOCATIONS" class="btn btn-primary rounded-pill px-5 py-3">
                View All Locations
            </a>
        </div>
    </div>
</template>

<script>
    import constants from '../../config/constants';

    import LocationListRoundedCard from './LocationListRoundedCard';
    import LocationMixin from '../mixins/LocationMixin';
    import LocationService from '../../../services/LocationService';
    import SplideRoundedArrows from '../utility/SplideRoundedArrows';
    import UtilitiesMixin from '../mixins/UtilitiesMixin';

    export default {
        name: 'LocationListRounded',
        components: {
            LocationListRoundedCard,
            SplideRoundedArrows
        },
        mixins: [LocationMixin, UtilitiesMixin],
        props: {
            backgroundImage: {
                type: String,
                required: true
            },
            excludedLocations: {
                type: String,
                required: true
            },
            enableRouter: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                elementConfig: constants.elementConfig,
                locations: [],
                windowWidth: document.documentElement.clientWidth
            };
        },
        computed: {
            sliderOptions() {
                return {
                    autoplay: true,
                    breakpoints: {
                        991: {
                            perPage: 2
                        },
                        576: {
                            perPage: 1
                        }
                    },
                    gap: '1rem',
                    pagination: false,
                    perPage: Math.min(this.locations.length, 3),
                    rewind: true,
                    speed: 1000
                };
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.setWindowSize);
            });

            this.setBackground(this.$refs.wrapper, this.backgroundImage);

            LocationService.getLocations().then(result => {
                this.locations = result.locations;

                this.locations = result.locations.filter(
                    el =>
                        this.excludedLocations.split(',').indexOf(el.DealerLocationTitle) < 0 && !el.HideLocation
                );

                this.initMap(this.$refs.map, this.locations);
            });
        },
        methods: {
            setWindowSize() {
                this.windowWidth = document.documentElement.clientWidth;
            }
        }
    };
</script>
