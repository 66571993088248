<template>
    <div class="saved-equipment d-flex align-items-center h-100" :class="{ rounded: rounded }">
        <div v-if="rounded" class="d-flex align-items-center h-100 text-nowrap">
            <button
                class="d-flex align-items-center saved-eq-button my-auto rounded border-0"
                @click="showSavedEquipment"
            >
                <span class="fas fa-star" :class="iconClass"></span>
                <span v-show="!iconOnly" class="text-primary px-1">{{ SAVED_EQUIPMENT_LABEL }}</span>
                <span v-show="savedEquipmentCounter" class="badge badge-primary">{{ savedEquipmentCounter }}</span>
            </button>
            <button type="button" class="compare-button border-0 ml-1">
                <a :href="COMPARE_LINK" class="d-flex align-items-center">
                    <span class="fas fa-exchange-alt" :class="iconClass"></span>
                    <span v-show="!iconOnly" class="text-primary px-1">{{ COMPARE_LABEL }}</span>
                </a>
            </button>
        </div>
        <div v-else class="d-inline-flex w-100 h-100 text-nowrap">
            <button
                :class="{ arrow_box: savedEquipmentCounter > 0, 'w-100': savedEquipmentCounter == 0 }"
                class="d-none d-md-block saved-eq-button bg-gray-100 py-0 border-0"
                type="button"
                aria-label="Show saved equipment"
                @click="showSavedEquipment"
            >
                <span class="fas fa-star text-primary" :class="iconClass"></span>
                <span v-if="!iconOnly" class="text-primary">{{ SAVED_EQUIPMENT_LABEL }}</span>
            </button>
            <span
                v-if="savedEquipmentCounter > 0"
                class="d-none d-md-flex align-items-center bg-primary text-primary-yiq saved-badge px-3 position-relative"
                @click="showSavedEquipment"
            >
                {{ savedEquipmentCounter }}
            </span>
            <button type="button" class="compare-button border-0">
                <a :href="COMPARE_LINK">
                    <span v-if="!iconOnly" class="text-primary">{{ COMPARE_LABEL }}</span>
                    <span class="fas fa-exchange-alt" :class="iconClass"></span>
                </a>
            </button>
        </div>
        <div
            :class="{
                'saved-eq': showSavedEq && !transOut,
                'saved-out': transOut
            }"
        >
            <div v-if="showSavedEq" class="h-100">
                <div
                    class="saved-heading bg-primary text-primary-yiq d-flex align-items-center justify-content-between px-sm-5 p-4"
                >
                    <h4 class="mb-0">Your Saved Equipment</h4>
                    <div class="font-weight-bold cursor-pointer" @click="closeSavedEquipment">
                        <i class="far fa-2x fa-times-circle"></i>
                    </div>
                </div>
                <div
                    v-if="compareEquipmentCounter"
                    class="comparing bg-dark text-white w-100 mt-3 mx-auto d-flex justify-content-between align-items-center py-2"
                >
                    <div class="pl-3">
                        <span>{{ compareEquipmentCounter }} Item</span> Selected
                    </div>
                    <div class="pr-3">
                        <a :href="COMPARE_LINK" class="text-white">
                            Compare Now
                            <span>
                                <i class="fa fa-chevron-right mx-2"></i>
                            </span>
                        </a>
                    </div>
                </div>
                <div v-if="compareEquipmentCounter" class="comparing-shadow pb-3"></div>
                <div
                    class="saved-equipment-list d-flex justify-content-flex-start align-items-center flex-column ml-3 pr-3"
                >
                    <saved-equipment-item
                        v-for="(machine, index) in savedEquipmentMachines"
                        :key="index"
                        :machine="machine"
                    ></saved-equipment-item>
                </div>

                <h5 v-if="!savedEquipmentMachines.length" class="px-5 py-3">
                    You haven't saved any equipment yet
                </h5>
            </div>
        </div>
    </div>
</template>

<script>
    import StoreConstants from '../../store/constants';
    import { mapGetters } from 'vuex';
    import SavedEquipmentItem from './SavedEquipmentItem.vue';

    export default {
        name: 'SavedEquipment',
        components: {
            SavedEquipmentItem
        },
        props: {
            iconClass: {
                default: '',
                type: String
            },
            iconOnly: {
                default: false,
                type: Boolean
            },
            rounded: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                COMPARE_LABEL: 'COMPARE',
                COMPARE_LINK: '/Compare',
                SAVED_EQUIPMENT_LABEL: 'SAVED EQ',
                showSavedEq: false,
                transOut: false
            };
        },
        computed: {
            ...mapGetters({
                savedEquipmentMachines: StoreConstants.EQUIPMENT.GETTERS.SAVED_EQUIPMENT_MACHINES,
                savedEquipmentCounter: StoreConstants.EQUIPMENT.GETTERS.SAVED_EQUIPMENT_COUNTER,
                compareEquipmentCounter: StoreConstants.EQUIPMENT.GETTERS.COMPARE_EQUIPMENT_COUNTER
            })
        },
        watch: {
            savedEquipmentCounter: function(counter) {
                if (counter === 0) {
                    this.closeSavedEquipment();
                }
            }
        },
        created() {
            this.$store.dispatch(StoreConstants.EQUIPMENT.ACTIONS.LOAD_SAVED_EQUIPMENT);
        },
        methods: {
            showSavedEquipment(e) {
                // Show Saved EQ
                if (this.showSavedEq === false || this.transOut === false) {
                    e.preventDefault();
                }
                this.transOut = false;
                this.showSavedEq = true;
            },
            closeSavedEquipment() {
                this.transOut = true;
                setTimeout(() => (this.showSavedEq = false), 500);
            }
        }
    };
</script>
