export default function({ listRouteName, itemRouteName, listRouteParams, pathField, itemField } = {}) {
    /**
     * Pushes form state changes to the path and querystring.
     * Persists showroom changes applied to the navbar, sort, grid size and pagination.
     * @param vue
     */
    this.handleFormStateChange = function(vue) {
        if (formStateIsStale(vue)) {
            let formState = vue.$store.state.search.formState;
            let hierarchySegments = formState[pathField] && formState[pathField].split('|');

            // get all parts of formState except for ResultHierarchyPath and UrlName to add to the querystring
            let queryStringData = {
                ...formState
            };
            delete queryStringData[pathField];
            delete queryStringData[itemField];

            // copy hierarchy path to the route params
            let params =
                hierarchySegments &&
                hierarchySegments.reduce((acc, curr, i) => {
                    if (listRouteParams[i]) {
                        acc[listRouteParams[i]] = curr;
                    }
                    return acc;
                }, {});

            vue.$router
                .push({
                    name: listRouteName,
                    params,
                    query: queryStringData
                })
                /*eslint no-unused-vars: 0*/
                .catch(err => {});
        }
    };

    this.install = function(vue) {
        vue.$router.afterEach(() => {
            this.handlePersistedDataChange(vue);
        });
    };

    /**
     * Pushes path or querystring changes to formstate.
     * @param vue
     */
    this.handlePersistedDataChange = function(vue) {
        if (!formStateIsStale(vue)) {
            return;
        }
        let formState = getPersistedState(vue);
        vue.$store.commit('search/resetFormValues', formState);
    };

    this.getInitialState = function(vue) {
        return getPersistedState(vue);
    };

    //#region private methods

    function formStateIsStale(vue) {
        let routeData = getPersistedState(vue);

        // make a copy of form state so sorting doesn't try to modify it
        let formState = {
            ...vue.$store.state.search.formState
        };

        // sort both objects to help ensure a match
        routeData = sortObject(routeData);
        formState = sortObject(formState);

        return JSON.stringify(routeData) !== JSON.stringify(formState);
    }

    /**
     * Retrieve form state that's persisted to the route (path and querystring)
     */
    function getPersistedState(vue) {
        let isItemRoute = vue.$route.name === itemRouteName;

        // first, get querystring data
        let formState = { ...vue.$route.query };

        // get hierarchy path and convert back to pipe-delimited path
        formState[pathField] = listRouteParams
            .map(p => vue.$route.params[p])
            .filter(x => x) // filter removes nulls
            .join('|');

        // add item slug to formState if user is viewing a detail page of the showroom
        if (isItemRoute) {
            formState[itemField] = vue.$route.params.model;
        }

        return formState;
    }

    function sortObject(obj) {
        return Object.keys(obj)
            .sort()
            .reduce((result, key) => {
                result[key] = obj[key];

                if (Array.isArray(result[key])) {
                    result[key] = result[key].sort();
                }
                return result;
            }, {});
    }

    //#endregion private methods
}
