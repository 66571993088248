﻿<template>
    <div class="px-3 py-5 d-flex video-container">
        <h1 class="text-center">Videos</h1>
        <div class="video-wrapper">
            <iframe :src="machine.VideoUrl" class="video-player"></iframe>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EquipmentDetailVideos',
        props: {
            machine: {
                type: Object
            }
        },
        mounted() {},
        watch: {
            machine: function (newValue) {}
        }
    };
</script>