﻿<template>
    <ul class="mega-menu">
        <li
            v-for="(page, index) in pages"
            :key="index"
            class="border-0"
            @mouseenter="handleEnter(index, $event)"
            @mouseleave="handleLeave"
        >
            <a class="d-inline-block" :href="page.Url.substr(1)" :target="page.Target">
                <span>{{ page.Title.toUpperCase() }}</span>
                <i
                    v-if="showCaret && page.children && page.children.length"
                    class="fas fa-caret-down ml- text-gray-400"
                ></i>
            </a>
            <div v-show="showSubpages === index" ref="menuItems" class="mega-menu-items">
                <div class="container">
                    <div class="row text-center justify-content-center">
                        <mega-subpages
                            v-for="child in page.children"
                            :key="child.Id"
                            :children="child.children"
                            :title="child.Title"
                            :url="child.Url.substr(1)"
                            :image="child.PageImageUrls[0]"
                            :target="child.Target"
                        ></mega-subpages>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
    import MegaSubpages from './MegaSubPages';
    import PageService from '../../../services/PageService';

    export default {
        name: 'MegaMenu',
        components: {
            MegaSubpages
        },
        props: {
            mainNavPages: {
                type: Array,
                default: () => []
            },
            showCaret: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                pages: [],
                showSubpages: null,
                megamenuIndex: null
            };
        },
        created() {
            PageService.mapPages(this.mainNavPages).then(result => (this.pages = result));
        },
        methods: {
            handleEnter(index, event) {
                this.megamenuIndex = index;

                setTimeout(() => {
                    this.showSubpages = this.megamenuIndex;
                }, 400);

                let rect = event.target.getBoundingClientRect();
                let xPos = rect.x || rect.left;
                this.$refs.menuItems[index].style.left = '-' + xPos + 'px';
            },
            handleLeave() {
                this.megamenuIndex = null;
                this.showSubpages = null;
                this.level = false;
            }
        }
    };
</script>