﻿<template>
    <div class="build-your-own-content">
        <div class="container">
            <div class="row row-cols-1 justify-content-center px-3">
                <div
                    v-for="item in machineCollection"
                    :key="item.Id"
                    class="h-100 card shadow col mb-3 mx-md-2 px-5 py-3 fade-in"
                >
                    <div class="row pt-3 px-2">
                        <div class="col-12 col-md-6 h3 text-primary class text-capitalize">
                            {{ getTitle }}
                        </div>
                        <div
                            class="
                                col-12 col-md-6
                                flex-wrap
                                d-md-flex
                                justify-content-end
                                align-items-baseline
                            "
                        >
                            <span class="text-black-50 h6 mr-1 mb-0">List Price</span>
                            <s v-if="item.StartingPrice" class="h5 text-danger mb-0">{{
                                currencyFormat(item.ListPrice)
                            }}</s>
                            <span v-else class="h5 text-dark mb-0">{{ currencyFormat(item.ListPrice) }}</span>
                            <div v-if="item.StartingPrice" class="text-right h5 text-dark w-100">
                                {{ currencyFormat(item.StartingPrice) }}
                            </div>
                        </div>
                    </div>
                    <hr class="border-primary" />
                    <div class="row">
                        <div class="col-12 col-md justify-content-center">
                            <element-image
                                container-class="d-flex justify-content-center mb-3"
                                :src="getMachineImage(item)"
                                :alt="item.Title"
                            ></element-image>
                        </div>
                        <div class="col-12 col-md-6">
                            <h4 class="text-dark">{{ item.Title }}</h4>
                            <div class="text-black-50" v-html="item.Summary"></div>
                        </div>
                        <div class="col-12 col-md d-flex flex-column align-items-center align-items-md-end">
                            <router-link
                                tag="button"
                                :to="getPath(item.UrlName)"
                                class="btn btn-secondary btn-sm btn-block text-uppercase"
                                exact
                                >Build</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Utilities
    import utilities from '../../lib/utilities';

    export default {
        props: {
            machineCollection: {
                type: Array,
                required: true
            }
        },
        computed: {
            getTitle() {
                let path = this.$route.path.split('/');
                return path[path.length - 1].replace(/-/g, ' ');
            },
            getPath() {
                return machine => {
                    return {
                        params: { ...this.$route.params },
                        path: `${this.$route.path}/builder`,
                        query: { machine }
                    };
                };
            },
            getMachineImage() {
                return item => {
                    if (!!item.Images) {
                        return item.Images.replace(/&nbsp;/g, '').split(',')[0];
                    } else {
                        return 'https://via.placeholder.com/250x150.png';
                    }
                };
            }
        },
        methods: {
            currencyFormat(number) {
                return utilities.currencyFormat.format(number);
            }
        }
    };
</script>
