﻿<template>
    <div class="w-100 pt-4">
        <h1 class="text-center mb-4">Gallery</h1>
        <span v-if="!showSlider" class="fas fa-spinner fa-spin"></span>
        <splide v-else :options="sliderOptions">
            <splide-slide v-for="(image, i) in machine.Images.split(',')" :key="i" class="px-0">
                <img :data-splide-lazy="image" :alt="`${machine.Title} image ${i}`"/>
            </splide-slide>
        </splide>
    </div>
</template>

<script>
    export default {
        name: 'EquipmentDetailGallery',
        props: {
            machine: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                showSlider: false,
                sliderOptions: {
                    autoplay: true,
                    arrows: false,
                    breakpoints: {
                        576: {
                            height: '200px'
                        },
                        991: {
                            height: '250px'
                        }
                    },
                    gap: '1rem',
                    height: '450px',
                    lazyLoad: 'sequential',
                    rewind: true,
                    speed: 1000
                }
            };
        },
        mounted() {
            setTimeout(() => {
                this.showSlider = true;
            }, 1000); // The DOM needs to be fully loaded to insert the Slider. 1000 in case of slow connections
        }
    };
</script>
