﻿<template>
    <article class="blog-featured d-flex flex-md-row-reverse flex-column">
        <aside class="blog-img-container mb-0 featured-img-box d-flex flex-column bg-white">
            <element-image
                img-class="img-fluid featured-border mh-100"
                container-class="element-image d-flex justify-content-center align-items-center h-md-100"
                :src="postImage"
                :alt="postAltText"
            ></element-image>
        </aside>
        <div class="featured-info-box d-flex flex-column justify-content-end m-md-3">
            <div class="d-flex justify-content-between mt-3">
                <em class="blog-subtitle mb-3">Featured Post</em>
                <div class="mb-1 justify-content-end">
                    <i class="far fa-calendar-alt blog-icon"></i>
                    <small>Posted {{ getDate(post.PublicationDate) }}</small>
                </div>
            </div>
            <h2 class="blog-title mt-3 mb-3">{{ post.Title }}</h2>
            <p v-if="post.Summary.length > 145" class="mb-3">{{ post.Summary.substring(0, 145) }} ...</p>
            <p v-else class="mb-3">{{ post.Summary }}</p>
            <div>
                <router-link class="btn btn-primary px-5" :to="routeData">Read Post</router-link>
            </div>
        </div>
    </article>
</template>

<script>
    import blogPostMixins from '../mixins/BlogPostMixin';

    export default {
        name: 'BlogPostFeatured',
        mixins: [blogPostMixins],
        computed: {
            routeData() {
                return {
                    name: 'details',
                    params: {
                        blog: this.post.Parent.UrlName,
                        year: this.year,
                        month: this.month,
                        day: this.day,
                        detail: this.post.UrlName,
                        post: this.post
                    }
                };
            }
        }
    };
</script>
