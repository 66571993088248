<template>
    <div v-if="attachments.length > 0" class="d-flex flex-column justify-content-center">
        <div class="text-primary text-center text-md-left font-weight-bold">SUGGESTED IMPLEMENTS</div>
        <hr class="w-100 pb-3 pb-md-0" />
        <div v-for="(attachment, i) in attachments.slice(0, 3)" :key="i" class="row mb-4 my-md-2">
            <div class="col-4">
                <img class="p-3 p-lg-1" :src="attachment.Image" />
            </div>
            <div class="col-8 d-flex flex-column justify-content-center suggested-implement">
                <div class="font-weight-bold">{{attachment.Title}}</div>
                <div>{{attachment.Description}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MachineSuggesterSuggestedImplements',
        props: {
            attachments: {
                type: Array,
                default: () => []
            }
        }
    };
</script>
