<template>
    <div class="mt-3 mt-md-0">
        <h3 class="text-primary text-uppercase">Affordability Calculator</h3>

        <div class="row py-3 border-top">
            <div class="col-6 my-auto">
                <span class="font-weight-bold">* Desired monthly payment:</span>
            </div>
            <div class="col-6">
                <input
                    v-model="desiredMonthlyPayment"
                    class="bg-white form-control"
                    placeholder="e.g. $250"
                    type="number"
                    min="1"
                />
            </div>
        </div>

        <div class="row py-3 border-top">
            <div class="col-6 my-auto">
                <span class="font-weight-bold">Sales tax:</span>
            </div>
            <div class="col-6">
                <input v-model="tax" class="form-control" placeholder="e.g. 6.5%" type="number" min="0" />
            </div>
        </div>

        <div class="row py-3 border-top">
            <div class="col-6 my-auto">
                <span class="font-weight-bold">* Terms in months:</span>
            </div>
            <div class="col-6">
                <input v-model="months" class="form-control" placeholder="e.g. 5" type="number" min="1" />
            </div>
        </div>

        <div class="row py-3 border-top">
            <div class="col-6 my-auto">
                <span class="font-weight-bold">* Rate/APR:</span>
            </div>
            <div class="col-6">
                <input v-model="rate" class="form-control" placeholder="e.g. 10" type="number" min="0" />
            </div>
        </div>

        <div class="row py-3 border-top">
            <div class="col-6 my-auto">
                <span class="font-weight-bold">Down Payment or Trade-In Value:</span>
            </div>
            <div class="col-6">
                <input
                    v-model="downPayment"
                    class="form-control"
                    placeholder="e.g. $0"
                    type="number"
                    min="0"
                />
            </div>
        </div>

        <div class="row py-3 border-top">
            <div class="col-6 my-auto">
                <h5 class="text-primary">ESTIMATED VEHICLE PRICE</h5>
            </div>
            <div class="col-6 my-auto">
                <h4 class="font-weight-bold">{{ fullPrice }}</h4>
            </div>
        </div>
    </div>
</template>

<script>
    import { currencyFormat } from '../../lib/utilities';

    export default {
        name: 'AffordabilityCalculator',
        data() {
            return {
                downPayment: null,
                desiredMonthlyPayment: null,
                months: null,
                rate: null,
                tax: null
            };
        },
        computed: {
            monthlyPayment() {
                return this.desiredMonthlyPayment > 0 ? parseFloat(this.desiredMonthlyPayment) : 0;
            },
            monthlyRate() {
                return this.rate > 0 ? parseFloat(this.rate) / 100 / 12 : 0;
            },
            fullPrice() {
                if (!this.monthlyPayment || !this.months) {
                    return currencyFormat.format(0);
                }

                let xRatio = 1 / this.months;

                if (this.rate > 0) {
                    let x = Math.pow(1 + this.monthlyRate, this.months);
                    xRatio = (x * this.monthlyRate) / (x - 1);
                }

                let price = this.monthlyPayment / (xRatio * this.taxRatio);
                price += this.downPayment > 0 ? parseFloat(this.downPayment) : 0;

                return currencyFormat.format(Math.floor(price));
            },
            taxRatio() {
                return 1 + (this.tax > 0 ? parseFloat(this.tax) / 100 : 0);
            }
        }
    };
</script>
