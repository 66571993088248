﻿<template>
    <div>
        <div class="container my-5">
            <div class="row">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-3">
                            <element-image
                                class="card-img-top"
                                :src="event.EventImage[event.EventImage.length - 1].Url"
                                :alt="event.EventImage[event.EventImage.length - 1].AlternativeText"
                                img-class="object-fit-cover"
                            ></element-image>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <div class="d-none d-md-block">
                                    <time :datetime="event.EventStart" class="icon">
                                        <em>{{ eventDay(event.EventStart) }}</em>
                                        <strong>{{ eventMonth(event.EventStart) }}</strong>
                                        <span>{{ eventDate(event.EventStart) }}</span>
                                    </time>
                                </div>
                            </div>
                            <div class="col-md-9 col-sm-12">
                                <h5 class="card-title text-dark">{{ event.Title }}</h5>
                                <span class="d-sm-block d-md-none">
                                    <i class="far fa-calendar-alt pr-2"></i>
                                    {{ getDate(event.EventStart) }}
                                </span>
                                <div v-if="event.EventStart">
                                    <i class="far fa-clock"></i>
                                    <span v-if="event.AllDayEvent == true">All Day</span>
                                    <span v-else
                                        >{{ eventTime(event.EventStart) }} -
                                        {{ eventTime(event.EventEnd) }}</span
                                    >
                                </div>
                                <span>
                                    <i class="fas fa-map-marker-alt pr-2"></i>
                                    {{ event.Street }} {{ event.City + ', ' + event.State }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <router-link
                        :to="{
                            name: 'details',
                            params: {
                                year: year,
                                month: month,
                                day: day,
                                calendar: event.Parent.UrlName,
                                detail: event.UrlName,
                                event: event
                            }
                        }"
                    >
                        <div
                            class="card-footer text-center font-weight-bold text-dark"
                            :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                        >
                            View Event Info
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventMixin from '../mixins/EventMixin';
    import ThemeMixin from '../mixins/ThemeMixin';

    export default {
        mixins: [EventMixin, ThemeMixin],
        props: {
            event: {
                type: Object,
                required: true
            }
        },
        computed: {
            detail: function() {
                return this.$route && this.$route.path.indexOf('/detail') > -1;
            }
        }
    };
</script>
