<template>
    <div ref="wrapper">
        <div class="container pt-3 pt-md-5">
            <div v-if="featuredOffer">
                <div class="desktop-offer d-none d-md-flex row no-gutters mx-0 mb-5 p-3">
                    <div class="col-md-12 col-lg-6 align-self-center">
                        <h6><i class="fas fa-star pr-2"></i>Deal of the Month</h6>
                        <h3 v-html="featuredOffer.Title"></h3>
                        <h4 v-html="featuredOffer.Subtitle"></h4>
                        <br />

                        <router-link
                            class="btn btn-primary"
                            :to="{
                                name: 'details',
                                params: { detail: featuredOffer.UrlName, offer: featuredOffer }
                            }"
                            >View Details</router-link
                        >
                    </div>

                    <div v-if="dealerPromotion" class="col-md-12 col-lg-6 pt-md-3">
                        <div v-if="featuredOffer.Image && featuredOffer.Image.length > 0">
                            <img
                                :src="featuredOffer.Image[featuredOffer.Image.length - 1].Url"
                                :alt="featuredOffer.Title"
                                class="special-offers-img img-fluid"
                            />
                        </div>
                    </div>

                    <div v-else class="col-md-12 col-lg-6 pt-md-3">
                        <img
                            :src="featuredOffer.Image"
                            :alt="featuredOffer.Title"
                            class="special-offers-img img-fluid"
                        />
                    </div>
                </div>

                <!--Featured Offer card on mobile-->
                <div class="mobile-offer d-block d-md-none mb-5">
                    <div class="card h-100">
                        <h5 class="card-header text-center">
                            <i class="fas fa-star pr-2"></i>Deal of the Month
                        </h5>
                        <div class="card-body">
                            <div class="col-lg-6 col-sm-12 p-0">
                                <div v-if="dealerPromotion">
                                    <div v-if="featuredOffer.Image && featuredOffer.Image.length > 0">
                                        <img
                                            :src="featuredOffer.Image[featuredOffer.Image.length - 1].Url"
                                            :alt="featuredOffer.Title"
                                            class="card-img-top mb-3 object-fit-cover"
                                        />
                                    </div>
                                </div>
                                <div v-else>
                                    <img
                                        :src="featuredOffer.Image"
                                        :alt="featuredOffer.Title"
                                        class="card-img-top mb-3 object-fit-cover"
                                    />
                                </div>
                            </div>
                            <h5 class="card-title" v-html="featuredOffer.Title"></h5>
                            <div class="subtitle" v-html="featuredOffer.Subtitle"></div>
                        </div>
                        <router-link
                            class="card-footer text-center bg-primary text-primary-yiq"
                            :to="{
                                name: 'details',
                                params: { detail: featuredOffer.UrlName, offer: featuredOffer }
                            }"
                            >Details</router-link
                        >
                    </div>
                </div>
            </div>
            <related-offers
                :base-url="baseUrl"
                :filtered-by-machines="filteredByMachines"
                :filtered-by-promotions="filteredByPromotions"
                :manufacturer="manufacturer"
                :selected-promotions-ids="selectedPromotionsIds"
                :use-router="useRouter"
                :show-pagination="showPagination"
            ></related-offers>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import relatedOffers from './RelatedSpecialOffers.vue';
    import promoService from '../../../services/PromotionService';

    export default {
        name: 'SpecialOffers',
        components: {
            relatedOffers: relatedOffers
        },
        props: {
            backgroundImageUrl: {
                type: String,
                default: ''
            },
            baseUrl: {
                type: String,
                required: true
            },
            featuredItemId: {
                type: String,
                default: ''
            },
            filteredByMachines: {
                type: Boolean,
                required: true
            },
            filteredByPromotions: {
                type: Boolean,
                required: true
            },
            manufacturer: {
                type: String,
                required: true
            },
            selectedPromotionsIds: {
                type: Array,
                default: () => []
            },
            useRouter: {
                type: Boolean,
                default: false
            },
            showPagination: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                featuredOffer: undefined,
                dealerPromotion: false
            };
        },
        mounted() {
            this.dealerPromotion = this.manufacturer === 'dealer';

            if (this.backgroundImageUrl) {
                this.$refs.wrapper.style.cssText = `background: url(${this.backgroundImageUrl}); background-size: cover`;
            }

            this.getFeaturedItemData();
        },
        methods: {
            getFeaturedItemData() {
                if (this.featuredItemId) {
                    promoService
                        .getPromotionById(this.manufacturer, this.featuredItemId)
                        .then(response => (this.featuredOffer = response))
                        .catch(reason => console.error(reason));
                }
            }
        }
    };
</script>
