<template>
    <div class="modal fade" :id="this.id" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ this.title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <iframe class="h-100 w-100 border-0" :src="iframeSource"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .modal-body {
        height: 75vh;
    }
</style>

<script>
    export default {
        props: {
            id: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            iframeSource: {
                type: String,
                required: true
            }
        }
    };
</script>
