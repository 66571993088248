﻿<template>
    <div :class="['card card-feature d-flex mx-auto', { shadow: enableShadow }]">
        <div class="row no-gutters">
            <div class="col-12 col-md-7 order-md-2">
                <div class="card-img-container p-2 shadow-sm">
                    <element-image
                        v-if="image"
                        :alt="image.alt"
                        img-class="align-self-start card-img"
                        :src="image.src"
                    />
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="card-body">
                    <component :is="`h${titleSize}`" class="card-title">
                        {{ title }}
                    </component>
                    <p class="card-text mb-3" v-html="description"></p>
                    <a
                        v-if="enableLink"
                        :href="actionUrl"
                        :target="target"
                        :class="['btn px-5 py-1', btnStyle]"
                    >
                        {{ actionName }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            image: {
                type: Object,
                default: () => ({})
            },
            title: {
                type: String,
                default: ''
            },
            titleSize: {
                type: Number,
                default: 3
            },
            description: {
                type: String,
                default: ''
            },
            enableButtonLink: {
                type: Boolean
            },
            actionName: {
                type: String,
                default: ''
            },
            actionUrl: {
                type: String,
                default: ''
            },
            btnStyle: {
                type: String,
                default: 'btn-primary'
            },
            enableShadow: {
                type: Boolean,
                default: true
            },
            openLinkInNewTab: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            enableLink() {
                return this.enableButtonLink && this.actionName && this.actionUrl;
            },
            target() {
                return this.openLinkInNewTab ? '_blank' : '_self';
            }
        }
    };
</script>
