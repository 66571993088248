<template>
    <div>
        <div v-for="(question, i) in answeredQuestions" :key="i">
            <div class="row">
                <div class="col-6 font-weight-bold text-uppercase">{{ question.Tag }}</div>
                <div class="col-6 pl-0">
                    <div
                        class="text-right text-primary"
                        v-for="(answer, j) in question.Answers.filter(an => an.Type === 'Text' && an.Text || an.IsSelected)"
                        :key="j"
                    >{{ answer.Text }}</div>
                </div>
            </div>
            <hr />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MachineSuggesterSummaryContent',
        props: {
            answeredQuestions: {
                type: Array,
                required: true
            }
        }
    };
</script>
