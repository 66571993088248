<template>
    <div class="font-weight-bold">
        <div class="d-flex flex-row align-items-center mt-2">
            <hr class="flex-grow-1" />
            <div class="px-3 text-gray-400">OR</div>
            <hr class="flex-grow-1" />
        </div>
        <button class="btn btn-gray-300 w-100 mt-2 rounded-0">CONTACT SALES</button>
        <a
            v-if="builderPath"
            class="btn btn-gray-300 w-100 mt-1 rounded-0"
            :href="builderPath"
        >CUSTOMIZE PACKAGE</a>
        <div class="d-flex flex-row mt-4 border-top border-bottom border-dark cursor-pointer">
            <div
                class="w-50 text-center border-right border-dark p-3"
                @click="$parent.goToQuestion($parent.questionIndex - 1)"
            >GO BACK</div>
            <div class="w-50 text-center p-3" @click="$parent.resetQuestions()">START OVER</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MachineSuggesterRequestAQuoteOptions',
        props: {
            builderPath: {
                type: String,
                default: null
            }
        }
    };
</script>
