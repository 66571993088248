<template>
    <div ref="wrapper" class="promotions">
        <eb-index
            v-if="dataSource"
            :api-key="elementConfig.BLAZE_API_KEY"
            :default-filters="promotionsFilters"
            :form-persistence-provider="formPersistenceProvider"
            :index-name="elementConfig.BLAZE_INDEX_NAME"
            :service-name="elementConfig.BLAZE_SERVICE_NAME"
        >
            <div v-if="loading" class="pt-5">
                <promotions-skeleton />
            </div>

            <div v-else-if="warningMessage" class="container my-3">
                <div class="alert alert-primary">{{ warningMessage }}</div>
            </div>

            <div v-else class="container pt-5">
                <card-feature
                    v-if="featuredItem"
                    class="mb-3"
                    action-name="Details"
                    :action-url="
                        baseUrl.endsWith('/')
                            ? `${baseUrl}${featuredItem.UrlName}`
                            : `${baseUrl}/${featuredItem.UrlName}`
                    "
                    :description="featuredItem.Summary || featuredItem.Description"
                    :enable-button-link="true"
                    :image="{ alt: featuredItem.Title, src: featuredImageUrl }"
                    :title="featuredItem.Title"
                ></card-feature>

                <!-- Filters -->
                <div class="row">
                    <!-- Search -->
                    <div class="col-12 col-lg-2 order-lg-1 pb-3 pb-lg-0">
                        <eb-suggestions
                            id="search-input"
                            class="w-100"
                            placeholder="Search..."
                        ></eb-suggestions>
                    </div>
                    <!-- Category -->
                    <div class="col-6 col-lg-2 order-lg-3">
                        <eb-multi-select-filter
                            id="category-dropdown"
                            class="mb-0"
                            field="ResultIndustries"
                            field-type="collection"
                            placeholder="Category"
                        ></eb-multi-select-filter>
                    </div>
                    <!-- Sort by -->
                    <div class="col col-lg-2 order-lg-4 pb-0 pb-lg-3">
                        <eb-sort-by-dropdown
                            id="sort-by-dropdown"
                            class="form-control"
                            :options="sortOptions"
                        ></eb-sort-by-dropdown>
                    </div>
                    <!-- Current selection -->
                    <div class="col-12 col-lg-3 order-lg-2 d-flex justify-content-center pt-3 pt-lg-2">
                        <eb-current-form-selections
                            :fields="filterParameters"
                            badge-class="badge badge-secondary"
                            badge-all-items-class="badge badge-secondary"
                        ></eb-current-form-selections>
                    </div>
                    <!-- Pagination -->
                    <div class="d-none d-lg-block col-lg-3 mx-auto mr-lg-0 order-lg-5">
                        <eb-pagination
                            v-if="showPagination"
                            pagination-class="align-self-end pagination justify-content-center justify-content-lg-end"
                            :pagination-size="2"
                            previous-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                        ></eb-pagination>
                    </div>
                </div>
                <!-- Promotions -->
                <eb-results :results-modifier="resultsModifier" results-wrapper-class="row">
                    <search-result-promotion
                        slot-scope="{ item }"
                        class="col-12 col-md-6 col-lg-4 mb-4"
                        :item="item"
                        :badges="['Special']"
                        :is-featured-item="featuredItemId == item.OriginalItemId"
                        :use-router="true"
                    ></search-result-promotion>
                </eb-results>
                <!-- Pagination -->
                <div v-if="showPagination" class="w-100 pb-2">
                    <eb-pagination
                        pagination-class="align-self-end pagination justify-content-center"
                        :pagination-size="2"
                        previous-icon="fa fa-angle-left"
                        next-icon="fa fa-angle-right"
                    ></eb-pagination>
                </div>
            </div>
        </eb-index>
    </div>
</template>

<script>
    import constants from '../../config/constants';

    import { QueryStringProvider } from '@teamsi/element-blaze';
    import CardFeature from '../cards/CardFeature';
    import PromotionService from '../../../services/PromotionService';
    import PromotionsSkeleton from './PromotionsSkeleton';
    import SearchResultPromotion from '../search/SearchResultPromotions';

    export default {
        name: 'Promotions',
        components: {
            CardFeature,
            PromotionsSkeleton,
            SearchResultPromotion
        },
        props: {
            backgroundImageUrl: {
                type: String,
                default: ''
            },
            baseUrl: {
                type: String,
                required: true
            },
            dataSource: {
                type: String,
                required: true
            },
            featuredItemId: {
                type: String,
                default: ''
            },
            filteredByMachines: {
                type: Boolean,
                required: true
            },
            filteredByPromotions: {
                type: Boolean,
                required: true
            },
            routeName: {
                type: String,
                required: true
            },
            selectedPromotionsIds: {
                type: Array,
                default: () => []
            },
            showPagination: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                featuredItem: null,
                formPersistenceProvider: new QueryStringProvider(this.routeName),
                filterParameters: ['category', 'q'],
                elementConfig: constants.elementConfig,
                sortOptions: [
                    {
                        field: 'Priority',
                        fieldDirection: 'asc',
                        name: 'Sort by: Default'
                    },
                    {
                        field: 'PublicationDate',
                        fieldDirection: 'desc',
                        name: 'Sort by: Recent'
                    },
                    {
                        field: 'Title',
                        fieldDirection: 'asc',
                        name: 'Sort by: Title A-Z'
                    },
                    {
                        field: 'Title',
                        fieldDirection: 'desc',
                        name: 'Sort by: Title Z-A'
                    }
                ]
            };
        },
        computed: {
            featuredImageUrl() {
                return this.featuredItem?.Image[0]?.Url || this.featuredItem?.Image;
            },
            loading() {
                return this.$store.state.search.isSearching;
            },
            promotionsFilters() {
                let result = [];

                if (constants.mappings[this.dataSource].promotion) {
                    result.push({
                        field: 'ContentType',
                        conditions: [
                            {
                                expression: 'eq',
                                value: constants.mappings[this.dataSource].promotion.type
                            }
                        ]
                    });
                }

                if (this.filteredByMachines || this.filteredByPromotions) {
                    if (this.selectedPromotionsIds.length > 0) {
                        result.push(this.getSelectedPromotionsFilterConditions());
                    } else {
                        result.push({
                            field: 'Id',
                            conditions: [
                                {
                                    expression: 'eq',
                                    value: '00000000-0000-0000-0000-000000000000'
                                }
                            ]
                        });
                    }
                }

                result.push({
                    field: 'EndDate',
                    fieldType: 'datetime',
                    conditions: [
                        {
                            expression: 'eq',
                            value: null
                        },
                        {
                            expression: 'gt',
                            value: new Date().toISOString()
                        }
                    ]
                });

                return result;
            },
            warningMessage() {
                if (this.filteredByMachines && this.selectedPromotionsIds.length == 0) {
                    return 'The selected machines have no current promotions available';
                } else if (this.filteredByPromotions && this.selectedPromotionsIds.length == 0) {
                    return 'No selected promotions to display';
                }

                return null;
            }
        },
        created() {
            this.$store.commit('search/applyResultsPerPage', this.showPagination ? 9 : 50);
        },
        mounted() {
            if (this.backgroundImageUrl) {
                this.$refs.wrapper.style.cssText = `background: url(${this.backgroundImageUrl}); background-size: cover`;
            }

            if (this.featuredItemId) {
                PromotionService.getPromotionById(this.dataSource, this.featuredItemId)
                    .then(promotion => (this.featuredItem = promotion))
                    .catch(reason => console.error(reason));
            }
        },
        methods: {
            getSelectedPromotionsFilterConditions() {
                let conditions = [];

                this.selectedPromotionsIds.forEach(id => {
                    conditions.push({
                        expression: 'eq',
                        value: id
                    });
                });

                if (conditions.length > 0) {
                    return {
                        conditions,
                        field: 'OriginalItemId'
                    };
                }
            },
            resultsModifier(results) {
                const sortBy = this.$store.state.search.sortBy.name;
                const usingDefaultSort = sortBy === this.sortOptions[0].name;

                if (!this.selectedPromotionsIds || this.selectedPromotionsIds.length === 0 || !usingDefaultSort) {
                    return results;
                }

                return results
                    .map(r => {
                        const i = this.selectedPromotionsIds.indexOf(r.OriginalItemId);
                        return { ...r, index: i >= 0 ? i : 10000 };
                    })
                    .sort((a, b) => a.index - b.index);
            }
        }
    };
</script>
