<template>
    <card-standard
        :enable-image="true"
        :image="{
            src: machine.Images ? machine.Images.split(',')[0] : equipmentPlaceholderImage,
            alt: machine.Title
        }"
        :title="machine.Title"
        :title-size="4"
        :enable-button-link="true"
        :action-name="isLayoutRounded ? 'View Details' : 'Details'"
        :action-url="searchResultsPage + '/' + machine.UrlName"
        :btn-style="
            isLayoutRounded ? 'd-flex justify-content-center btn-outline-primary' : 'btn-primary text-center'
        "
        class="h-100"
        :limit-image-height="true"
        :use-block-btn="!isLayoutRounded"
        :rounded="isLayoutRounded"
    >
        <div v-if="machine.Price || machine.SuggestedPrice" class="price font-weight-bold mb-2">
            <span v-if="!machine.SuggestedPrice && machine.Price">{{ machine.Price | money }}</span>
            <span v-else-if="machine.Price > machine.SuggestedPrice">
                <strike class="font-weight-normal">{{ machine.Price | money }}</strike>
                {{ machine.SuggestedPrice | money }}
            </span>
            <span v-if="!machine.Price || machine.SuggestedPrice >= machine.Price">{{
                machine.SuggestedPrice | money
            }}</span>
        </div>
        <div v-if="machine.OperationHours" class="subtitle">
            <i class="far fa-clock"></i>
            <span class="font-weight-bold">Hours:</span>
            {{ machine.OperationHours }}
        </div>
        <div v-if="machine.EquipmentYear" class="subtitle">
            <span class="font-weight-bold">Year:</span>
            {{ machine.EquipmentYear }}
        </div>
    </card-standard>
</template>

<script>
    import CardStandard from '../cards/CardStandard';

    export default {
        name: 'HomepageUsedEquipmentCard',
        components: {
            CardStandard
        },
        props: {
            isLayoutRounded: {
                type: Boolean,
                required: true
            },
            machine: {
                type: Object,
                required: true
            },
            searchResultsPage: {
                type: String,
                required: true
            }
        }
    };
</script>
