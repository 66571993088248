<template>
    <div class="text-center">
        <span class="fas fa-bars text-primary" @click.stop.prevent="openMenu"></span>
        <div class="mobile-pusher text-left" :class="{ 'mobile-pushed': open }">
            <nav ref="mainNav" class="mobile-menu" :class="{ 'mobile-cover': open }">
                <div class="mobile-level">
                    <div class="parent-page-link main-header">
                        <h2>All Categories</h2>
                        <span class="close-menu" @click.stop.prevent="resetMenu">
                            <i class="fa fa-times-circle"></i>
                        </span>
                    </div>
                    <ul>
                        <mobile-menu
                            v-for="page in pages"
                            :key="page.Title"
                            :children="page.children"
                            :reset="resetMenu"
                            :title="page.Title"
                            :parent="page.ParentId"
                            :url="page.Url.substr(1)"
                        ></mobile-menu>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    const bodyScrollLock = require('body-scroll-lock');

    import MobileMenu from '../../menu/MobileMenu';
    import PageService from '../../../../services/PageService';

    export default {
        name: 'HeaderMobilePusher',
        components: {
            MobileMenu
        },
        data() {
            return {
                open: false,
                pages: []
            };
        },
        created() {
            PageService.getPages().then(result => {
                this.pages = result;
            });
        },
        mounted() {
            document.addEventListener('click', this.bodyClick);
        },
        methods: {
            bodyClick(e) {
                // Define Main Navigation Element
                let el = this.$refs.mainNav;

                // If the Main Navigation Element is not the target & it does not contain the target : stop default action & flip the 'open' variable to false closing the menu
                if (el !== e.target && !el.contains(e.target)) {
                    // Only prevent default action if the mobile menu is open
                    if (this.open) {
                        e.preventDefault();
                    }

                    this.resetMenu();
                }
            },
            openMenu() {
                this.open = true;
                // Because iOS
                document.querySelector('body').style.overflow = 'hidden';
                // https://www.npmjs.com/package/body-scroll-lock#more-complex
                bodyScrollLock.disableBodyScroll(this.$refs.mainNav, {
                    allowTouchMove: el => {
                        while (el && el !== document.body) {
                            if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                                return true;
                            }

                            el = el.parentElement;
                        }
                    }
                });
            },
            resetMenu() {
                document.querySelector('body').style.overflow = 'unset';

                this.open = false;
                bodyScrollLock.enableBodyScroll(this.$refs.mainNav);
            }
        }
    };
</script>