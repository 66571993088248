<template>
    <div ref="formContainer">
        <div v-if="sitefinityFormSelector" ref="sfFormPlaceholder"></div>
        <iframe
            v-else
            class="w-100 border-0"
            frameborder="0"
            :height="iframeHeight"
            :src="iframeSource"
        />
    </div>
</template>

<script>
    const IFRAME_MIN_HEIGHT = 500;

    export default {
        name: 'FormLoader',
        props: {
            sitefinityFormSelector: {
                type: String,
                default: null
            },
            removeSitefinityFormLabels: {
                type: Boolean,
                default: false
            },
            iframeSource: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                sfForm: null,
                iframeHeight: `${IFRAME_MIN_HEIGHT}px`
            };
        },
        mounted() {
            if (this.sitefinityFormSelector) {
                if (!document.querySelector(this.sitefinityFormSelector)) {
                    return;
                }

                this.sfForm = $(this.sitefinityFormSelector).detach();
                this.sfForm.removeClass('d-none').appendTo(this.$refs.sfFormPlaceholder);

                if (this.removeSitefinityFormLabels) {
                    this.$refs.sfFormPlaceholder.querySelectorAll('label').forEach(element => {
                        element.parentNode.removeChild(element);
                    });
                }
            } else if (this.iframeSource) {
                setTimeout(() => {
                    let height = this.$refs.formContainer
                        .querySelector('iframe')
                        .contentWindow.document.querySelector('html').clientHeight;

                    // height * 1.33: Give room for the field validators to show
                    this.iframeHeight = Math.max(height * 1.33, IFRAME_MIN_HEIGHT) + 'px';
                }, 2000);
            }
        },
        beforeDestroy() {
            if (this.sitefinityFormSelector && this.sfForm) {
                this.sfForm.addClass('d-none').appendTo('body');
            }
        }
    };
</script>
