﻿<template>
    <div class="new-equipment-showroom-detail" :class="$_isLightTheme ? 'bg-white' : 'bg-dark'">
        <h1 class="text pt-1 px-2 d-block d-md-none">{{ machine.MachineTitle }}</h1>
        <div class="title-bar row d-none d-md-flex" :class="$_isLightTheme ? 'bg-white' : 'bg-dark'">
            <ul class="breadcrumbs col-12 d-none d-md-block">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li v-if="$route.params.type">></li>
                <li v-if="$route.params.type">
                    <router-link :to="route($route.params.type)">{{
                        slugToTitle($route.params.type)
                    }}</router-link>
                </li>
            </ul>
        </div>
        <div id="machine-nav-carousel" class="d-block d-md-none w-100 pt-3">
            <splide :options="sliderOptions">
                <splide-slide :class="activeLink('overview')">
                    <router-link to="overview">Overview</router-link>
                </splide-slide>
                <splide-slide :class="[activeLink('features'), { 'd-none': !machine.Features }]">
                    <router-link to="features">Features</router-link>
                </splide-slide>
                <splide-slide
                    :class="[
                        activeLink('specifications'),
                        { 'd-none': !machine.Specifications && !machine.SpecsImage }
                    ]"
                >
                    <router-link to="specifications">Specs</router-link>
                </splide-slide>
                <splide-slide
                    v-if="machine.VideoUrl"
                    :class="[activeLink('videos'), { 'd-none': !machine.VideoUrl }]"
                >
                    <router-link to="videos">Videos</router-link>
                </splide-slide>
                <splide-slide
                    :class="[
                        activeLink('gallery'),
                        { 'd-none': !machine.Images || machine.Images.split(',').length < 2 }
                    ]"
                >
                    <router-link to="gallery">Gallery</router-link>
                </splide-slide>
                <splide-slide
                    v-show="machine.Brochure1Title && machine.Brochure1Url"
                    :class="[
                        activeLink('brochures'),
                        { 'd-none': !machine.Brochure1Title || !machine.Brochure1Url }
                    ]"
                >
                    <router-link to="brochures">Brochures</router-link>
                </splide-slide>
                <!-- <splide-slide :class="activeLink('specials')">
                    <router-link to="specials">Specials</router-link>
                </splide-slide> -->
            </splide>
        </div>
        <div class="machine-detail-banner pt-1 pl-1 d-flex d-md-none">
            <div class="machine-image">
                <img
                    v-if="machine.Images && machine.Images.split(',')[0]"
                    :src="machine.Images.split(',')[0]"
                    :alt="machine.MachineTitle"
                />
            </div>
            <div class="machine-cta shortline-color-primary">
                <a
                    :href="call"
                    class="cta-button my-2 mx-2"
                    :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                >
                    <span class="fas fa-phone"></span>
                </a>
                <a
                    data-toggle="modal"
                    :data-target="`#${quoteFormModalId}`"
                    class="cta-button my-2 mr-2"
                    :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                >
                    <span class="shortline-color-primary fas fa-dollar-sign"></span>
                </a>
                <a
                    class="cta-button mb-2 mx-2"
                    :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                    @click="save(machine)"
                >
                    <span class="fas fa-star"></span>
                </a>
                <a
                    class="cta-button mb-2 mr-2"
                    :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                    @click="print"
                >
                    <span class="fas fa-print"></span>
                </a>
                <a
                    v-if="machine.CtaUrl"
                    :href="machine.CtaUrl"
                    class="cta-button mb-2 mx-2"
                    :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                >
                    <span class="showroom-color-primary fas fa-link"></span>
                    {{ machine.CtaUrlButtonText }}
                </a>
            </div>
        </div>
        <div ref="detail-wrapper" class="equipment-detail-wrapper">
            <router-link
                :to="backHref"
                class="equipment-detail-back text px-3 py-2 ml-4 d-none d-md-block"
                :class="$_isLightTheme ? 'bg-white' : 'bg-dark'"
            >
                <span class="fas fa-chevron-left shortline-color-primary"></span>
                Back to Results
            </router-link>
            <div
                v-if="!gallery && !specs && !videos && !specials"
                class="equipment-image-wrapper d-none d-md-block"
            >
                <h3 class="equipment-detail-title pt-3">{{ machine.MachineTitle }}</h3>
                <img
                    v-if="machine.Images && machine.Images.split(',')[0]"
                    class="d-none d-md-block equipment-detail-image"
                    :src="machine.Images.split(',')[0]"
                    :alt="machine.MachineTitle"
                />
                <div class="machine-cta d-none d-md-flex p-3 text">
                    <a
                        :href="call"
                        class="cta-button my-2 mx-2"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                    >
                        <span class="shortline-color-primary fas fa-phone"></span> Call Us
                    </a>
                    <a
                        data-toggle="modal"
                        :data-target="`#${quoteFormModalId}`"
                        class="cta-button my-2 mr-2"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                    >
                        <span class="shortline-color-primary fas fa-dollar-sign"></span> Get A Quote
                    </a>
                    <a
                        class="cta-button mb-2 mx-2"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                        @click="save(machine)"
                    >
                        <span class="shortline-color-primary fas fa-star"></span> Save
                    </a>
                    <a
                        class="cta-button mb-2 mr-2"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                        @click="print"
                    >
                        <span class="shortline-color-primary fas fa-print"></span> Print
                    </a>
                    <a
                        v-if="machine.CtaUrl"
                        :href="machine.CtaUrl"
                        class="cta-button mb-2 mx-2"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                    >
                        <span class="showroom-color-primary fas fa-link"></span>
                        {{ machine.CtaUrlButtonText }}
                    </a>
                </div>
            </div>
            <router-view class="detail-section" :machine="machine" :manufacturer="manufacturer"></router-view>
        </div>
        <div class="d-none d-md-flex desktop-nav w-100 shortline-bg-color-primary">
            <div class="desktop-nav-link-container w-100 pb-2">
                <router-link class="text" :class="activeLinkDesktop('overview')" to="overview">
                    <span class="fas fa-info-circle shortline-color-primary"></span>
                    <br />Overview
                </router-link>
                <router-link
                    v-if="machine.Features"
                    class="text"
                    :class="activeLinkDesktop('features')"
                    to="features"
                >
                    <span class="fas fa-check-circle shortline-color-primary"></span>
                    <br />Features
                </router-link>
                <router-link
                    v-if="machine.Specifications || machine.SpecsImage"
                    class="text"
                    :class="activeLinkDesktop('specifications')"
                    to="specifications"
                >
                    <span class="fas fa-clipboard-list shortline-color-primary"></span>
                    <br />Specs
                </router-link>
                <router-link
                    v-if="machine.VideoUrl"
                    class="text"
                    :class="activeLinkDesktop('videos')"
                    to="videos"
                >
                    <span class="fab fa-youtube shortline-color-primary"></span>
                    <br />Videos
                </router-link>
                <router-link
                    v-if="machine.Images && machine.Images.split(',').length > 1"
                    class="text"
                    :class="activeLinkDesktop('gallery')"
                    to="gallery"
                >
                    <span class="fas fa-images shortline-color-primary"></span>
                    <br />Gallery
                </router-link>
                <router-link
                    v-if="machine.Brochure1Title && machine.Brochure1Url"
                    class="text"
                    :class="activeLinkDesktop('brochures')"
                    to="brochures"
                >
                    <span class="fas fa-book shortline-color-primary"></span>
                    <br />Brochures
                </router-link>
            </div>
        </div>

        <modal-form
            v-if="useSitefinityForm"
            :id="quoteFormModalId"
            :form-selector="`.${quoteFormModalId}`"
            title="Get A Quote"
            :field-values="[
                {
                    selector: `.interested-eq input[class='form-control']`,
                    value: interestedInItem
                }
            ]"
        ></modal-form>

        <modal-iframe
            v-if="!useSitefinityForm && formUrl"
            :id="quoteFormModalId"
            title="Get a quote"
            :iframe-source="formUrl"
        ></modal-iframe>
    </div>
</template>

<script>
    import shortLineService from '../../../services/ShortLineService';
    import modalForm from '../utility/ModalForm';
    import modalIFrame from '../utility/ModalIFrame';

    import ThemeMixin from '../mixins/ThemeMixin';
    import UtilitiesMixin from '../mixins/UtilitiesMixin';

    export default {
        name: 'ShortLineShowroomDetail',
        components: {
            modalForm,
            modalIframe: modalIFrame
        },
        mixins: [ThemeMixin, UtilitiesMixin],
        props: {
            backgroundTexture: {
                type: Object,
                default: () => ({})
            },
            manufacturer: {
                type: String,
                default: null
            },
            call: {
                type: String,
                default: null
            },
            save: {
                type: Function,
                default: () => {}
            },
            email: {
                type: String,
                required: true
            },
            selectedMachineId: {
                type: String,
                default: ''
            },
            formUrl: {
                type: String,
                default: null
            },
            useSitefinityForm: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                machine: {},
                quoteFormModalId: 'shortline-get-a-quote',
                sliderOptions: {
                    gap: '1rem',
                    pagination: false,
                    perPage: 3
                }
            };
        },
        computed: {
            // used to populate the Interested In field in the Get a Quote modal
            interestedInItem() {
                let itemTitle = this.machine.MachineTitle;
                return this.manufacturer ? `${this.manufacturer} - ${itemTitle}` : itemTitle;
            },
            gallery() {
                return this.$route && this.$route.path.indexOf('/gallery') > -1;
            },
            specs() {
                return this.$route && this.$route.path.indexOf('/specifications') > -1;
            },
            videos() {
                return this.$route && this.$route.path.indexOf('/videos') > -1;
            },
            specials() {
                return this.$route && this.$route.path.indexOf('/specials') > -1;
            },
            backHref() {
                return `/${this.slugify(this.$route.params.type)}`;
            },
            mailLink() {
                return this.email + '?subject=' + this.machine.MachineTitle;
            }
        },
        watch: {
            selectedMachineId: function(newValue, oldValue) {
                if (newValue && newValue !== oldValue) {
                    shortLineService.getShortlineMachineById(newValue).then(result => {
                        this.machine = result;
                    });
                }
            }
        },
        created() {
            if (this.selectedMachineId) {
                shortLineService.getShortlineMachineById(this.selectedMachineId).then(result => {
                    this.machine = result;
                });
            }
        },
        mounted() {
            if (this.backgroundTexture && this.backgroundTexture.ItemDefaultUrl) {
                this.$refs['detail-wrapper'].style.cssText = `background-image: url('${this.backgroundTexture
                    .ItemDefaultUrl.Value + this.backgroundTexture.Extension}')`;
            }
        },
        methods: {
            activeLink(route) {
                return this.$route.path.indexOf(route) > -1 ? 'shortline-color-primary' : 'text';
            },
            activeLinkDesktop(route) {
                return this.$route.path.indexOf(route) > -1
                    ? 'shortline-bg-color-primary'
                    : this.$_isLightTheme
                        ? 'bg-gray-200'
                        : 'bg-gray-800';
            },
            detailUrl(detail) {
                return `${this.$route.path}/${detail}`;
            },
            route(p1, p2) {
                return p2 ? `/${p1}/${p2}` : `/${p1}`;
            },
            slugToTitle(slug) {
                return slug
                    .replace(/-/g, ' ')
                    .replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
            },
            print() {
                window.print();
            }
        }
    };
</script>
