﻿<template>
    <li :class="[{ subs: !isFirstLevel }, { hassubs: page.children }, 'border-0 h-100']">
        <a
            class="d-flex align-items-center justify-content-start h-100"
            :href="page.Url.substr(1)"
            :target="page.Target"
        >
            <span>{{ page.Title.toUpperCase() }}</span>
            <i v-if="page.children && page.children.length" class="fas fa-caret-down ml-1"></i>
        </a>
        <ul class="dropdown shadow">
            <simple-menu-submenu
                v-for="childPage in page.children"
                :key="childPage.Url"
                :page="childPage"
                :is-first-level="false"
            >
            </simple-menu-submenu>
        </ul>
    </li>
</template>

<script>
    export default {
        name: 'SimpleMenuSubmenu',
        props: {
            isFirstLevel: {
                type: Boolean,
                required: true
            },
            page: {
                type: Object,
                default: () => ({})
            }
        }
    };
</script>
