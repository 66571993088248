<!--
Name: Homepage Used Equipment

Description:
Includes a used equipment search form to the left and featured items slider on the right side.

Usage:
Root component for Sitefinity Featured Used Equipment widget. Generally placed on the homepage.
-->
<template>
    <div ref="wrapper" class="homepage-used-equipment">
        <div class="container">
            <div class="row py-5">
                <!-- left sidebar -->
                <div v-if="!isLayoutRounded" class="searchform col-md-12 col-lg-5 pt-4 pb-1 shadow rounded">
                    <h3 class="border-left border-content mb-0">Search Our Used Equipment</h3>
                    <eb-index
                        :api-key="elementConfig.BLAZE_API_KEY"
                        :default-filters="defaultFilters"
                        :form-persistence-provider="formPersistenceProvider"
                        :index-name="elementConfig.BLAZE_INDEX_NAME"
                        :scoring-profiles="[config.scoringProfile]"
                        :service-name="elementConfig.BLAZE_SERVICE_NAME"
                    >
                        <div class="row">
                            <div class="col-md-12 my-5 px-5">
                                <div class="d-flex justify-content-center p-4 results-count">
                                    <object data="../../assets/icons/tractor-front.svg" class="p-2"></object>
                                    <div class="flex-column">
                                        <h3 class="m-0">{{ resultsCount }}</h3>
                                        <span>Pieces of Used Equipment</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- form filter components -->
                        <div class="row">
                            <div class="col-md-6">
                                <eb-multi-select-filter
                                    id="YearMin"
                                    label="Year"
                                    field="Year"
                                    field-type="number"
                                    secondary-parameter="YearMax"
                                    :is-multiselect="false"
                                    :is-searchable="false"
                                    :reverse="true"
                                ></eb-multi-select-filter>
                                <eb-multi-select-filter
                                    id="make"
                                    label="Make"
                                    field="Manufacturer"
                                ></eb-multi-select-filter>
                                <eb-multi-select-filter
                                    id="model"
                                    label="Model"
                                    field="Model"
                                ></eb-multi-select-filter>
                            </div>

                            <div class="col-md-6">
                                <eb-multi-select-filter
                                    id="category"
                                    label="Category"
                                    field="ResultCategories"
                                    field-type="collection"
                                ></eb-multi-select-filter>
                                <eb-multi-select-filter
                                    id="location"
                                    label="Location"
                                    field="ResultLocation"
                                ></eb-multi-select-filter>
                                <div class="form-group">
                                    <label for="keywords-input">Search</label>
                                    <eb-suggestions id="keywords-input"></eb-suggestions>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-md-6">
                                <!-- reset button -->
                                <div class="form-group">
                                    <eb-reset btn-class="btn btn-outline-primary btn-lg btn-block"
                                        >Clear Filters</eb-reset
                                    >
                                </div>
                            </div>

                            <div class="col-md-6">
                                <button class="btn btn-primary btn-lg btn-block" @click="showResults">
                                    Search
                                </button>
                            </div>
                        </div>
                    </eb-index>
                </div>

                <div class="col-12 px-0" :class="[{ 'col-lg-7 pl-lg-5': !isLayoutRounded }]">
                    <div class="featured-items border-top border-bottom border-primary py-5">
                        <!-- TITLE -->
                        <div v-if="isLayoutRounded" class="text-center text-primary">
                            <h3 class="featured-rounded-title mb-0 line-height-0">featured</h3>
                            <h3>USED EQUIPMENT</h3>
                        </div>
                        <div v-else class="row mx-0">
                            <div class="col">
                                <h3 class="font-weight-light">Featured Used Equipment</h3>
                            </div>
                            <div class="col-auto">
                                <a :href="searchResultsPage" class="btn btn-hollow-primary mt-3 mt-sm-0"
                                    >View All Used</a
                                >
                            </div>
                        </div>

                        <!-- SLIDER -->
                        <div v-if="machines.length > 0" class="pt-4">
                            <splide
                                v-if="!isLayoutRounded || windowWidth >= 768"
                                class="pb-0 px-5"
                                :options="sliderOptions"
                            >
                                <splide-slide v-for="machine in machines" :key="machine.Id" class="pb-2">
                                    <homepage-used-equipment-card
                                        :is-layout-rounded="isLayoutRounded"
                                        :machine="machine"
                                        :search-results-page="searchResultsPage"
                                    />
                                </splide-slide>

                                <template v-if="isLayoutRounded" #controls>
                                    <splide-rounded-arrows />
                                </template>
                            </splide>
                            <div v-else-if="isLayoutRounded" class="row mx-0">
                                <div
                                    v-for="machine in machines.slice(0, 3)"
                                    :key="machine.Id"
                                    class="col-12 px-4 py-2"
                                >
                                    <homepage-used-equipment-card
                                        :is-layout-rounded="isLayoutRounded"
                                        :machine="machine"
                                        :search-results-page="searchResultsPage"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- BUTTON (ROUNDED) -->
                        <div v-if="isLayoutRounded" class="d-flex justify-content-center pt-5">
                            <a :href="searchResultsPage" class="btn btn-primary rounded-pill px-5 py-3">
                                Search All Used Equipment
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import constants from '../../config/constants';
    import searchConfig from '../../config/search-config';

    import HomepageUsedEquipmentCard from './HomepageUsedEquipmentCard';
    import { QueryStringProvider } from '@teamsi/element-blaze';
    import SplideRoundedArrows from '../utility/SplideRoundedArrows';

    const usedEqAPI = '/api/default/usedequipments';

    export default {
        name: 'HomepageUsedEquipment',
        components: {
            HomepageUsedEquipmentCard,
            SplideRoundedArrows
        },
        props: {
            backgroundImage: {
                type: Object,
                default: () => ({})
            },
            featuredMachines: {
                type: Array,
                required: true
            },
            layout: {
                type: String,
                required: true
            },
            routeName: {
                type: String,
                required: true
            },
            searchResultsPage: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                config: searchConfig,
                equipmentPlaceholderImage: constants.equipmentPlaceholderImage,
                elementConfig: constants.elementConfig,
                machines: [],
                defaultFilters: [
                    {
                        field: 'ContentType',
                        conditions: [
                            {
                                expression: 'eq',
                                value: constants.contentTypeNames.USED_EQUIPMENT
                            }
                        ]
                    }
                ],
                formPersistenceProvider: new QueryStringProvider(this.routeName),
                windowWidth: document.documentElement.clientWidth
            };
        },
        computed: {
            isLayoutRounded() {
                return this.layout === constants.layouts.ROUNDED;
            },
            resultsPageUrl() {
                const params = this.$store.state.search.formState;
                const queryString = Object.keys(params)
                    .map(key => {
                        return Array.isArray(params[key])
                            ? params[key].map(subkey => `${key}=${encodeURIComponent(subkey)}`).join('&')
                            : `${key}=${params[key]}`;
                    })
                    .join('&');

                return `${this.searchResultsPage}?${queryString}`;
            },
            resultsCount() {
                return (
                    (this.$store.state.search.searchResponse &&
                        this.$store.state.search.searchResponse['@odata.count']) ||
                    0
                );
            },
            sliderOptions() {
                return {
                    autoplay: true,
                    breakpoints: {
                        991: {
                            perPage: this.isLayoutRounded ? 2 : 1
                        }
                    },
                    gap: '1rem',
                    pagination: false,
                    perPage: this.machines.length > 1 ? (this.isLayoutRounded ? 4 : 2) : 1,
                    rewind: true,
                    speed: 1000
                };
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.setWindowSize);
            });

            // TODO: refactor to use service
            if (this.featuredMachines.length > 0) {
                this.machines = this.featuredMachines;
            } else {
                axios
                    .get(`${usedEqAPI}?$top=10&$filter=length(Images) gt 0`)
                    .then(response => (this.machines = response.data.value));
            }

            if (this.backgroundImage && this.backgroundImage.src) {
                this.$refs.wrapper.style.cssText = `background-image: url(${this.backgroundImage.src})`;
            }
        },
        methods: {
            currencyFormatter() {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                });
                return formatter;
            },
            setWindowSize() {
                this.windowWidth = document.documentElement.clientWidth;
            },
            showResults() {
                window.location.href = this.resultsPageUrl;
            }
        }
    };
</script>
