﻿import axios from 'axios';

export function mapPages(data) {
    return new Promise((resolve) => {
        resolve(processPages(data));
    });
}

export function getPages() {
    return new Promise(function (resolve) {
        axios.get('/api/pages/get').then(function (response) {
            const initialPages = response.data;
            resolve(processPages(initialPages));
        });
    });
}

function processPages(data) {
    let tier0 = [];
    if (data) {
        const ROOT_PAGE_ID = '00000000-0000-0000-0000-000000000000';
        // Order pages as set in Sitefinity Backend
        let pages = data.sort((a, b) => a.Ordinal - b.Ordinal);
        var rootPage = pages.find(page => page.ParentId === ROOT_PAGE_ID);

        // Find top level pages as set in Sitefinity Backend
        tier0 = pages.filter(page => page.ParentId === rootPage.Id);
        // Filter for the 1st level of Child Pages
        let tier1 = pages.filter(
            page => tier0.map(parentPage => parentPage.Id).indexOf(page.ParentId) > -1
        );
        // Filter for the 2nd level
        let tier2 = pages.filter(
            page => tier1.map(parentPage => parentPage.Id).indexOf(page.ParentId) > -1
        );
        // Attach grand children to children
        tier1 = tier1.map(page => {
            page.children = tier2.filter(childPage => childPage.ParentId === page.Id);
            return page;
        });
        // Attach children to top level pages
        tier0 = tier0.map(page => {
            page.children = tier1.filter(childPage => childPage.ParentId === page.Id);
            return page;
        });
    }

    return tier0;
}

export default {
    getPages,
    mapPages
};
