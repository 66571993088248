/* eslint-disable prettier/prettier */
import axios from 'axios';
import constants from '../frontend/config/constants';
const geocodeBaseURL = 'https://maps.googleapis.com/maps/api/geocode/json';

let init = async () => {
    return new Promise(resolve => {
        const googleMapsScript = Array.from(document.querySelectorAll('script')).find(x =>
            x.src.includes('maps.googleapis.com')
        );
        const isGoogleDefined = typeof google === 'object';

        if (!googleMapsScript) {
            let scriptTag = document.createElement('script');
            scriptTag.type = 'text/javascript';
            scriptTag.src = `//maps.googleapis.com/maps/api/js?key=${constants.elementConfig.GOOGLE_MAPS_KEY}&language=en&libraries=places`;
            scriptTag.onload = () => resolve();
            document.body.appendChild(scriptTag);
        } else if (!isGoogleDefined) {
            googleMapsScript.onload = () => resolve();
        } else {
            resolve();
        }
    });
};

export function getCoordinatesFromAddress(address, apiKey) {
    let url = `${geocodeBaseURL}?key=${apiKey}&address=${address}`;

    return new Promise(function(resolve, reject) {
        axios
            .get(url)
            .then(function(response) {
                if (response && response.data && response.data.results.length > 0) {
                    resolve(response.data.results[0].geometry.location);
                } else {
                    reject('Could not find any geolocation');
                }
            })
            .catch(() => {
                reject('Could not access google maps service');
            });
    });
}

export function detectCoordinates() {
    return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(
            function(pos) {
                resolve({
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude
                });
            },
            function() {
                reject('Could not access google maps service');
            }
        );
    });
}

export function autocomplete(input) {
    return new Promise(function(resolve, reject) {
        // eslint-disable-next-line no-undef
        let acs = new google.maps.places.AutocompleteService();
        let req = {
            componentRestrictions: {
                country: 'us'
            },
            types: ['(cities)'],
            input: input
        };
        acs.getPlacePredictions(
            req,
            function(results) {
                if (results) {
                    let resultCount = Math.min(results.length, 3);
                    let suggestions = [];
                    for (let index = 0; index < resultCount; index++) {
                        let suggestion = results[index].terms[0].value;
                        if (results[index].terms.length > 1) {
                            suggestion += ', ' + results[index].terms[1].value;
                        }
                        suggestions.push(suggestion);
                    }
                    resolve(suggestions);
                }
            },
            function() {
                reject('Could not access google maps places service');
            }
        );
    });
}

export function createMap(element, options) {
    // eslint-disable-next-line no-undef
    return new google.maps.Map(element, options);
}

export function createMarker(options) {
    // eslint-disable-next-line no-undef
    return new google.maps.Marker(options);
}

export function createInfoWindow(map, marker, location, address, infoWindow) {
    var googleMapDirLink =
        // eslint-disable-next-line no-useless-escape
        'https://www.google.com/maps/dir/current+location/' + address.replace(/\<br\>/g, ' ');

    let phoneTag = location.Phone
        ? `<a class="text-gray-900 mt-1" href="tel:${location.Phone.replace(/[^\d]/g, '')}">
                <i class="fas fa-phone"></i>
                ${location.Phone}
            </a>`
        : '';

    var infoWindowContent =
        '<div class="info-window text-gray-900">' +
        '<div class=container>' +
        '<div class="row">' +
        '<div class=col-sm-12>' +
        '<h3 class=info-window-title>' +
        location.DealerLocationTitle +
        '</h3>' +
        '<i class=fas&#32;fa-map-marker-alt></i> ' +
        address +
        '<br>' +
        phoneTag +
        '<div class="row mt-3 text-center">' +
        '<div class=col-sm-6>' +
        `<a href=${constants.elementConfig.SITEMAP.LOCATIONS}/${location.UrlName}` +
        ' class=btn&#32;btn-primary&#32;btn-sm&#32;info-window-btn role=button>Details</a>' +
        '</div>' +
        '<div class=col-sm-6>' +
        '<a href=' +
        googleMapDirLink.replace(/ /g, '+') +
        ' class="btn&#32;btn-primary&#32;btn-sm info-window-btn google-maps-directions" role=button>Directions</a>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>';

    // eslint-disable-next-line no-undef
    google.maps.event.addListener(marker, 'click', function() {
        infoWindow.setContent(infoWindowContent);
        infoWindow.open(map, marker);
    });
}

export function adjustMapBounds(map, positions) {
    // eslint-disable-next-line no-undef
    const boundAdjust = new google.maps.LatLngBounds();
    positions.forEach(value => boundAdjust.extend(value));
    map.fitBounds(boundAdjust);
}

export default {
    init,
    getCoordinatesFromAddress,
    detectCoordinates,
    autocomplete,
    createMap,
    createMarker,
    createInfoWindow,
    adjustMapBounds
};
