﻿<template>
    <div>
        <div class="row mb-3 mb-md-5 pt-md-5">
            <!-- Machine info -->
            <div class="col-sm-12 col-lg-4 align-self-center">
                <content-skeleton
                    :use-title="true"
                    :title-length="100"
                    :line-lengths="[40, 60, 50, 50, 100]"
                ></content-skeleton>
                <div class="skeleton skeleton-inline mt-3">
                    <div v-for="i in 2" :key="i" class="skeleton-item p-5"></div>
                </div>
            </div>
            <!-- Image gallery -->
            <div class="col-sm-12 col-lg-8 skeleton">
                <div class="skeleton-item h-100"></div>
            </div>
        </div>
        <div class="row mb-5">
            <!-- Contact us about this machine -->
            <div class="col-sm-12 col-md-7">
                <div class="skeleton mb-3">
                    <div class="skeleton-title w-50"></div>
                </div>
                <div class="skeleton skeleton-inline">
                    <div v-for="i in 3" :key="i" class="skeleton-item p-5"></div>
                </div>
            </div>
            <!-- Share this machine -->
            <div class="col-sm-12 col-md-5">
                <div class="skeleton mb-3">
                    <div class="skeleton-title w-50"></div>
                </div>
                <div class="skeleton skeleton-inline">
                    <div v-for="i in 2" :key="i" class="skeleton-item p-5"></div>
                </div>
            </div>
        </div>
        <!-- Description -->
        <content-skeleton :use-title="true" :title-length="25"></content-skeleton>
        <div class="skeleton mt-5 mb-3">
            <div class="skeleton-title w-25"></div>
        </div>
        <!-- Similar equipment -->
        <div class="skeleton skeleton-inline">
            <div v-for="i in 3" :key="i" class="skeleton-item p-5"></div>
        </div>
    </div>
</template>

<script>
    import ContentSkeleton from '../utility/ContentSkeleton';

    export default {
        name: 'UsedMachineDetailSkeleton',
        components: {
            ContentSkeleton
        }
    };
</script>
