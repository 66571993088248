<template>
    <section class="location-nearby container my-4">
        <div class="d-flex flex-wrap justify-content-between">
            <div class="d-flex justify-content-between">
                <div class="border-left border-content">
                    <h3 v-if="!$_selectedLocation" class="mb-0">Find your dealer</h3>
                    <h3 v-if="$_selectedLocation" class="mb-0">
                        Your Store is {{ $_selectedLocation.DealerLocationTitle }}
                    </h3>
                    <div class="mb-0 mt-1">Nearby Stores</div>
                </div>
                <div class="d-flex align-items-start justify-content-center flex-wrap pl-3">
                    <a
                        v-if="$_selectedLocation && $_selectedLocation.Phone"
                        :href="telLink($_selectedLocation.Phone)"
                        class="font-weight-bold pr-3 mt-2"
                        >{{ $_selectedLocation.Phone }}</a
                    >
                    <a
                        v-if="$_selectedLocation"
                        :href="directionLink(locationFullAddress($_selectedLocation))"
                        target="_blank"
                        class="btn btn-primary text-uppercase mb-2 google-maps-directions"
                        >Get Directions</a
                    >
                </div>
            </div>
            <div>
                <a :href="elementConfig.SITEMAP.LOCATIONS" class="btn btn-hollow-primary mt-3 mt-md-0"
                    >See all dealers</a
                >
            </div>
        </div>

        <div class="row no-gutters mt-3">
            <div v-for="(store, i) in stores" :key="i" :class="['col-sm-4 pb-2', { 'pr-sm-3': i != 2 }]">
                <card-standard
                    class="h-100"
                    :enable-button-link="true"
                    :use-block-btn="true"
                    action-name="View Store Info"
                    :action-url="detailsLink(store.UrlName)"
                    btn-style="btn-gray-200 btn-semi-lg text-center"
                    title=""
                >
                    <div class="row no-gutters text-center text-lg-left">
                        <!-- location image column -->
                        <div class="col-lg-auto">
                            <img
                                v-if="store.Image.length > 0"
                                class="d-lg-block store-image"
                                :src="store.Image[store.Image.length - 1].ThumbnailUrl"
                                :alt="locationName(store)"
                            />
                        </div>
                        <!-- store name and address column -->
                        <div class="col-lg pt-3 pt-lg-0 pl-lg-3">
                            <h5 class="card-title">{{ locationName(store) }}</h5>
                            <small v-html="locationFullAddress(store)"></small>
                            <br />
                            <span class="d-inline-block text-center">
                                <a :href="telLink(store.Phone)" class="font-weight-bold">{{ store.Phone }}</a>
                                <br />
                                <small v-if="checkOpenNow(store)" class="font-weight-bold">Open Now</small>
                                <small v-else class="font-weight-bold text-danger">Closed</small>
                            </span>
                        </div>
                    </div>
                </card-standard>
            </div>
        </div>

        <div v-if="!$_selectedLocation" class="mt-3">
            <store-finder :show-location-finder="true" />
        </div>
        <div v-if="stores.length === 0 && $_selectedLocation" class="text-center">No items to display</div>
    </section>
</template>

<script>
    import constants from '../../config/constants';
    import locationService from '../../../services/LocationService.js';

    import CardStandard from '../cards/CardStandard';
    import LocationMixin from '../mixins/LocationMixin';
    import StoreConstants from '../../store/constants';
    import StoreFinder from '../header/StoreFinder.vue';

    export default {
        name: 'LocationNearbyList',
        components: {
            StoreFinder,
            CardStandard
        },
        mixins: [LocationMixin],
        data() {
            return {
                elementConfig: constants.elementConfig,
                stores: []
            };
        },
        watch: {
            $_selectedLocation: function(newVal) {
                this.stores = [];
                if (newVal) {
                    locationService
                        .getClosestLocation(
                            this.locationFullAddress(this.$_selectedLocation),
                            false,
                            this.elementConfig.GOOGLE_MAPS_KEY
                        )
                        .then(response => {
                            this.stores = response.filter(s => s.Id != this.$_selectedLocation.Id).slice(0, 3);
                        });
                }
            }
        },
        mounted() {
            this.$store.dispatch(StoreConstants.LOCATION.ACTIONS.LOAD_LOCATION_ID);
        },
        methods: {
            directionLink(location) {
                return 'http://maps.google.com/?saddr=Current+Location&daddr=' + location;
            }
        }
    };
</script>
