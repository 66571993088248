// @ts-check
import axios from 'axios';

/**
 * Makes request to Sitefinity REST API endpoint to retrieve dynamic content.
 * @param {string} url - REST API endpoint to request dynamic content from.
 * @param {number} skip - number of items to skip before requesting 50 items.
 * @param {boolean} recursive - if true, the method calls itself and requests the next 50 results.
 */
export function getDataPage(url, skip, recursive) {
    let urlToCall = url;

    if (url.includes('?')) {
        urlToCall = `${url}&$count=true&$skip=${skip}`;
    } else {
        urlToCall = `${url}?$count=true&$skip=${skip}`;
    }

    return new Promise((resolve, reject) => {
        axios
            .get(urlToCall)
            .then(response => {
                if (!recursive || response.data['@odata.count'] <= response.data.value.length + skip) {
                    resolve(response);
                } else {
                    getDataPage(url, skip + 50, recursive).then(res => {
                        response.data.value = response.data.value.concat(res.data.value);
                        resolve(response);
                    });
                }
            })
            .catch(reason => {
                reject(reason);
            });
    });
}
