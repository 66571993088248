﻿import axios from 'axios';
import { toSlug } from '../frontend/lib/utilities';

const baseUrl = '/api/default/shortlines';
const expand =
    'ShortLineMachineTypes,ShortLineMachineCategories,ShortLineMachines,ShortLineSpecials,ShortLineLogo';

const machineUrl = '/api/default/shortlinemachines';
const machineExpand = 'MachineImages,MachineBrochure';

export function getShortLine(shortlineId) {
    let apiUrl = baseUrl + `(${shortlineId})?$expand=${expand}`;
    return new Promise(resolve => {
        axios.get(apiUrl).then(response => resolve(response.data));
    });
}

function mapMachine(machine) {
    if (machine.MachineSpecs) {
        machine.Description = machine.MachineSpecs;
        delete machine.MachineSpecs;
    }
    if (machine.MachineImages) {
        machine.Images = machine.MachineImages.map(img => img.Url).join();
        delete machine.MachineImages;
    }

    if (machine.MachineBrochures) {
        if (machine.MachineBrochures.length > 0) {
            machine.Brochure1Title = machine.MachineBrochures[0].Title;
            machine.Brochure1Url = machine.MachineBrochures[0].Url;
        }

        if (machine.MachineBrochures.length > 1) {
            machine.Brochure2Title = machine.MachineBrochures[1].Title;
            machine.Brochure2Url = machine.MachineBrochures[1].Url;
        }

        if (machine.MachineBrochures.length > 2) {
            machine.Brochure3Title = machine.MachineBrochures[2].Title;
            machine.Brochure3Url = machine.MachineBrochures[2].Url;
        }

        if (machine.MachineBrochures.length > 3) {
            machine.Brochure4Title = machine.MachineBrochures[3].Title;
            machine.Brochure4Url = machine.MachineBrochures[3].Url;
        }

        delete machine.MachineBrochures;
    }

    machine.promos = [];

    return machine;
}

export function getShortlineMachineById(machineId) {
    let apiUrl = machineUrl + `(${machineId})?$expand=${machineExpand}`;
    return new Promise(resolve => {
        axios.get(apiUrl).then(response => {
            resolve(mapMachine(response.data));
        });
    });
}

// for use with search index data to get the full machine url
export function getShortlineEquipmentUrl(machine) {
    let basePath = machine.BaseUrl || '/equipment/new-shortlines';
    let industry = machine.ResultIndustries && machine.ResultIndustries[0];
    let machineType = (industry && toSlug(industry)) || 'type-not-found';

    return `${basePath}/${machineType}/${machine.UrlName}/overview`;
}

export default {
    getShortLine,
    getShortlineEquipmentUrl,
    getShortlineMachineById
};
