﻿<template>
    <div class="careers container py-4">
        <div class="row">
            <div v-for="(career, i) in careers" :key="i" class="col-12 col-md-4 col-xl-3 mb-4">
                <card-standard
                    :enable-image="false"
                    :title="career.Title"
                    :title-size="4"
                    :subtitle="career.Subtitle"
                    :enable-button-link="true"
                    :action-name="'Details'"
                    :action-url="
                        baseUrl.endsWith('/') ? `${baseUrl}${career.UrlName}` : `${baseUrl}/${career.UrlName}`
                    "
                    :btn-style="'btn-primary text-center'"
                    class="h-100"
                    :limit-image-height="true"
                    :use-block-btn="false"
                    :rounded="false"
                    :use-router="useRouter"
                    :route="{
                        name: 'details',
                        params: {
                            baseUrl: baseUrl,
                            career: career,
                            slug: career.UrlName,
                            useRouter: useRouter
                        }
                    }"
                >
                    <div class="career-content" v-html="career.Description"></div>
                </card-standard>
            </div>
        </div>
    </div>
</template>

<script>
    import CardStandard from '../cards/CardStandard';

    export default {
        name: 'CareerList',
        components: {
            CardStandard
        },
        props: {
            baseUrl: {
                type: String,
                required: true
            },
            useRouter: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                careers: []
            };
        },
        mounted() {
            this.getCareers();
        },
        methods: {
            getCareers() {
                var careerEndpoint = '/api/default/careers';
                var orderBy = 'Title';
                let apiUrl = `${careerEndpoint}?$orderby=${orderBy}`;
                fetch(apiUrl)
                    .then(response => response.json())
                    .then(response => {
                        if (response.value?.length) {
                            this.careers = response.value;
                        }
                    });
            }
        }
    };
</script>
