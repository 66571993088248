﻿<template>
    <div class="hero position-relative py-5" :class="`hero-${layout.toLowerCase()}`">
        <div class="hero-bg w-100 position-absolute" :style="background"></div>

        <div class="hero-fg w-100">
            <div :class="{ container: windowWidth >= 992 }">
                <div
                    v-if="enableSearch"
                    class="hero-searchbar d-flex position-relative w-100 pt-3 mt-2 mb-4"
                    :class="searchBarTheme"
                >
                    <div class="d-flex align-items-center w-100">
                        <div class="search-icon d-none d-lg-block ml-4">
                            <i class="fas fa-search"></i>
                        </div>
                        <search-bar
                            class="mx-4"
                            :placeholder="placeholderText"
                            :enable-mobile-view="true"
                            :css-class="'search-bar-hero-cards'"
                        />
                    </div>
                </div>
                <div class="row mb-3 mx-0">
                    <div class="col-12 hero-slider px-0">
                        <component :is="`slider-${layout.toLowerCase()}`" :slides="slides" />
                    </div>
                </div>
                <div v-if="cards.length > 0">
                    <splide
                        v-if="!isLayoutRounded || windowWidth >= 768"
                        :options="sliderOptions"
                        :class="{ 'px-5': windowWidth >= 768 }"
                    >
                        <splide-slide v-for="(card, i) in cards" :key="i">
                            <card-flippable v-if="!isLayoutRounded" :card="card"></card-flippable>
                            <card-image-overlay
                                v-else
                                :action-name="card.ctaText"
                                :action-url="card.ctaLink"
                                :image="card.image"
                                :open-link-in-new-tab="card.openLinkInNewTab"
                                :subtitle="card.subtitle"
                                :title="card.title"
                            ></card-image-overlay>
                        </splide-slide>
                    </splide>
                    <div v-else-if="isLayoutRounded" class="row mx-0">
                        <div v-for="card in cards.slice(0, 3)" :key="card.Id" class="col-12 px-4 py-3">
                            <card-image-overlay
                                :action-name="card.ctaText"
                                :action-url="card.ctaLink"
                                :image="card.image"
                                :open-link-in-new-tab="card.openLinkInNewTab"
                                :subtitle="card.subtitle"
                                :title="card.title"
                            ></card-image-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import constants from '../../config/constants';
    import { optimizeImage } from '../../lib/utilities';

    import CardFlippable from '../cards/CardFlippable';
    import CardImageOverlay from '../cards/CardImageOverlay';
    import SearchBar from '../search/SearchBar';
    import SliderCards from './HeroSlider.Cards';
    import SliderRounded from './HeroSlider.Rounded';
    import SliderWide from './HeroSlider.Wide';

    const typingSpeed = 60;
    const placeholderTextCollection = [
        { Text: 'Looking for something specific?' },
        { Text: 'Start typing here!' }
    ];

    export default {
        name: 'Hero',
        components: {
            CardFlippable,
            CardImageOverlay,
            SearchBar,
            SliderCards,
            SliderRounded,
            SliderWide
        },
        props: {
            layout: {
                type: String,
                required: true
            },
            backgroundImage: {
                type: String,
                default: ''
            },
            cards: {
                type: Array,
                default: () => []
            },
            slides: {
                type: Array,
                default: () => []
            },
            enableSearch: {
                type: Boolean,
                default: false
            },
            searchBarTheme: {
                type: String,
                default: ''
            },
            phrases: {
                type: Array,
                default: () => []
            },
            typewriterPhraseDelay: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                currentPhraseIndex: 0,
                phraseLetterIndex: 0,
                interval: null,
                placeholderText: '',
                sliderOptions: {
                    arrows: this.cards.length > 3,
                    autoplay: true,
                    breakpoints: {
                        767: {
                            pagination: false,
                            perPage: 1
                        }
                    },
                    gap: '1rem',
                    pagination: this.cards.length > 3,
                    perPage: Math.min(3, this.cards.length),
                    speed: 1000,
                    type: 'loop'
                },
                windowWidth: document.documentElement.clientWidth
            };
        },
        computed: {
            background() {
                let imgSrc = optimizeImage(this.backgroundImage, document.documentElement.clientWidth);
                return `background-image: url(${imgSrc});`;
            },
            isLayoutRounded() {
                return this.layout === constants.layouts.ROUNDED;
            }
        },
        mounted() {
            this.startInterval();
            this.$nextTick(() => {
                window.addEventListener('resize', this.setWindowSize);
            });
        },
        methods: {
            setWindowSize() {
                this.windowWidth = document.documentElement.clientWidth;
            },
            startInterval() {
                let customPhrases = this.phrases.length > 0 ? this.phrases : placeholderTextCollection;
                var currentPhrase = customPhrases[this.currentPhraseIndex].Text;
                if (currentPhrase) {
                    this.interval = setInterval(() => {
                        if (this.phraseLetterIndex <= currentPhrase.length) {
                            this.placeholderText = currentPhrase.substr(0, this.phraseLetterIndex++);
                        } else {
                            clearInterval(this.interval);
                            setTimeout(() => {
                                this.phraseLetterIndex = 0;
                                this.placeholderText = '';
                                this.currentPhraseIndex =
                                    this.currentPhraseIndex == customPhrases.length - 1
                                        ? 0
                                        : this.currentPhraseIndex + 1;
                                this.startInterval();
                            }, this.typewriterPhraseDelay);
                        }
                    }, typingSpeed);
                }
            }
        }
    };
</script>
