﻿<template>
    <div class="d-flex">
        <div class="store-finder desktop d-none d-lg-inline-flex">
            <template v-if="useLegacyLocationDropdown">
                <location-list-dropdown />
            </template>
            <div
                v-else
                :class="{ grow: grow, shrink: !grow, middle: grow || loading, 'text-center': loading }"
                class="bg-primary text-primary-yiq button-wrapper"
            >
                <transition-group name="fade-desktop" class="w-100">
                    <button
                        v-show="!init && !showInput && !grow && !hasDetails && !loading"
                        key="button"
                        type="button"
                        class="bg-primary bg-fixed text-primary-yiq border-0"
                        @click="openStoreFinderDesktop()"
                    >
                        <span class="fas fa-map-marker-alt pr-1"></span>
                        <span class="sf-mr-s text-uppercase">Find your dealer</span>
                    </button>
                    <span
                        v-show="!init && !showInput && !grow && loading"
                        key="loading"
                        class="fas fa-spinner fa-spin p-3"
                    ></span>
                    <div v-show="!init && showInput && grow" key="input" class="row mx-0">
                        <div class="col-6 d-flex align-items-center px-0">
                            <span class="fas fa-search px-2"></span>
                            <input
                                ref="location-input"
                                v-model="zip"
                                type="text"
                                class="bg-primary text-primary-yiq location-input desktop border-0"
                                autocomplete="off"
                                :placeholder="$_enterCityPlaceholder"
                                @keydown.enter.prevent="searchLocationKeyUpDesktop"
                            />
                        </div>
                        <div class="col-6 px-0">
                            <button
                                v-if="detectionFailed"
                                class="border-0 detect-button-desktop px-2"
                                :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                                type="button"
                                @click="detectDesktop()"
                            >
                                Retry
                                <span class="fas fa-redo-alt"></span>
                            </button>
                            <button
                                v-else
                                class="border-0 detect-button-desktop px-3"
                                :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                                type="button"
                                @click="detectDesktop()"
                            >
                                Detect Location
                                <span class="fas fa-crosshairs ml-1"></span>
                            </button>
                        </div>
                        <div v-if="citySuggestions.length > 0" class="suggestions desktop bg-primary w-100">
                            <button
                                v-for="(suggestion, i) in citySuggestions"
                                :key="i"
                                class="
                                    suggestion
                                    py-2
                                    pr-0
                                    mx-2
                                    text-left
                                    bg-primary bg-fixed
                                    text-primary-yiq
                                "
                                type="button"
                                @click="searchSuggestionDesktop(suggestion)"
                            >
                                {{ suggestion }}
                            </button>
                        </div>
                    </div>
                    <div
                        v-show="!init && !showInput && !grow && hasDetails && !loading"
                        key="location"
                        class="w-100"
                    >
                        <button
                            v-if="locationName(location)"
                            id="btnLocationDetailToggle"
                            type="button"
                            class="border-0 w-100 bg-primary bg-fixed text-primary-yiq px-4"
                            @click="toggleDetails()"
                        >
                            <span :class="caret" class="fas pr-1"></span>
                            {{ location.DealerLocationTitle }}
                        </button>
                        <transition mode="out-in" name="drawer">
                            <div v-show="showDetails" key="abc">
                                <div id="location-details" key="bcd" ref="locationDetails" class="bg-primary">
                                    <location-details
                                        key="mfp"
                                        :location="location"
                                        :toggle-input="openStoreFinderDesktop"
                                    />
                                </div>
                            </div>
                        </transition>
                    </div>
                </transition-group>
            </div>
            <transition name="fade-desktop">
                <ul
                    v-show="!grow && !loading"
                    key="1"
                    class="menu-bricks"
                    :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                >
                    <li v-if="location && location.Phone" class="border-0">
                        <a :href="storeTelLink" :class="navLinkClasses">
                            <span class="fas fa-phone text-primary"></span>
                            <span class="d-none d-xl-inline-block">{{ location.Phone }}</span>
                        </a>
                    </li>
                    <li
                        v-else-if="!contactSettings.HideCallUsButton && !useLegacyLocationDropdown"
                        class="border-0"
                    >
                        <a :href="storeTelLink" :class="navLinkClasses">
                            <span class="fas fa-phone text-primary mr-1"></span>
                            <span class="d-none d-xl-inline-block">{{
                                contactSettings.CustomCallUsLabelText || 'CALL US'
                            }}</span>
                        </a>
                    </li>
                    <li v-if="!contactSettings.HideEmailUsButton" class="border-0">
                        <a :href="storeEmailLink" :class="navLinkClasses" target="_blank">
                            <span class="fas fa-envelope text-primary mr-1"></span>
                            <span class="d-none d-xl-inline-block">{{
                                contactSettings.CustomEmailLabelText || 'CONTACT US'
                            }}</span>
                        </a>
                    </li>
                    <li
                        v-if="contactSettings.CustomerPortalLink && !contactSettings.HideCustomerPortalButton"
                        class="border-0"
                    >
                        <a :href="contactSettings.CustomerPortalLink" :class="navLinkClasses" target="_blank">
                            <span class="fas fa-user text-primary mr-1"></span>
                            <span class="d-none d-xl-inline-block">CUSTOMER PORTAL</span>
                        </a>
                    </li>
                    <li v-for="page in additionalPages" :key="page.FullUrl" class="border-0">
                        <a :href="page.FullUrl" :class="navLinkClasses">
                            <span class="d-none d-xl-inline-block text-uppercase">{{
                                page.Title.Value
                            }}</span>
                        </a>
                    </li>
                </ul>
            </transition>
        </div>
        <div class="store-finder mobile d-flex justify-content-between align-items-center d-lg-none w-100">
            <location-list-dropdown
                v-if="useLegacyLocationDropdown"
                class="float-left"
            ></location-list-dropdown>
            <div v-else :class="leftBarClasses" class="bg-primary text-primary-yiq float-left">
                <transition-group name="fade">
                    <form v-show="showInput" key="1">
                        <!-- Store Finder Input -->
                        <div class="d-flex">
                            <input
                                ref="location-input"
                                v-model="zip"
                                class="text-primary-yiq location-input w-100 pl-2"
                                type="text"
                                :placeholder="$_enterCityPlaceholder"
                                autocomplete="off"
                                @keydown.enter.prevent="searchLocationKeyUp"
                            />
                            <transition name="fade">
                                <button
                                    v-if="loadingSuggestions"
                                    class="icon fas fa-spinner fa-spin pr-3"
                                    type="button"
                                    @click="searchLocation"
                                ></button>
                                <button
                                    v-else
                                    class="icon fas fa-search pr-3"
                                    type="button"
                                    @click="searchLocation"
                                ></button>
                            </transition>
                            <div v-if="citySuggestions.length > 0" class="suggestions bg-primary w-100">
                                <button
                                    v-for="(suggestion, i) in citySuggestions"
                                    :key="i"
                                    class="suggestion py-2 pr-0 pl-3 text-left w-100"
                                    type="button"
                                    @click="searchSuggestion(suggestion)"
                                >
                                    {{ suggestion }}
                                </button>
                            </div>
                        </div>
                    </form>
                    <div v-show="!showInput && hasDetails" key="2">
                        <!-- Location Details -->
                        <button
                            v-if="locationName(location)"
                            id="btnLocationDetailToggle"
                            class="text-primary-yiq icon text-center w-100"
                            :aria-label="location.name"
                            type="button"
                            @click="toggleDetails()"
                        >
                            <span :class="caret"></span>
                            {{ location.DealerLocationTitle }}
                        </button>
                        <transition mode="out-in" name="drawer">
                            <div v-show="showDetails" key="abc">
                                <div id="location-details" key="bcd" ref="locationDetails" class="bg-primary">
                                    <location-details
                                        key="mfp"
                                        :location="location"
                                        :toggle-input="openStoreFinder"
                                    />
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div v-show="!showInput && !hasDetails" key="3">
                        <!-- Store Finder Button -->
                        <button
                            class="icon text-center w-100"
                            aria-label="Find your store"
                            type="button"
                            @click="openStoreFinder()"
                        >
                            <transition name="fade">
                                <span v-if="loading" key="5" class="fas fa-spinner fa-spin"></span>
                                <span v-else key="6">
                                    <span class="fas fa-map-marker-alt"></span> Find your dealer
                                </span>
                            </transition>
                        </button>
                    </div>
                </transition-group>
            </div>
            <div :class="rightBarClasses" class="float-right">
                <transition name="fade">
                    <div v-if="showInput">
                        <button
                            class="icon w-100 text"
                            aria-label="Find your store"
                            type="button"
                            @click="searchLocation(true)"
                        >
                            <i class="fas fa-crosshairs"></i> USE LOCATION
                        </button>
                    </div>
                    <div v-else>
                        <ul v-if="!showLocationFinder" class="menu-bricks float-right">
                            <li>
                                <a :href="storeTelLink" class="ts-icon">
                                    <span class="fa fa-phone"></span>
                                </a>
                            </li>
                            <li>
                                <a :href="storeEmailLink" class="ts-icon">
                                    <span class="fa fa-envelope"></span>
                                </a>
                            </li>
                            <li v-if="weatherPage">
                                <a
                                    :href="weatherPage.FullUrl"
                                    class="ts-icon"
                                    :target="weatherPage.OpenNewWindow ? '_blank' : ''"
                                >
                                    <span class="fa fa-cloud"></span>
                                </a>
                            </li>
                            <li v-if="showEventsIconMobile">
                                <a href="/events" class="ts-icon">
                                    <span class="fa fa-calendar-alt"></span>
                                </a>
                            </li>
                            <li
                                v-if="
                                    contactSettings.CustomerPortalLink &&
                                    !contactSettings.HideCustomerPortalButton
                                "
                            >
                                <a :href="contactSettings.CustomerPortalLink" class="ts-icon">
                                    <span class="fas fa-user"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import constants from '../../config/constants';
    import debounce from 'lodash.debounce';

    import LocalStorageService from '../../../services/LocalStorageService';
    import LocationDetails from '../locations/LocationDetails';
    import LocationListDropdown from './LocationListDropdown';
    import LocationMixin from '../mixins/LocationMixin';
    import LocationService from '../../../services/LocationService.js';
    import MapsService from '../../../services/GoogleMapService';
    import StoreConstants from '../../store/constants';
    import ThemeMixin from '../mixins/ThemeMixin';

    export default {
        name: 'StoreFinder',
        components: {
            LocationDetails,
            LocationListDropdown
        },
        mixins: [LocationMixin, ThemeMixin],
        props: {
            additionalPages: {
                type: Array,
                default: () => []
            },
            contactSettings: {
                type: Object,
                default: () => ({})
            },
            showEventsIconMobile: {
                type: Boolean,
                default: false
            },
            showLocationFinder: {
                type: Boolean,
                default: false
            },
            useLegacyLocationDropdown: {
                type: Boolean,
                default: false
            },
            weatherPage: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                elementConfig: constants.elementConfig,
                citySuggestions: [],
                detectionFailed: false,
                grow: false,
                hasDetails: false,
                init: true,
                loading: false,
                loadingSuggestions: false,
                showDetails: false,
                showInput: false,
                show: true,
                zip: ''
            };
        },
        computed: {
            location() {
                return this.$_selectedLocation;
            },
            storeTelLink() {
                const tel = this.location && this.location.Phone ? this.location.Phone : this.elementConfig.PHONE;
                return 'tel:' + tel;
            },
            storeEmailLink() {
                const email = this.location?.Email ?? this.elementConfig.EMAIL;
                return 'mailto:' + email + '?subject=' + (this.contactSettings.CustomEmailSubjectLineText || '');
            },
            leftBarClasses() {
                if (this.showInput) {
                    return 'bar-grow';
                } else {
                    return 'bar-shrink';
                }
            },
            rightBarClasses() {
                if (this.showInput) {
                    return 'bar-right-shrink';
                } else {
                    return 'bar-right-grow';
                }
            },
            showDetailWidget() {
                if (this.showDetails && this.hasDetails && !this.showInput) {
                    return true;
                }
                return false;
            },
            caret() {
                return this.showDetails && this.hasDetails ? 'fas fa-caret-up' : 'fas fa-caret-down';
            },
            navLinkClasses() {
                let classes = 'd-flex align-items-center h-100 ';
                return classes + (this.$_isLightTheme ? 'text-gray-500' : 'text-gray-400');
            }
        },
        watch: {
            zip: function(val) {
                if (val.length > 2 && isNaN(val)) {
                    this.getCitySuggestions(val, this);
                }
            },
            location: function(val) {
                if (val && val.Id) {
                    this.hasDetails = true;
                    this.init = false;
                    this.grow = false;
                    this.showInput = false;
                    if (val.Phone) {
                        window.SelectedLocationPhoneNumber = val.Phone;
                    }
                } else {
                    this.hasDetails = false;
                }
            }
        },
        mounted() {
            const locationId = LocalStorageService.getItem(StoreConstants.LOCATION.LOCAL_STORAGE_KEY, null);
            const locationCleared = this.$store.getters[StoreConstants.LOCATION.GETTERS.LOCATION_CLEARED];

            // set default location if it exists and location has not already been set
            if (!locationCleared && !locationId && constants.elementConfig.DEFAULT_LOCATION_ID) {
                this.$store.dispatch(
                    StoreConstants.LOCATION.ACTIONS.SET_LOCATION_ID,
                    constants.elementConfig.DEFAULT_LOCATION_ID
                );
            }

            this.$store.dispatch(StoreConstants.LOCATION.ACTIONS.LOAD_LOCATION_ID);

            this.grow = this.showLocationFinder;
            this.showInput = this.showLocationFinder;
            this.init = false;

            window.addEventListener('click', e => {
                if (
                    e.target.closest('#btnLocationDetailToggle') == null &&
                    e.target.id !== 'btnLocationDetailToggle' &&
                    e.target.id !== 'location-details' &&
                    this.$refs.locationDetails &&
                    !this.$refs.locationDetails.contains(e.target)
                ) {
                    this.showDetails = false;
                }
            });
        },
        methods: {
            openStoreFinderDesktop() {
                this.grow = true;
                setTimeout(() => this.openStoreFinder(), 500);
            },
            openStoreFinder() {
                this.showInput = true;
                this.showDetails = false;
                this.zip = '';
                this.$store.dispatch(StoreConstants.LOCATION.ACTIONS.DELETE_LOCATION_ID);

                this.$nextTick(() => {
                    this.$refs['location-input'].focus();
                });
            },
            searchSuggestion(city) {
                this.zip = '';
                this.searchLocation(false, city);
            },
            searchSuggestionDesktop(city) {
                this.grow = false;
                this.searchSuggestion(city);
            },
            searchLocationKeyUp(e) {
                if (e.keyCode === 13) {
                    this.searchLocation();
                }
            },
            searchLocationKeyUpDesktop(e) {
                if (e.keyCode === 13) {
                    this.grow = false;
                    this.searchLocation();
                }
            },
            getCitySuggestions: debounce((input, vue) => {
                vue.loadingSuggestions = true;
                MapsService.autocomplete(input)
                    .then(results => {
                        vue.citySuggestions = results;
                        vue.loadingSuggestions = false;
                    })
                    .catch(() => {
                        vue.loadingSuggestions = false;
                    });
            }),
            searchLocation(autoDetectLocation = false, city) {
                this.showInput = false;
                this.hasDetails = false;

                if (autoDetectLocation) {
                    this.loading = true;
                } else {
                    this.citySuggestions = [];
                    city = city || this.zip;
                    if (city) {
                        this.loading = true;
                    }
                }

                if (this.loading) {
                    let vm = this;

                    LocationService.getClosestLocation(city)
                        .then(location => {
                            this.$store.dispatch(StoreConstants.LOCATION.ACTIONS.SET_LOCATION_ID, location.Id);

                            if (location) {
                                vm.detectionFailed = false;
                                vm.loading = false;
                                vm.hasDetails = true;
                            }
                        })
                        .catch(reason => {
                            console.log(reason);
                            vm.loading = false;
                            vm.loadingSuggestions = false;
                            vm.detectionFailed = true;
                            vm.openStoreFinderDesktop();
                        });
                }
            },
            toggleDetails() {
                this.showDetails = !this.showDetails;
            },
            detectDesktop() {
                this.grow = false;
                this.searchLocation(true);
            }
        }
    };
</script>
