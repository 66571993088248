﻿import LocalStorageService from '../../../services/LocalStorageService';
import StoreConstants from '../constants';

const state = {
    locationId: null,
    locationCleared: false
};

const getters = {
    locationId: state => state.locationId,
    locationCleared: state => state.locationCleared
};

const mutations = {
    deleteLocationId(state) {
        state.locationId = null;
        state.locationCleared = true;
    },
    setLocationId(state, locationId) {
        state.locationId = locationId;
        state.locationCleared = false;
    }
};

const actions = {
    load(context) {
        const locationId = LocalStorageService.getItem(StoreConstants.LOCATION.LOCAL_STORAGE_KEY, null);
        context.commit('setLocationId', locationId);
    },
    delete(context) {
        LocalStorageService.removeItem(StoreConstants.LOCATION.LOCAL_STORAGE_KEY);
        context.commit('deleteLocationId');
    },
    set(context, locationId) {
        LocalStorageService.setItem(StoreConstants.LOCATION.LOCAL_STORAGE_KEY, locationId);
        context.commit('setLocationId', locationId);
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
};
