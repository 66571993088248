﻿<template>
    <div id="header" class="header-wrapper d-none d-lg-block">
        <!--STORE FINDER-->
        <div :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <store-finder
                        :contact-settings="contactSettings"
                        :additional-pages="additionalPages"
                        :use-legacy-location-dropdown="useLegacyLocationDropdown"
                    ></store-finder>
                    <div>
                        <brow-links
                            :brow-links="browLinks"
                            :social-bar="socialBar"
                            :weather-page="weatherPage"
                            class="menu-bricks float-right"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!--/STORE FINDER-->
        <!--LOGO & SEARCH-->
        <div>
            <div class="container">
                <div class="row d-flex justify-content-between">
                    <div>
                        <span class="logo">
                            <a href="/">
                                <img :src="$_logoUrl" :alt="$_logoAlt" />
                            </a>
                        </span>
                    </div>
                    <div class="d-flex align-items-center">
                        <search-bar css-class="search-bar-header" />
                    </div>
                </div>
            </div>
        </div>
        <!--/LOGO & SEARCH-->
        <!--MEGA MENU + SAVE & COMPARE-->
        <div :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <div>
                        <simple-menu
                            v-if="useSimpleMenus"
                            class="menu-bricks"
                            :main-nav-pages="mainNavPages"
                        ></simple-menu>
                        <mega-menu v-else class="menu-bricks" :main-nav-pages="mainNavPages" />
                    </div>
                    <div class="d-flex">
                        <saved-equipment icon-class="text-primary"></saved-equipment>
                    </div>
                </div>
            </div>
        </div>
        <!--/MEGA MENU + SAVE & COMPARE-->
    </div>
</template>

<script>
    import BrowLinks from './BrowLinks';
    import MegaMenu from '../menu/MegaMenu';
    import SavedEquipment from '../saved-equipment/SavedEquipment';
    import SearchBar from '../search/SearchBar';
    import SimpleMenu from '../menu/SimpleMenu';
    import StoreFinder from './StoreFinder';

    import HeaderMixin from '../mixins/HeaderMixin';
    import ThemeMixin from '../mixins/ThemeMixin';

    export default {
        name: 'HeaderCards',
        components: {
            SearchBar,
            StoreFinder,
            BrowLinks,
            MegaMenu,
            SavedEquipment,
            SimpleMenu
        },
        mixins: [HeaderMixin, ThemeMixin],
        props: {
            additionalPages: {
                type: Array,
                default: () => []
            },
            browLinks: {
                type: Array,
                default: () => []
            },
            contactSettings: {
                type: Object,
                default: () => ({})
            },
            mainNavPages: {
                type: Array,
                required: true
            },
            socialBar: {
                type: Object,
                default: () => ({})
            },
            useLegacyLocationDropdown: {
                type: Boolean,
                default: false
            },
            useSimpleMenus: {
                type: Boolean,
                required: true
            },
            weatherPage: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                // TODO: review these 3 properties. Added to solve runtime errors. Not sure why they they weren't defined...
                iconOnly: false,
                iconClass: '',
                textClass: ''
            };
        }
    };
</script>
