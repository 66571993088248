﻿import './search-config-types';

/**
 * Place any constants here that can be re-used throughout components and libraries.
 */
export const DEV_ENV_SERVER = 'https://element-si-dev.azurewebsites.net';

/**
 * Server-side configuration values that are provided by Sitefinity via a global JS object.
 * @type {ElementConfig}
 */
const elementConfig = window.ElementConfig;

const sfDistPath = '/ElementUI/dist/sitefinity/';
const staticDistPath = process.env.NODE_ENV === 'development' ? '/' : sfDistPath;

const inventoryConditions = ['New', 'Used'];

/**
 * Full type names for Sitefinity new equipment content types.
 */
const newEqTypeNames = {
    CASECE: 'Telerik.Sitefinity.DynamicTypes.Model.CaseCE.CaseCEMachine',
    CASEIH: 'Telerik.Sitefinity.DynamicTypes.Model.CaseIH.CaseIHMachine',
    DEERE: 'Telerik.Sitefinity.DynamicTypes.Model.JohnDeere.DeereMachine',
    KUBOTA: 'Telerik.Sitefinity.DynamicTypes.Model.Kubota.KubotaSeries',
    MAHINDRA: 'Telerik.Sitefinity.DynamicTypes.Model.Mahindra.MahindraMachine',
    MASSEY_FERGUSON: 'Telerik.Sitefinity.DynamicTypes.Model.MasseyFerguson.MasseyFergusonMachine',
    NEW_HOLLAND_AG: 'Telerik.Sitefinity.DynamicTypes.Model.NewHolland.NewHollandMachine',
    NEW_HOLLAND_CONSTRUCTION: 'Telerik.Sitefinity.DynamicTypes.Model.NewHolland.NewHollandConstructionModel',
    STIHL: 'Telerik.Sitefinity.DynamicTypes.Model.Stihl.StihlProduct',
    VERMEER: 'Telerik.Sitefinity.DynamicTypes.Model.Vermeer.VermeerMachine',
    SHORTLINE: 'Telerik.Sitefinity.DynamicTypes.Model.ShortLine.ShortLineMachine'
};

/**
 * Full type names for Sitefinity promotions content types.
 */
const promotionsTypeNames = {
    CASEIH: 'Telerik.Sitefinity.DynamicTypes.Model.CaseIH.CaseIHPromotion',
    DEALER: 'Telerik.Sitefinity.DynamicTypes.Model.Dealer.DealerPromotion',
    DEERE: 'Telerik.Sitefinity.DynamicTypes.Model.JohnDeere.DeerePromotion',
    KUBOTA: 'Telerik.Sitefinity.DynamicTypes.Model.Kubota.KubotaPromotion',
    MASSEYFERGUSON: 'Telerik.Sitefinity.DynamicTypes.Model.MasseyFerguson.MasseyFergusonPromotion',
    MAHINDRA: 'Telerik.Sitefinity.DynamicTypes.Model.Mahindra.Mahindrapromotion'
};

/**
 * Full type names for Sitefinity standard and reusable content types.
 */
const contentTypeNames = {
    BLOG_POSTS: 'Telerik.Sitefinity.Blogs.Model.BlogPost',
    EVENTS: 'Telerik.Sitefinity.Events.Model.Event',
    LOCATION: 'Telerik.Sitefinity.DynamicTypes.Model.Dealer.DealerLocation',
    NEWS: 'Telerik.Sitefinity.News.Model.NewsItem',
    PAGES: 'Telerik.Sitefinity.Pages.Model.PageNode',
    TESTIMONIALS: 'Telerik.Sitefinity.DynamicTypes.Model.Dealer.Testimonial',
    USED_EQUIPMENT: 'Telerik.Sitefinity.DynamicTypes.Model.SyncedUsedEquipment.UsedEquipment'
};

/**
 * List of social icons classes and supported social links.
 */
const socialItemsClass = {
    Facebook: 'fab fa-facebook-f',
    Twitter: 'fab fa-twitter',
    Instagram: 'fab fa-instagram',
    Youtube: 'fab fa-youtube',
    GooglePlus: 'fab fa-google-plus-g',
    LinkedIn: 'fab fa-linkedin-in'
};

/**
 * List of save states
 */
const saveStatusOptions = {
    NONE: 0,
    SAVING: 1,
    SUCCESS: 2,
    FAILED: 3
};

/**
 * List of Sync Data Sources for Used Equipment Machines
 */
const usedEqSyncDataSources = {
    MFP: '1',
    DIS: '2',
    CDK: '3',
    HBS: '4',
    manualEntry: '5',
    SH: '6',
    IRON: '7'
};

const mappings = {
    deere: {
        label: 'Deere',
        hierarchy: [
            {
                title: 'Title',
                name: 'Industry',
                type: 'Telerik.Sitefinity.DynamicTypes.Model.JohnDeere.DeereIndustry'
            },
            {
                title: 'Title',
                name: 'Category',
                type: 'Telerik.Sitefinity.DynamicTypes.Model.JohnDeere.DeereCategory'
            },
            {
                title: 'Title',
                name: 'Subcategory',
                type: 'Telerik.Sitefinity.DynamicTypes.Model.JohnDeere.DeereSubcategory'
            },
            {
                title: 'Title',
                name: 'Series',
                type: 'Telerik.Sitefinity.DynamicTypes.Model.JohnDeere.DeereSeries'
            },
            {
                title: 'Title',
                name: 'Category',
                type: 'Telerik.Sitefinity.DynamicTypes.Model.JohnDeere.DeereSeriesCategory'
            }
        ],
        machine: {
            title: 'Title',
            name: 'Deere Machines',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.JohnDeere.DeereMachine'
        },
        promotion: {
            title: 'Title',
            name: 'Deere Promotions',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.JohnDeere.DeerePromotion'
        }
    },
    kubota: {
        label: 'Kubota',
        machine: {
            title: 'Title',
            name: 'Kubota Series',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.Kubota.KubotaSeries'
        },
        promotion: {
            title: 'Title',
            name: 'Kubota Promotion',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.Kubota.KubotaPromotion'
        }
    },
    used_equipment: {
        label: 'Used Equipment',
        machine: {
            title: 'Title',
            name: 'Used Equipment',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.SyncedUsedEquipment.UsedEquipment'
        },
        promotion: {
            title: 'Title',
            name: 'Dealer Promotion',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.Dealer.DealerPromotion'
        }
    },
    mahindra: {
        label: 'Mahindra',
        machine: {
            title: 'Title',
            name: 'Mahindra Machines',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.Mahindra.MahindraMachine'
        },
        promotion: {
            title: 'Title',
            name: 'Mahindra Promotions',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.Mahindra.MahindraPromotion'
        }
    },
    stihl: {
        label: 'Stihl',
        machine: {
            title: 'Title',
            name: 'Stihl Machines',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.Stihl.StihlMachine'
        },
        promotion: {
            title: 'Title',
            name: 'Stihl Promotions',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.Stihl.StihlPromotion'
        }
    },
    massey_ferguson: {
        label: 'Massey Ferguson',
        machine: {
            title: 'Title',
            name: 'Massey Ferguson Series',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.MasseyFerguson.MasseyFergusonSeries'
        },
        promotion: {
            title: 'Title',
            name: 'Massey Ferguson Promotions',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.MasseyFerguson.MasseyFergusonPromotion'
        }
    },
    newholland: {
        label: 'New Holland',
        machine: {
            title: 'Title',
            name: 'New Holland Machines',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.NewHolland.NewHollandMachine'
        },
        promotion: {
            title: 'Title',
            name: 'New Holland Promotions',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.NewHolland.NewHollandPromotion'
        }
    },
    vermeer: {
        label: 'Vermeer',
        machine: {
            title: 'Title',
            name: 'Vermeer Machines',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.Vermeer.VermeerMachine'
        },
        promotion: {
            title: 'Title',
            name: 'Vermeer Promotions',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.Vermeer.VermeerPromotion'
        }
    },
    caseih: {
        label: 'Case IH',
        machine: {
            title: 'Title',
            name: 'Case IH Machines',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.CaseIH.CaseIHMachine'
        },
        promotion: {
            title: 'Title',
            name: 'Case IH Promotions',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.CaseIH.CaseIHPromotion'
        }
    },
    casece: {
        label: 'Case CE',
        machine: {
            title: 'Title',
            name: 'Case CE Machines',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.CaseCE.CaseCEMachine'
        }
    },
    dealer: {
        label: 'Dealer',
        promotion: {
            title: 'Title',
            name: 'Dealer Promotion',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.Dealer.DealerPromotion'
        }
    },
    shortline: {
        label: 'Shortline',
        machine: {
            title: 'MachineTitle',
            name: 'Shortline Machines',
            type: 'Telerik.Sitefinity.DynamicTypes.Model.ShortLine.ShortLineMachine'
        }
    }
};

/**
 * Mapping of equipment manufacturer names to manufacturer logos
 */
let manufacturerLogos = {
    'CASE CE': `${staticDistPath}manufacturer-logos/casece-250.png`,
    'CASE IH': `${staticDistPath}manufacturer-logos/caseih-250.png`,
    DEERE: `${staticDistPath}manufacturer-logos/john-deere-250.png`,
    'JOHN DEERE': `${staticDistPath}manufacturer-logos/john-deere-250.png`,
    KUBOTA: `${staticDistPath}manufacturer-logos/kubota-250.png`,
    MAHINDRA: `${staticDistPath}manufacturer-logos/mahindra-250.png`,
    'MASSEY FERGUSON': `${staticDistPath}manufacturer-logos/massey-ferguson-250.png`,
    'NEW HOLLAND': `${staticDistPath}manufacturer-logos/new-holland-ag-250.png`,
    'NEW HOLLAND AG': `${staticDistPath}manufacturer-logos/new-holland-ag-250.png`,
    'NEW HOLLAND CONSTRUCTION': `${staticDistPath}manufacturer-logos/new-holland-construction-250.png`,
    STIHL: `${staticDistPath}manufacturer-logos/stihl-250.png`,
    VERMEER: `${staticDistPath}manufacturer-logos/vermeer-250.png`,
    'SHORTLINE AG': `${staticDistPath}manufacturer-logos/shortline-250.png`
};

let layouts = {
    CARDS: 'Cards',
    CLASSIC: 'Classic',
    ROUNDED: 'Rounded',
    WIDE: 'Wide'
}

export default {
    inventoryConditions,
    saveStatusOptions,
    contentTypeNames,
    newEqTypeNames,
    promotionsTypeNames,
    socialItemsClass,
    elementConfig,
    mappings,
    errorPage404: '/error-pages/404',
    employeePlaceholderImage: staticDistPath + 'employee-placeholder.png',
    equipmentPlaceholderImage: staticDistPath + 'equipment-placeholder.jpg',
    locationPlaceholderImage: staticDistPath + 'location-placeholder.jpg',
    pilboxProxyUrl: 'https://image-proxy.teamsi.com',
    cfImageResizingUrl: 'https://lapero.io/cdn-cgi/image',
    usedEqSyncDataSources,
    manufacturerLogos,
    DEV_ENV_SERVER,
    layouts
};
