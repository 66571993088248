﻿<template>
    <transition name="fade">
        <div
            v-show="loaded"
            class="new-equipment-showroom-detail"
            :class="$_isLightTheme ? 'bg-white' : 'bg-dark'"
        >
            <h1 class="text pt-1 px-2 d-block d-md-none">{{ machine.Title }}</h1>
            <div class="title-bar row d-none d-md-flex" :class="$_isLightTheme ? 'bg-white' : 'bg-dark'">
                <ul class="breadcrumbs col-12 d-none d-md-block">
                    <li>
                        <router-link to="/">Home</router-link>
                    </li>
                    <li v-if="$route.params.category">></li>
                    <li v-if="$route.params.category">
                        <router-link :to="route($route.params.category)">
                            {{ slugToTitle($route.params.category) }}
                        </router-link>
                    </li>
                    <li v-if="$route.params.subcategory">></li>
                    <li v-if="$route.params.subcategory">
                        <router-link :to="route($route.params.category, $route.params.subcategory)">
                            {{ slugToTitle($route.params.subcategory) }}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div id="machine-nav-carousel" class="d-block d-md-none w-100 pt-3">
                <splide :options="sliderOptions">
                    <splide-slide :class="activeLink('overview')">
                        <router-link to="overview">Overview</router-link>
                    </splide-slide>
                    <splide-slide :class="[activeLink('features'), { 'd-none': !machine.Features }]">
                        <router-link to="features">Features</router-link>
                    </splide-slide>
                    <splide-slide
                        :class="[
                            activeLink('specifications'),
                            { 'd-none': !machine.Specifications && !machine.SpecsImage }
                        ]"
                    >
                        <router-link to="specifications">Specs</router-link>
                    </splide-slide>
                    <splide-slide
                        v-if="machine.VideoUrl"
                        :class="[activeLink('videos'), { 'd-none': !machine.VideoUrl }]"
                    >
                        <router-link to="videos">Videos</router-link>
                    </splide-slide>
                    <splide-slide
                        :class="[
                            activeLink('gallery'),
                            { 'd-none': !machine.Images || machine.Images.split(',').length < 2 }
                        ]"
                    >
                        <router-link to="gallery">Gallery</router-link>
                    </splide-slide>
                    <splide-slide
                        v-show="machine.Brochure1Title && machine.Brochure1Url"
                        :class="[
                            activeLink('brochures'),
                            { 'd-none': !machine.Brochure1Title || !machine.Brochure1Url }
                        ]"
                    >
                        <router-link to="brochures">Brochures</router-link>
                    </splide-slide>
                    <splide-slide :class="activeLink('specials')">
                        <router-link to="specials">Specials</router-link>
                    </splide-slide>
                </splide>
            </div>

            <div class="machine-detail-banner pt-1 pl-1 d-flex d-md-none">
                <div class="machine-image">
                    <img :src="imgUrl" :alt="machine.Title" />
                </div>
                <div class="machine-cta showroom-color-primary">
                    <a
                        :href="dealerPhoneNumber"
                        class="cta-button my-2 mx-2"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                    >
                        <span class="fas fa-phone"></span>
                    </a>
                    <a
                        class="cta-button my-2 mr-2"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                        data-toggle="modal"
                        :data-target="`#${quoteFormModalId}`"
                    >
                        <span class="fas fa-dollar-sign"></span>
                    </a>
                    <a
                        class="cta-button mb-2 mx-2"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                        @click="saveMachine(machine, manufacturer)"
                    >
                        <span class="fas fa-star"></span>
                    </a>
                    <a
                        class="cta-button mb-2 mr-2"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                        @click="print"
                    >
                        <span class="fas fa-print"></span>
                    </a>
                    <a
                        v-if="machine.CtaUrl"
                        :href="machine.CtaUrl"
                        class="cta-button mb-2 mx-2"
                        :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                    >
                        <span class="showroom-color-primary fas fa-link"></span>
                        {{ machine.CtaUrlButtonText }}
                    </a>
                </div>
            </div>

            <div ref="detail-wrapper" class="d-flex equipment-detail-wrapper">
                <router-link
                    :to="backHref"
                    class="equipment-detail-back bg-accent-0 text-accent-6 px-3 py-2 ml-4 d-none d-md-block"
                >
                    <span class="fas fa-chevron-left showroom-color-primary"></span>
                    Back to Results
                </router-link>

                <div
                    v-if="!gallery && !specs && !videos && !specials"
                    class="equipment-image-wrapper d-none d-md-block"
                >
                    <h3 v-if="machine.MachineExtraContent" class="equipment-detail-title">
                        {{ machine.MachineExtraContent }}
                    </h3>
                    <h3 class="equipment-detail-title pt-2">{{ machine.Title }}</h3>
                    <element-image
                        v-if="loaded"
                        class="d-none d-md-block equipment-detail-image"
                        :src="imgUrl"
                        width="390"
                    ></element-image>

                    <div class="machine-cta d-none d-md-flex p-3 text">
                        <a
                            :href="dealerPhoneNumber"
                            class="cta-button my-2 mx-2"
                            :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                        >
                            <span class="showroom-color-primary fas fa-phone mr-2"></span> Call Us
                        </a>
                        <a
                            data-toggle="modal"
                            :data-target="`#${quoteFormModalId}`"
                            class="cta-button my-2 mr-2"
                            :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                        >
                            <span class="showroom-color-primary fas fa-dollar-sign mr-2"></span> Get A Quote
                        </a>
                        <a
                            class="cta-button mb-2 mx-2"
                            :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                            @click="saveMachine(machine, manufacturer)"
                        >
                            <span class="showroom-color-primary fas fa-star mr-2"></span> Save
                        </a>
                        <a
                            class="cta-button mb-2 mr-2"
                            :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                            @click="print"
                        >
                            <span class="showroom-color-primary fas fa-print mr-2"></span> Print
                        </a>
                        <a
                            v-if="machine.CtaUrl"
                            :href="machine.CtaUrl"
                            class="cta-button mb-2 mx-2"
                            :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                        >
                            <span class="showroom-color-primary fas fa-link"></span>
                            {{ machine.CtaUrlButtonText }}
                        </a>
                        <a
                            v-if="builderPath"
                            :href="builderPath"
                            class="cta-button mx-2"
                            :class="$_isLightTheme ? 'bg-gray-200' : 'bg-gray-800'"
                        >
                            <span class="showroom-color-primary fas fa-palette mr-2"></span> Build your Own
                        </a>
                    </div>
                </div>

                <router-view
                    :machine="machine"
                    :manufacturer="manufacturer"
                    :offers="offers"
                    :offers-loaded="offersLoaded"
                ></router-view>
            </div>

            <div class="d-none d-md-flex desktop-nav w-100 showroom-bg-color-primary">
                <div class="desktop-nav-link-container w-100 pb-2">
                    <router-link class="text" :class="activeLinkDesktop('overview')" to="overview">
                        <span class="fas fa-info-circle showroom-color-primary"></span>
                        <br />Overview
                    </router-link>
                    <router-link
                        v-if="machine.Features"
                        class="text"
                        :class="activeLinkDesktop('features')"
                        to="features"
                    >
                        <span class="fas fa-check-circle showroom-color-primary"></span>
                        <br />Features
                    </router-link>
                    <router-link
                        v-if="machine.Specifications || machine.SpecsImage"
                        class="text"
                        :class="activeLinkDesktop('specifications')"
                        to="specifications"
                    >
                        <span class="fas fa-clipboard-list showroom-color-primary"></span>
                        <br />Specs
                    </router-link>
                    <router-link
                        v-if="machine.Machines && machine.Machines.length"
                        class="text"
                        :class="activeLinkDesktop('models')"
                        to="models"
                    >
                        <span class="fas fa-clipboard-list showroom-color-primary"></span>
                        <br />Models
                    </router-link>
                    <router-link
                        v-if="machine.VideoUrl"
                        class="text"
                        :class="activeLinkDesktop('videos')"
                        to="videos"
                    >
                        <span class="fab fa-youtube showroom-color-primary"></span>
                        <br />Videos
                    </router-link>
                    <router-link
                        v-if="machine.Images && machine.Images.split(',').length > 1"
                        class="text"
                        :class="activeLinkDesktop('gallery')"
                        to="gallery"
                    >
                        <span class="fas fa-images showroom-color-primary"></span>
                        <br />Gallery
                    </router-link>
                    <router-link
                        v-if="machine.Brochure1Title && machine.Brochure1Url"
                        class="text"
                        :class="activeLinkDesktop('brochures')"
                        to="brochures"
                    >
                        <span class="fas fa-book showroom-color-primary"></span>
                        <br />Brochures
                    </router-link>
                    <router-link class="text" :class="activeLinkDesktop('specials')" to="specials">
                        <span class="fas fa-star showroom-color-primary"></span>
                        <br />Specials
                    </router-link>
                </div>
            </div>
            <modal-form
                v-if="useSitefinityForm"
                :id="quoteFormModalId"
                :form-selector="`.${quoteFormModalId}`"
                title="Get A Quote"
                :field-values="formFieldValues"
            ></modal-form>

            <modal-iframe
                v-if="!useSitefinityForm && formUrl"
                :id="quoteFormModalId"
                title="Get a quote"
                :iframe-source="formUrl"
            ></modal-iframe>
        </div>
    </transition>
</template>

<script>
    import axios from 'axios';
    import constants from '../../config/constants';

    import newEquipmentService from '../../../services/NewEquipmentService';
    import modalForm from '../utility/ModalForm';
    import modalIframe from '../utility/ModalIFrame.vue';
    import promotionService from '../../../services/PromotionService';

    import LocationMixin from '../mixins/LocationMixin';
    import NewEquipmentMixin from '../mixins/NewEquipmentMixin';
    import ThemeMixin from '../mixins/ThemeMixin';

    export default {
        name: 'EquipmentShowroomDetail',
        components: {
            modalForm,
            modalIframe
        },
        mixins: [LocationMixin, NewEquipmentMixin, ThemeMixin],
        props: {
            backgroundTexture: {
                type: Object,
                default: () => ({})
            },
            buildYourOwnPagePath: {
                type: String,
                default: null
            },
            email: {
                type: String,
                required: true
            },
            formUrl: {
                type: String,
                default: null
            },
            manufacturer: {
                type: String,
                required: true
            },
            useSitefinityForm: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                loaded: false,
                machine: {
                    Images: '',
                    Machines: []
                },
                offers: [],
                offersLoaded: false,
                categoryAndSubcategory: '',
                quoteFormModalId: 'new-equipment-get-a-quote',
                sliderOptions: {
                    gap: '1rem',
                    pagination: false,
                    perPage: 3
                }
            };
        },
        computed: {
            // used to populate the Interested In field in the Get a Quote modal
            interestedInItem() {
                return this.manufacturer
                    ? `${this.manufacturer.capitalize()} ${this.machine.Title}`
                    : this.machine.Title;
            },
            builderPath() {
                if (this.buildYourOwnPagePath) {
                    let routeParams = this.$route.params;
                    return `${this.buildYourOwnPagePath}/${routeParams.category}/${routeParams.subcategory}/builder?machine=${routeParams.model}`;
                }
                return null;
            },
            equipmentPlaceholderImage() {
                return constants.equipmentPlaceholderImage;
            },
            imgUrl() {
                return (this.machine.Images && this.machine.Images.split(',')[0]) || '';
            },
            gallery() {
                return this.$route && this.$route.path.indexOf('/gallery') > -1;
            },
            specs() {
                return this.$route && this.$route.path.indexOf('/specifications') > -1;
            },
            videos() {
                return this.$route && this.$route.path.indexOf('/videos') > -1;
            },
            specials() {
                return this.$route && this.$route.path.indexOf('/specials') > -1;
            },
            backHref() {
                return this.$route.params.subcategory !== 'series'
                    ? `/${this.$route.params.category}/${this.$route.params.subcategory}`
                    : `/${this.$route.params.category}`;
            },
            mailLink() {
                return this.email + '?subject=' + this.machine.Title;
            },
            dealerPhoneNumber() {
                if (this.$_selectedLocation && this.$_selectedLocation.Phone) {
                    return `tel:${this.$_selectedLocation.Phone}`;
                } else {
                    return `tel:${constants.elementConfig.PHONE}`;
                }
            },
            formFieldValues() {
                if (!this.machine) {
                    return [];
                }

                return [
                    {
                        selector: '.interested-eq input[class="form-control"]',
                        value: this.interestedInItem
                    },
                    {
                        selector: '.category-field input[type="text"]',
                        value: this.categoryAndSubcategory
                    }
                ];
            }
        },
        watch: {
            '$route.params.model': function(newValue, oldValue) {
                if (newValue && newValue !== oldValue) {
                    this.requestMachine(newValue, { $expand: true });
                }
            }
        },
        created() {
            this.requestMachine(this.$route.params.model, { $expand: true });
        },
        mounted() {
            // scroll to the top of the page on load
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: 0
            });

            if (this.backgroundTexture && this.backgroundTexture.ItemDefaultUrl) {
                this.$refs['detail-wrapper'].style.cssText = `background-image: url('${this.backgroundTexture
                    .ItemDefaultUrl.Value + this.backgroundTexture.Extension}')`;
            }
        },
        methods: {
            activeLink(route) {
                return this.$route.path.indexOf(route) > -1 ? 'showroom-color-primary' : 'text';
            },
            activeLinkDesktop(route) {
                return this.$route.path.indexOf(route) > -1
                    ? 'showroom-bg-color-primary'
                    : this.$_isLightTheme
                        ? 'bg-gray-200'
                        : 'bg-gray-800';
            },
            detailUrl(detail) {
                return `${this.$route.path}/${detail}`;
            },
            route(p1, p2) {
                return p2 ? `/${p1}/${p2}` : `/${p1}`;
            },
            slugToTitle(slug) {
                return slug
                    .replace(/-/g, ' ')
                    .replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
            },
            print() {
                window.print();
            },
            requestMachine(slug, options) {
                this.loaded = false;
                this.offersLoaded = false;
                this.categoryAndSubcategory = '';

                newEquipmentService
                    .getNewMachineBySlug(this.manufacturer, slug, options)
                    .then(machine => {
                        // display the machine data that we've retrieved here while the category is requested
                        this.machine = machine;
                        this.loaded = true;

                        this.requestCategory();
                        this.requestOffers();
                    })
                    .catch(() => {
                        window.location.replace(constants.errorPage404);
                    });
            },
            requestCategory() {
                if (!this.machine || !this.machine.Id) {
                    return;
                }

                let apiBase = '/api/showroom/CategoryAndSubcategoryFromId';
                let itemType = constants.newEqTypeNames[this.manufacturer.toUpperCase()];
                let categoryRequestUrl = `${apiBase}?machineId=${this.machine.Id}&machineType=${itemType}`;

                axios(categoryRequestUrl).then(response => {
                    this.categoryAndSubcategory = (response && response.data) || '';
                });
            },
            requestOffers() {
                if (!this.machine || !this.machine.Id) {
                    return;
                }

                let manufacturerOffersPromise = promotionService.getMachinePromotions(
                    this.manufacturer,
                    this.machine,
                    { $orderby: 'Title' }
                );

                let offersPromises = [manufacturerOffersPromise];

                // request full data for DealerPromotions in order to retrieve images
                if (this.machine.DealerPromotions && this.machine.DealerPromotions.length > 0) {
                    let dealerOffersPromise = promotionService.getSelectedPromotions(
                        'dealer',
                        this.machine.DealerPromotions.map(p => p.Id)
                    );
                    offersPromises.push(dealerOffersPromise);
                }

                Promise.all(offersPromises).then(responses => {
                    let manufacturerOffers = responses[0];
                    let dealerOffers = responses[1] || [];

                    if (dealerOffers.length > 0) {
                        // convert image objects on each dealer promotion to simple url string
                        dealerOffers.forEach(p => {
                            p.Image = p.Image && p.Image[0] && p.Image[0].Url;
                        });
                    }

                    this.offers = [...dealerOffers, ...manufacturerOffers];
                    this.offersLoaded = true;
                });
            }
        }
    };
</script>
