﻿<template>
    <div>
        <div v-if="shortList" class="d-flex justify-content-between my-3">
            <h3 class="border-left border-content mb-0 text-primary">{{ title }}</h3>
            <a :href="baseUrl" class="btn btn-hollow-primary mt-3 mt-sm-0">See All Testimonials</a>
        </div>

        <div class="row">
            <div v-for="testimonial in testimonials" :key="testimonial.Id" class="col-12 col-sm-4 mb-3">
                <card-standard
                    class="h-100"
                    :enable-image="testimonial.TestimonialImage != null"
                    :image="{ src: testimonial.TestimonialImage && testimonial.TestimonialImage.Url }"
                    :limit-image-height="true"
                    :subtitle="testimonial.TestimonialJobTitle"
                    :title="testimonial.TestimonialName"
                    :title-size="4"
                >
                    <div
                        class="border-top pt-3 testimonial-content text-justify"
                        v-html="testimonial.Testimonial"
                    ></div>
                    <a v-if="shortList" class="text-italic" :href="baseUrl">
                        <span>Read More</span>
                    </a>
                </card-standard>
            </div>
        </div>
    </div>
</template>

<script>
    import CardStandard from './cards/CardStandard';

    export default {
        name: 'Testimonials',
        components: {
            CardStandard
        },
        props: {
            shortList: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                baseUrl: '/testimonials',
                testimonials: []
            };
        },
        mounted() {
            let url = `/api/default/testimonials?$expand=TestimonialImage${this.shortList ? '&$top=3' : ''}`;

            fetch(url)
                .then(response => response.json())
                .then(response => (this.testimonials = response.value));
        }
    };
</script>
