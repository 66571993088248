<template>
    <div ref="wrapper">
        <div v-if="offer">
            <div class="special-offers-detail container my-4">
                <div class="offer-header row mb-5 mx-0 p-3">
                    <div class="col-lg-6 col-sm-12 align-self-center p-0">
                        <h3 v-html="offer.Title"></h3>
                        <h4 v-html="offer.Subtitle"></h4>
                    </div>

                    <div v-if="isDealerPromotion" class="col-lg-6 col-sm-12 px-0">
                        <div v-if="offer.Image && offer.Image.length > 0">
                            <img
                                :src="offer.Image[offer.Image.length - 1].Url"
                                :alt="offer.Title"
                                class="special-offers-img img-fluid"
                            />
                        </div>
                    </div>
                    <div v-else class="col-lg-6 col-sm-12 px-0">
                        <img :src="offer.Image" :alt="offer.Title" class="special-offers-img img-fluid" />
                    </div>
                </div>

                <div class="row mb-5 mx-0">
                    <div class="disclaimer-container col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div
                            v-if="offer.Description"
                            class="card disclaimer h-md-100 d-flex flex-row align-items-center"
                        >
                            <div class="card-body">
                                <h5 class="card-title">Details</h5>
                                <div>
                                    <div class="card-text" v-html="offer.Description"></div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="share">
                            <p class="border-left border-content text-uppercase font-weight-bold mb-3">
                                Contact Us About This Special
                            </p>
                            <div class="share-buttons mb-3">
                                <a
                                    v-if="elementConfig.EMAIL"
                                    data-toggle="modal"
                                    data-target="#promotions-get-a-quote"
                                >
                                    <i class="fas fa-dollar-sign fa-2x"></i>
                                    <p>Get A Quote</p>
                                </a>
                                <a v-if="elementConfig.PHONE" :href="`tel:${elementConfig.PHONE}`">
                                    <i class="fas fa-phone fa-2x"></i>
                                    <p>Call</p>
                                </a>
                                <a v-if="elementConfig.FACEBOOK" target="_blank" :href="elementConfig.FACEBOOK">
                                    <i class="fab fa-facebook-f fa-2x"></i>
                                    <p>Facebook</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div v-if="offer.Description" class="share">
                            <p class="border-left border-content text-uppercase font-weight-bold mb-3">
                                Contact Us About This Special
                            </p>
                            <div class="d-flex justify-content-between align-self-center share-buttons mb-3">
                                <a
                                    v-if="elementConfig.EMAIL"
                                    class="btn btn-lg rounded-0"
                                    data-toggle="modal"
                                    data-target="#promotions-get-a-quote"
                                >
                                    <i class="fas fa-dollar-sign fa-2x"></i>
                                    <p>Get A Quote</p>
                                </a>
                                <a
                                    v-if="elementConfig.PHONE"
                                    class="btn btn-lg rounded-0"
                                    :href="`tel:${elementConfig.PHONE}`"
                                >
                                    <i class="fas fa-phone fa-2x"></i>
                                    <p>Call</p>
                                </a>
                                <a
                                    v-if="elementConfig.FACEBOOK"
                                    class="btn btn-lg rounded-0"
                                    target="_blank"
                                    :href="elementConfig.FACEBOOK"
                                >
                                    <i class="fab fa-facebook-f fa-2x"></i>
                                    <p>Facebook</p>
                                </a>
                            </div>
                        </div>
                        <div class="share">
                            <p class="border-left border-content text-uppercase font-weight-bold mb-3">
                                Share This Special
                            </p>
                            <div class="d-flex justify-content-between share-buttons">
                                <a class="btn btn-lg rounded-0" @click="print">
                                    <i class="fas fa-print fa-2x"></i>
                                    <p>Print</p>
                                </a>
                                <a class="btn btn-lg rounded-0" :href="mailLink">
                                    <i class="fas fa-at fa-2x"></i>
                                    <p>Email</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="relatedCategories.length" class="mb-3">
                    <h3>Eligible Categories</h3>
                    <splide :options="relatedCategoriesSliderOptions">
                        <splide-slide v-for="(card, i) in relatedCategoryCards" :key="i">
                            <card-standard
                                action-name="Details"
                                :action-url="card.ctaLink"
                                btn-style="bg-primary text-white text-center"
                                :enable-button-link="true"
                                :enable-image="true"
                                :image="card.image"
                                :title="card.title"
                                :title-size="5"
                                :use-block-btn="true"
                            />
                        </splide-slide>
                    </splide>
                </div>

                <div v-if="relatedMachines.length" class="mb-3">
                    <h3>Eligible Equipment</h3>
                    <splide class="px-3" :options="relatedMachinesSliderOptions">
                        <splide-slide v-for="(card, i) in relatedMachineCards" :key="i">
                            <card-standard
                                action-name="Details"
                                :action-url="card.ctaLink"
                                btn-style="bg-primary text-white text-center"
                                :enable-button-link="true"
                                :enable-image="true"
                                :image="card.image"
                                :title="card.title"
                                :title-size="5"
                                :use-block-btn="true"
                            />
                        </splide-slide>
                    </splide>
                </div>

                <div class="row mx-0">
                    <related-offers
                        base-url=""
                        class="w-100"
                        :manufacturer="manufacturer"
                        :excluded-promotion-url="offer.UrlName"
                        :filtered-by-machines="false"
                        :filtered-by-promotions="false"
                        :show-pagination="false"
                    ></related-offers>
                </div>

                <div class="row mx-0">
                    <div class="col-12 mx-auto">
                        <small class="text-muted" v-html="offer.Disclaimer"></small>
                    </div>
                </div>

                <hr />

                <div class="row mx-0">
                    <div class="col-12 mx-auto">
                        <div id="special-form-wrapper"></div>
                    </div>
                </div>
            </div>
            <modal-form
                id="promotions-get-a-quote"
                title="Get A Quote"
                form-selector=".promotions-get-a-quote"
                :field-values="[
                    {
                        selector: `.interested-eq input[class='form-control']`,
                        value: promotionInterestedIn
                    }
                ]"
            ></modal-form>
        </div>
    </div>
</template>

<script>
    import constants from '../../config/constants';
    import PromoService from '../../../services/PromotionService';
    import { getTextFromHtml } from '../../lib/utilities';

    // Components
    import CardStandard from '../cards/CardStandard';
    import ModalForm from '../utility/ModalForm.vue';
    import RelatedOffers from './RelatedSpecialOffers.vue';

    function toCard(array) {
        return array.map(function(item) {
            if (item.Images) {
                item.Image = item.Images.split(',')[0];
            }

            return {
                title: item.Title,
                image: {
                    src: item.Image,
                    alt: item.Title
                },
                ctaLink: item.FullUrl
            };
        });
    }

    export default {
        name: 'SpecialOffersDetail',
        components: {
            CardStandard,
            ModalForm,
            RelatedOffers
        },
        props: {
            backgroundImageUrl: {
                type: String,
                default: ''
            },
            manufacturer: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                offer: undefined,
                relatedCategories: [],
                relatedMachines: [],
                elementConfig: constants.elementConfig
            };
        },
        computed: {
            promotionInterestedIn() {
                return (
                    this.offer &&
                    getTextFromHtml(`${this.offer.Title} ${this.offer.Subtitle}`.replace(/&nbsp;/g, ' '))
                );
            },
            relatedCategoryCards() {
                return toCard(this.relatedCategories);
            },
            relatedMachineCards() {
                return toCard(this.relatedMachines);
            },
            isDealerPromotion() {
                return this.manufacturer === 'dealer';
            },
            mailLink() {
                return `mailto:?subject=${this.offer.Title}&body=${window.location.href}%0D`;
            },
            relatedCategoriesSliderOptions() {
                return {
                    arrows: this.relatedCategories.length > 3,
                    drag: true,
                    gap: '1rem',
                    pagination: this.relatedCategories.length > 3,
                    pauseOnHover: true,
                    perPage: 3,
                    rewind: true
                };
            },
            relatedMachinesSliderOptions() {
                return {
                    arrows: this.relatedMachines.length > 3,
                    gap: '1rem',
                    pagination: this.relatedMachines.length > 3,
                    perPage: 3,
                    rewind: true
                };
            }
        },
        watch: {
            $route: {
                deep: true,
                handler: function(to, from) {
                    let offers = this.$route.params.offer;

                    if (offers) {
                        this.offer = offers;
                    } else {
                        this.getPromotion();
                    }

                    this.scrollToTop();
                }
            },
            offer: function(newValue) {
                // ensures we don't show the previous promo's eligible machines for a promotion that has none
                this.relatedMachines = [];

                if (newValue && newValue.HasEligibleItems) {
                    PromoService
                        .getPromotionCategories(this.manufacturer, newValue, { $orderby: 'Title' })
                        .then(value => (this.relatedCategories = value));

                    PromoService
                        .getPromotionMachines(this.manufacturer, newValue, { $orderby: 'Title' })
                        .then(value => (this.relatedMachines = value));
                }
            }
        },
        mounted() {
            this.scrollToTop();

            if (this.backgroundImageUrl) {
                this.$refs.wrapper.style.cssText = `background: url(${this.backgroundImageUrl}); background-size: cover`;
            }

            let form = $('.special-offer-form');
            let wrapper = $('#special-form-wrapper');
            wrapper.append(form.html());
        },
        created() {
            let offers = this.$route.params.offer;

            if (offers) {
                this.offer = offers;
            } else {
                this.getPromotion();
            }
        },
        methods: {
            print() {
                window.print();
            },
            getPromotion() {
                PromoService
                    .getPromotionBySlug(this.manufacturer, this.$route.params.detail)
                    .then(offer => {
                        this.offer = offer;
                    })
                    .catch(() => window.location.replace(constants.errorPage404));
            },
            scrollToTop() {
                window.scroll({
                    behavior: 'smooth',
                    left: 0,
                    top: 0
                });
            }
        }
    };
</script>
