import LocalStorageService from './LocalStorageService';
import { store } from '../frontend/store/store';
import StoreConstants from '../frontend/store/constants';

const deleteMachine = machine => {
    store.dispatch(StoreConstants.EQUIPMENT.ACTIONS.DELETE_SAVED_EQUIPMENT, machine);
};

const exists = machineOriginalItemId => {
    return LocalStorageService.getItem(StoreConstants.EQUIPMENT.LOCAL_STORAGE_KEY, []).some(
        x => x.OriginalItemId === machineOriginalItemId
    );
};

const getMachine = machineOriginalItemId => {
    return LocalStorageService.getItem(StoreConstants.EQUIPMENT.LOCAL_STORAGE_KEY, []).find(
        x => x.OriginalItemId === machineOriginalItemId
    );
};

const saveMachine = machine => {
    if (!exists(machine.OriginalItemId)) {
        store.dispatch(StoreConstants.EQUIPMENT.ACTIONS.ADD_SAVED_EQUIPMENT, machine);
    }
};

const toggleCompare = machine => {
    let updatedMachine = LocalStorageService.getItem(StoreConstants.EQUIPMENT.LOCAL_STORAGE_KEY, []).find(
        x => x.OriginalItemId == machine.OriginalItemId
    );
    updatedMachine.Compare = !updatedMachine.Compare;

    store.dispatch(StoreConstants.EQUIPMENT.ACTIONS.UPDATE_SAVED_EQUIPMENT, updatedMachine);
}

const updateMachine = machine => {
    store.dispatch(StoreConstants.EQUIPMENT.ACTIONS.UPDATE_SAVED_EQUIPMENT, machine);
};

export default {
    deleteMachine,
    exists,
    getMachine,
    saveMachine,
    toggleCompare,
    updateMachine
};
