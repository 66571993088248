<template>
    <div class="container">
        <div class="row">
            <!-- Image -->
            <div class="col-12 col-lg-6 order-lg-2 mb-3 mb-lg-0">
                <div class="skeleton h-100 w-100">
                    <div class="skeleton-item h-100 py-5 w-100"></div>
                </div>
            </div>
            <!-- Content -->
            <div class="col-12 col-lg-6 order-lg-1 px-4">
                <content-skeleton :use-title="true" />
            </div>
            <!-- Button -->
            <div class="col-3 px-4 order-lg-3">
                <div class="skeleton"><div class="skeleton-item py-3 mt-3"></div></div>
            </div>
        </div>
        <hr class="mx-3 my-5" />
        <div class="row">
            <!-- Buttons -->
            <div class="col-12 col-lg-4 order-lg-3 px-4">
                <div v-for="index in 5" :key="index" class="skeleton mb-2">
                    <div class="skeleton-item py-3"></div>
                </div>
            </div>
            <!-- Description -->
            <div class="col-12 col-lg-4 order-lg-2 px-4 mt-3 mt-lg-0">
                <content-skeleton :use-title="false" />
            </div>
            <!-- Details -->
            <div class="col-12 col-lg-4 order-lg-1 px-4 mt-3 mt-lg-0">
                <content-skeleton :use-title="true" />
            </div>
        </div>
    </div>
</template>

<script>
    import ContentSkeleton from '../utility/ContentSkeleton';

    export default {
        name: 'PromotionDetailSkeleton',
        components: {
            ContentSkeleton
        }
    };
</script>
