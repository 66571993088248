<template>
    <div class="used-equipment-detail container">
        <!-- loading indicator -->
        <used-machine-detail-skeleton v-if="!machine"></used-machine-detail-skeleton>
        <!-- machine details -->
        <template v-else>
            <div class="row mb-3 mb-md-5 pt-md-5">
                <div class="col-lg-4 col-sm-12 align-self-center p-3">
                    <h3 id="used-machine-title">{{ machine.Title }}</h3>

                    <strike v-if="suggestedPrice()" class="text-danger">{{
                        suggestedPrice() | money
                    }}</strike>
                    <h3 v-if="price()">{{ price() | money }}</h3>
                    <h3 v-else>Call For Price</h3>

                    <ul class="list-unstyled">
                        <li v-if="machine.StockNumber">
                            <strong>Stock:</strong>
                            {{ machine.StockNumber }}
                        </li>
                        <li v-if="machine.SerialNumber">
                            <strong>Serial:</strong>
                            {{ machine.SerialNumber }}
                        </li>
                        <li v-if="machine.OperationHours">
                            <strong>Hours:</strong>
                            {{ machine.OperationHours }}
                        </li>
                        <li v-if="machine.SeparatorHours">
                            <strong>Separator Hours:</strong>
                            {{ machine.SeparatorHours }}
                        </li>
                        <li v-if="showLocation && machineLocationName">
                            <strong>Location:</strong>
                            <a :href="locationLink">{{ machineLocationName }}</a>
                        </li>
                        <li v-if="phoneNumber">
                            <strong>Phone:</strong>
                            <a :href="`tel:${phoneNumber}`">{{ phoneNumber }}</a>
                        </li>
                        <li v-if="!machine.EquipmentType && machine.Category">
                            <strong>Category:</strong>
                            {{ machine.Category }}
                        </li>
                        <li v-if="machine.EquipmentType">
                            <strong>Type:</strong>
                            {{ machine.EquipmentType }}
                        </li>
                    </ul>
                    <a class="btn btn-lg rounded-0 horizontal-links" @click="clickToCompare()">
                        <i class="fas fa-star"></i>
                        <span class="pt-2">Click To Compare</span>
                    </a>
                    <a
                        class="btn btn-lg rounded-0 horizontal-links"
                        :data-target="`#${TOP_BUTTON.formClass}`"
                        data-toggle="modal"
                    >
                        <i :class="TOP_BUTTON.icon" class="fa-2x"></i>
                        <span class="pt-2">{{ TOP_BUTTON.displayName }}</span>
                    </a>
                </div>
                <div class="col-lg-8 col-sm-12">
                    <div
                        v-if="machine.Images || machine.Videos"
                        class="position-relative"
                        @mouseleave="imageHovered = false"
                        @mouseover="imageHovered = true"
                    >
                        <!-- tier icons -->
                        <div
                            v-if="tierIcon"
                            class="tier-icon position-absolute"
                            :class="{ 'd-md-none': imageHovered }"
                        >
                            <img :src="tierIcon" :alt="tierName" />
                        </div>

                        <!-- machine gallery -->
                        <splide
                            class="used-equipment-gallery mt-2"
                            :options="sliderOptions"
                            @splide:click="onGalleryClick"
                            @splide:moved="onGallerySlideMoved"
                        >
                            <!-- videos -->
                            <splide-slide
                                v-for="videoSrc in machineVideos"
                                :key="videoSrc"
                                class="embed-responsive embed-responsive-16by9"
                            >
                                <element-iframe-video
                                    :autoplay="false"
                                    :src="videoSrc"
                                    :prevent-events="true"
                                />
                            </splide-slide>
                            <!-- images -->
                            <splide-slide v-for="imgSrc in machineImages" :key="imgSrc">
                                <img :data-splide-lazy="optimizeImage(imgSrc, 730)" :alt="machine.Title" />
                            </splide-slide>
                            <template #controls>
                                <div class="text-center d-lg-none mt-2">
                                    <span class="font-weight-bold">{{ currentGallerySlide }}</span> out of
                                    <span class="font-weight-bold">{{
                                        machineImages.length + machineVideos.length
                                    }}</span>
                                </div>
                            </template>
                        </splide>

                        <!-- machine lightbox -->
                        <fslightbox
                            :source-index="fslightboxSourceIndex"
                            :sources="fslightboxSources"
                            :toggler="fslightboxToggler"
                            :types="machineMediaTypes"
                        />
                    </div>
                    <div v-else>
                        <!-- placeholder image -->
                        <img class="w-100" :src="constants.equipmentPlaceholderImage" :alt="machine.Title" />
                    </div>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col-md-7 col-sm-12">
                    <div class="share share-buttons">
                        <p class="border-left border-content text-uppercase font-weight-bold mb-3">
                            Contact Us About This Machine
                        </p>
                        <div class="d-flex justify-content-between">
                            <a
                                :href="FIRST_CONTACT_LINK.url || `tel:${phoneNumber}`"
                                class="btn btn-lg rounded-0"
                            >
                                <i :class="FIRST_CONTACT_LINK.icon" class="fa-2x"></i>
                                <span class="pt-2">{{ FIRST_CONTACT_LINK.displayName }}</span>
                            </a>
                            <button
                                class="btn btn-lg rounded-0"
                                type="button"
                                data-toggle="modal"
                                :data-target="`#${MODAL_BUTTON_ONE.formClass}`"
                            >
                                <i :class="MODAL_BUTTON_ONE.icon" class="fa-2x"></i>
                                <span class="pt-2">{{ MODAL_BUTTON_ONE.displayName }}</span>
                            </button>
                            <button
                                class="btn btn-lg rounded-0"
                                type="button"
                                data-toggle="modal"
                                :data-target="`#${MODAL_BUTTON_TWO.formClass}`"
                            >
                                <i :class="MODAL_BUTTON_TWO.icon" class="fa-2x"></i>
                                <span class="pt-2">{{ MODAL_BUTTON_TWO.displayName }}</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-md-5 col-sm-12">
                    <div class="share share-buttons">
                        <p class="border-left border-content text-uppercase font-weight-bold mb-3">
                            Share This Machine
                        </p>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-lg rounded-0" @click="print">
                                <i class="fas fa-print fa-2x"></i>
                                <span class="pt-2">Print</span>
                            </button>
                            <a :href="mailLink" class="btn btn-lg rounded-0">
                                <i class="fas fa-at fa-2x"></i>
                                <span class="pt-2">Email</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-5">
                <div v-if="showDescription">
                    <p class="border-left border-content text-uppercase font-weight-bold mb-3">Description</p>
                    <div v-if="showDescription" v-html="machineDescription"></div>
                </div>
            </div>

            <div class="mb-5">
                <div v-if="showfinancingOptions">
                    <p class="border-left border-content text-uppercase font-weight-bold mb-3">Financing</p>
                    <div v-html="machine.FinancingOptions"></div>
                </div>
            </div>

            <div class="mb-5">
                <div v-if="specifications">
                    <p class="border-left border-content text-uppercase font-weight-bold mb-3">
                        Specifications
                    </p>
                    <div v-html="specifications || machine.Horsepower || machine.ChippingCapacity"></div>
                    <div v-if="machine.Horsepower">
                        <span class="font-weight-bold">Horsepower: </span>{{ machine.Horsepower }} HP
                    </div>
                    <div v-if="machine.ChippingCapacity">
                        <span class="font-weight-bold">Chipping Capacity: </span>{{ machine.ChippingCapacity }}
                    </div>
                </div>
            </div>

            <!-- similar equipment gallery -->
            <div v-if="relatedMachines" class="mb-5">
                <p class="border-left border-content text-uppercase font-weight-bold mb-3">
                    Similar Equipment
                </p>
                <splide class="similar-equipment-slider" :options="similarEquipmentOptions">
                    <splide-slide v-for="(item, i) in relatedMachines" :key="i">
                        <card-standard
                            class="mr-3 related-equipment-card h-100"
                            :enable-image="true"
                            :image="{
                                src:
                                    (item.Images && item.Images.split(',')[0]) ||
                                    constants.equipmentPlaceholderImage
                            }"
                            :title="item.Title"
                            :title-size="4"
                            :action-name="'Details'"
                            :action-url="item.UrlName"
                            :enable-button-link="true"
                            :btn-style="'btn-primary text-center'"
                            :use-block-btn="true"
                            :limit-image-height="true"
                        >
                            <div class="subtitle">
                                <strike v-if="suggestedPrice(item)" class="text-danger">
                                    {{ suggestedPrice(item) | money }}
                                </strike>
                                <span v-if="price(item)" class="font-weight-bold">{{
                                    price(item) | money
                                }}</span>
                                <span v-else class="font-weight-bold">$ Call For Price</span>
                            </div>

                            <div v-if="item.OperationHours" class="subtitle">
                                <strong>Hours:</strong>
                                {{ item.OperationHours }}
                            </div>
                        </card-standard>
                    </splide-slide>
                </splide>
            </div>
        </template>

        <modal-form
            v-if="useSitefinityForm"
            :id="TOP_BUTTON.formClass"
            :field-values="formFieldValues"
            :form-selector="`.${TOP_BUTTON.formClass}`"
            :title="TOP_BUTTON.formHeader"
        ></modal-form>
        <modal-iframe
            v-if="!useSitefinityForm && formUrl"
            :id="TOP_BUTTON.formClass"
            :iframe-source="formUrl"
            :title="TOP_BUTTON.formHeader"
        ></modal-iframe>

        <modal-form
            :id="MODAL_BUTTON_ONE.formClass"
            :form-selector="`.${MODAL_BUTTON_ONE.formClass}`"
            :title="MODAL_BUTTON_ONE.formHeader"
            :field-values="formFieldValues"
        ></modal-form>

        <modal-form
            :id="MODAL_BUTTON_TWO.formClass"
            :form-selector="`.${MODAL_BUTTON_TWO.formClass}`"
            :title="MODAL_BUTTON_TWO.formHeader"
            :field-values="formFieldValues"
        ></modal-form>
    </div>
</template>

<script>
    import constants from '../../config/constants';
    import SavedMachineService from '../../../services/SavedMachineService';
    import UsedEquipmentService from '../../../services/UsedEquipmentService';
    import { isHtml, optimizeImage, replaceAllText } from '../../lib/utilities';

    // components
    import ElementIframeVideo from '../utility/ElementIframeVideo';
    import fslightbox from 'fslightbox-vue';
    import ModalForm from '../utility/ModalForm';
    import ModalIframe from '../utility/ModalIFrame.vue';
    import CardStandard from '../cards/CardStandard';
    import UsedMachineDetailSkeleton from './UsedMachineDetailSkeleton';

    // mixins
    import LocationMixin from '../mixins/LocationMixin';

    const TIERS = {
        1: 'None',
        2: 'Signature',
        4: 'Premium',
        8: 'Select'
    };
    const SFNewLineTag = '<p>&nbsp;</p>';

    export default {
        name: 'UsedMachineDetail',
        components: {
            fslightbox,
            ElementIframeVideo,
            ModalForm,
            ModalIframe,
            CardStandard,
            UsedMachineDetailSkeleton
        },
        mixins: [LocationMixin],
        props: {
            buttonsOptions: {
                type: Array,
                default: () => []
            },
            formUrl: {
                type: String,
                default: null
            },
            tierIcons: {
                type: Object,
                default: () => ({})
            },
            useSitefinityForm: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                COMPARE_LINK: '/Compare',
                constants,
                currentGallerySlide: 1,
                elementConfig: constants.elementConfig,
                fslightboxSourceIndex: 0,
                fslightboxToggler: false,
                imageHovered: false,
                machine: null,
                optimizeImage: optimizeImage,
                relatedMachines: null,
                showLocation: constants.elementConfig.HAS_MULTIPLE_LOCATIONS,
                TOP_BUTTON: this.buttonsOptions.find(f => f.name === 'TOP_BUTTON'),
                FIRST_CONTACT_LINK: this.buttonsOptions.find(f => f.name === 'FIRST_CONTACT_LINK'),
                MODAL_BUTTON_ONE: this.buttonsOptions.find(f => f.name === 'MODAL_BUTTON_ONE'),
                MODAL_BUTTON_TWO: this.buttonsOptions.find(f => f.name === 'MODAL_BUTTON_TWO')
            };
        },
        computed: {
            fslightboxSources() {
                let sources = [];

                if (this.machineVideos.length) {
                    sources.push(
                        ...this.machineVideos.map(source => {
                            if (source.includes('vimeo')) {
                                return {
                                    component: ElementIframeVideo,
                                    props: {
                                        src: source
                                    }
                                };
                            } else {
                                return source;
                            }
                        })
                    );
                }

                sources.push(...this.machineImages);

                return sources;
            },
            machineDescription() {
                return this.machine.Description?.replace(/\r\n/gi, '<br>');
            },
            /**
             * Normalizes machine location to always be an object or null
             * (sometimes Sitefinity outputs it as an array)
             */
            machineLocation() {
                const locations = this.machine?.Location;

                return Array.isArray(locations) && locations.length > 0
                    ? locations[0]
                    : this.machine.LocationNotes;
            },
            machineLocationName() {
                return this.locationName(this.machineLocation) || this.machine.LocationNotes;
            },
            locationLink() {
                let baseUrl = this.elementConfig.SITEMAP.LOCATIONS;
                let locationSlug = this.machineLocation?.UrlName;

                return locationSlug ? `${baseUrl}/${locationSlug}` : null;
            },
            phoneNumber() {
                return (this.machineLocation && this.machineLocation.Phone) || this.elementConfig.PHONE;
            },
            mailLink() {
                let title = (this.machine && this.machine.Title) || '';
                return `mailto:?subject=${title}&body=${window.location.href}%0D`;
            },
            specifications() {
                return isHtml(this.machine?.Options)
                    ? replaceAllText(this.machine.Options, SFNewLineTag,'<br>')
                    : replaceAllText(this.machine.Options, /(\r\n|\n|\r)/gm, '<br>');
            },
            currencyFormatter() {
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                });
            },
            showDescription() {
                return (
                    this.machine &&
                    this.machine.SyncDataSource &&
                    this.machine.SyncDataSource !== constants.usedEqSyncDataSources.HBS &&
                    this.machine.Description
                );
            },
            machinePrice() {
                return this.machine && this.currencyFormatter.format(this.machine.Price).replace('.00', '');
            },
            machineVideos() {
                return (this.machine && this.machine.Videos && this.machine.Videos.split(',')) || [];
            },
            machineImages() {
                return (this.machine && this.machine.Images && this.machine.Images.split(',')) || [];
            },
            machineMediaTypes() {
                let videoTypes = this.machineVideos.map(video =>
                    video.includes('vimeo') ? null : video.includes('youtube') ? 'youtube' : 'video'
                );
                let imageTypes = this.machineImages.map(image => 'image');

                return [...videoTypes, ...imageTypes];
            },
            formFieldValues() {
                if (!this.machine) {
                    return [];
                }

                // feeding all the forms the same selectors and values for simplicity;
                // the modal won't render values that a field does not exist for
                return [
                    {
                        selector: '.interested-eq input[class="form-control"]',
                        value: this.machine.Title + ' - ' + this.machine.StockNumber,
                        readOnly: true
                    },
                    {
                        selector: '.dealer-phone-field input[type="text"]',
                        value: this.phoneNumber,
                        readOnly: true
                    },
                    {
                        selector: '.eq-location-field input[type="text"]',
                        value: this.machineLocationName,
                        readOnly: true
                    },
                    {
                        selector: '.eq-price-field input[type="text"]',
                        value:
                            // for some reason, SuggestedPrice is the lower/promotional price
                            this.machine.SuggestedPrice && this.machine.Price > this.machine.SuggestedPrice
                                ? this.machine.SuggestedPrice
                                : this.machine.Price,
                        readOnly: true
                    },
                    {
                        selector: '.serial-number-field input[type="text"]',
                        value: this.machine.SerialNumber,
                        readOnly: true
                    },
                    {
                        selector: '.stock-number-field input[type="text"]',
                        value: this.machine.StockNumber,
                        readOnly: true
                    },
                    {
                        selector: '.category-field input[type="text"]',
                        value: this.machine.Category,
                        readOnly: true
                    }
                ];
            },
            showfinancingOptions() {
                return !!this.machine && !!this.machine.FinancingOptions;
            },
            sliderOptions() {
                return {
                    autoplay: this.machineImages.length + this.machineVideos.length > 1,
                    breakpoints: {
                        991: {
                            pagination: false
                        }
                    },
                    lazyLoad: 'nearby',
                    rewind: true
                };
            },
            similarEquipmentOptions() {
                return {
                    perPage: 3,
                    gap: '1rem',
                    breakpoints: {
                        992: {
                            pagination: false,
                            perPage: 2
                        },
                        576: {
                            pagination: false,
                            perPage: 1
                        }
                    }
                };
            },
            tierIcon() {
                let selectedTier = this.machine.Tier ?? 1; // Choice value is saved in Blaze
                return this.tierIcons[TIERS[selectedTier]];
            },
            tierName() {
                return TIERS[this.machine.Tier ?? 1];
            }
        },
        mounted() {
            // scroll to the top of the page on load
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: 0
            });

            let slug = this.$route.path.substring(1);

            UsedEquipmentService.GetMachineBySlug(slug, { $expand: true })
                .then(
                    response => {
                        this.machine = response;
                    },
                    // reject handler placed here instead of in a catch so chain is broken if machine isn't found
                    () => {
                        window.location.replace(constants.errorPage404);
                    }
                )
                .then(() => {
                    if (!this.machine || !this.machine.Category) {
                        return new Promise.resolve([]);
                    }

                    return UsedEquipmentService.GetSimilarMachines(this.machine);
                })
                .then(
                    response => {
                        this.relatedMachines = response;
                    },
                    () => {
                        this.relatedMachines = null;
                    }
                );
        },
        methods: {
            onGalleryClick(clickEvent, slide) {
                this.fslightboxSourceIndex = slide.index;
                this.fslightboxToggler = !this.fslightboxToggler;
            },
            onGallerySlideMoved(destIndex, newIndex, oldIndex) {
                this.currentGallerySlide = newIndex + 1;
            },
            print() {
                window.print();
            },
            clickToCompare() {
                let savedMachine = SavedMachineService.getMachine(this.machine.Id);
                if (savedMachine) {
                    if (!savedMachine.Compare) {
                        SavedMachineService.toggleCompare(savedMachine);
                    }
                    else {
                        this.$toasted.show(`${savedMachine.Title} has already been saved.`).goAway(1500);
                    }
                } else {
                    let usedEqUrl = this.elementConfig.SITEMAP.USED_EQUIPMENT ?? '/used-equipment';

                    let machineToSave = {
                        Compare: true,
                        DataSource: 'used_equipment',
                        EquipmentYear: this.machine.Year,
                        FullUrl: `${usedEqUrl}/${this.machine.UrlName}`,
                        Images: this.machine.Images,
                        OperationHours: this.machine.OperationHours,
                        OriginalItemId: this.machine.OriginalItemId || this.machine.Id,
                        Title: this.machine.Title
                    };

                    SavedMachineService.saveMachine(machineToSave);
                    this.$toasted.show(`Saved ${this.machine.Title} to your saved equipment!`).goAway(1500);
                }

                window.location.href = this.COMPARE_LINK;
            },
            price(machine) {
                machine = machine ?? this.machine;

                return machine.Price && machine.SuggestedPrice
                    ? Math.min(machine.Price, machine.SuggestedPrice)
                    : machine.Price ?? machine.SuggestedPrice;
            },
            suggestedPrice(machine) {
                machine = machine ?? this.machine;

                return machine.Price && machine.SuggestedPrice
                    ? Math.max(machine.Price, machine.SuggestedPrice)
                    : null;
            }
        }
    };
</script>

<style scoped>
    span {
        /* TODO: use a class utility instead */
        font-size: 0.9375rem;
    }

    .tab-pane {
        background-color: white;
    }

    .nav-tabs .nav-link {
        border: 0;
    }

    .thumbnail img {
        height: 75px;
        width: 75px;
    }
</style>
