﻿// @ts-check
import './search-config-types';
import constants from './constants';

/**
 * This file contains configuration data for Element sitewide search components.
 */

const defaultNewEqVendors = [constants.newEqTypeNames.DEERE, constants.newEqTypeNames.SHORTLINE];
const defaultPromotionsTypeName = constants.promotionsTypeNames.DEERE;

const configuredNewEqVendors =
    (constants.elementConfig.NEW_EQ_VENDORS?.split(',').map(x => constants.newEqTypeNames[x]) || []).filter(
        x => x && x.length > 0
    ) || [];

const configuredPromotionsVendors =
    constants.elementConfig.PROMOTIONS_VENDORS
        ?.split(',')
        .map(x => constants.promotionsTypeNames[x])
        .filter(x => x && x.length > 0) || [];

const activeNewEqTypeNames = configuredNewEqVendors.length > 0 ? configuredNewEqVendors : defaultNewEqVendors;

const activePromotionsTypeNames =
    configuredPromotionsVendors.length > 0 ? configuredPromotionsVendors : [defaultPromotionsTypeName];

/**
 * @type {IContentType[]}
 */
const activeNewEqContentTypes = activeNewEqTypeNames.map(typeName => ({
    description: 'New',
    typeName: typeName,
    resultsComponent: 'search-result-new-equipment'
}));

/**
 * @type {IContentType[]}
 */
const activePromotionsContentTypes = activePromotionsTypeNames.map(typeName => ({
    description: 'Special',
    typeName: typeName,
    resultsComponent: 'search-result-content'
}));

/**
 * @type {IContentType}
 */
const usedEqContentType = {
    description: 'Used',
    typeName: constants.contentTypeNames.USED_EQUIPMENT,
    resultsComponent: 'search-result-used-equipment'
};

/**
 * @type {IContentType}
 */
const testimonialContentType = {
    description: 'Testimonial',
    typeName: constants.contentTypeNames.TESTIMONIALS,
    resultsComponent: 'search-result-content'
};

/**
 * @type {IContentType}
 */
const locationContentType = {
    description: 'Location',
    typeName: constants.contentTypeNames.LOCATION,
    resultsComponent: 'search-result-content'
};

/**
 * @type {IContentType}
 */
const staffContentType = {
    description: 'Staff',
    typeName: constants.contentTypeNames.STAFF,
    resultsComponent: 'search-result-content'
};

/**
 * @type {IContentType}
 */
const blogPostContentType = {
    description: 'Post',
    typeName: constants.contentTypeNames.BLOG_POSTS,
    resultsComponent: 'search-result-content'
};

/**
 * @type {IContentType}
 */
const newsItemContentType = {
    description: 'Article',
    typeName: constants.contentTypeNames.NEWS,
    resultsComponent: 'search-result-content'
};

/**
 * @type {IContentType}
 */
const eventItemContentType = {
    description: 'Event',
    typeName: constants.contentTypeNames.EVENTS,
    resultsComponent: 'search-result-content'
};

/**
 * @type {IContentType}
 */
const pageContentType = {
    description: 'Page',
    typeName: constants.contentTypeNames.PAGES,
    resultsComponent: 'search-result-content'
};

/**
 * Data for the `eb-results` and `eb-suggestions-bar` components.
 * It maps all the allowed content types to a component to render the results.
 * @type {IContentType[]}
 */
const resultsContentTypes = [
    ...activeNewEqContentTypes,
    ...activePromotionsContentTypes,
    ...[
        // NOTE: staff not included here because there isn't a working detail route for employees
        blogPostContentType,
        eventItemContentType,
        locationContentType,
        newsItemContentType,
        pageContentType,
        testimonialContentType,
        usedEqContentType
    ]
];

/**
 * Options for the sort-by dropdown on search pages
 * @type {ISortBy[]}
 */
const sortByOptions = [
    {
        name: 'Default',
        scoringProfile: 'EquipmentFirst',
        sortExpression: 'Priority asc, search.score() desc'
    },
    {
        field: 'PublicationDate',
        fieldDirection: 'asc',
        name: 'Recent'
    },
    {
        field: 'Manufacturer',
        fieldDirection: 'asc',
        name: 'Manufacturer A-Z'
    },
    {
        field: 'Manufacturer',
        fieldDirection: 'desc',
        name: 'Manufacturer Z-A'
    }
];

/**
 * Scoring profile configuration for the search actions.
 * Passed on the ls-index component's :scoring-profiles prop.
 */
const scoringProfile = {
    name: 'EquipmentFirst',
    parameter: 'contentType',
    values: [...activeNewEqContentTypes, usedEqContentType].map(x => x.typeName)
};

/**
 * Passed to the `eb-index` component "default-filters" prop.
 * Defines what types of content may be returned from search or suggestions queries.
 * @type {IFilter[]}
 */
const defaultFilters = [
    {
        conditions: resultsContentTypes.map(x => ({
            expression: 'eq',
            value: x.typeName
        })),
        field: 'ContentType'
    },
    {
        conditions: [
            {
                expression: 'eq',
                value: null
            },
            {
                expression: 'gt',
                value: new Date().toISOString()
            }
        ],
        field: 'EndDate',
        fieldType: 'datetime'
    }
];

/**
 * Data for the `eb-static-filters-buttons-list` component "filters" prop.
 * Used to generate the list of buttons.
 * @type {IFilter[]}
 */
const contentFilters = [
    {
        conditions: activeNewEqContentTypes.map(t => ({
            expression: 'eq',
            value: t.typeName
        })),
        field: 'ContentType',
        description: 'New',
        readonly: true,
        iconClass: 'fa-tractor'
    },
    {
        conditions: [
            {
                expression: 'eq',
                value: usedEqContentType.typeName
            }
        ],
        field: 'ContentType',
        description: 'Used',
        iconClass: 'fa-tractor'
    },
    {
        conditions: [
            {
                filterType: 'search.in',
                value: 'Clearance'
            }
        ],
        description: 'Clearance',
        field: 'ResultTags',
        fieldType: 'collection'
    },
    {
        conditions: [
            {
                filterType: 'search.in',
                value: 'Coming Soon'
            }
        ],
        description: 'Coming Soon',
        field: 'ResultTags',
        fieldType: 'collection',
        iconClass: 'fa-clock'
    },
    {
        conditions: activePromotionsContentTypes.map(t => ({
            expression: 'eq',
            value: t.typeName
        })),
        field: 'ContentType',
        description: 'Offers & Discounts',
        readonly: true,
        iconClass: 'fa-certificate'
    },
    {
        conditions: [
            {
                expression: 'eq',
                value: constants.contentTypeNames.NEWS
            }
        ],
        field: 'ContentType',
        description: 'News',
        iconClass: 'fa-newspaper'
    },
    {
        conditions: [
            {
                expression: 'eq',
                value: constants.contentTypeNames.BLOG_POSTS
            }
        ],
        field: 'ContentType',
        description: 'Blogs',
        iconClass: 'fa-newspaper'
    },
    {
        conditions: [
            {
                expression: 'eq',
                value: constants.contentTypeNames.PAGES
            }
        ],
        field: 'ContentType',
        description: 'Pages',
        iconClass: 'fa-file-alt'
    },
    {
        conditions: [
            {
                expression: 'eq',
                value: constants.contentTypeNames.EVENTS
            }
        ],
        field: 'ContentType',
        description: 'Events',
        iconClass: 'fa-calendar-week'
    }
];

/**
 * Data for :filters prop on `ls-static-filters-dropdown` component.
 */
const contentTypeFilters = resultsContentTypes.map(t => ({
    conditions: [
        {
            expression: 'eq',
            value: t.typeName
        }
    ],
    field: 'ContentType',
    summary: t.description
}));

export default {
    activeNewEqContentTypes,
    activePromotionsContentTypes,
    contentFilters,
    contentTypeFilters,
    defaultFilters,
    locationContentType,
    staffContentType,
    usedEqContentType,
    testimonialContentType,
    blogPostContentType,
    newsItemContentType,
    eventItemContentType,
    pageContentType,
    resultsContentTypes,
    scoringProfile,
    sortByOptions
};
