<template>
    <div>
        <btn-icon
            btn-style="d-lg-none btn btn-primary btn-block p-2"
            data-toggle="modal"
            :data-target="`#${modalId}`"
            icon-left
            icon-right="fa-chevron-right"
            icon-color="#fff"
        >
            <i class="fas fa-tags mr-2"></i>
            Eligible Discounts
        </btn-icon>
        <button
            class="d-none d-lg-block btn btn-primary w-100 rounded-0"
            data-toggle="modal"
            :data-target="`#${modalId}`"
            type="button"
        >
            Eligible Discounts
        </button>
        <modal-pane
            :id="modalId"
            title="Eligible Discounts"
            class="byo-eligible-discounts-modal"
        >
            <div class="row m-0">
                <div v-for="(discount, i) in displayDiscounts" :key="i" class="col-12 col-md-6 mt-3">
                    <div class="card h-100">
                        <h5 v-if="discount.DiscountType == DISCOUNT_TYPES.FIXED" class="card-header">
                            {{ currencyFormat(discount.DiscountValue) }} off!
                        </h5>
                        <h5 v-else class="card-header">{{ discount.DiscountValue }}% off!</h5>
                        <div class="card-body d-flex flex-column">
                            <h4 class="card-title">{{ discount.Title }}</h4>
                            <p class="card-text">Available when adding these options:</p>
                            <div
                                v-for="(customization, j) in discount.Customizations"
                                :key="j"
                                class="badge badge-secondary d-block mb-2"
                            >
                                {{ customization.Name }}
                            </div>
                            <div v-if="discount.IsAdded" class="btn btn-primary mt-auto">Added</div>
                            <a
                                v-else
                                href="#"
                                class="btn btn-primary mt-auto"
                                @click="setOptions(discount.Customizations)"
                                >Add them for me</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </modal-pane>
    </div>
</template>

<script>
    import BtnIcon from '../utility/BtnIcon.vue';
    import { DISCOUNT_TYPES } from './constants';
    import ModalPane from '../utility/ModalPane';
    import Utilities from '../../lib/utilities';

    export default {
        name: 'BYOEligibleDiscounts',
        components: {
            BtnIcon,
            ModalPane
        },
        props: {
            discounts: {
                type: Array,
                required: true
            },
            modalId: {
                type: String,
                required: true
            },
            selectedCustomizations: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                DISCOUNT_TYPES
            };
        },
        computed: {
            displayDiscounts() {
                let allCustomizationsIds = this.selectedCustomizations.map(x => x.Id);
                let allDiscounts = Utilities.deepCopy(this.discounts);

                allDiscounts.forEach(discount => {
                    let discountCustomizationsIds = discount.Customizations.map(x => x.Id);
                    discount.IsAdded = discountCustomizationsIds.every(id => allCustomizationsIds.includes(id));
                });

                return allDiscounts;
            }
        },
        methods: {
            currencyFormat(number) {
                return Utilities.currencyFormat.format(number);
            },
            setOptions(customizations) {
                let customizationToAdd = customizations.filter(
                    x => !this.selectedCustomizations.some(y => x.Id == y.Id)
                );

                for (let customization of customizationToAdd) {
                    this.$parent.setOption(customization);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .byo-eligible-discounts-modal .badge:last-of-type {
        margin-bottom: 1rem !important;
    }
</style>
