﻿<template>
    <li>
        <!--OPEN NEXT LEVEL IF THE NODE HAS CHILDREN OTHERWISE NAVIGATE TO PAGE URL-->
        <a
            v-if="children && children.length"
            :class="{ 'top-level': parent == 'f669d9a7-009d-4d83-ddaa-000000000002' }"
            @click.stop.prevent="openLevel"
        >
            {{ title }}
        </a>
        <a v-else :class="{ 'top-level': parent == 'f669d9a7-009d-4d83-ddaa-000000000002' }" :href="url">
            {{ title }}
        </a>
        <!--/OPEN NEXT LEVEL IF THE NODE HAS CHILDREN OTHERWISE NAVIGATE TO PAGE URL-->
        <!--MENU LEVELS, PARENT PAGE LINK & BACKLINK-->
        <ul class="mobile-level" :class="{ 'mobile-level-open': levelOpen }" body-scroll-lock-ignore>
            <a class="parent-page-link" :href="url" target="_blank">
                <h2>{{ title }}</h2>
                <span class="close-menu" @click.stop.prevent="reset">
                    <i class="fa fa-times-circle"></i>
                </span>
            </a>
            <div class="mobile-back" @click.stop.prevent="backLink">
                Go Back
                <span>
                    <i class="fa fa-angle-right"></i>
                </span>
            </div>

            <!--RECURSIVE MOBILE MENU TO RENDER SUBPAGES-->
            <mobile-menu
                v-for="child in children"
                :key="child.Title"
                :children="child.children"
                :reset="reset"
                :title="child.Title"
                :url="child.Url.substr(1)"
            ></mobile-menu>
            <!--/RECURSIVE MOBILE MENU TO RENDER SUBPAGES-->
        </ul>
        <!--/MENU LEVELS, PARENT PAGE LINK & BACKLINK-->
    </li>
</template>

<script>
    export default {
        name: 'MobileMenu',
        props: ['children', 'title', 'url', 'reset', 'parent'],
        data() {
            return {
                levelOpen: false
            };
        },
        methods: {
            backLink() {
                this.levelOpen = false;
            },
            closeMenu() {
                this.open = false;
            },
            openLevel() {
                this.levelOpen = false;
                if (this.children) {
                    this.levelOpen = true;
                }
            }
        }
    };
</script>
