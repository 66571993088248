import auth from './AuthenticationService';
import axios from 'axios';

const dealerSettingsEndpoint = '/api/default/settings';

let getSetting = settingName => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${dealerSettingsEndpoint}?$filter=Title eq '${settingName}'`)
            .then(setting => {
                resolve(setting.data?.value[0] ?? '');
            })
            .catch(error => {
                console.error(error);
                reject(error);
            });
    });
};

let getSettingAsync = async settingName => {
    let response = await axios.get(`${dealerSettingsEndpoint}?$filter=Title eq '${settingName}'`);
    return response.data?.value[0] ?? '';
};

let upsertSetting = (settingName, settingValue) => {
    return new Promise(resolve => {
        getSetting(settingName).then(setting => {
            if (setting) {
                updateSetting(setting.Id, settingName, settingValue).then(result => {
                    resolve(result);
                });
            } else {
                createSetting(settingName, settingValue).then(result => {
                    resolve(result);
                });
            }
        });
    });
};

let upsertSettings = settings => {
    let promises = settings.map(setting => upsertSetting(setting.name, setting.value));

    return new Promise((resolve, reject) => {
        Promise.all(promises)
            .then(() => {
                resolve(true);
            })
            .catch(error => {
                console.error(error);
                reject(error);
            });
    });
};

let createSetting = (settingName, settingValue) => {
    return new Promise((resolve, reject) => {
        auth.getUser().then(user => {
            let config = {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            };

            axios
                .post(
                    `${dealerSettingsEndpoint}`,
                    {
                        Title: settingName,
                        Value: settingValue
                    },
                    config
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    });
};

let updateSetting = (settingId, settingName, settingValue) => {
    return new Promise((resolve, reject) => {
        auth.getUser().then(user => {
            let config = {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            };

            axios
                .patch(
                    `${dealerSettingsEndpoint}(${settingId})`,
                    {
                        Title: settingName,
                        Value: settingValue
                    },
                    config
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    });
};

export default {
    getSetting,
    getSettingAsync,
    upsertSetting,
    upsertSettings
};
