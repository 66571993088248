<template>
    <div class="card card-wide flex-row-reverse h-100 border-0">
        <img
            v-lazy-load
            class="object-fit-cover floa-right card-img rounded-0 d-none d-lg-block w-50"
            :data-url="image"
            :alt="title"
        />
        <img
            v-lazy-load
            class="object-fit-cover position-absolute card-img rounded-0 d-block d-lg-none"
            :data-url="mobileImage"
            :alt="title"
        />
        <div
            class="card-body d-flex flex-column justify-content-center align-items-center align-items-sm-start m-lg-0 w-100"
        >
            <h2 class="card-title text-primary">{{ title }}</h2>
            <p class="card-text">{{ description }}</p>
            <a v-if="enableLink" :href="actionUrl" :target="target" class="btn btn-primary">
                {{ actionName }}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            description: {
                type: String,
                default: ''
            },
            image: {
                type: String,
                required: true
            },
            mobileImage: {
                type: String,
                required: true
            },
            actionName: {
                type: String,
                default: ''
            },
            actionUrl: {
                type: String,
                default: ''
            },
            openLinkInNewTab: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            enableLink() {
                return this.actionName && this.actionUrl;
            },
            target() {
                return this.openLinkInNewTab ? '_blank' : '_self';
            }
        }
    };
</script>
