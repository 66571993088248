<template>
    <div class="mb-3 p-3 bg-light theme-dark__bg-gray-800 border rounded">
        <div class="row no-gutters">
            <!-- BADGES -->
            <div class="col-12 mb-1 skeleton">
                <div class="skeleton-item w-10 mb-2 rounded">&nbsp;</div>
            </div>
            <!-- IMAGE -->
            <div class="col-md-4 skeleton">
                <div class="skeleton-item py-5"></div>
            </div>
            <!-- TITLE & INFO -->
            <div class="col-md-6 my-3 my-md-0 pl-md-3">
                <content-skeleton :use-title="true" />
            </div>
            <div class="col-9 d-md-none"></div>
            <!-- BUTTON -->
            <div class="col-3 col-md-2 text-right skeleton">
                <div class="skeleton-item float-right py-3 rounded"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import ContentSkeleton from '../utility/ContentSkeleton';

    export default {
        name: 'CardHorizontalSkeleton',
        components: {
            ContentSkeleton
        }
    };
</script>
