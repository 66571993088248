<!-- Renders breadcrumbs from search facets on both list and detail pages -->
<template>
    <nav class="showroom-breadcrumbs d-none d-md-block border-bottom" aria-label="breadcrumb">
        <ol
            class="breadcrumb bg-transparent my-3 px-2 px-md-3 py-0 font-width-condensed font-size-sm text-uppercase"
        >
            <li class="breadcrumb-item">
                <router-link :to="{ name: listRouteName }">Home</router-link>
            </li>
            <li
                v-for="(item, i) in breadcrumbs"
                :key="i"
                :class="['breadcrumb-item', {active: isActive(i)}]"
            >
                <router-link v-if="!isActive(i)" :to="getLink(item.path)">{{item.title}}</router-link>
                <span v-else>{{item.title}}</span>
            </li>
        </ol>
    </nav>
</template>

<script>
    import showroomMixin from './ShowroomMixin.vue';
    export default {
        mixins: [showroomMixin],
        props: {
            item: {
                type: Object
            }
        },
        computed: {
            breadcrumbs() {
                let breadcrumbs = [];

                if (this.$_currentHierarchy) {
                    breadcrumbs = this.$_currentHierarchy[0].split('|').map((nodeTitle, i) => {
                        return {
                            title: nodeTitle,
                            path: this.$_currentHierarchy[1].split('|').slice(0, i + 1)
                        };
                    });

                    if (this.$_isItemPage && this.item) {
                        breadcrumbs.push({
                            title: this.item.Title,
                            path: []
                        });
                    }
                }

                return breadcrumbs;
            }
        },
        methods: {
            isActive(i) {
                return i === this.breadcrumbs.length - 1;
            },
            getLink(path) {
                if (!path || path.length === 0) {
                    return null;
                }
                return {
                    name: this.listRouteName,
                    params: this.listRouteParams.reduce((params, key, i) => ({ ...params, [key]: path[i] }), {})
                };
            }
        }
    };
</script>
