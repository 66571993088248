import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import dayjsObjectSupport from 'dayjs/plugin/objectSupport';
dayjs.extend(dayjsObjectSupport);
dayjs.extend(dayjsUTC);

let blogpostsAPI = '/api/default/blogposts';
let selectFields =
    'Id, Tags, Category, PublicationDate, DateCreated, Title, UrlName, Summary, Content, ParentId';

let getArchives = () => {
    let url = `${blogpostsAPI}?$select=PublicationDate&$orderby=PublicationDate desc`;
    return new Promise((resolve, reject) => {
        fetch(url)
            .then(response => response.json())
            .then(data => {
                let response = data.value.map(item => dayjs.utc(item.PublicationDate).format('MMM YYYY'));

                resolve(new Set(response));
            })
            .catch(error => reject(error));
    });
};

let getBlogpostFromId = blogpostId => {
    let url = `${blogpostsAPI}(${blogpostId})?$expand=HeroImage,Parent&$select=${selectFields}`;

    return new Promise((resolve, reject) => {
        fetch(url)
            .then(response => response.json())
            .then(data => resolve(data))
            .catch(error => reject(error));
    });
};

/**
 *
 * @param {number} [month] - Filters the blogposts by month. `year` must also be present
 * @param {number} [year] - Filters the blogposts by year. `month` must also be present
 * @param {string} [category] - Filters the blogposts by category id (GUID)
 * @param {number} [take] - Retrieves `take` number of blogposts
 */
let getPosts = (month, year, category, take = 0) => {
    let url = `${blogpostsAPI}?$expand=HeroImage,Parent&$select=${selectFields}&$orderby=PublicationDate desc`;
    url = take ? `${url}&$top=${take}` : url;

    if (month && year) {
        let dateStart = dayjs({
            day: 1,
            month,
            year
        }).format();

        let endStart = dayjs({
            year,
            month
        })
            .endOf('month')
            .format();

        url = `${url}&$filter=PublicationDate gt ${dateStart} and PublicationDate lt ${endStart}`;
    }

    url = category
        ? url.includes('$filter')
            ? `${url} and Category/any(x:x eq ${category})`
            : `${url}&$filter=Category/any(x:x eq ${category})`
        : url;

    return new Promise((resolve, reject) => {
        fetch(url)
            .then(response => response.json())
            .then(data => resolve(data))
            .catch(error => reject(error));
    });
};

export default {
    getArchives,
    getBlogpostFromId,
    getPosts
};
