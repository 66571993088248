﻿<template>
    <div class="container compare-container">
        <compare-equipment-skeleton v-if="isLoading"></compare-equipment-skeleton>
        <div v-else-if="machines.length === 0" class="row justify-content-center">
            <h3 class="mx-auto pt-5 pb-3 px-5">You have not selected machines to compare.</h3>
        </div>
        <eb-index
            v-show="!isLoading"
            :api-key="elementConfig.BLAZE_API_KEY"
            :default-filters="defaultFilters"
            :index-name="elementConfig.BLAZE_INDEX_NAME"
            :service-name="elementConfig.BLAZE_SERVICE_NAME"
            :persist-form-state="false"
        >
            <eb-suggestions
                id="search-input"
                class="w-100 mb-3"
                input-class="form-control text-center"
                placeholder="SEARCH EQUIPMENT TO COMPARE"
                select-fields="Title, DataSource, ResultImages"
            >
                <template #default="slotProps">
                    <div
                        class="suggestions-results overflow-hidden cursor-pointer position-absolute bg-gray-200 w-100 rounded shadow"
                    >
                        <div
                            v-for="(result, i) in slotProps.results"
                            :key="i"
                            class="suggestions-result border d-flex align-items-center p-2"
                            @click="slotProps.onSuggestionClick(result)"
                        >
                            <img
                                v-if="result['ResultImages'] && result['ResultImages'].length"
                                :src="result['ResultImages'].split(',')[0]"
                                class="border rounded bg-white"
                                width="100"
                            />
                            <span class="font-weight-bold ml-2 flex-grow-1">{{ result['Title'] }}</span>
                            <img
                                v-if="getManufacturerLogo(result['DataSource'])"
                                :src="getManufacturerLogo(result['DataSource'])"
                                class="mr-2"
                                width="35"
                            />
                        </div>
                    </div>
                </template>
            </eb-suggestions>
        </eb-index>

        <table v-if="machines.length" class="table table-striped table-bordered">
            <thead class="bg-light theme-dark__bg-gray-800">
                <tr class="d-flex">
                    <th class="d-flex align-items-center" scope="col">
                        <h4>Specs &amp; Compare</h4>
                    </th>
                    <th
                        v-for="(machine, i) in displayMachines"
                        :key="`header${i}`"
                        scope="col"
                        class="position-relative d-flex flex-wrap"
                    >
                        <a :href="getMachineFullUrl(machine)" class="w-100">
                            <element-image
                                :src="getMachineImage(machine)"
                                class="justify-content-center"
                                img-class="card-img object-fit-cover"
                            />
                        </a>
                        <a :href="getMachineFullUrl(machine)" class="align-self-end">
                            <h4 class="mt-2 text-center">{{ machine.Title }}</h4>
                        </a>
                        <i class="remove-machine-button fa fa-times-circle p-2" @click="remove(machine)"></i>
                    </th>
                    <template v-if="machines.length < compareColumnsCount">
                        <th
                            v-for="j in compareColumnsCount - machines.length"
                            :key="`additional-header${j}`"
                            scope="col"
                        ></th>
                    </template>
                </tr>
            </thead>
            <tbody>
                <tr class="d-flex">
                    <td class="font-weight-bold">Price</td>
                    <td v-for="(machine, i) in displayMachines" :key="`body${i}`" scope="col">
                        <small class="d-block muted">AS LOW AS</small>
                        <s
                            v-if="
                                machine.ListPrice &&
                                machine.StartingPrice &&
                                machine.ListPrice != machine.StartingPrice
                            "
                            class="text-danger"
                            >{{ currencyFormat(machine.ListPrice) }}</s
                        >
                        <div v-if="machine.StartingPrice || machine.ListPrice" class="font-weight-bold">
                            {{ currencyFormat(machine.StartingPrice || machine.ListPrice) }}
                        </div>
                        <span v-else class="font-weight-bold">Call For Price</span>
                    </td>
                    <template v-if="machines.length < compareColumnsCount">
                        <td
                            v-for="j in compareColumnsCount - machines.length"
                            :key="`additional-body${j}`"
                            scope="col"
                        ></td>
                    </template>
                </tr>
                <tr v-for="(keySpec, i) in keySpecs" :key="i" class="d-flex">
                    <td class="font-weight-bold">{{ keySpec }}</td>
                    <td
                        v-for="machine in displayMachines"
                        :key="machine.Id"
                        scope="col"
                        v-html="getMachineKeySpecValue(machine, keySpec)"
                    ></td>
                    <template v-if="machines.length < compareColumnsCount">
                        <td
                            v-for="j in compareColumnsCount - machines.length"
                            :key="`additional-body${j}`"
                            scope="col"
                        ></td>
                    </template>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import CompareEquipmentUtilities from './CompareEquipmentUtilities';
    import constants from '../../config/constants';
    import { currencyFormat } from '../../lib/utilities';
    import { mapGetters } from 'vuex';

    import CompareEquipmentSkeleton from './CompareEquipmentSkeleton.vue';
    import NewEquipmentMixin from '../mixins/NewEquipmentMixin';
    import SavedMachineService from '../../../services/SavedMachineService';
    import StoreConstants from '../../store/constants';

    export default {
        name: 'CompareEquipment',
        components: {
            CompareEquipmentSkeleton
        },
        mixins: [NewEquipmentMixin],
        data() {
            return {
                elementConfig: constants.elementConfig,
                isLoading: true,
                machines: [],
                searchedEquipment: null,
                windowWidth: document.documentElement.clientWidth
            };
        },
        computed: {
            defaultFilters() {
                let allMachineCTPs = Object.values(constants.mappings)
                    .reduce((result, value) => {
                        if (value.machine) {
                            result.push(value.machine);
                        }

                        return result;
                    }, [])
                    .map(x => x.type);

                return [
                    {
                        field: 'ContentType',
                        conditions: allMachineCTPs.map(ctp => ({
                            expression: 'eq',
                            value: ctp
                        }))
                    },
                    {
                        field: 'Title',
                        conditions: [
                            {
                                expression: 'ne',
                                value: null
                            }
                        ]
                    },
                    {
                        field: 'DataSource',
                        conditions: [
                            {
                                expression: 'ne',
                                value: null
                            }
                        ]
                    }
                ];
            },
            displayMachines() {
                return this.machines.slice(0, this.compareColumnsCount);
            },
            compareColumnsCount() {
                return this.windowWidth >= 768 ? 3 : 2;
            },
            keySpecs() {
                let keys = this.displayMachines
                    .filter(machine => machine.KeySpecs)
                    .map(machine => machine.KeySpecs.map(spec => spec.key.toLowerCase()))
                    .flat()
                    .map(key => `${key[0].toUpperCase()}${key.slice(1)}`);

                return [...new Set(keys)].sort();
            },
            ...mapGetters({ savedEquipmentMachines: StoreConstants.EQUIPMENT.GETTERS.SAVED_EQUIPMENT_MACHINES })
        },
        watch: {
            savedEquipmentMachines: function() {
                this.loadMachines();
            },
            searchedEquipment: function(equipment) {
                this.saveMachine(equipment, equipment.DataSource);
            }
        },
        created() {
            this.loadMachines();
            this.$nextTick(() => {
                window.addEventListener('resize', this.setWindowSize);
            });

            this.$store.subscribe((mutation, state) => {
                if (mutation.type === StoreConstants.SEARCH.MUTATIONS.APPLY_SEARCH_RESPONSE) {
                    if (state.search.searchTerms && state.search.searchResponse.value.length) {
                        this.searchedEquipment = state.search.searchResponse.value[0];
                        this.$store.commit(StoreConstants.SEARCH.MUTATIONS.RESET, state);
                    }
                }
            });
        },
        methods: {
            loadMachines() {
                this.isLoading = true;
                this.machines = [];

                const compareItems = this.savedEquipmentMachines.filter(x => x.Compare);
                const dataSources = [...new Set(compareItems.map(x => x.DataSource))];

                if (!dataSources.length) {
                    this.isLoading = false;
                }

                dataSources.forEach(dataSource => {
                    const machineIds = compareItems
                        .filter(x => x.DataSource == dataSource)
                        .map(x => x.OriginalItemId);

                    if (machineIds.length) {
                        CompareEquipmentUtilities.getCompareEquipmentByIds(dataSource, machineIds)
                            .then(result => (this.machines = this.machines.concat(result)))
                            .finally(() => (this.isLoading = false));
                    }
                });
            },
            currencyFormat(number) {
                return currencyFormat.format(number);
            },
            remove(machine) {
                SavedMachineService.toggleCompare(machine);
            },
            getManufacturerLogo(dataSource) {
                return constants.manufacturerLogos[dataSource.toUpperCase()];
            },
            getMachineImage(machine) {
                return (
                    machine.Thumbnail ||
                    machine.Images?.split(',')[0] ||
                    machine.ImageUrl?.split(',')[0] ||
                    this.savedEquipmentMachines
                        .find(x => x.OriginalItemId === machine.OriginalItemId)
                        .Images?.split(',')[0] ||
                    constants.equipmentPlaceholderImage
                );
            },
            getMachineFullUrl(machine) {
                return this.savedEquipmentMachines.find(x => x.OriginalItemId === machine.OriginalItemId).FullUrl;
            },
            getMachineKeySpecValue(machine, keySpec) {
                return machine.KeySpecs?.find(spec => spec.key.toLowerCase() === keySpec.toLowerCase())?.value;
            },
            setWindowSize() {
                this.windowWidth = document.documentElement.clientWidth;
            }
        }
    };
</script>
