<template>
    <div>
        <location-list-dropdown :rounded="roundedCallUsButton"></location-list-dropdown>
        <ul class="d-flex align-items-center menu-bricks">
            <li v-if="contactSettings.CustomerPortalLink && !contactSettings.HideCustomerPortalButton">
                <a :href="contactSettings.CustomerPortalLink" :class="navLinkClasses" target="_blank">
                    Customer Portal
                </a>
            </li>
            <li v-if="!contactSettings.HideEmailUsButton">
                <a :href="contactSettings.storeEmailLink" :class="navLinkClasses" target="_blank">
                    {{ contactSettings.CustomEmailLabelText || 'Contact Us' }}
                </a>
            </li>
            <li v-for="page in additionalPages" :key="page.FullUrl" class="border-0-kevin">
                <a :href="page.FullUrl" :class="navLinkClasses">
                    <span class="d-none d-xl-inline-block">{{ page.Title.Value }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import constants from '../../../config/constants';

    import LocationListDropdown from '../LocationListDropdown';

    export default {
        name: 'LeftBrowLinks',
        components: {
            LocationListDropdown
        },
        props: {
            additionalPages: {
                type: Array,
                default: () => []
            },
            contactSettings: {
                type: Object,
                default: () => ({})
            },
            roundedCallUsButton: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                elementConfig: constants.elementConfig
            };
        },
        computed: {
            location() {
                return this.$_selectedLocation;
            },
            navLinkClasses() {
                let classes = 'd-flex align-items-center h-100 ';
                return classes + (this.$_isLightTheme ? 'text-gray-500' : 'text-gray-400');
            },
            storeEmailLink() {
                const email = this.location?.Email ?? this.elementConfig.EMAIL;
                return 'mailto:' + email + '?subject=' + (this.contactSettings.CustomEmailSubjectLineText || '');
            }
        }
    };
</script>

