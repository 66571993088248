<template>
    <div class="mb-4">
        <div class="skeleton skeleton-inline">
            <div class="bg-white w-25 p-3"><h4 class="my-auto">Specs &amp; Compare</h4></div>
            <div v-for="i in 3" :key="i" class="ml-1 mb-0 py-5 w-25 skeleton-item"></div>
        </div>
        <div v-for="i in 3" :key="i" class="skeleton skeleton-inline mt-1">
            <div class="bg-white w-25"></div>
            <div v-for="j in 3" :key="j" class="ml-1 mb-0 py-4 w-25 skeleton-item"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CompareEquipmentSkeleton'
    };
</script>
