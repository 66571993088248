<template>
    <div class="d-md-none">
        <!-- modal trigger -->
        <button
            data-toggle="modal"
            data-target="#machine-suggester-summary-modal"
            class="summary-button bg-light text-primary px-1 py-4 position-absolute cursor-pointer font-weight-bold"
            :class="{'invisible': answeredQuestions.length == 0}"
        >SUMMARY</button>

        <div id="machine-suggester-summary-modal" class="modal fade" tabindex="-1">
            <div class="modal-dialog h-100 modal-dialog-scrollable">
                <div class="modal-content rounded-0 p-4">
                    <div class="modal-header justify-content-between align-items-center mb-3">
                        <div class="text-dark" data-dismiss="modal">
                            <i class="fa fa-arrow-left"></i>
                        </div>
                        <div class="h4 mb-0 text-primary">SUMMARY</div>
                        <div class="invisible">&nbsp;</div>
                    </div>
                    <div class="modal-body p-0 overflow-hidden">
                        <summary-content :answered-questions="answeredQuestions" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SummaryContent from './MachineSuggesterSummaryContent';

    export default {
        name: 'MachineSuggesterSummaryModal',
        components: {
            SummaryContent
        },
        props: {
            answeredQuestions: {
                type: Array,
                required: true
            }
        }
    };
</script>
