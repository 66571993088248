<script>
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc'; // load on demand
    import advancedFormat from 'dayjs/plugin/advancedFormat';

    dayjs.extend(utc); // use plugin
    dayjs.extend(advancedFormat);

    export default {
        computed: {
            year() {
                return dayjs.utc(this.event.DateCreated).format('YYYY');
            },
            month() {
                return dayjs.utc(this.event.DateCreated).format('MM');
            },
            day() {
                return dayjs.utc(this.event.DateCreated).format('DD');
            }
        },
        methods: {
            getDate(date) {
                return dayjs.utc(date).format('MMM. D, YYYY');
            },
            eventTime(time) {
                return dayjs.utc(time).format('hh:mm:ss A');
            },
            eventMonth(start) {
                return dayjs.utc(start).format('MMMM');
            },
            eventDay(start) {
                return dayjs.utc(start).format('dddd');
            },
            eventDate(start) {
                return dayjs.utc(start).format('Do');
            },
            getYear(ev) {
                return dayjs.utc(ev.DateCreated).format('YYYY');
            },
            getMonth(ev) {
                return dayjs.utc(ev.DateCreated).format('MM');
            },
            getDay(ev) {
                return dayjs.utc(ev.DateCreated).format('DD');
            }
        }
    };
</script>
