import NewEquipmentService from '../../../services/NewEquipmentService';
import UsedEquipmentService from '../../../services/UsedEquipmentService';

const DEERE_KEY_SPEC_KEY = 'Key Specs';
const DEERE_DRIVELINE_KEY = 'DRIVELINE';
const ZERO_WIDTH_SPACE = /\u200B/g;

const getCompareEquipmentByIds = (dataSource, machineIds) => {
    switch (dataSource) {
        case 'used_equipment':
            return new Promise((resolve, reject) => {
                UsedEquipmentService.GetMachinesByIds(
                    machineIds,
                    [
                        'Id',
                        'Description',
                        'Horsepower',
                        'Images',
                        'LocationNotes',
                        'OperationHours',
                        'Price',
                        'Title'
                    ],
                    true
                )
                    .then(response => {
                        let usedMachines = response;
                        usedMachines.forEach(machine => {
                            machine.KeySpecs = getKeySpecs(machine, dataSource);
                            machine.ListPrice = machine.Price;
                            machine.OriginalItemId = machine.Id;
                        });

                        resolve(usedMachines);
                    })
                    .catch(reason => reject(reason));
            });
        case 'shortline':
            return new Promise((resolve, reject) => {
                NewEquipmentService.getNewEquipmentByIds(dataSource, null, machineIds, [
                    'Id',
                    'MachineTitle',
                    'ImageUrl',
                    'MachinePrice',
                    'MachineSpecs'
                ])
                    .then(response => {
                        let newMachines = response;
                        newMachines.map(machine => {
                            machine.Images = machine.ImageUrl;
                            machine.KeySpecs = getKeySpecs(machine, dataSource);
                            machine.ListPrice = machine.MachinePrice;
                            machine.OriginalItemId = machine.Id;
                            machine.Title = machine.MachineTitle;
                            machine.StartingPrice = machine.MachinePrice;
                            machine.Specifications = machine.MachineSpecs;
                        });

                        resolve(newMachines);
                    })
                    .catch(reason => reject(reason));
            });
        default:
            return new Promise((resolve, reject) => {
                NewEquipmentService.getNewEquipmentByIds(dataSource, null, machineIds, [
                    'Id',
                    'Title',
                    'Features',
                    'Images',
                    'ListPrice',
                    'StartingPrice',
                    'Specifications'
                ])
                    .then(response => {
                        let newMachines = response;
                        newMachines.map(machine => {
                            machine.KeySpecs = getKeySpecs(machine, dataSource);
                            machine.OriginalItemId = machine.Id;
                        });

                        resolve(newMachines);
                    })
                    .catch(reason => reject(reason));
            });
    }
};

/**
 * Gets the machine comparsion specifications as a dictionary
 * @param {string} machine
 * @param {string} datasource
 */
const getKeySpecs = (machine, dataSource) => {
    switch (dataSource) {
        case 'caseih': {
            if (!machine.Specifications) {
                return;
            }

            let machineSpecs = document.createElement('div');
            machineSpecs.innerHTML = machine.Specifications;

            let machineKeySpecs = Array.from(machineSpecs.querySelectorAll('li')).reduce(
                (result, keySpec) => {
                    let keyValue = keySpec.innerText?.split(':');

                    if (keyValue?.length == 2) {
                        result.push({
                            key: keyValue[0].replace(ZERO_WIDTH_SPACE, '').trim(),
                            value: keyValue[1].trim()
                        });
                    }

                    return result;
                },
                []
            );

            return machineKeySpecs;
        }
        case 'deere': {
            if (!machine.Specifications) {
                return;
            }

            let machineSpecs = document.createElement('div');
            machineSpecs.innerHTML = machine.Specifications;

            let machineKeySpecs = Array.from(machineSpecs.querySelectorAll('h4')).find(
                x => x.innerText === DEERE_KEY_SPEC_KEY || x.innerText === DEERE_DRIVELINE_KEY
            )?.nextSibling;

            if (!machineKeySpecs) {
                return;
            }

            let keys = Array.from(machineKeySpecs.childNodes)
                .filter(x => x.tagName === 'DT' && x.nextSibling.innerText)
                .map(x => ({ key: x.innerText.trim(), value: x.nextSibling.innerHTML?.trim() }));

            return keys;
        }
        case 'kubota': {
            if (!machine.Features) {
                return;
            }

            let machineSpecs = document.createElement('div');
            machineSpecs.innerHTML = machine.Features;

            let machineKeySpecs = Array.from(machineSpecs.querySelectorAll('.kubota-feature-name')).reduce(
                (result, keySpec) => {
                    let key = keySpec?.innerText?.trim();
                    let value = keySpec.nextSibling?.innerHTML?.trim();

                    if (key && value) {
                        result.push({ key, value });
                    }
                    return result;
                },
                []
            );

            return machineKeySpecs;
        }
        case 'new_holland_ag':
        case 'new_holland_construction':
        case 'stihl': {
            if (!machine.Specifications) {
                return;
            }

            let machineSpecs = document.createElement('div');
            machineSpecs.innerHTML = machine.Specifications;

            let machineKeySpecs = Array.from(machineSpecs.querySelectorAll('table tbody tr')).reduce(
                (result, tr) => {
                    let keySpec = tr.querySelector('td');
                    let key = keySpec?.innerText?.trim();
                    let value =
                        keySpec?.nextSibling?.innerText?.trim() ??
                        keySpec?.nextElementSibling?.innerText?.trim();

                    if (key && value) {
                        result.push({ key, value });
                    }

                    return result;
                },
                []
            );

            return machineKeySpecs;
        }
        case 'massey_ferguson': {
            return machine.Specifications
                ? [
                      {
                          key: 'Specs',
                          value: machine.Specifications
                      }
                  ]
                : undefined;
        }
        case 'shortline': {
            if (!machine.MachineSpecs) {
                return;
            }

            let machineSpecs = document.createElement('div');
            machineSpecs.innerHTML = machine.MachineSpecs;

            let value = machineSpecs.querySelector('ul');
            return value
                ? [
                      {
                          key: 'Specs',
                          value: value.innerHTML
                      }
                  ]
                : undefined;
        }
        case 'used_equipment': {
            let result = [];

            machine.Description && result.push({ key: 'Description', value: machine.Description });
            machine.Horsepower && result.push({ key: 'Horsepower', value: machine.Horsepower });
            machine.OperationHours && result.push({ key: 'Operation Hours', value: machine.OperationHours });
            (machine.Location?.DealerLocationTitle || machine.LocationNotes) &&
                result.push({
                    key: 'Location',
                    value: machine.Location?.DealerLocationTitle || machine.LocationNotes
                });

            return result;
        }
        case 'vermeer': {
            if (!machine.Specifications) {
                return;
            }

            let machineSpecs = document.createElement('div');
            machineSpecs.innerHTML = machine.Specifications;

            let machineKeySpecs = Array.from(machineSpecs.querySelectorAll('ul li h3')).reduce(
                (result, keySpec) => {
                    let key = keySpec.innerText?.trim();
                    let value = keySpec.nextSibling?.innerHTML ?? keySpec.nextElementSibling?.innerHTML;

                    if (key && value) {
                        result.push({ key, value });
                    }

                    return result;
                },
                []
            );

            return machineKeySpecs;
        }
    }
};

export default {
    getCompareEquipmentByIds,
    getKeySpecs
};
